import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { Form, Container, Row, Col } from 'react-bootstrap';
import { nations } from '@/utils/nations';
import { getCity } from '../../actions/getCity';
import { getCities } from '../../actions/getCities';
import { eshopMothlyOrdersLabels } from '@/utils/utilities';
import { debounce } from '@material-ui/core';

const GeneralData = ({ elem, setElem }) => {

  const dispatch = useDispatch();
  const [item, setItem] = useState(elem);
  const info = item?.info;
  const eshopData = item?.eshopData;
  const showcaseData = eshopData?.showcaseData;
  const structuredData = eshopData?.structured_data;
  const registrationAddress = eshopData?.registration_address;
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    let val = type === 'checkbox' ? (checked ? 1 : 0) : value;
    const keys = name.split('[').map(key => key.replace(']', ''));
    setItem((prevState) => {
      let newState = { ...prevState };
      let currentPart = newState;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!currentPart[keys[i]]) {
          currentPart[keys[i]] = {};
        }
        currentPart = currentPart[keys[i]];
      }
      currentPart[keys[keys.length - 1]] = val;
      return newState;
    });
    setElem(item);
  };

  const handleChangeSelect = debounce((inputValue) => {
    setInputValue(inputValue);
  }, 500);

  const handleSelected = (option) => {
    setSelectedOption(option);
    registrationAddress.municipality_id = option.value;
  };

  useEffect(() => {
    if (inputValue.length >= 2) {
      dispatch(getCities({ search: inputValue })).then((data) => {
        const items = data.payload.map((item) => {
          return { value: item.value, label: item.label };
        });
        setOptions(items);
      });
    }
  }, [inputValue]);

  useEffect(() => {
    if (elem) {
      setItem(elem);
      const cityId = elem.eshopData?.registration_address?.municipality_id
      if (cityId) {
        dispatch(getCity({ id: cityId })).then((data) => {
          setSelectedOption({ value: data.payload.id, label: data.payload.fullName });
        });
      }
    }
  }, [elem]);

  return (
    <Container>
      <Row>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="eshopData_url">
            <Form.Label>Indirizzo web</Form.Label>
            <Form.Control name="eshopData[url]" value={eshopData?.url} type="text" onChange={handleChange} />
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="eshopData_email">
            <Form.Label>Email</Form.Label>
            <Form.Control name="eshopData[email]" value={eshopData?.email} type="text" onChange={handleChange} />
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="structuredData_pec">
            <Form.Label>Pec</Form.Label>
            <Form.Control name="eshopData[structured_data][pec]" value={structuredData?.pec} type="text" onChange={handleChange} />
          </Form.Group>
        </Col>
        {
          /*
            <Col xs={12} lg={6} className='my-3'>
              <Form.Group controlId="showcaseData_eshop_name">
                <Form.Label>Ragione sociale</Form.Label>
                <Form.Control name="eshopData[showcaseData][eshop_name]" value={showcaseData?.eshop_name} type="text" onChange={handleChange} />
              </Form.Group>
            </Col>
          */
        }
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="structuredData_p_iva">
            <Form.Label>P.Iva</Form.Label>
            <Form.Control name="eshopData[structured_data][p_iva]" value={structuredData?.p_iva} type="text" onChange={handleChange} />
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="structuredData_sdi">
            <Form.Label>Codice SDI</Form.Label>
            <Form.Control name="eshopData[structured_data][sdi]" value={structuredData?.sdi} type="text" onChange={handleChange} />
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="info_name">
            <Form.Label>Nome responsabile</Form.Label>
            <Form.Control name="info[name]" value={info?.name} type="text" onChange={handleChange} />
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="info_surname">
            <Form.Label>Cognome responsabile</Form.Label>
            <Form.Control name="info[surname]" value={info?.surname} type="text" onChange={handleChange} />
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="structuredData_c_fiscale">
            <Form.Label>Codice fiscale</Form.Label>
            <Form.Control name="eshopData[structured_data][c_fiscale]" value={structuredData?.c_fiscale} type="text" onChange={handleChange} />
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="info_phone">
            <Form.Label>Telefono principale</Form.Label>
            <Form.Control name="info[phone]" value={info?.phone} type="text" onChange={handleChange} />
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="structured_data_urlAff">
            <Form.Label>Url programma di affiliazione</Form.Label>
            <Form.Control name="eshopData[structured_data][urlAff]" value={structuredData?.urlAff} type="text" onChange={handleChange} />
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="registration_address_nation">
            <Form.Label>Nazione</Form.Label>
            <Form.Control name="eshopData[registration_address][nation]" value={registrationAddress?.nation} as="select" onChange={handleChange}>
              {
                Object.keys(nations).map((key) => {
                  return <option key={key} value={key}>{nations[key]}</option>
                })
              }
            </Form.Control>
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="registration_address_municipality_id">
            <Form.Label>Comune</Form.Label>
            <Select
              name="eshopData[registration_address][municipality_id]"
              options={options}
              value={selectedOption}
              placeholder="Ricerca comune..."
              onInputChange={handleChangeSelect}
              onChange={(selectedOption) => handleSelected(selectedOption)}
            />
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="registration_address_address">
            <Form.Label>Indirizzo</Form.Label>
            <Form.Control name="eshopData[registration_address][address]" value={registrationAddress?.address} type="text" onChange={handleChange} />
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="registration_address_zip_code">
            <Form.Label>Cap</Form.Label>
            <Form.Control name="eshopData[registration_address][zip_code]" value={registrationAddress?.zip_code} type="text" onChange={handleChange} />
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="structuredData_monthly_orders">
            <Form.Label>Vendite mensili</Form.Label>
            <Form.Control name="eshopData[structured_data][monthly_orders]" value={structuredData?.monthly_orders} as="select" onChange={handleChange}>
              {
                Object.keys(eshopMothlyOrdersLabels).map((key) => {
                  return <option key={key} value={key}>{eshopMothlyOrdersLabels[key]}</option>
                })
              }
            </Form.Control>
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="structured_data_is_marketplace">
            <Form.Check
              id={'is_marketplace_check'}
              name="eshopData[structured_data][is_marketplace]"
              checked={structuredData?.is_marketplace == 1}
              type="checkbox"
              label={'Cliente di un marketplace'}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
};

export default GeneralData;