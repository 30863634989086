import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import FilterForm from './components/filters/filterLinkProducts';
import { Col, Container, Row, Spinner, Modal, Button, } from 'react-bootstrap';
import { Card, CardBody, CardTitleWrap } from '@/shared/components/Card';
import { Table } from '@/shared/components/TableElements';
import styled from 'styled-components';
import { cleanDate } from '@/shared/helpers';
import { colorAccent } from '@/utils/palette';
import { fetchLink } from './actions/fetchLink';
import LinkItemList from './components/LinkItemList';
import PaginationTable from '@/containers/Components/PaginationTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import LinkForm from './forms/linkForm';

const LinkTable = ({ prod_id = false }) => {
  const dispatch = useDispatch();
  const itemsState = useSelector(state => state.esastore.fetchLink)
  const items = itemsState.entities;
  const loading = itemsState.loading;
  const pagination = itemsState.pagination;
  const TableSpinner = styled(Spinner)`color: ${colorAccent};`;
  const tableLabels = prod_id
    ? ['Nome', 'Prezzo', 'Cadenza', 'Rinnovo', 'Data crazione', 'Paypal', '']
    : ['Riferimento Prodotto', 'Nome', 'Prezzo', 'Cadenza', 'Rinnovo', 'Data crazione', 'Paypal', '']
  const [globalFilters, setGlobalFilters] = useState({ 'per-page': 25, page: 1 });
  const [toRefresh, setToRefresh] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleOpen = () => {
    setShowModal(true);
  }

  const handleClose = () => {
    setShowModal(false);
  }

  const handleFilterSubmit = (values) => {

    let modifiedValues = { ...values };
    delete modifiedValues.creation_date_start;
    delete modifiedValues.creation_date_end;

    if (values.creation_date_start || values.creation_date_end) {
      modifiedValues.creation_date = {
        start: values.creation_date_start ? cleanDate(values.creation_date_start) : null,
        end: values.creation_date_end ? cleanDate(values.creation_date_end) : null,
      }
    }

    if (values.period) {
      modifiedValues.period = values.period.value;
    }

    if (values.renew) {
      modifiedValues.renew = values.renew.value;
    }

    setGlobalFilters(modifiedValues);

    modifiedValues.page = pagination.current_page;
    modifiedValues['per-page'] = 25

    const payload = {
      params: modifiedValues
    };

    dispatch(fetchLink(payload));
  };

  useEffect(() => {
    if (toRefresh) {
      setToRefresh(false);
    }
    const initialPayload = {
      params: globalFilters
    };

    dispatch(fetchLink(initialPayload));
  }, [dispatch, toRefresh]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const action = urlParams.get('action');
    if (action) {
      switch (action) {
        case 'create':
          handleOpen();
          break;
        default:
          break;
      }
    }
  }, []);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Elenco Link Prodotto</h3>
        </Col>
      </Row>
      <FilterForm onSubmit={handleFilterSubmit} showPoductFilter={prod_id ? false : false} />
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <CardTitleWrap>
                <Row className='align-items-center'>
                  <Col xs={12} lg={4}>
                    <p className="page-subtitle">Numero elementi: {pagination?.total_count}</p>
                  </Col>
                  <Col xs={12} lg={8} className='text-end'>
                    <Button onClick={() => handleOpen()} variant="outline-primary" size='sm'>
                      <FontAwesomeIcon icon={faPlus} className="me-2" />
                      <span>Aggiungi link</span>
                    </Button>
                  </Col>
                </Row>
              </CardTitleWrap>
              {loading === 'loading' ? (
                <div className="text-center">
                  <TableSpinner animation="border" />
                </div>
              ) : (
                <>
                  <Table responsive striped bordered>
                    <thead>
                      <tr>
                        {tableLabels.map((label) => {
                          return <th>{label}</th>
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {items && loading === 'idle' && items.length > 0 && (
                        items.map((elem) => {
                          return (<LinkItemList item={elem} showParent={prod_id ? false : true} refreshTable={setToRefresh} />)
                        })
                      ) || (
                          <tr>
                            <td colSpan={tableLabels.length} className='text-start'>Nessun elemento trovato</td>
                          </tr>
                        )}
                    </tbody>
                  </Table>
                  {items && items.length > 0 && (
                    <PaginationTable pagination={pagination} filters={globalFilters} action={fetchLink} />
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal show={showModal} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Aggiungi Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LinkForm prod_id={prod_id} refreshTable={setToRefresh} setShowModal={setShowModal} />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

LinkTable.propTypes = {
  onSubmit: PropTypes.func,
};

export default LinkTable;