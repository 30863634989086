import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';
import endpoint from '../endpoints/eshopBlocklist';

export const postEshopBlocklistData = createAsyncThunk(
    'eshopBlocklist/postEshopBlocklistData',
    async (userData) => {
        const response = await api.post(endpoint.insert, userData);
        return response.data;
    }
);