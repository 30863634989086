import { createSlice } from '@reduxjs/toolkit';
import { fetchEshopReplies } from '../actions/fetchEshopReplies';

export const eshopReplies = createSlice({
    name: 'eshopReplies',
    initialState: {
        items: [],
        loading: false,
        error: null,
        pagination: {
            total_count: 0,
            page_count: 0,
            current_page: 0,
            per_page: 0,
            next_page_url: null,
            prev_page_url: null,
        }
    },
    reducers: {

    },
    extraReducers: {
        [fetchEshopReplies.pending]: (state) => {
            state.loading = 'loading';
            state.error = null;
        },
        [fetchEshopReplies.fulfilled]: (state, action) => {
            console.log("action", action);
            state.items = action.payload.items;
            state.pagination = action.payload.pagination;
            state.loading = 'idle';
            console.log("state", state);
        },
        [fetchEshopReplies.rejected]: (state, action) => {
            state.error = action.payload;
            state.loading = 'idle';
        },
    },
});

export const { reviewApproved, reviewRejected } = eshopReplies.actions;

export default eshopReplies.reducer;
