// reducers.js

import { createSlice } from '@reduxjs/toolkit';
import { postCatalogoPianiData } from '../actions/insertCatalogoItem';

const insertCatalogoItemSlice = createSlice({
    name: 'catalogoItems',
    initialState: { plans: {}, status: 'idle', error: null },
    reducers: {
        // Potrebbe avere eventuali altri reducer qui
    },
    extraReducers: (builder) => {
        builder
            .addCase(postCatalogoPianiData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(postCatalogoPianiData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // Aggiungi l'utente al stato
                state.plans = action.payload;
            })
            .addCase(postCatalogoPianiData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export const { /* other action creators, if any */ } = insertCatalogoItemSlice.actions;

export default insertCatalogoItemSlice.reducer;
