import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../config/api';

export const updateImageReview = createAsyncThunk(
    'reviews/manage-review-media',
    async ({ id, data }) => {
        console.log("Invio dettagli: ", { id, data }); // Log dettagliato
        const response = await api.post(`v1/reviews/manage-review-media/${id}`, data);
        console.log("Risposta del server: ", response.data); // Log della risposta
        return response.data;
    }
);