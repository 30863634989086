import React from 'react';
import { Container } from 'react-bootstrap';
import Badge from '@/shared/components/Badge';

const ReviewAlerts = ({ review }) => {

  return (
    <Container className="d-flex flex-wrap">
      {review.receipt && (
        <Badge className="m-1" bg="success">Prova D'acquisto</Badge>
      )}
      {review.certified == 2 && (
        <Badge className="m-1" bg="success">Verificata</Badge>
      )}
      {review.modified == 1 && (
        <Badge className="m-1" bg="warning">Modificata</Badge>
      )}
      {review.is_anonymous == 1 && (
        <Badge className="m-1" bg="warning">Anonima</Badge>
      )}
      {review.import_id != null && (
        <Badge className="m-1" bg="warning">Importata</Badge>
      )}
      {review.is_censored && (
        <Badge className="m-1" bg="warning">Censurata</Badge>
      )}
      {review.same_ip_allert && (
        <Badge className="m-1" bg="danger">Allerta IP = Eshop</Badge>
      )}
      {review.review_ripetion == 1 && (
        <Badge className="m-1" bg="danger">Recensione Ripetuta</Badge>
      )}
    </Container>

  );
};

export default ReviewAlerts;