import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Form, Container, Row, Col, Card } from 'react-bootstrap';
import { getPaymentMethods } from "../../actions/getPaymentMethods";
import { defaultStatusColors, imageNotFound } from '@/utils/utilities';

const PaymentMethodsData = ({ elem, setElem }) => {
  const dispatch = useDispatch();
  const dataState = useSelector(state => state.eshops.paymentMethods)
  const dataItems = dataState.entities;
  const loading = dataState.loading;
  const [item, setItem] = useState(elem);
  const eshopData = item?.eshopData;

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    let val = type === 'checkbox' ? (checked ? 1 : 0) : value;
    const keys = name.split('[').map(key => key.replace(']', ''));
    setItem((prevState) => {
      let newState = { ...prevState };
      let currentPart = newState;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!currentPart[keys[i]]) {
          currentPart[keys[i]] = {};
        }
        currentPart = currentPart[keys[i]];
      }
      currentPart[keys[keys.length - 1]] = val;
      return newState;
    });
    setElem(item);
  };

  useEffect(() => {
    if (elem) {
      setItem(elem);
    }
  }, [elem]);

  useEffect(() => {
    dispatch(getPaymentMethods());
  }, []);

  return (
    <Container>
      <Row>
        {dataItems && loading === 'idle' && dataItems.length > 0 && (
          dataItems.map((elem) => {
            const logoUrl = process.env.REACT_APP_ESA_CDN_URL + 'assets/paymentsLogo/' + elem.img;
            return (
              <Col xs={6} lg={2} className='my-3'>
                <Form.Group controlId={`eshopData_${elem.id}`} className="d-flex align-items-center">
                  <Form.Check
                    id={`payment-method-${elem.id}`}
                    name={`eshopData[paymentMethods][${elem.id}]`}
                    checked={eshopData?.paymentMethods ? eshopData?.paymentMethods[elem.id] == 1 : false}
                    type="checkbox"
                    onChange={handleChange}
                  />
                  <label htmlFor={`payment-method-${elem.id}`} className="d-flex align-items-center me-2">
                    <img src={logoUrl} className='img-fluid mx-2' style={{ width: "35px" }} onError={(e) => { e.target.src = imageNotFound }} />
                    {elem.name}
                  </label>
                </Form.Group>
              </Col>
            );
          })
        )}
      </Row>
    </Container>
  );
};

export default PaymentMethodsData;