// reducers.js

import { createSlice } from '@reduxjs/toolkit';
import { updateCatalogo } from '../actions/updateCatalogo';

const updateCatalogoSlice = createSlice({
    name: 'updateCatalogo',
    initialState: { user: {}, status: 'idle', error: null },
    reducers: {
        // Potrebbe avere eventuali altri reducer qui
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateCatalogo.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateCatalogo.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // Aggiungi l'utente al stato
                state.user = action.payload;
            })
            .addCase(updateCatalogo.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export const { /* other action creators, if any */ } = updateCatalogoSlice.actions;

export default updateCatalogoSlice.reducer;
