import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { Card, CardBody, CardTitle, CardTitleWrap } from '@/shared/components/Card';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faLockOpen, faSave } from "@fortawesome/free-solid-svg-icons";
import { successNotify, customWarningNotify } from '../../App/Router';
import { nations } from '@/utils/nations';
import { getCity } from '../actions/getCity';
import { getCities } from '../actions/getCities';
import { toggleBillingStatus } from '../actions/toggleBillingStatus';
import { updateEshopBillingInfo } from '../actions/updateEshopBillingInfo';
import { debounce } from '@material-ui/core';

const EshopBillingData = ({ item }) => {

  const [toRefresh, setToRefresh] = useState(false);
  const [elem, setElem] = useState(item);
  const dispatch = useDispatch();
  const info = item?.info;
  const billingInfo = info?.billingInfo;
  const [billingDisabled, setBillingDisabled] = useState((info?.disable_update_billing_info) ? info.disable_update_billing_info : 0)
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSave = () => {
    const dataToSend = { ...item };
    dataToSend.info.billingInfo.city_id = selectedOption.value;
    console.log(dataToSend);
    dispatch(updateEshopBillingInfo({ id: item.id, data: dataToSend })).then(() => {
      successNotify();
    });
  }

  const handleToggleBilling = () => {
    const value = (billingDisabled == 1) ? 0 : 1;
    dispatch(toggleBillingStatus({ id: item.id })).then(() => {
      successNotify();
      setBillingDisabled(value)
      setToRefresh(true)
    });
  }

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    let val = type === 'checkbox' ? (checked ? 1 : 0) : value;
    const keys = name.split('[').map(key => key.replace(']', ''));
    setElem((prevState) => {
      let newState = { ...prevState };
      let currentPart = newState;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!currentPart[keys[i]]) {
          currentPart[keys[i]] = {};
        }
        currentPart = currentPart[keys[i]];
      }
      currentPart[keys[keys.length - 1]] = val;
      return newState;
    });
  };

  const handleChangeSelect = debounce((inputValue) => {
    setInputValue(inputValue);
  }, 500);

  const handleSelected = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    if (inputValue.length >= 2) {
      dispatch(getCities({ search: inputValue })).then((data) => {
        const items = data.payload.map((item) => {
          return { value: item.value, label: item.label };
        });
        setOptions(items);
      });
    }
  }, [inputValue]);

  useEffect(() => {
    setElem(item);
    if (billingInfo?.city_id) {
      dispatch(getCity({ id: billingInfo?.city_id })).then((data) => {
        setSelectedOption({ value: data.payload.id, label: data.payload.fullName });
      });
    }
  }, [item, toRefresh]);

  return (
    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle className="d-block">
            Dati fatturazione
            <Button onClick={handleSave} size="sm" variant={'success'} className="float-end">
              <FontAwesomeIcon icon={faSave} className="me-2" /> Salva
            </Button>
            <Button onClick={handleToggleBilling} size="sm" variant={(billingDisabled) ? 'danger' : 'primary'} className="float-end mx-2">
              <FontAwesomeIcon icon={(billingDisabled) ? faLock : faLockOpen} className="me-2" /> Modifica {(billingDisabled) ? 'Disabilitata' : 'Abilitata'}
            </Button>
          </CardTitle>
        </CardTitleWrap>
        <Form autoComplete="off">
          <Container>
            <Row>
              <Col xs={12} lg={6} className='my-3'>
                <Form.Group controlId="billingInfo_business_name">
                  <Form.Label>Ragione sociale</Form.Label>
                  <Form.Control name="info[billingInfo][business_name]" value={billingInfo?.business_name} type="text" onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} lg={6} className='my-3'>
                <Form.Group controlId="billingInfo_phone">
                  <Form.Label>Telefono</Form.Label>
                  <Form.Control name="info[billingInfo][phone]" value={billingInfo?.phone} type="text" onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} lg={6} className='my-3'>
                <Form.Group controlId="billingInfo_name">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control name="info[billingInfo][name]" value={billingInfo?.name} type="text" onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} lg={6} className='my-3'>
                <Form.Group controlId="billingInfo_surname">
                  <Form.Label>Cognome</Form.Label>
                  <Form.Control name="info[billingInfo][surname]" value={billingInfo?.surname} type="text" onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} lg={6} className='my-3'>
                <Form.Group controlId="billingInfo_address">
                  <Form.Label>Indirizzo</Form.Label>
                  <Form.Control name="info[billingInfo][address]" value={billingInfo?.address} type="text" onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} lg={6} className='my-3'>
                <Form.Group controlId="billingInfo_postal_code">
                  <Form.Label>Cap</Form.Label>
                  <Form.Control name="info[billingInfo][postal_code]" value={billingInfo?.postal_code} type="text" onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} lg={6} className='my-3'>
                <Form.Group controlId="billingInfo_nation_id">
                  <Form.Label>Nazione</Form.Label>
                  <Form.Control name="info[billingInfo][nation_id]" value={billingInfo?.nation_id} as="select" onChange={handleChange}>
                    {
                      Object.keys(nations).map((key) => {
                        return <option key={key} value={key}>{nations[key]}</option>
                      })
                    }
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col xs={12} lg={6} className='my-3'>
                <Form.Group controlId="eshopData_city_id">
                  <Form.Label>Città</Form.Label>
                  <Select
                    name="info[billingInfo][city_id]"
                    options={options}
                    value={selectedOption}
                    placeholder="Ricerca comune..."
                    onInputChange={handleChangeSelect}
                    onChange={(selectedOption) => handleSelected(selectedOption)}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} lg={6} className='my-3'>
                <Form.Group controlId="billingInfo_pec">
                  <Form.Label>Pec</Form.Label>
                  <Form.Control name="info[billingInfo][pec]" value={billingInfo?.pec} type="text" onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} lg={6} className='my-3'>
                <Form.Group controlId="billingInfo_sdi">
                  <Form.Label>Sdi</Form.Label>
                  <Form.Control name="info[billingInfo][sdi]" value={billingInfo?.sdi} type="text" onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} lg={6} className='my-3'>
                <Form.Group controlId="billingInfo_p_iva">
                  <Form.Label>Partita IVA</Form.Label>
                  <Form.Control name="info[billingInfo][p_iva]" value={billingInfo?.p_iva} type="text" onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} lg={6} className='my-3'>
                <Form.Group controlId="billingInfo_c_fiscale">
                  <Form.Label>Codice fiscale</Form.Label>
                  <Form.Control name="info[billingInfo][c_fiscale]" value={billingInfo?.c_fiscale} type="text" onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} lg={6} className='my-3'>
                <Form.Group controlId="billingInfo_no_p_iva">
                  <Form.Check
                    id={'np_p_check'}
                    name="info[billingInfo][no_p_iva]"
                    checked={billingInfo?.no_p_iva == 1}
                    type="checkbox"
                    label={'Cliente senza Partita IVA'}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Form>
      </CardBody>
    </Card>
  )
}

export default EshopBillingData
