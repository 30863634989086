import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Container, Row, Col, ListGroup } from 'react-bootstrap';
import { Card, CardBody, CardTitle, CardTitleWrap } from '@/shared/components/Card';
import { getEshopImportProducts } from "../actions/getEshopImportProducts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment';
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { importProductStatusLabel, importProductStatusColors } from '@/utils/utilities';

const EshopImportProducts = ({ esa_id }) => {

  const dispatch = useDispatch();
  const [elem, setElem] = useState(false);

  function formatDate(dateString) {
    return moment(dateString).format('DD/MM/YYYY')
  }

  async function getInfo() {
    const infoData = await dispatch(getEshopImportProducts({ esa_id: esa_id }));
    setElem(infoData.payload);
  }

  function getListInfo(type) {
    const info = elem ? {
      url: elem[type]?.url,
      status: elem[type]?.status,
      last_update: elem[type]?.last_update ? formatDate(elem[type]?.last_update) : null,
      last_send: elem[type]?.last_send ? formatDate(elem[type]?.last_send) : null,
      first_send: elem[type]?.first_send ? formatDate(elem[type]?.first_send) : null,
    } : {
      url: null,
      status: null,
      last_update: null,
      last_send: null,
      first_send: null,
    };
    return (
      <ListGroup as="ul" className="my-3">
        <ListGroup.Item as="li">
          <b>Url del CSV inserita dall'eshop:</b>
          <span className="ms-2">
            {info.url ? info.url : '-'}
          </span>
        </ListGroup.Item>
        <ListGroup.Item as="li">
          <b>Stato dell'import:</b>
          <span className="ms-2">
            {info.status ? (
              <Button variant={importProductStatusColors[info.status]} size="sm">
                {importProductStatusLabel[info.status]}
              </Button>
            ) : '-'}
          </span>
        </ListGroup.Item>
        <ListGroup.Item as="li">
          <b>Ultimo aggiornamento dello stato:</b>
          <span className="ms-2">
            {info.last_update ? info.last_update : '-'}
          </span>
        </ListGroup.Item>
        <ListGroup.Item as="li">
          <b>Ultimo invio della url csv:</b>
          <span className="ms-2">
            {info.last_send ? info.last_send : '-'}
          </span>
        </ListGroup.Item>
        <ListGroup.Item as="li">
          <b>Primo invio della url csv:</b>
          <span className="ms-2">
            {info.first_send ? info.first_send : '-'}
          </span>
        </ListGroup.Item>
      </ListGroup>
    );
  }

  useEffect(() => {
    getInfo();
  }, [esa_id]);

  return (
    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle className="d-block">
            Info import prodotti
          </CardTitle>
        </CardTitleWrap>
        <Container>
          <Row>
            <Col xs={12} className="my-3">
              <h3>G-Feed</h3>
              <p>Inserisci lo stesso url feed che utilizzi per caricare i tuoi prodotti su Google Shopping: un feed è un file contenente l'elenco dei prodotti e che utilizza dei dati standardizzati, i quali rendono possibile l'import dei tuoi prodotti, anche sul motore di ricerca di eShoppingAdvisor.</p>
              {getListInfo('gfeed')}
            </Col>
            <Col xs={12} className="my-3">
              <h3>CSV</h3>
              <Button variant="info" href='https://business.eshoppingadvisor.com/public/files/Importazione_prodotti_da_WebURL-CSV.pdf' target="_blank" size="sm" className="my-3">
                <FontAwesomeIcon icon={faBook} className="me-2" />
                Guida pdf
              </Button>
              <p>Il file csv relativo ai prodotti dovrà essere posizionato su un indirizzo HTTPS dal quale sarà possibile recuperarlo. I dati inseriti all'interno del csv dovranno essere strutturati in righe e colonne contenenti campi specifici</p>
              {getListInfo('csv')}
            </Col>
            <Col xs={12} className="my-3">
              <h3>Scraping sitemap</h3>
              <Button variant="info" href='https://business.eshoppingadvisor.com/public/files/Guida_dati_strutturati.pdf' target="_blank" size="sm" className="my-3">
                <FontAwesomeIcon icon={faBook} className="me-2" />
                Guida pdf
              </Button>
              <p>Il cliente deve inserire link della sua sitemap.</p>
              <p>E' necessario che all'interno delle pagine sono stati correttamente utilizzati i metadati prodotto dello schema.org in modo tale da poter eseguire lo scraping delle url e inserire i prodotti nel motore di ricerca di eShoppingAdvisor.</p>
              {getListInfo('sitemap')}
            </Col>
          </Row>
        </Container>
      </CardBody>
    </Card >
  )
}

export default EshopImportProducts
