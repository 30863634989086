import React, { useState, useEffect } from 'react';
import {
    Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';

import { Field, Form } from 'react-final-form';
import { Col } from 'react-bootstrap';
import renderSelectField from '@/shared/components/form/Select';
import FormField from '@/shared/components/form/FormField';
import {
    FormButtonToolbar,
    FormContainer,
    FormGroupField,
    FormGroupLabel,
    FormGroup,
    FormGroupIcon,
    FormHalfContainer,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import renderCheckBoxField from '@/shared/components/form/CheckBox';

import TextEditor from '@/shared/components/text-editor/TextEditor';

import { BorderedTopTabs } from '@/shared/components/Tabs';
import { Nav, Tab } from 'react-bootstrap';
import { NavItem, NavLink, TabsWrap } from '@/shared/components/Tabs';

function formatInitialValues() {
    let periodValue = null;
    
    let categoryValue = null;
    

    return {
        period: periodValue,
        category: categoryValue
    };
}

function getLabelForCategory(categoryNumber) {
    switch (categoryNumber) {
        case 1:
            return 'Reputazione Offline';
        case 2:
            return 'Reputazione Online';
        case 3:
            return 'Visibilità';
        case 4:
            return 'Assistenza';
        default:
            return ''; // gestione di default, ma dovrebbe sempre corrispondere a una delle opzioni sopra
    }
}



function GeneralForm() {

    const formattedInitialValues = formatInitialValues();
    
    const onSubmit = (values) => {
        console.log('Dati inviati:', values);
        // Qui puoi gestire la logica di invio del tuo form, ad es. effettuare una chiamata API
    };

    return (
        <Form onSubmit={onSubmit}>
            {({ handleSubmit }) => (
                <FormContainer vertical onSubmit={handleSubmit}>
        <Col md={12}>
            
            <Card>
                <CardBody>
                    <CardTitleWrap>
                        <CardTitle>Banner: Test</CardTitle>
                                   
                    </CardTitleWrap>
                                
                                <FormGroup>
                                    <FormGroupLabel>Nome banner</FormGroupLabel>
                                    <FormGroupField>
                                        <Field
                                            name="title"
                                            component={FormField}
                                            type="text"
                                            placeholder=""
                                        />
                                    </FormGroupField>
                                </FormGroup>
                                <FormGroup>
                                    <FormGroupLabel>Url</FormGroupLabel>
                                    <FormGroupField>
                                        <Field
                                            name="title"
                                            component={FormField}
                                            type="text"
                                            placeholder=""
                                        />
                                    </FormGroupField>
                                </FormGroup>
                                <FormGroupField key="2">
                                    <Field
                                        name="nome"
                                        type="checkbox"
                                        component={renderCheckBoxField}
                                        label="Eshop"
                                        initialValue="true"
                                        
                                    />
                                </FormGroupField>
                                <FormGroupField key="2">
                                    <Field
                                        name="nome"
                                        type="checkbox"
                                        component={renderCheckBoxField}
                                        label="Ricerca eshop"
                                        initialValue="true"
                                        
                                    />
                                </FormGroupField>
                                <FormGroupField key="2">
                                    <Field
                                        name="nome"
                                        type="checkbox"
                                        component={renderCheckBoxField}
                                        label="Scrivi recensione"
                                        initialValue="true"
                                        
                                    />
                                </FormGroupField>
                                <FormGroupField key="2">
                                    <Field
                                        name="nome"
                                        type="checkbox"
                                        component={renderCheckBoxField}
                                        label="Pagina utente"
                                        initialValue="true"
                                    />
                                </FormGroupField>
                               
                                <FormButtonToolbar>
                                    <Button variant="primary" type="submit">Salva</Button>
                                </FormButtonToolbar>
                </CardBody>

            </Card>
                        
        </Col>
                </FormContainer>
            )}
        </Form>
    );
}

export default GeneralForm;
