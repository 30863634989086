import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';

export const updatePaymentMethod = createAsyncThunk(
    'domains/updatePaymentMethod',
    async ({ id, data }) => {
        const actionUrl = '/v1/domains/update-payment-method/' + id;
        const url = actionUrl;
        const response = await api.put(url, data);
        return response.data;
    }
);