import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';

export const updateSettings = createAsyncThunk(
    'properties/updateSettings',

    async ({id, data}) => {
        // let data = JSON.stringify(text);
        // console.log(text);
        debugger;
        const response = await api.put(`v1/properties/settings-update/${id}`, data);
        console.log(response.error);
        return response.data;
    }
);