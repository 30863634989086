import React, { useEffect, useState, useContext } from "react";
import { useDispatch } from 'react-redux';
import { Button, Table, Modal, Form, Container, Row, Col } from 'react-bootstrap';
import { Card, CardBody, CardTitle, CardTitleWrap } from '@/shared/components/Card';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faPlus, faSave } from "@fortawesome/free-solid-svg-icons";
import { api } from '@/config/api';
import endpoint_eshop from '../endpoints/eshops';
import { insertNote } from '../actions/insertNote';
import { updateNote } from '../actions/updateNote';
import { successNotify, customWarningNotify } from '../../App/Router';

const EshopNotes = ({ item }) => {

  const [elem, setElem] = useState(item);
  const [updatedElem, setUpdatedElem] = useState({ ...item });
  const [show, setShow] = useState(false);
  const [idToUpdate, setIdToUpdate] = useState(false);
  const [indexToUpdate, setIndexToUpdate] = useState(false);
  const [typeOption, setTypeOption] = useState(false);
  const [toRefresh, setToRefresh] = useState(false);
  const dispatch = useDispatch();
  const adminId = 20 // TODO: get admin id from the acual logged user

  const handleShowInsert = () => {
    setTypeOption('insert')
    setIdToUpdate(false);
    setIndexToUpdate(false);
    handleShow();
  }

  const handleShowUpdate = (id, index) => {
    setTypeOption('update')
    setIdToUpdate(id);
    setIndexToUpdate(index);
    handleShow();
  }

  const handleInsert = () => {
    let dataToSend = { ...updatedElem.newNote };
    dataToSend.user_id = adminId;
    dataToSend.eshop_id = elem.id;
    dispatch(insertNote({ data: dataToSend })).then(() => {
      setToRefresh(true);
      handleClose()
      successNotify();
    });
  }

  const handleUpdate = (id) => {
    const dataToSend = { ...updatedElem.notes[indexToUpdate]['note'] };
    dispatch(updateNote({ eshop_id: elem.id, id: id, data: dataToSend, adminId: adminId })).then(() => {
      setToRefresh(true);
      handleClose()
      successNotify();
    });
  }

  const handleClose = () => setShow(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    let val = type === 'checkbox' ? (checked ? 1 : 0) : value;
    const keys = name.split('[').map(key => key.replace(']', ''));
    setUpdatedElem((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      let currentPart = newState;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!currentPart[keys[i]]) {
          currentPart[keys[i]] = {};
        }
        currentPart = currentPart[keys[i]];
      }
      currentPart[keys[keys.length - 1]] = val;
      return newState;
    });
  };

  const refreshData = async () => {
    const response = await api.get(endpoint_eshop.getEshop.replace(':id', elem.id));
    setElem(response.data);
  }

  useEffect(() => {
    if (toRefresh) {
      setToRefresh(false);
      refreshData();
    }
    else {
      setElem(item);
    }
  }, [item, toRefresh]);

  return (
    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle className="d-block">
            Note eshop
            <Button onClick={handleShowInsert} size="sm" variant={'primary'} className="float-end">
              <FontAwesomeIcon icon={faPlus} className="me-2" /> Inserisci
            </Button>
          </CardTitle>
        </CardTitleWrap>
        <Table striped bordered>
          <thead>
            <tr>
              <th>Data</th>
              <th>Data modifica</th>
              <th>Autore</th>
              <th>Nota</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {elem.notes?.length && elem.notes.map((note, index) => (
              <tr key={index}>
                <td>{note.note.data}</td>
                <td>{note.note.data_modifica || '-'}</td>
                <td>{note.user}</td>
                <td>{note.note.nota}</td>
                <td className="text-center">
                  {note.note.user_id == adminId && (
                    <Button onClick={() => handleShowUpdate(note.note.id, index)} variant="info" size="sm">
                      <FontAwesomeIcon icon={faPencil} />
                    </Button>
                  )}
                </td>
              </tr>
            )) ||
              <tr>
                <td colSpan={5}>Nessuna nota presente</td>
              </tr>
            }
          </tbody>
        </Table>
        <Modal show={show} onHide={handleClose} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>{typeOption == 'insert' ? 'Inserisci' : 'Modifica'} nota</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form autoComplete="off">
              <Container>
                <Row>
                  <Col xs={12} className='my-3'>
                    <Form.Group controlId={`note_${idToUpdate}`}>
                      <Form.Label>Descrizione nota</Form.Label>
                      <Form.Control
                        name={idToUpdate ? `notes[${indexToUpdate}][note][nota]` : 'newNote[nota]'}
                        value={idToUpdate ? updatedElem.notes[indexToUpdate].note.nota : updatedElem.newNote?.nota}
                        type="text"
                        as="textarea"
                        rows='5'
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} className='my-3 text-end'>
                    <Button onClick={() => idToUpdate ? handleUpdate(idToUpdate) : handleInsert()} size="sm" variant={'success'}>
                      <FontAwesomeIcon icon={faSave} className="me-2" /> Salva
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Form>
          </Modal.Body>
        </Modal>
      </CardBody>
    </Card>
  )
}

export default EshopNotes
