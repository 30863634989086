import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../config/api';

export const fetchUserDetails = createAsyncThunk(
    'users/fetchUserDetails',
    async (userId) => {
        console.log("action detail " + userId);
        const response = await api.get(`/v1/consumer-users/${userId}`);
        return response.data;
    }
);