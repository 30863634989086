import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';
import endpoint from '../endpoints/b2bUsers';

export const putB2bUserData = createAsyncThunk(
    'b2bUsers/postB2bUserData',
    async ({userData,id}) => {
        debugger;
        const response = await api.put(endpoint.update.replace(':id',id), userData);
        debugger;
        return response.data;
    }
);