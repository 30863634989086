import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';

import { Col, Row, Container, Spinner } from 'react-bootstrap';

import renderSelectField from '@/shared/components/form/Select';
import PasswordField from '@/shared/components/form/Password';
import FormField from '@/shared/components/form/FormField';
import {
    Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import {
    FormButtonToolbar, FormContainer, FormGroupField, FormGroupLabel, FormGroup,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import validate from './validate';
import renderRadioButtonField from '@/shared/components/form/RadioButton';
import customStyle from '@/shared/assets/css/custom.module.css';


const HorizontalForm = ({ onSubmit, b2bUserData, isUpdate }) => {

    return (
        <Col md={12} lg={9} xl={9}>
            <Card>
                <CardBody>
                    <CardTitleWrap>
                        <CardTitle>Anagrafica</CardTitle>
                        
                    </CardTitleWrap>
                    <Form onSubmit={onSubmit} validate={validate} autoComplete="off">
                        {({
                            handleSubmit,
                            form: { reset },
                        }) => (
                            <FormContainer onSubmit={handleSubmit} className="w-100">
                                <Col md={12} lg={12} xl={12}>
                                    <Row>
                                        <Col md={6} lg={6} xl={6}>
                                            <FormGroup>
                                                <FormGroupLabel>Nome</FormGroupLabel>
                                                <FormGroupField>
                                                    <Field
                                                        name="name"
                                                        component={FormField}
                                                        type="text"
                                                        placeholder="Nome"
                                                        defaultValue={b2bUserData.name}
                                                    />
                                                    
                                                </FormGroupField>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} lg={6} xl={6}>
                                            <FormGroup>
                                                <FormGroupLabel>Cognome</FormGroupLabel>
                                                <FormGroupField>
                                                    <Field
                                                        name="surname"
                                                        component={FormField}
                                                        type="text"
                                                        placeholder="Cognome"
                                                        defaultValue={b2bUserData.surname}
                                                    />
                                                </FormGroupField>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} lg={6} xl={6}>
                                            <FormGroup>
                                                <FormGroupLabel>E-mail</FormGroupLabel>
                                                <FormGroupField>
                                                    <Field
                                                        name="email"
                                                        component={FormField}
                                                        type="email"
                                                        placeholder="example@mail.com"
                                                        defaultValue={b2bUserData.email}
                                                    />
                                                </FormGroupField>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} lg={6} xl={6}>
                                            <FormGroup>
                                                <FormGroupLabel>Telefono</FormGroupLabel>
                                                <FormGroupField>
                                                    <Field
                                                        name="phone"
                                                        component={FormField}
                                                        type="text"
                                                        placeholder="070000000"
                                                        defaultValue={b2bUserData.phone}
                                                    />
                                                </FormGroupField>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12} lg={12} xl={12}>
                                            <CardTitleWrap>
                                                <CardTitle>Dati di accesso</CardTitle>
                                            </CardTitleWrap>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} lg={6} xl={6}>
                                            <FormGroup>
                                                <FormGroupLabel>Username</FormGroupLabel>
                                                <FormGroupField>
                                                    <Field
                                                        name="username"
                                                        component={FormField}
                                                        type="text"
                                                        placeholder="Username"
                                                        defaultValue={b2bUserData.username}
                                                    />
                                                </FormGroupField>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} lg={6} xl={6}>
                                            <FormGroup>
                                                <FormGroupLabel>Password</FormGroupLabel>
                                                <FormGroupField>
                                                    <Field
                                                        name="password"
                                                        component={PasswordField}
                                                        placeholder="Password"
                                                        autoComplete="off"
                                                        keyIcon
                                                    />
                                                </FormGroupField>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} lg={6} xl={6}>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <FormGroupLabel>Conferma password</FormGroupLabel>
                                                <FormGroupField>
                                                    <Field
                                                        name="confirm_password"
                                                        component={PasswordField}
                                                        placeholder="Ripeti Password"
                                                        autoComplete="new-password"
                                                        keyIcon
                                                    />
                                                </FormGroupField>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <FormButtonToolbar>
                                                <Button variant="primary" type="submit">{isUpdate ? 'Aggiorna' : 'Aggiungi'}</Button>
                                                <Button variant="secondary" type="button" onClick={reset}>
                                                    Reset
                                                </Button>
                                            </FormButtonToolbar>
                                        </Col>
                                    </Row>
                                </Col>
                            </FormContainer>
                        )}
                    </Form>
                </CardBody>
            </Card>
        </Col>
    );
};

HorizontalForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default HorizontalForm;
