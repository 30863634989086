import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../config/api';

export const updateUserDetails = createAsyncThunk(
    'users/updateUserDetails',
    async (userId, userData) => {
        console.log("action detail " + userId);
        const response = await api.put(`/v1/consumer-users/${userId}`, userData);
        return response.data;
    }
);