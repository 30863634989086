import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, Dropdown, DropdownButton } from 'react-bootstrap';
import { Button } from '@/shared/components/Button';
import { deleteEshopReply } from '../actions/deleteEshopReply';
import { updateEshopReply } from '../actions/updateEshopReply';
import Badge from '@/shared/components/Badge';
import styled from 'styled-components';
import ReviewReplyModal from './modals/ReviewReplyModal';
import DeleteForeverIcon from 'mdi-react/DeleteForeverIcon';
import moment from 'moment';
import customStyle from '@/shared/assets/css/custom.module.css'

function ReviewItemList({ item, isProduct = false, triggerRefresh }) {

  const dispatch = useDispatch();
  const formattedDate = moment(item.reply.date).format('DD/MM/YYYY HH:mm:ss');
  const FilterButton = styled(Button)`margin-top: calc(1.5em);`;
  const [showModal, setShowModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [selectedElem, setSelectedElem] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [visibleDropdownId, setVisibleDropdownId] = useState(null);

  const toggleDropdown = (id) => {
    setVisibleDropdownId(visibleDropdownId === id ? null : id);
  };

  const handleEditClick = (elem) => {
    setSelectedElem(elem);
    setModalShow(true);
  };

  const handleDeleteClick = (userId) => {
    setIdToDelete(userId);
    setShowModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteEshopReply({ id: idToDelete, isProduct: isProduct })).then(() => {
      handleClose();
      triggerRefresh();
    });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleSave = (updatedElem) => {

    const data = {
      reply_content: updatedElem.reply.content
    }

    dispatch(updateEshopReply({ id: updatedElem.reply.id, status: updatedElem.reply.status, isProduct: isProduct, data: data })).then(() => {
      setModalShow(false);
      triggerRefresh();
    });
  };

  const handleChangeStatus = (id, status) => {

    dispatch(updateEshopReply({ id: id, status: status, isProduct: isProduct })).then(() => {
      setModalShow(false);
      triggerRefresh();
    });

  };

  const statusBadge = (status, id) => {
    switch (status) {
      case 0:
        return (
          <DropdownButton
            id={`dropdown-button-drop-${id}`}
            key={id}
            size="sm"
            variant="warning"
            title="Attesa di validazione"
            onClick={(e) => {
              e.preventDefault();
              toggleDropdown(id);
            }}
          >
            <Dropdown.Item eventKey="1" onClick={() => handleChangeStatus(id, 1)}>Approva</Dropdown.Item>
            <Dropdown.Item eventKey="2" onClick={() => handleChangeStatus(id, 2)}>Rifiuta</Dropdown.Item>
          </DropdownButton>
        );
      case 1:
        return <Badge bg="success">Risposta pubblicata</Badge>;
      case 2:
        return <Badge bg="danger">Non approvata</Badge>;
      default:
        return null;
    }
  };

  return (
    <>
      <tr key={item.id}>
        <td className="first-letter-uppercase">{formattedDate}</td>
        <td>
          <Link to={`/registered-eshops/update/${item.eshop.id}`} target="_blank">{item.eshop.domain}</Link>
        </td>
        <td>
          <Badge bg={'success'} >{item.eshop.plan}</Badge>
        </td>
        <td> <span className={customStyle.clickable} onClick={() => handleEditClick(item)}>{item.review.title}</span> </td>
        <td>
          {item.user?.email ? (
            <Link to={`/consumer_users/detail/${item.user.id}`} target="_blank">
              {item.user.email}
            </Link>
          ) : (
            item.user.name + ' ' + item.user.surname
          )}
        </td>
        <td className="first-letter-uppercase">
          {statusBadge(item.reply.status, item.reply.id)}
        </td>
        <td>
          <FilterButton
            variant="danger"
            size='sm'
            onClick={() => handleDeleteClick(item.reply.id)}
          >
            <DeleteForeverIcon />
          </FilterButton>
        </td>
      </tr>
      {
        modalShow && (
          <ReviewReplyModal
            show={modalShow}
            handleClose={handleModalClose}
            elem={selectedElem}
            handleSave={handleSave}
            handleChangeStatus={handleChangeStatus}
            readOnly={true}
          />
        )
      }
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Conferma eliminazione</Modal.Title>
        </Modal.Header>
        <Modal.Body>Sei sicuro di voler cancellare la recensione?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Annulla
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Elimina
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ReviewItemList;