import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Card, CardBody } from '@/shared/components/Card';
import OrdersList from '../ordersList';

const EshopOrders = ({ esa_id }) => {

  return (
    <Card>
      <CardBody>
        {<OrdersList esa_id={esa_id} />}
      </CardBody>
    </Card>
  )

}

export default EshopOrders
