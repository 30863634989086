import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import FilterForm from './components/filters/filterProducts';
import { Col, Container, Row, Spinner, Modal, Button } from 'react-bootstrap';
import { Card, CardBody, CardTitleWrap } from '@/shared/components/Card';
import { Table } from '@/shared/components/TableElements';
import styled from 'styled-components';
import { cleanDate } from '@/shared/helpers';
import { colorAccent } from '@/utils/palette';
import { fetchProducts } from './actions/fetchProducts';
import ProductItemList from './components/ProductItemList';
import PaginationTable from '@/containers/Components/PaginationTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ProductForm from './forms/productForm';

const ProductsTable = () => {
  const dispatch = useDispatch();
  const itemsState = useSelector(state => state.esastore.fetch)
  const items = itemsState.entities;
  const loading = itemsState.loading;
  const pagination = itemsState.pagination;
  const TableSpinner = styled(Spinner)`color: ${colorAccent};`;
  const tableLabels = ['Nome', 'Prezzo', 'Periodo', 'In catalogo', 'Data crazione', '']
  const [globalFilters, setGlobalFilters] = useState({ 'per-page': 25, page: 1 });
  const [toRefresh, setToRefresh] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleOpen = () => {
    setShowModal(true);
  }

  const handleClose = () => {
    setShowModal(false);
  }

  const handleFilterSubmit = (values) => {

    let modifiedValues = { ...values };
    delete modifiedValues.creation_date_start;
    delete modifiedValues.creation_date_end;

    if (values.creation_date_start || values.creation_date_end) {
      modifiedValues.creation_date = {
        start: values.creation_date_start ? cleanDate(values.creation_date_start) : null,
        end: values.creation_date_end ? cleanDate(values.creation_date_end) : null,
      }
    }

    if (values.period) {
      modifiedValues.period = values.period.value;
    }

    setGlobalFilters(modifiedValues);

    modifiedValues.page = pagination.current_page;
    modifiedValues['per-page'] = 25

    const payload = {
      params: modifiedValues
    };

    dispatch(fetchProducts(payload));
  };

  useEffect(() => {
    if (toRefresh) {
      setToRefresh(false);
    }
    const initialPayload = {
      params: globalFilters
    };

    dispatch(fetchProducts(initialPayload));
  }, [dispatch, toRefresh]);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Elenco Prodotti</h3>
        </Col>
      </Row>
      <FilterForm onSubmit={handleFilterSubmit} />
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <CardTitleWrap>
                <Row className='align-items-center'>
                  <Col xs={12} lg={4}>
                    <p className="page-subtitle">Numero elementi: {pagination?.total_count}</p>
                  </Col>
                  <Col xs={12} lg={8} className='text-end'>
                    <Button onClick={() => handleOpen()} variant="outline-primary" size='sm'>
                      <FontAwesomeIcon icon={faPlus} className="me-2" />
                      <span>Aggiungi prodotto</span>
                    </Button>
                  </Col>
                </Row>
              </CardTitleWrap>
              {loading === 'loading' ? (
                <div className="text-center">
                  <TableSpinner animation="border" />
                </div>
              ) : (
                <>
                  <Table responsive striped bordered>
                    <thead>
                      <tr>
                        {tableLabels.map((label) => {
                          return <th>{label}</th>
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {items && loading === 'idle' && items.length > 0 && (
                        items.map((elem) => {
                          return (<ProductItemList item={elem} refreshTable={setToRefresh} />)
                        })
                      ) || (
                          <tr>
                            <td colSpan={tableLabels.length} className='text-start'>Nessun elemento trovato</td>
                          </tr>
                        )}
                    </tbody>
                  </Table>
                  {items && items.length > 0 && (
                    <PaginationTable pagination={pagination} filters={globalFilters} action={fetchProducts} />
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal show={showModal} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Aggiungi prodotto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProductForm refreshTable={setToRefresh} setShowModal={setShowModal} />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

ProductsTable.propTypes = {
  onSubmit: PropTypes.func,
};

export default ProductsTable;