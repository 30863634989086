import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import FormField from '@/shared/components/form/FormField';
import { Button } from '@/shared/components/Button';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Container, Row, Col } from 'react-bootstrap';
import { fetchCensoredWords } from './actions/censoredWords';
import { updateCensoredWords } from './actions/updateCensoredWords';
import { successNotify } from '../App/Router';

const CensoredWords = () => {
  const dispatch = useDispatch();
  const wordsFromRedux = useSelector(state => state.reviews.censoredWords.words || "");

  const [words, setWords] = useState(wordsFromRedux);

  const entireState = useSelector(state => state);
  console.log("Entire state:", entireState);

  useEffect(() => {
    dispatch(fetchCensoredWords());

  }, [dispatch]);

  useEffect(() => {
    setWords(wordsFromRedux);

  }, [wordsFromRedux]);

  const onSubmit = (values) => {
    const data = { censored_words: values.censoredWords }
    dispatch(updateCensoredWords({ data: data })).then(() => {
      dispatch(fetchCensoredWords());
      successNotify();
    });
  };

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <h3 className="page-title">Parole da censurare</h3>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Form
            onSubmit={onSubmit}
            initialValues={{ censoredWords: words }}
          >
            {({ handleSubmit }) => (
              <FormContainer horizontal onSubmit={handleSubmit}>
                <FormGroup>
                  <FormGroupLabel>Parole da censurare</FormGroupLabel>
                  <FormGroupField>
                    <Field name="censoredWords" component="textarea" type="text" />
                  </FormGroupField>
                </FormGroup>
                <FormButtonToolbar>
                  <Button variant="primary" type="submit">Salva</Button>
                </FormButtonToolbar>
              </FormContainer>
            )}
          </Form>
        </Col>
      </Row>
    </Container>
  );

}

export default CensoredWords;
