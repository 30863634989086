import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';

export const updateJson = createAsyncThunk(
    'properties/updateJson',

    async ({ position, text }) => {
        // let data = JSON.stringify(text);
        // console.log(text);
        const response = await api.put(`/v1/properties/json/${position}`, text);
        console.log(response.error);
        return response.data;
    }
);