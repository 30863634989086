import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import Badge from '@/shared/components/Badge';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

function ReviewModifyModal({ item, show, handleClose }) {

  const dispatch = useDispatch();
  const [detailsRating, setDetailsRating] = useState([]);

  let reviewReceipt = false;
  let countReceipt = 0
  if (item.original.receipt !== null && item.original.receipt !== "") {
    try {
      reviewReceipt = JSON.parse(item.original.receipt);
      countReceipt = Array.isArray(reviewReceipt) ? reviewReceipt.length : 0
    } catch (error) {
      console.error('Error parsing receipt:', item.original.receipt);
    }
  }

  useEffect(() => {
    let detailsRatingArray;
    try {
      const detailsRatingString = item.review && typeof item.review.details_rating === 'string'
        ? item.review.details_rating
        : JSON.stringify(item.review.details_rating || {});
      detailsRatingArray = JSON.parse(detailsRatingString);
      console.log("detailsRatingArray", detailsRatingArray);
    } catch (error) {
      console.error("Errore nella conversione di details_rating:", error);
      detailsRatingArray = [];
    }
    setDetailsRating(detailsRatingArray);

  }, [item]);

  const renderDetailsRating = (originalRating, modifyRating) => {
    if (item.modified.is_prod_review) {
      return (
        <Col xs={12} md={4}>
          <strong className='me-2 d-block'>Rating:</strong>
          <span className='text-danger'>
            {originalRating.Valutazione}
          </span>
          <FontAwesomeIcon icon={faArrowRight} className="mx-2" />
          <span className='text-success'>
            {modifyRating}
          </span>
        </Col>
      );
    }
    else {
      try {
        const originalDetails = JSON.parse(originalRating);
        const modifyDetails = JSON.parse(modifyRating);
        return (
          <>
            {Object.entries(originalDetails).map(([key, value]) => (
              <Col xs={12} md={4}>
                <strong className='me-2 d-block'>{key}:</strong>
                <span className='text-danger'>
                  {value}
                </span>
                <FontAwesomeIcon icon={faArrowRight} className="mx-2" />
                <span className='text-success'>
                  {modifyDetails[key]}
                </span>
              </Col>
            ))}
          </>
        );
      } catch (e) {
        console.error('Errore nel parsing della stringa JSON:', e);
        return <Col xs={12} md={6}>Dettagli non disponibili</Col>;
      }
    }

  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Modifiche alla recensione
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} className='border-bottom'>
              <strong className='me-2 d-block'>Titolo:</strong>
              <span className='text-danger'> {item.original.title} </span>
              <FontAwesomeIcon icon={faArrowRight} className="mx-2" />
              <span className='text-success'> {item.modified.title} </span>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className='border-bottom'>
              <strong className='me-2 d-block'>Descrizione:</strong>
              <span className='text-danger'> {item.original.description} </span>
              <FontAwesomeIcon icon={faArrowRight} className="mx-2" />
              <span className='text-success'> {item.modified.description} </span>
            </Col>
          </Row>
          {item.original.detail_ratings && (
            <Row>
              {renderDetailsRating(item.original.detail_ratings, item.modified.detail_ratings)}
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          {reviewReceipt && (
            <a
              href={process.env.REACT_APP_ESA_CDN_URL + `reviewsReceipt/${item.original.id}/${reviewReceipt[countReceipt - 1]}`}
              target="_blank"
              rel="noreferrer"
              download
            >
              <Button variant="warning" className='mx-1'>P.Acquisto</Button>
            </a>
          )}
          <Button variant="secondary" onClick={handleClose}>
            Chiudi
          </Button>
        </Modal.Footer>
      </Modal >
    </>
  );
}

export default ReviewModifyModal;
