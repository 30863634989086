import React, { useEffect , useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faList, faTrash } from '@fortawesome/free-solid-svg-icons';
import { api } from '@/config/api';
import endpoints from '../endpoints/plans';


const PromoItemList = ({ item, selectedItem}) => {

  const [elem, setElem] = useState(item); 

  function formatDateFromTimestamp(dateString) 
  {
    return moment(dateString).format('DD/MM/YYYY')
  }
  
  useEffect(() => {
      setElem(item);
  }, [item]);

  return (
    <>
      <tr key={elem.id}>        
        <td>
          {elem.name}
        </td>
        <td>
          {elem.stripe_id}
        </td>
        <td>
          {elem.creation_date ? formatDateFromTimestamp(elem.creation_date) : '-'}
        </td>
        <td>
          {elem.update_date ? formatDateFromTimestamp(elem.update_date) : '-'}
        </td>
        <td>
          {elem.amount_off > 0 ? '€'+elem.amount_off : (elem.percent_off > 0 ? elem.percent_off + '%' : '----')}
        </td>
        <td>
          {elem.activation_cost ? '€'+elem.activation_cost : '----'}
        </td>
        <td>
          <Badge>
            {
              elem.duration == 1 ? 'Solo primo pagamento' : (elem.duration == 2 ? elem.duration_in_months + ' mes'+(elem.duration_in_months > 1 ? 'i' : 'e') : 'Per sempre')
            }
          </Badge>
        </td>
        <td>
          <Link to="#" onClick={() => selectedItem({item: elem, action: 'togglePaypal'})}>
            <Badge className={elem.paypal_enabled > 0 ? 'bg-success' : 'bg-danger'}>
              <FontAwesomeIcon icon={elem.paypal_enabled > 0 ? faCheck : faTimes} />
            </Badge>
          </Link>
        </td>
        
        <td>
          <Link to="#" onClick={() => selectedItem({item: elem, action: 'toggleWireTransfer'})}>
            <Badge className={elem.wire_transfer_enabled > 0 ? 'bg-success' : 'bg-danger'}>
              <FontAwesomeIcon icon={elem.wire_transfer_enabled > 0 ? faCheck : faTimes} />
            </Badge>            
          </Link>
        </td>
        <td>
          {
            elem.promoLinks.length !== 0 ? (
              <Badge variant="primary" onClick={() => selectedItem({item: elem, action: 'showLinks'})}>
                <FontAwesomeIcon icon={faList} />
              </Badge>
            ) : '----'
          }
          
        </td>
        <td>
          <Link to="#" onClick={() => selectedItem({item: elem,action: 'delete'})} className="btn btn-danger btn-sm">
            <FontAwesomeIcon icon={faTrash} />
          </Link>
        </td>
      </tr >      
    </>
  );
};

export default PromoItemList;