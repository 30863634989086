import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../config/api';

export const deleteUser = createAsyncThunk(
    'consumer-users/delete',
    async ({ id }) => {
        console.log("Invio dettagli: ", { id }); // Log dettagliato
        const response = await api.delete(`/v1/consumer-users/${id}`);
        console.log("Risposta del server: ", response.data); // Log della risposta
        return response.data;
    }
);