import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { Col } from 'react-bootstrap';

import renderSelectField from '@/shared/components/form/Select';
import PasswordField from '@/shared/components/form/Password';
import FormField from '@/shared/components/form/FormField';
import {
    Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import {
    FormButtonToolbar, FormContainer, FormGroupField, FormGroupLabel, FormGroup,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import validate from './validate';
import renderRadioButtonField from '@/shared/components/form/RadioButton';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// Importa la tua action
import { fetchUserDetails } from '../../actions/detail';
import { updateUserDetails } from '../../actions/update';

const HorizontalForm = ({ onSubmit }) => {
    const dispatch = useDispatch();
    const { userId } = useParams(); // Ottiene lo userId dalla URL
    const userDetails = useSelector((state) => state.consumerUsers.detail); // Modifica in base al tuo state tree
    
    useEffect(() => {
        dispatch(fetchUserDetails(userId)); // Effettua la chiamata API quando il componente viene montato
    }, [dispatch, userId]);

    // const state = useSelector(state => state);
    
    const formRef = useRef(); 

    return (
        <Col md={12} lg={12} xl={12}>
            <Card>
                <CardBody>
                    <CardTitleWrap>
                        <CardTitle>anagrafica</CardTitle>
                        
                    </CardTitleWrap>
                    
                    <Form onSubmit={onSubmit} initialValues={extractInitialValues(userDetails)} validate={validate}>
                        {({
                            handleSubmit,
                            form: { reset },
                        }) => (
                            <FormContainer horizontal onSubmit={handleSubmit}>
                                <FormGroup>
                                    <FormGroupLabel>Nome</FormGroupLabel>
                                    <FormGroupField>
                                        <Field
                                            name="name"
                                            component={FormField}
                                            type="text"
                                            placeholder="Nome"
                                        />
                                    </FormGroupField>
                                </FormGroup>
                                <FormGroup>
                                    <FormGroupLabel>Cognome</FormGroupLabel>
                                    <FormGroupField>
                                        <Field
                                            name="surname"
                                            component={FormField}
                                            type="text"
                                            placeholder="Cognome"
                                        />
                                    </FormGroupField>
                                </FormGroup>
                                <FormGroup>
                                    <FormGroupLabel>E-mail</FormGroupLabel>
                                    <FormGroupField>
                                        <Field
                                            name="email"
                                            component={FormField}
                                            type="email"
                                            placeholder="example@mail.com"
                                        />
                                    </FormGroupField>
                                </FormGroup>
                                
                                
                                <CardTitleWrap>
                                    <CardTitle>Dati di accesso</CardTitle>

                                </CardTitleWrap>
                                <FormGroup>
                                    <FormGroupLabel>Username</FormGroupLabel>
                                    <FormGroupField>
                                        <Field
                                            name="username"
                                            component={FormField}
                                            type="text"
                                            placeholder="Username"
                                        />
                                    </FormGroupField>
                                </FormGroup>
                                
                                <FormGroup>
                                    <FormGroupLabel>Nuova Password</FormGroupLabel>
                                    <FormGroupField>
                                        <Field
                                            name="password"
                                            component={PasswordField}
                                            placeholder="Password"
                                            keyIcon
                                        />
                                    </FormGroupField>
                                </FormGroup>
                                <FormGroup>
                                    <FormGroupLabel>Conferma password</FormGroupLabel>
                                    <FormGroupField>
                                        <Field
                                            name="confirm_password"
                                            component={PasswordField}
                                            placeholder="Ripeti Password"
                                            keyIcon
                                        />
                                    </FormGroupField>
                                </FormGroup>
                                <FormButtonToolbar>
                                    <Button variant="primary" type="submit">Modifica</Button>
                                    <Button variant="secondary" type="button" onClick={reset}>
                                        Reset
                                    </Button>
                                </FormButtonToolbar>
                            </FormContainer>
                        )}
                    </Form>
                    
                </CardBody>
            </Card>
        </Col>
    );
    
};

const extractInitialValues = (userDetails) => {
    if (userDetails && userDetails.details) {
        let initialValues = {
            name: userDetails.details.name,
            surname: userDetails.details.surname,
            email: userDetails.details.email,
            username: userDetails.details.username,
        };

        if (userDetails.details.structured_data) {
            try {
                const structuredDataObj = JSON.parse(userDetails.details.structured_data);
                if (structuredDataObj && structuredDataObj.sesso !== undefined) {
                    if (structuredDataObj.sesso == 0) {
                        initialValues.gender = false;
                    } else {
                        initialValues.gender = true;
                    }
                    console.log("sesso " + initialValues.gender);
                    //initialValues.gender = (structuredDataObj.sesso === 1); // Converte il genere in booleano
                }
            } catch (error) {
                console.error('Error parsing structured_data:', error);
            }
        }
        return initialValues;
    } else {
        return {};
    }
};

HorizontalForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default HorizontalForm;
