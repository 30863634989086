import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Card, CardBody, CardTitle, CardTitleWrap } from '@/shared/components/Card';
import MetabaseQuestion from '../UI/MetabaseQuestion';

const Payments = () => {

  const questionId = process.env.REACT_APP_ENVIRONMENT=='production' ? 718 : 702;;

  return (
    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle className="d-block">
            Acquisti Esa Store
          </CardTitle>
        </CardTitleWrap>
        <MetabaseQuestion question_id={questionId} plan={false} bordered={false} titled={false} />
      </CardBody>
    </Card>
  )
}

export default Payments
