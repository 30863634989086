/* eslint-disable */
const validate = (values) => {
  const errors = {};
  if (!values.domain) {
    errors.domain = 'Il campo dominio non può essere vuoto';
  }

  return errors;
};

export default validate;
