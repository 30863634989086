import { createSlice } from '@reduxjs/toolkit';
import { fetchJson } from '../actions/fetchJson';

const fetchJsonSlice = createSlice({
    name: 'fetchJson',
    initialState: {
        item: '',
        loading: 'idle',
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchJson.pending, (state) => {
                state.loading = 'loading';
            })
            .addCase(fetchJson.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.item = action.payload;
                
            })
            .addCase(fetchJson.rejected, (state) => {
                state.loading = 'idle';
            });
    },
});

export default fetchJsonSlice.reducer;