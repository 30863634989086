import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../config/api';

export const sendHibridNotify = createAsyncThunk(
    'reviews/sendHibridNotify',
    async ({ email, reviewId }) => {
        console.log('try to send email to: ' + email + ' for review: ' + reviewId)
        const response = await api.get(`/v1/reviews/send-hibrid-notify/${email}/${reviewId}`);
        return response.data;
    }
);