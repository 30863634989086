import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';

import { Col, Row, Container, Spinner } from 'react-bootstrap';

import renderSelectField from '@/shared/components/form/Select';
import PasswordField from '@/shared/components/form/Password';
import FormField from '@/shared/components/form/FormField';
import {
    Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import {
    FormButtonToolbar, FormContainer, FormGroupField, FormGroupLabel, FormGroup,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import validate from './validate';
import renderRadioButtonField from '@/shared/components/form/RadioButton';
import customStyle from '@/shared/assets/css/custom.module.css';


const HorizontalForm = ({ onSubmit }) => {

    return (
        <Col md={12} lg={12} xl={12}>
            <Card>
                <CardBody>
                    <CardTitleWrap>
                        <CardTitle>anagrafica</CardTitle>
                        
                    </CardTitleWrap>
                    <Form onSubmit={onSubmit} validate={validate} autoComplete="off">
                        {({
                            handleSubmit,
                            form: { reset },
                        }) => (
                            <FormContainer onSubmit={handleSubmit} className="w-100">
                                <Col md={12} lg={12} xl={12}>
                                    <Row>
                                        <Col md={6} lg={6} xl={6}>
                                            <FormGroup>
                                                <FormGroupLabel>Dominio</FormGroupLabel>
                                                <FormGroupField>
                                                    <Field
                                                        name="domain"
                                                        component={FormField}
                                                        type="text"
                                                        placeholder="es. (www.)dominio.it"
                                                    />
                                                </FormGroupField>
                                            </FormGroup>
                                        </Col> 
                                        <Col md={6} lg={6} xl={6}>
                                            <FormButtonToolbar>
                                                <Button variant="primary" type="submit">Aggiungi</Button>
                                                <Button variant="secondary" type="button" onClick={reset}>
                                                    Reset
                                                </Button>
                                            </FormButtonToolbar>
                                        </Col>
                                    </Row>
                                </Col>
                            </FormContainer>
                        )}
                    </Form>
                </CardBody>
            </Card>
        </Col>
    );
};

HorizontalForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default HorizontalForm;
