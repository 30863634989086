import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { Card, CardBody, CardTitleWrap } from '@/shared/components/Card';
import { Table } from '@/shared/components/TableElements';
import styled from 'styled-components';
import { colorAccent } from '@/utils/palette';
import { fetchDeadlines } from './actions/fetchDeadlines';
import PaginationTable from '@/containers/Components/PaginationTable';
import DeadlineItemList from './components/DeadlineItemList';

const DeadlinesTable = () => {
  const dispatch = useDispatch();
  const itemsState = useSelector(state => state.esastore.deadlines)
  const items = itemsState.entities;
  const loading = itemsState.loading;
  const pagination = itemsState.pagination;
  const TableSpinner = styled(Spinner)`color: ${colorAccent};`;
  const [globalFilters, setGlobalFilters] = useState({ 'per-page': 25, page: 1 });
  const tableLabels = ['Dominio', 'Prodotto', 'Sottoprodotto', 'Link', 'Prezzo', 'Pagamento', 'Scadenza']

  useEffect(() => {
    const initialPayload = {
      params: globalFilters
    };
    dispatch(fetchDeadlines(initialPayload));
  }, [dispatch]);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Elenco Scadenze</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <CardTitleWrap>
                <Row className='align-items-center'>
                  <Col xs={12} lg={4}>
                    <p className="page-subtitle">Numero elementi: {pagination?.total_count}</p>
                  </Col>
                </Row>
              </CardTitleWrap>
              {loading === 'loading' ? (
                <div className="text-center">
                  <TableSpinner animation="border" />
                </div>
              ) : (
                <>
                  <Table responsive striped bordered>
                    <thead>
                      <tr>
                        {tableLabels.map((label) => {
                          return <th>{label}</th>
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {items && loading === 'idle' && items.length > 0 && (
                        items.map((elem) => {
                          return (<DeadlineItemList item={elem} />)
                        })
                      ) || (
                          <tr>
                            <td colSpan={tableLabels.length} className='text-start'>Nessun elemento trovato</td>
                          </tr>
                        )}
                    </tbody>
                  </Table>
                  {items && items.length > 0 && (
                    <PaginationTable pagination={pagination} filters={globalFilters} action={fetchDeadlines} />
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

DeadlinesTable.propTypes = {
  onSubmit: PropTypes.func,
};

export default DeadlinesTable;