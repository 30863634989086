import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { statusMapping, } from '@/utils/utilities';
import { Accordion, Col, Row, Button, Card, Form } from 'react-bootstrap';
import { formatDate, checkReviewMedia } from '@/utils/utilities';
import Badge from '@/shared/components/Badge';
import MediaModal from '@/containers/Reviews/mediaManagement';
import { updateReview } from '@/containers/Reviews/actions/updateReview';
import { updateProductReview } from '@/containers/Reviews/actions/updateProductReview';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImage, faFileContract, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import ReviewDetailModal from '@/containers/Reviews/components/modals/ReviewDetailModal';
import ReviewRatingDetails from './ReviewRatingDetails';
import customStyle from '@/shared/assets/css/custom.module.css'
import { successNotify, warningNotify } from '@/containers/App/Router/index';
import ReviewAlerts from './ReviewAlerts';

function ReviewValidationItem({ item, isProduct = false, reasons, toRefresh }) {

  const dispatch = useDispatch();
  const details_rating = item.review.details_rating ? JSON.parse(item.review.details_rating) : {};
  const [imageModalContent, setImageModalContent] = useState(null);
  const review_medias_obj = checkReviewMedia(item.review);
  const [selectedElem, setSelectedElem] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showNonValidationReason, setShowNonValidationReason] = useState(false);
  const [nonValidationReason, setNonValidationReason] = useState('');
  const optinWaiting = item.user?.visibility != 1 && item.review?.order_id == null && item.review?.status == '0' ? true : false;

  const handleChangeStatus = (reviewId, status, reason = false) => {
    debugger
    let reviewData = {};
    const statusCode = statusMapping[status] !== undefined ? statusMapping[status] : null;
    if (status === "valid_certificate") {
      reviewData = {
        status: 1,
        certified: 2,
      };
    } else if (status === "not_valid") {
      reviewData = {
        status: 2,
      };
      if (reason) {
        reviewData['novalidation_reason'] = reason;
      }
    } else {
      reviewData = {
        status: statusCode,
      };
    }
    if (isProduct) {
      dispatch(updateProductReview({ id: reviewId, data: reviewData })).then(() => {
        toRefresh(true);
        successNotify();
      });
    }
    else {
      dispatch(updateReview({ id: reviewId, data: reviewData })).then(() => {
        toRefresh(true);
        successNotify();
      });
    }
  };

  let reviewReceipt = false;
  let countReceipt = 0
  if (item.review.receipt !== null && item.review.receipt !== "") {
    try {
      reviewReceipt = JSON.parse(item.review.receipt);
      countReceipt = Array.isArray(reviewReceipt) ? reviewReceipt.length : 0
    } catch (error) {
      console.error('Error parsing receipt:', item.review.receipt);
    }
  }

  const handleEditClick = (elem) => {
    setSelectedElem(elem);
    setModalShow(true);
  };

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleSave = (updatedElem) => {

    const reviewData = {
      title: updatedElem.review.title,
      description: updatedElem.review.description
    }

    if (isProduct) {
      dispatch(updateProductReview({ id: updatedElem.review.id, data: reviewData })).then(() => {
        handleClose();
        window.location.reload();
      });
    }
    else {
      dispatch(updateReview({ id: updatedElem.review.id, data: reviewData })).then(() => {
        handleClose();
        window.location.reload();
      });
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const toggleNonValidationReason = () => {
    setShowNonValidationReason(!showNonValidationReason);
  };

  return (
    <>
      <tr>
        <td className='py-0'>
          <Row className='justify-content-center'>
            <Col xs={12} md={4} className='mb-3 mb-md-0'>
              <Row className='my-1 my-md-3'>
                <Col><b>Eshop:</b></Col>
                <Col>
                  <Link to={`/registered-eshops/update/${item.eshop.id}`} target="_blank">{item.eshop.domain}</Link>
                  <Badge bg="success" className="mx-2 py-1 px-2">{item.eshop.plan}</Badge>
                </Col>
              </Row>
              <Row className='my-1 my-md-3'>
                <Col><b>Data inserimento:</b></Col>
                <Col>{formatDate(item.review.insert_date)}</Col>
              </Row>
              <Row className='my-1 my-md-3'>
                <Col><b>Utente:</b></Col>
                <Col>
                  {item.user?.email ? (
                    <Link to={`/consumer_users/detail/${item.user.id}`} target="_blank">
                      {item.user.email}
                    </Link>
                  ) : (
                    item.review.user_name + ' ' + item.review.user_surname
                  )}
                </Col>
              </Row>
              <Row className='my-1 my-md-3'>
                <Col><b>Valutazione media:</b></Col>
                <Col>
                  {item.review.overall_rating}
                </Col>
              </Row>
              <Row className='my-1 my-md-3'>
                <Col><b>Meta:</b></Col>
                <Col>
                  <ReviewAlerts review={item.review} />
                  {
                    optinWaiting && (
                      <Badge bg={"secondary"}>{"Attesa optin"}</Badge>
                    )
                  }
                </Col>
              </Row>
              {!isProduct && (
                <>
                  <Row className='d-flex d-lg-none my-1 my-md-3'>
                    <Col><b>Dettaglio valutazioni:</b></Col>
                    <Col>
                      <Button variant='secondary' onClick={() => setOpen(!open)} className='px-2 py-1' size='sm'>
                        {open ? 'Nascondi' : 'Mostra'}
                        <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} className='ms-2' />
                      </Button>
                    </Col>
                  </Row>
                  <Row className='d-block d-lg-none my-1 my-md-3'>
                    <Col>
                      <Accordion activeKey={open ? '0' : null}>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <ReviewRatingDetails details_rating={details_rating} />
                          </Card.Body>
                        </Accordion.Collapse>
                      </Accordion>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
            {!isProduct && (
              <Col xs={12} md={4} className='d-none d-lg-block mb-3 mb-md-0'>
                <ReviewRatingDetails details_rating={details_rating} />
              </Col>
            )}
            <Col xs={12} md={4} className='mb-3 mb-md-0'>
              <Row className='my-1 my-md-3'>
                <Col><b>Titolo:</b></Col>
                <Col><span className={customStyle.clickable} onClick={() => handleEditClick(item)}>{item.review.title}</span></Col>
              </Row>
              <Row className='my-1 my-md-3'>
                <Col><b>Descrizione:</b></Col>
                <Col>{item.review.description}</Col>
              </Row>
              {!isProduct && (
                <Row className='my-1 my-md-3'>
                  <Col><b>Prova d'acquisto:</b></Col>
                  <Col>
                    {reviewReceipt ? (
                      <>
                        {
                          <a href={process.env.REACT_APP_ESA_CDN_URL + `reviewsReceipt/${item.review.id}/${reviewReceipt[countReceipt - 1]}`}
                            target="_blank"
                            rel="noreferrer"
                            download
                          >
                            <Button variant="warning" className="px-2 py-1" size="sm">
                              <FontAwesomeIcon icon={faFileContract} />
                            </Button>
                          </a>
                        }
                      </>
                    ) : '-'}
                  </Col>
                </Row>
              )}
              <Row className='my-1 my-md-3'>
                <Col><b>Foto / Video:</b></Col>
                <Col>
                  {(review_medias_obj !== undefined && review_medias_obj.has_data) ? (
                    <Button variant="info" className="px-2 py-1" size="sm" onClick={() => setImageModalContent(review_medias_obj)}>
                      <FontAwesomeIcon icon={faFileImage} />
                    </Button>
                  ) : '-'}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              {showNonValidationReason && (
                <>
                  <Form.Group className="mb-3">
                    <Form.Label>Motivazione Non Validazione</Form.Label>
                    <Form.Control as="select" value={nonValidationReason} onChange={(e) => setNonValidationReason(e.target.value)}>
                      <option value="">Seleziona una motivazione...</option>
                      {reasons.reasons.map((reason) => (
                        <option key={reason.id} value={reason.id}>{reason.title}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Button variant="danger" disabled={!nonValidationReason} onClick={() => handleChangeStatus(item.review.id, "not_valid", nonValidationReason)}>Conferma Non Validazione</Button>
                </>
              )}
            </Col>
          </Row>
          <Row className="mb-0 mt-3 mr-3 mx-2 py-3 border-top d-flex justify-content-center align-items-center w-100">
            <Col xs={'auto'} className="text-center my-2">
              <Button className="mb-0" variant="outline-dark" onClick={() => handleChangeStatus(item.review.id, 'suspend')}>Sospendi</Button>
            </Col>
            {
              !optinWaiting && (
                <>
                  <Col xs={'auto'} className="text-center my-2">
                    <Button className="mb-0" variant="outline-danger" onClick={toggleNonValidationReason}>Non Validare</Button>
                  </Col>
                  {!isProduct && (
                    <Col xs={'auto'} className="text-center my-2">
                      <Button className="mb-0" variant="outline-warning" onClick={() => handleChangeStatus(item.review.id, 'valid_certificate')}>Valida e Verifica</Button>
                    </Col>
                  )}
                  <Col xs={'auto'} className="text-center my-2">
                    <Button className="mb-0" variant="outline-success" onClick={() => handleChangeStatus(item.review.id, 'valid')}>Valida</Button>
                  </Col>
                </>
              )
            }
          </Row>
        </td >
      </tr >
      <MediaModal show={imageModalContent !== null} handleClose={() => setImageModalContent(null)} title="Gestione Media" review_data={imageModalContent} />
      {
        modalShow && (
          <ReviewDetailModal
            show={modalShow}
            handleClose={handleModalClose}
            elem={selectedElem}
            handleSave={handleSave}
            handleChangeStatus={handleChangeStatus}
          />
        )
      }
    </>
  );
}

export default ReviewValidationItem;