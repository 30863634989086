import { combineReducers } from 'redux';
import censoredWordsReducer from './censoredWords';
import fetchReviewsReducer from './fetchReviews';
import modifiedReviews from './modifiedReviewsReducer';
import reasonsSlice from './reasonsReducer';
import reportReviews from './fetchReportReviewsReducer';
import eshopReplies from './fetchEshopRepliesReducer';
import eshopPrivateReplies from './fetchEshopPrivateRepliesReducer';

const reviewsReducer = combineReducers({
    censoredWords: censoredWordsReducer,
    fetch: fetchReviewsReducer,
    modifiedReviews: modifiedReviews,
    reasons: reasonsSlice,
    reportReviews: reportReviews,
    replies: eshopReplies,
    privateReplies: eshopPrivateReplies,
});

export default reviewsReducer;