import { combineReducers } from 'redux';
import fetchReducer from './fetch';
import insertReducer from './insert';
import updateReducer from './update';
//import detailReducer from './detail';

const b2bUsersReducer = combineReducers({
    fetch: fetchReducer,
    insert: insertReducer,
    update: updateReducer
    //detail: detailReducer,
});

export default b2bUsersReducer;
