import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import FormField from '@/shared/components/form/FormField';
import {
    Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';


import { Button, ButtonToolbar, ButtonGroup } from '@/shared/components/Button';
import {
    FormButtonToolbar,
    FormContainer,
    FormGroup,
    FormGroupField,
    FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Container, Row, Col } from 'react-bootstrap';
import { fetchCensoredWords } from './actions/censoredWords';

import PlusIcon from 'mdi-react/PlusIcon';
import AddUserIcon from 'mdi-react/AccountPlusOutlineIcon';
import DeleteUserIcon from 'mdi-react/AccountCancelOutlineIcon';
import DatepickerField from '@/containers/UI/DatepickerField';
import DeleteForeverIcon from 'mdi-react/DeleteForeverIcon';
import { Table } from '@/shared/components/TableElements';

import Badge from '@/shared/components/Badge';
import { Modal } from 'react-bootstrap';

const Homepage = () => {
    const dispatch = useDispatch();
    const wordsFromRedux = useSelector(state => state.reviews.censoredWords.words || "");

    const [words, setWords] = useState(wordsFromRedux);

    const entireState = useSelector(state => state);
    console.log("Entire state:", entireState);

    const [showModal, setShowModal] = useState(false);
    const handleClose = () => {
        setShowModal(false);
    };

    const [userIdToDelete, setUserIdToDelete] = useState(null);

    const handleConfirmDelete = () => {
        // qui si può eseguire la logica per cancellare l'utente, 
        // ad esempio chiamare un'API o dispatchare un'azione di Redux
        console.log(`Deleting user with id ${userIdToDelete}`);

        // dopo aver cancellato l'utente, chiudi il modal
        handleClose();
    };

    const handleDeleteClick = () => {
        // qui si può eseguire la logica per cancellare l'utente, 
        // ad esempio chiamare un'API o dispatchare un'azione di Redux
        console.log(`Deleting user with id ${userIdToDelete}`);

    };

    useEffect(() => {
        dispatch(fetchCensoredWords());

    }, [dispatch]);

    useEffect(() => {
        setWords(wordsFromRedux);

    }, [wordsFromRedux]);

    const onSubmit = (values) => {
        console.log(values);
        console.log(words);
    };

    return (
        <Container>
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Elenco Banner</h3>
                </Col>
            </Row>
            
            <Row>
                <Col md={12} lg={12} xl={12}>
                    <Card>
                        <CardBody>
                            <CardTitleWrap>

                                <Row>
                                    <Col md={4}>
                                        <p className="page-subtitle"></p>
                                    </Col>
                                    <Col md={8} style={{ textAlign: 'right' }}>
                                        <Button as={Link} to="/consumer_users/add" variant="outline-primary" size='sm'>
                                            <PlusIcon />
                                            <span>Aggiungi Banner Interno</span>
                                        </Button>
                                        <Button as={Link} to="/consumer_users/add" variant="outline-primary" size='sm'>
                                            <PlusIcon />
                                            <span>Aggiungi Banner Esterno</span>
                                        </Button>
                                    </Col>
                                    
                                </Row>
                            </CardTitleWrap>



                            <Table responsive striped bordered>
                                        <thead>
                                            <tr>
                                                
                                                <th>Banner</th>
                                                <th>Circuito</th>
                                                <th>Stato</th>
                                                <th></th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                    <tr>
                                        
                                        <td className="first-letter-uppercase"><Link to={`/properties/banner-detail/`}>Banner test</Link></td>
                                        <td className="first-letter-uppercase">
                                            Eshop | Ricerca eshop | Scrivi recensione | Pagina utente
                                        </td>
                                        <td className="first-letter-uppercase">
                                            <Badge bg="success">Attivo</Badge>
                                        </td>
                                        <td><ButtonToolbar>
                                            <ButtonGroup icons dir="ltr">

                                                <Button variant="danger" onClick={() => handleDeleteClick("elem.id")} title="Elimina"><span className="lnr lnr-trash" /></Button>
                                            </ButtonGroup>
                                        </ButtonToolbar></td>
                                        
                                    </tr>
                                        </tbody>
                                    </Table>

                                    <Modal show={showModal} onHide={handleClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Conferma eliminazione</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>Sei sicuro di voler cancellare l'utente?</Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleClose}>
                                                Annulla
                                            </Button>
                                            <Button variant="danger" onClick={handleConfirmDelete}>
                                                Elimina
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                
                            

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Homepage;
