import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import {
  themeReducer,
  rtlReducer,
  customizerReducer,
  sidebarReducer,
  roundBordersReducer,
  blocksShadowsReducer
} from '@/redux/reducers/index';
import appConfigReducer from '@/redux/reducers/appConfigReducer';


import consumerUsersReducer from '../ConsumerUsers/reducers';
import eshopsReducer from '../Eshops/reducers';
import couponsReducer from '../Coupons/reducers';
import paymentsReducer from '../Payments/reducers';
import b2bUsersReducer from '../B2bUsers/reducers';
import eshopBlocklistReducer from '../EshopBlocklist/reducers';
import plansReducer from '../Plans/reducers';
import invitationsReducer from '../Invitations/reducers';
import reviewsReducer from '../Reviews/reducers';
import esastoreReducer from '../EsaStore/reducers';
import propertiesReducer from '../Properties/reducers';
import adminsReducer from '../Admins/reducers';
import blockedEmailsReducer from '../BlockedEmails/reducers';

const reducer = combineReducers({
  theme: themeReducer,
  rtl: rtlReducer,
  border: roundBordersReducer,
  shadow: blocksShadowsReducer,
  appConfig: appConfigReducer,
  customizer: customizerReducer,
  sidebar: sidebarReducer,
  consumerUsers: consumerUsersReducer,
  eshops: eshopsReducer,
  coupons: couponsReducer,
  payments: paymentsReducer,
  b2bUsers: b2bUsersReducer,
  eshopBlocklist: eshopBlocklistReducer,
  plans: plansReducer,
  invitations: invitationsReducer,
  reviews: reviewsReducer,
  esastore: esastoreReducer,
  properties: propertiesReducer,
  admins: adminsReducer,
  blockedEmails: blockedEmailsReducer,
});

const store = createStore(reducer, applyMiddleware(thunk));

export default store;
