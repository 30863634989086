import React, { useEffect , useState } from 'react';
import { Link } from 'react-router-dom';


const SettingsItem = ({ item, selectedItem}) => {

  const [elem, setElem] = useState(item); 

  useEffect(() => {
      setElem(item);
  }, [item]);

  return (
    <>
      <tr key={elem.id}>        
        <td>
            {elem.category}
        </td>
        <td>
          <Link to="#" onClick={() => selectedItem({item: elem, action: 'update'})}>
            {elem.key}
          </Link>
        </td>
        <td>
          <Link to="#" onClick={() => selectedItem({item: elem, action: 'update'})}>
            {elem.description}
          </Link>
        </td>
        <td>
          {elem.value}
        </td>
      </tr >      
    </>
  );
};

export default SettingsItem;