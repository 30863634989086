import { createSlice } from '@reduxjs/toolkit';
import { fetchBlochedEmails} from '../actions/fetch';

export const fetchBlochedEmailsSlice = createSlice({
    name: 'fetchBlockedEmails',
    initialState: {
        entities: [],
        loading: 'idle',
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchBlochedEmails.pending, (state) => {
                state.loading = 'loading';
            })
            .addCase(fetchBlochedEmails.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.entities = action.payload;
                console.log(action);
            })
            .addCase(fetchBlochedEmails.rejected, (state) => {
                state.loading = 'idles';
            });
    },
});

export default fetchBlochedEmailsSlice.reducer;
