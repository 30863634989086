import { React, useState } from 'react';
import AsyncSelect from 'react-select/async';
import {api} from '@/config/api';
import styled from 'styled-components';
import FormField from '@/shared/components/form/FormField';
import { Field } from 'react-final-form';

const SelectAJX = (data) => {
    
    const [selectValue, setSelectValue] = useState('');

    const updateRefInput = (value) => {
        console.log(value);
        setSelectValue(value.value);
    }

    const loadOptions =  (inputValue) => {        
        console.log(data.name);
        if(inputValue !== '')
        {
            //var returnData;
            console.log('data: '+data.optionsUrl);
            console.log(inputValue);

            return api.get(data.optionsUrl+'/'+inputValue).then((response) => {
                console.log(typeof response.data);
                return response.data;
            });
        }
    }

    return (
        <>
        <StyledAsyncSelect 
            cacheOptions
            loadOptions={loadOptions}
            defaultOptions
            className="select2-single"
            classNamePrefix="dashboard-select2"
            onChange={updateRefInput}
        />
        <Field
            name={data.name}
            component={FormField}
            type="hidden"
            initialValue={selectValue}
        />
        </>
    );
}

export default SelectAJX;

const StyledAsyncSelect = styled(AsyncSelect)`

    .dashboard-select2__control
    {
        background-color: transparent;
        border: 1px solid #33333a;
    }
    .dashboard-select2__input input
    {
        color: #fff !important;
    }
    .dashboard-select2__single-value
    {
        color: #fff !important;
    }
    .dashboard-select2__option
    {
        background-color: #232329;
    }
    .dashboard-select2__menu
    {
        background-color: #232329;
        color: #fff;
        border: 1px solid #33333a;

    }

`;