import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { getStoreCategories } from '../actions/getStoreCategories';
import { insertProduct } from '../actions/insertProduct';
import { update } from '../actions/update';
import { successNotify, warningNotify } from '../../App/Router';

const ProductForm = ({ elem = false, lockPrice = false, refreshTable = false, setShowModal = false }) => {

  const dispatch = useDispatch();
  const initialItem = elem != false ? elem : {};
  const action = elem != false ? update : insertProduct;
  const [updatedElem, setUpdatedElem] = useState(initialItem);
  const [categories, setCategories] = useState([]);

  let periodOptions = [
    { value: null, label: 'Seleziona cadenza' },
    { value: '-1', label: '-' },
  ];

  for (let i = 1; i <= 12; i++) {
    periodOptions.push({ value: i, label: i.toString() });
  }

  const handleInsert = () => {
    const createdElem = { ...updatedElem };
    const payload = elem ? { id: elem.id, data: createdElem } : { data: createdElem };
    dispatch(action(payload)).then((res) => {
      if (res.payload?.success) {
        successNotify();
        if (setShowModal) {
          setShowModal(false);
        }
        setUpdatedElem({});
        if (refreshTable) {
          refreshTable(true);
        }
      }
      else {
        warningNotify();
      }
    })
  }

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    let val = type === 'checkbox' ? (checked ? 1 : 0) : value;
    const keys = name.split('[').map(key => key.replace(']', ''));
    const t = updatedElem;
    setUpdatedElem((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      let currentPart = newState;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!currentPart[keys[i]]) {
          currentPart[keys[i]] = {};
        }
        currentPart = currentPart[keys[i]];
      }
      currentPart[keys[keys.length - 1]] = val;
      return newState;
    });
  };

  useEffect(() => {
    dispatch(getStoreCategories()).then((response) => {
      const options = response.payload.items.map((cat) => ({
        value: cat.id,
        label: cat.name
      }));

      setCategories(options);
    });
  }, []);

  useEffect(() => {
    if (elem != false) {
      setUpdatedElem(elem);
    }
  }, [elem]);

  return (
    <Form autoComplete="off">
      <Container>
        <Row>
          <Col xs={12} lg={6} className='my-3'>
            <Form.Group controlId={`cost`}>
              <Form.Label>Titolo</Form.Label>
              <Form.Control
                name={'title'}
                value={updatedElem.title}
                type="text"
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg={6} className='my-3'>
            <Form.Group controlId={`cost`}>
              <Form.Label>Codice Prodotto (ID transazione)</Form.Label>
              <Form.Control
                name={'product_code'}
                value={updatedElem.product_code}
                type="text"
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col xs={12} className='my-3'>
            <Form.Group controlId={`cost`}>
              <Form.Label>Descrizione</Form.Label>
              <Form.Control
                name={'description'}
                value={updatedElem.description}
                type="text"
                as="textarea"
                rows={5}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg={4} className='my-3'>
            <Form.Group controlId={`price`}>
              <Form.Label>Prezzo</Form.Label>
              <Form.Control
                name={'price'}
                value={updatedElem.price}
                type="text"
                onChange={handleChange}
                disabled={lockPrice}
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg={4} className='my-3'>
            <Form.Group controlId={`period`}>
              <Form.Label>Cadenza</Form.Label>
              <Form.Select name={'period'} value={updatedElem.period} onChange={handleChange}>
                {periodOptions.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} lg={4} className='my-3'>
            <Form.Group controlId={`category_id`}>
              <Form.Label>Categoria</Form.Label>
              <Form.Select name={'category_id'} value={updatedElem.category_id} onChange={handleChange}>
                <option key={null} value={null}>
                  Seleziona categoria
                </option>
                {categories.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} lg={4} className='my-3'>
            <Form.Group controlId={`delivery_form`}>
              <Form.Label>Form spedizione</Form.Label>
              <Form.Check
                name={'delivery_form'}
                value={updatedElem.delivery_form}
                type="checkbox"
                checked={updatedElem.delivery_form}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className='my-3 text-end'>
            <Button onClick={() => handleInsert()} size="sm" variant={'success'}>
              <FontAwesomeIcon icon={faSave} className="me-2" /> Salva
            </Button>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default ProductForm;