import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import {
    Col,
    Container,
    Row,
    Spinner,
} from 'react-bootstrap';
import {
    Card, CardBody, CardTitleWrap, CardTitle, CardSubhead
} from '@/shared/components/Card';
import { Table } from '@/shared/components/TableElements';
import styled from 'styled-components';
import { Button, ButtonToolbar, ButtonGroup } from '@/shared/components/Button';
import PlusIcon from 'mdi-react/PlusIcon';
import DragVerticalIcon from 'mdi-react/DragVerticalIcon';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const MyTable = () => {
    // Items definiti direttamente all'interno del componente per il test
    const items = [
        { id: 'item-1', content: 'Elemento 1' },
        { id: 'item-2', content: 'Elemento 2' },
        { id: 'item-3', content: 'Elemento 3' },
        // ... altri elementi
    ];

    const [rows, setRows] = useState(items);

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const newRows = reorder(
            rows,
            result.source.index,
            result.destination.index
        );

        setRows(newRows);
        // Qui puoi ottenere gli indici e fare altre operazioni
        console.log(`Riga spostata da ${result.source.index} a ${result.destination.index}`);
    };

    return (
        <Container>
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Menu Footer</h3>
                </Col>
            </Row>

            <Row>
                <Col md={12} lg={12} xl={12}>
                    <Card>
                        <CardBody>
                            <CardTitleWrap>

                                <Row>
                                    <Col md={4}>
                                        <p className="page-subtitle"></p>
                                    </Col>
                                    <Col md={8} style={{ textAlign: 'right' }}>
                                        <Button as={Link} to="/consumer_users/add" variant="outline-primary" size='sm'>
                                            <PlusIcon />
                                            <span>Collega una pagina del sito</span>
                                        </Button>

                                    </Col>

                                </Row>
                            </CardTitleWrap>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppableTable">
                                    {(provided) => (
                                        <Table bordered
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            <tbody>
                                                {rows.map((row, index) => (
                                                    <Draggable key={row.id} draggableId={row.id} index={index}>
                                                        {(provided) => (
                                                            <tr
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <td>
                                                                    <DragVerticalIcon size={24} />
                                                                </td>
                                                                <td>{row.content}</td>
                                                                <td style={{ textAlign: 'right' }}>
                                                                    <ButtonToolbar>
                                                                        <ButtonGroup icons dir="ltr">

                                                                            <Button variant="danger" title="Elimina"><span className="lnr lnr-trash" /></Button>
                                                                        </ButtonGroup>
                                                                    </ButtonToolbar>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </tbody>
                                        </Table>
                                    )}
                                </Droppable>
                            </DragDropContext>

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>

    );
};

export default MyTable;
