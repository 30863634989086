import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import FilterForm from './components/filters/filter';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { Card, CardBody, CardTitleWrap } from '@/shared/components/Card';
import { Table } from '@/shared/components/TableElements';
import styled from 'styled-components';
import { cleanDate } from '@/shared/helpers';
import { colorAccent } from '@/utils/palette';
import { fetchEshops } from './actions/fetch';
import EshopItemList from './components/EshopItemList';
import PaginationTable from '@/containers/Components/PaginationTable';
import { TableCheckbox } from '@/shared/components/MaterialTableElements';
import { Button } from '@/shared/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { customSuccessNotify, customWarningNotify } from '../App/Router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { sendOptinRequest } from './actions/sendOptinRequest';

const EshopsTable = () => {
  const { t } = useTranslation('eshops');
  const dispatch = useDispatch();
  const eshopsState = useSelector(state => state.eshops.fetch)
  const eshops = eshopsState.entities;
  const loading = eshopsState.loading;
  const pagination = eshopsState.pagination;
  const TableSpinner = styled(Spinner)`color: ${colorAccent};`;
  const tableLabels = ['eshop', 'plan', 'email', 'phone', 'registration_date', 'optin_date', 'upgrade_date', 'promo_code', 'send_opt_in', 'carousel_enabled', 'login', 'status', 'visioned', 'billing_info', 'bookkeeping', 'actions'];
  const actionBaseUrl = '/v1/registered-eshops/admin-list'
  const [globalFilters, setGlobalFilters] = useState({ 'per-page': 25, page: 1 });
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [toRefresh, setToRefresh] = useState(false);

  const handleFilterSubmit = (values) => {
    let modifiedValues = { ...values };

    delete modifiedValues.creation_date_start;
    delete modifiedValues.creation_date_end;

    if (values.creation_date_start || values.creation_date_end) {
      modifiedValues.creation_date = {
        start: values.creation_date_start ? cleanDate(values.creation_date_start) : null,
        end: values.creation_date_end ? cleanDate(values.creation_date_end) : null,
      }
    }

    if (values.optin_date_start || values.optin_date_end) {
      modifiedValues.optin_date = {
        start: values.optin_date_start ? cleanDate(values.optin_date_start) : null,
        end: values.optin_date_end ? cleanDate(values.optin_date_end) : null,
      }
    }

    if (values.upgrade_date_start || values.upgrade_date_end) {
      modifiedValues.upgrade_date = {
        start: values.upgrade_date_start ? cleanDate(values.upgrade_date_start) : null,
        end: values.upgrade_date_end ? cleanDate(values.upgrade_date_end) : null,
      }
    }

    if (values.status) {
      modifiedValues.status = values.status.value;
    }

    if (values.billing_data) {
      modifiedValues.billing_data = values.billing_data.value;
    }

    if (values.plan) {
      modifiedValues.plan = values.plan.value;
    }

    if (values.pending_docs) {
      modifiedValues.pending_docs = values.pending_docs.value;
    }

    if (values.bookkeeping) {
      modifiedValues.bookkeeping = values.bookkeeping.value;
    }

    setGlobalFilters(modifiedValues);

    // Aggiungi page e perPage ai valori modificati
    modifiedValues.page = pagination.current_page;
    modifiedValues['per-page'] = 25

    const payload = {
      baseUrl: actionBaseUrl,
      params: modifiedValues
    };

    dispatch(fetchEshops(payload));
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
  }

  const handleSendOptin = () => {
    const elements = Object.keys(selectedItems).filter(key => selectedItems[key] === true)
    if (elements.length > 0) {
      const data = { ids: elements };
      dispatch(sendOptinRequest({ data: data })).then(() => {
        customSuccessNotify('Richiesta inviata con successo');
      });
    }
    else {
      customWarningNotify('Seleziona almeno un elemento');
    }
  }

  useEffect(() => {
    if (toRefresh) {
      setToRefresh(false);
    }
    const initialPayload = {
      baseUrl: actionBaseUrl,
      params: globalFilters
    };

    dispatch(fetchEshops(initialPayload));
  }, [dispatch, toRefresh]);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Elenco Eshops</h3>
        </Col>
      </Row>
      <FilterForm onSubmit={handleFilterSubmit} />
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <CardTitleWrap>
                <Row className='align-items-center'>
                  <Col xs={12} lg={4}>
                    <p className="page-subtitle">Numero elementi: {pagination.total_count}</p>
                  </Col>
                  <Col xs={12} lg={8} className='text-end'>
                    <Button variant={'warning'} onClick={handleSendOptin} size={'sm'}>
                      {t('send_optin')}
                      <FontAwesomeIcon icon={faEnvelope} className='ms-2' />
                    </Button>
                    {/* TODO: CHECK THIS ACTION
                      <Button as={Link} to="/registered-eshops/add" variant={'primary'} size={'sm'}>
                        {t('add_item_button_label')}
                        <FontAwesomeIcon icon={faUserPlus} className='ms-2' />
                      </Button> 
                    */}
                  </Col>
                </Row>
              </CardTitleWrap>
              {loading === 'loading' && (
                <div className="text-center">
                  <TableSpinner animation="border" />
                </div>
              )}
              {eshops && loading === 'idle' && eshops.length > 0 && (
                <>
                  <Table responsive striped bordered>
                    <thead>
                      <tr>
                        <th><TableCheckbox checked={selectAll} onChange={handleSelectAll} /></th>
                        {tableLabels.map((label) => {
                          return <th>{t('th_' + label)}</th>
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {eshops.map((elem) => {
                        return (<EshopItemList item={elem} isSelected={selectAll} handleSelected={setSelectedItems} selectedList={selectedItems} refreshTable={setToRefresh} />)
                      })}
                    </tbody>
                  </Table>
                  <PaginationTable pagination={pagination} filters={globalFilters} action={fetchEshops} />
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

EshopsTable.propTypes = {
  onSubmit: PropTypes.func,
};

export default EshopsTable;