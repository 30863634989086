import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { fetchProducts } from '../actions/fetchProducts';
import { insertSubProduct } from '../actions/insertSubProduct';
import { updateSub } from '../actions/updateSub';
import { successNotify, warningNotify } from '../../App/Router';

const SubProductForm = ({ prod_id, elem = false, lockPrice = false, refreshTable, setShowModal = false }) => {

  const dispatch = useDispatch();
  const initialItem = elem ? elem : {};
  const action = elem ? updateSub : insertSubProduct;
  const [updatedElem, setUpdatedElem] = useState(initialItem);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);

  const handleInsert = () => {
    const createdElem = { ...updatedElem };
    createdElem.prod_id = prod_id ? prod_id : selectedOption.value;
    const payload = elem ? { id: elem.id, data: createdElem } : { data: createdElem };
    dispatch(action(payload)).then((res) => {
      if (res.payload?.success) {
        successNotify();
        if (setShowModal) {
          setShowModal(false);
        }
        setUpdatedElem({});
        refreshTable(true);
      }
      else {
        warningNotify();
      }
    })
  }

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    let val = type === 'checkbox' ? (checked ? 1 : 0) : value;
    const keys = name.split('[').map(key => key.replace(']', ''));
    setUpdatedElem((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      let currentPart = newState;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!currentPart[keys[i]]) {
          currentPart[keys[i]] = {};
        }
        currentPart = currentPart[keys[i]];
      }
      currentPart[keys[keys.length - 1]] = val;
      return newState;
    });
  };

  const handleSelected = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    if (inputValue.length >= 2) {
      dispatch(fetchProducts({ title: inputValue })).then((data) => {
        const items = data.payload.items.map((item) => {
          return { value: item.id, label: item.title };
        });
        setOptions(items);
      });
    }
  }, [inputValue]);

  return (
    <Form autoComplete="off">
      <Container>
        <Row>
          <Col xs={12} className='my-3'>
            <Form.Group controlId={`prod_id`}>
              <Form.Label>Riferimento prodotto</Form.Label>
              {
                prod_id ? (
                  <Form.Control name="prod_id" type="hidden" value={prod_id} />
                ) : (
                  <Select
                    name="prod_id"
                    options={options}
                    value={selectedOption}
                    placeholder="Ricerca prodotto..."
                    onInputChange={setInputValue}
                    onChange={(selectedOption) => handleSelected(selectedOption)}
                  />
                )
              }
            </Form.Group>
          </Col>
          <Col xs={12} lg={6} className='my-3'>
            <Form.Group controlId={`cost`}>
              <Form.Label>Titolo</Form.Label>
              <Form.Control
                name={'title'}
                value={updatedElem.title}
                type="text"
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg={6} className='my-3'>
            <Form.Group controlId={`cost`}>
              <Form.Label>Codice Prodotto (ID transazione)</Form.Label>
              <Form.Control
                name={'product_code'}
                value={updatedElem.product_code}
                type="text"
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col xs={12} className='my-3'>
            <Form.Group controlId={`cost`}>
              <Form.Label>Descrizione</Form.Label>
              <Form.Control
                name={'description'}
                value={updatedElem.description}
                type="text"
                as="textarea"
                rows={5}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg={4} className='my-3'>
            <Form.Group controlId={`price`}>
              <Form.Label>Prezzo</Form.Label>
              <Form.Control
                name={'price'}
                value={updatedElem.price}
                type="text"
                onChange={handleChange}
                disabled={lockPrice}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className='my-3 text-end'>
            <Button onClick={() => handleInsert()} size="sm" variant={'success'}>
              <FontAwesomeIcon icon={faSave} className="me-2" /> Salva
            </Button>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default SubProductForm;