import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ProductsTable from '@/containers/EsaStore/list';
import SubProductsTable from '@/containers/EsaStore/listSub';
import LinkTable from '@/containers/EsaStore/listLink';
import Update from '@/containers/EsaStore/update';
import Payments from '@/containers/EsaStore/payments';
import Purchases from '@/containers/EsaStore/purchases';
import Deadlines from '@/containers/EsaStore/deadlines';
import EsaStoreInvoice from '@/containers/EsaStore/invoice'; 

export default () => (
  <Switch>
    <Route path="/esastore/products" component={ProductsTable} />
    <Route path="/esastore/subproducts" component={SubProductsTable} />
    <Route path="/esastore/product-links" component={LinkTable} />
    <Route path="/esastore/update-product/:id" component={Update} />
    <Route path="/esastore/payments" component={Payments} />
    <Route path="/esastore/purchases" component={Purchases} />
    <Route path="/esastore/deadlines" component={Deadlines} />
    <Route path="/esastore/invoice/:id" component={EsaStoreInvoice} />
  </Switch>
);

