import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import renderDatePickerField from '@/shared/components/form/date-pickers/DatePicker';
import { FormContainer, FormGroup, FormGroupField, FormGroupLabel, } from '@/shared/components/form/FormElements';
import { Col, Row, } from 'react-bootstrap';
import { Button } from '@/shared/components/Button';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { Card, CardBody } from '@/shared/components/Card';
import renderSelectField from '@/shared/components/form/Select';
import styled from 'styled-components';
import '../../css/filterList.css';

const FilterForm = ({ onSubmit, showEshop = true }) => (
  <Card>
    <CardBody>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, form }) => (
          <FormContainer onSubmit={handleSubmit} className="filterForm">
            <Row>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Data creazione</FormGroupLabel>
                  <FormGroupField>
                    <div className='d-inline-flex align-items-center'>
                      <Field
                        name="creation_date_start"
                        component={renderDatePickerField}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Dal"
                      />
                      <span className='mx-2'> - </span>
                      <Field
                        name="creation_date_end"
                        component={renderDatePickerField}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Al"
                      />
                    </div>
                  </FormGroupField>
                </FormGroup>
              </Col>
              {showEshop && (
                <Col>
                  <FormGroup>
                    <FormGroupLabel>Dominio</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="domain"
                        component="input"
                        type="text"
                        placeholder="Dominio"
                      />
                    </FormGroupField>
                  </FormGroup>
                </Col>
              )}
              <Col>
                <FormGroup>
                  <FormGroupLabel>Omaggio</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="gift"
                      component={renderSelectField}
                      type='select'
                      options={[
                        { value: 'all', label: 'Tutti' },
                        { value: '1', label: 'Solo Omaggio' },
                        // { value: '0', label: 'Non Omaggio' },
                      ]}
                      placeholder={'Coupon omaggio'}

                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Stato</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="status"
                      component={renderSelectField}
                      type='select'
                      options={[
                        { value: 'all', label: 'Validati / da validare' },
                        { value: '0', label: 'Da validare' },
                        { value: '1', label: 'Validati' },
                        { value: '2', label: 'Non validati' },
                      ]}
                      placeholder="Tutti gli stati"
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Scadenza</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="expired"
                      component={renderSelectField}
                      type='select'
                      options={[
                        { value: 'all', label: 'Scaduti / non scaduti' },
                        { value: '0', label: 'Non scaduti' },
                        { value: '1', label: 'Scaduti' },
                      ]}
                      placeholder="Tutti gli stati"
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup controlId="formButton">
                  <FilterButton
                    variant="outline-success"
                    size='sm'
                    onClick={handleSubmit}
                  >
                    <MagnifyIcon /><span>Filtra</span>
                  </FilterButton>
                </FormGroup>
              </Col>
            </Row>
          </FormContainer>
        )}
      </Form>
    </CardBody>
  </Card>
);

FilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default FilterForm;

const FilterButton = styled(Button)`
    margin-top: calc(1.5em + .35rem);
`;