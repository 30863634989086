import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../config/api';

export const fetchUserDetails = createAsyncThunk(
    'users/fetchUserDetails',
    async (userId) => {
        const response = await api.get(`consumer-users/${userId}`);
        return response.data;
    }
);

const usersSlice = createSlice({
    name: 'users',
    initialState: { details: {}, status: 'idle', error: null },
    reducers: {
        // Puoi definire altri reducers qui se necessario
    },
    extraReducers: builder => {
        builder
            .addCase(fetchUserDetails.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUserDetails.fulfilled, (state, action) => {
                state.status = 'idle';
                state.details = action.payload;
            })
            .addCase(fetchUserDetails.rejected, (state, action) => {
                state.status = 'idle';
                state.error = action.error.message;
            });
    }
});

export default usersSlice.reducer;
