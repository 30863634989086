import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";

const DeadlineItemList = ({ item }) => {

  const [elem, setElem] = useState(item);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const deadlineDate = new Date(elem.expiry_date);
  deadlineDate.setHours(0, 0, 0, 0);
  const isDeadlinePassed = today >= deadlineDate ? 'bg-warning' : '';

  function formatDate(dateString) {
    return moment(dateString).format('DD/MM/YYYY')
  }

  useEffect(() => {
    setElem(item);
  }, [item]);

  return (
    <tr key={elem.id} className={isDeadlinePassed}>
      <td className="first-letter-uppercase">
        <Link to={'/registered-eshops/update/' + elem.eshop_id}>
          {elem.domain}
        </Link>
        { } ({elem.eshop_id})
      </td>
      <td className="first-letter-uppercase">
        <Link to={'/esastore/update-product/' + elem.prod_id}>
          {elem.productName}
        </Link>
        { } ({elem.prod_id})
      </td>
      <td className="first-letter-uppercase">
        {elem.prod_sub_id ? elem.prod_sub_id : '-'}
      </td>
      <td className="first-letter-uppercase">
        {elem.prod_link_id ? elem.prod_link_id : '-'}
      </td>
      <td>
        {elem.price ? elem.price + '€' : '-'}
      </td>
      <td>
        {elem.payment_date ? formatDate(elem.payment_date) : '-'}
      </td>
      <td>
        {elem.expiry_date ? formatDate(elem.expiry_date) : '-'}
      </td>
    </tr >
  );
};

export default DeadlineItemList;