import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from '../../../Invitations/list';
import Add from '../../../Invitations/add';
import Update from '../../../Invitations/update';

export default () => (
  <Switch>
    <Route path="/invitations/list" component={List} />
    <Route path="/invitations/add" component={Add} />    
    <Route path="/invitations/update" component={Update} />
  </Switch>
);
