import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { Button } from '@/shared/components/Button';
import Badge from '@/shared/components/Badge';
import moment from 'moment';
import ReviewModifyModal from './modals/ReviewModifyModal';
import { reviewApproved, reviewRejected } from '../reducers/modifiedReviewsReducer';
import { modApproved, modRejected } from '../actions/updateModifiedReviews';
import { text } from '@fortawesome/fontawesome-svg-core';

function ReviewModifyItem({ item }) {

  const dispatch = useDispatch();
  const update_date = moment(item.modified.update_date).format('DD/MM/YYYY HH:mm:ss');
  const validation_date = item.modified.validation_date ? moment(item.modified.validation_date).format('DD/MM/YYYY HH:mm:ss') : '-';
  const [modalShow, setModalShow] = useState(false);
  const [selectedElem, setSelectedElem] = useState(null);
  const [visibleDropdownId, setVisibleDropdownId] = useState(null);

  const handleShow = (elem) => {
    setSelectedElem(elem);
    setModalShow(true);
  };

  const handleClose = () => {
    setModalShow(false);
  };

  const toggleDropdown = (id) => {
    setVisibleDropdownId(visibleDropdownId === id ? null : id);
  };

  const handleApprove = (id) => {
    dispatch(reviewApproved(id));
    dispatch(modApproved(id));
    setVisibleDropdownId(null);
  };

  const handleReject = (id) => {
    dispatch(reviewRejected(id));
    dispatch(modRejected(id));
    setVisibleDropdownId(null);
  };

  const statusBadge = (status, id) => {
    switch (status) {
      case 0:
        return (
          <DropdownButton
            id={`dropdown-button-drop-${id}`}
            key={id}
            size="sm"
            variant="warning"
            title="In attesa di validazione"
            onClick={(e) => {
              e.preventDefault();
              toggleDropdown(id);
            }}
          >
            <Dropdown.Item eventKey="1" onClick={() => handleApprove(id)}>Approva modifiche</Dropdown.Item>
            <Dropdown.Item eventKey="2" onClick={() => handleReject(id)}>Rifiuta modifiche</Dropdown.Item>
          </DropdownButton>
        );
      case 1:
        return <Badge bg="success">Modifiche validate</Badge>;
      case 2:
        return <Badge bg="danger">Modifiche rifiutate</Badge>;
      case 3:
        return <Badge bg="secondary">Annullata da utente</Badge>;
      default:
        return null;
    }
  };

  return (
    <>
      <tr key={item.modified.id}>
        <td>
          <span>{item.eshop.domain}</span>
        </td>
        <td>
          <Badge bg={'success'} >{item.eshop.plan}</Badge>
        </td>
        <td>
          <span>{item.original.title}</span>
          {(item.modified.is_prod_review == 1) && (<span className='text-success' title="Recensione Prodotto"> <b>[P]</b></span>)}
        </td>
        <td> <span>{item.user.email}</span></td>
        <td className="first-letter-uppercase">
          <span className={item.original.overall_rating > 4 ? 'text-success' : 'text-danger'}> {item.original.overall_rating} </span>
        </td>
        <td className="first-letter-uppercase">{update_date}</td>
        <td className="first-letter-uppercase">{validation_date}</td>
        <td className="first-letter-uppercase">
          <Button variant="primary" onClick={() => handleShow(item)} size={'sm'}>Vedi modifiche</Button>
        </td>
        <td className="first-letter-uppercase">
          {statusBadge(item.modified.status, item.modified.id)}
        </td>
      </tr>
      {
        modalShow && (
          <ReviewModifyModal
            show={modalShow}
            handleClose={handleClose}
            item={selectedElem}
          />
        )
      }
    </>
  );
}

export default ReviewModifyItem;