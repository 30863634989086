import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import FilterForm from './components/filters/filterReply';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { Card, CardBody, CardTitleWrap } from '@/shared/components/Card';
import { Table } from '@/shared/components/TableElements';
import styled from 'styled-components';
import { cleanDate } from '@/shared/helpers';
import { colorAccent } from '@/utils/palette';
import { fetchEshopReplies } from './actions/fetchEshopReplies';
import ReviewReplyItemList from './components/ReviewReplyItemList';
import PaginationTable from '@/containers/Components/PaginationTable';

const RepliesTable = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const reviewsState = useSelector(state => state.reviews.replies || {});
  const reviews = reviewsState.items;
  const loading = reviewsState.loading;
  const pagination = reviewsState.pagination;
  const TableSpinner = styled(Spinner)`color: ${colorAccent};`;
  const [globalFilters, setGlobalFilters] = useState({ 'per-page': 25, page: 1 });
  const tableLabels = ['Data e ora', 'Eshop', 'Piano', 'Titolo', 'Utente', 'Stato', '',]
  const actionBaseUrl = '/v1/reviews/fetch-replies'
  const [isProduct, setIsProduct] = useState(false);
  const [toRefresh, setToRefresh] = useState(false);

  const triggerRefresh = () => {
    setToRefresh(prevState => !prevState);
  };

  const handleFilterSubmit = (values) => {
    let modifiedValues = { ...values };

    delete modifiedValues.date_start;
    delete modifiedValues.date_end;
    modifiedValues.date = {
      start: values.date_start ? cleanDate(values.date_start) : null,
      end: values.date_end ? cleanDate(values.date_end) : null,
    }

    if (values.status && values.status.value !== 'all') {
      modifiedValues.status = values.status.value;
    }
    if (values.newsletter) {
      modifiedValues.newsletter = values.newsletter.value;
    }

    modifiedValues.product_review = false;
    if (values.product_review == true) {
      modifiedValues.product_review = values.product_review;
    }

    setIsProduct(modifiedValues.product_review);
    setGlobalFilters(modifiedValues);

    // Aggiungi page e perPage ai valori modificati
    modifiedValues.page = pagination.current_page;
    modifiedValues['per-page'] = 25

    const payload = {
      baseUrl: actionBaseUrl,
      params: modifiedValues
    };

    dispatch(fetchEshopReplies(payload));
  };

  useEffect(() => {
    const initialPayload = {
      baseUrl: actionBaseUrl,
      params: globalFilters
    };

    dispatch(fetchEshopReplies(initialPayload));
  }, [dispatch, toRefresh]);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Risposte eshop</h3>
        </Col>
      </Row>
      <FilterForm onSubmit={handleFilterSubmit} />
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <CardTitleWrap>
                <Row className='align-items-center'>
                  <Col xs={12} lg={6}>
                    <p className="page-subtitle">Numero recensioni: {pagination.total_count}</p>
                  </Col>
                </Row>
              </CardTitleWrap>
              {loading === 'loading' && (
                <div className="text-center">
                  <TableSpinner animation="border" />
                </div>
              )}
              {reviews && loading === 'idle' && reviews.length > 0 && (
                <>
                  <Table responsive striped bordered>
                    <thead>
                      <tr>
                        {tableLabels.map((label) => {
                          return <th>{label}</th>
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {reviews.map((elem) => {
                        return <ReviewReplyItemList item={elem} isProduct={isProduct} triggerRefresh={triggerRefresh} />
                      })}
                    </tbody>
                  </Table>
                  <PaginationTable pagination={pagination} filters={globalFilters} action={fetchEshopReplies} />
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

RepliesTable.propTypes = {
  onSubmit: PropTypes.func,
};

export default RepliesTable;