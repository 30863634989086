import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';
import endpoint from '../endpoints/plans';

export const postCatalogoPianiData = createAsyncThunk(
    'plans/postCatalogoPianiData',
    async (data) => {
        const response = await api.post(endpoint.insertCatalogoItem, data);
        return response.data;
    }
);