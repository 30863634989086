import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button } from '@/shared/components/Button';
import AddUserIcon from 'mdi-react/AccountPlusOutlineIcon';
import DeleteForeverIcon from 'mdi-react/DeleteForeverIcon';
import { Modal, Col, Container, Row, Spinner } from 'react-bootstrap';
import { Card, CardBody, CardTitleWrap } from '@/shared/components/Card';
import { Table } from '@/shared/components/TableElements';
import styled from 'styled-components';
import { colorAccent } from '@/utils/palette';
import { fetchBlochedEmails } from './actions/fetch';
import { deleteBlockedEmail } from './actions/delete';
import { useHistory } from 'react-router-dom';

const ApiTable = () => {
  const dispatch = useDispatch();
  const listState = useSelector(state => state.blockedEmails.fetch); // verifica se questa è la struttura corretta dello stato
  const emails = listState.entities;
  const loading = listState.loading;
  const history = useHistory();

  useEffect(() => {
    const initialPayload = {
      baseUrl: '/v1/blocked-emails'
    };
    dispatch(fetchBlochedEmails(initialPayload)); // assicurati che sia il nome corretto dell'action
  }, [dispatch]);

  const [showModal, setShowModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);

  const handleDeleteClick = (userId) => {
    setUserIdToDelete(userId);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleConfirmDelete = async () => {
    handleClose();
    const initialPayload = {
      baseUrl: '/v1/blocked-emails'
    };
    if (userIdToDelete) {
      await dispatch(deleteBlockedEmail(userIdToDelete));
      dispatch(fetchBlochedEmails(initialPayload));
    }
  };
  
  console.log("listState");
  console.log(loading);

  const rowsToShow = emails;
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Email Bloccate</h3>
        </Col>
      </Row>
      
      <Row>
        <Col md={12} lg={12} xl={12}>
          <Card>
            <CardBody>
              <CardTitleWrap>
                
                <Row>
                  <Col md={8}>
                    <p className="page-subtitle"></p>
                  </Col>
                  <Col md={4} style={{ textAlign: 'right' }}>
                    <Button as={Link} to="/blocked-email/add" variant="outline-primary" size='sm'>
                      <AddUserIcon />
                      <span>Aggiungi Dominio</span>
                    </Button>
                  </Col>
                </Row>
              </CardTitleWrap>
              
              

              {loading === 'loading' && (
                <div className="text-center">
                  <TableSpinner animation="border" />
                </div>
              )}
              {rowsToShow && loading === 'idle' && rowsToShow.length > 0 && (
                <>
                <Table responsive striped bordered>
                  <thead>
                    <tr>
                      
                      <th>Dominio</th>
                      
                      <th>Data creazione</th>
                      
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {emails.map(elem => {
                      const formattedDate = moment(elem.date).format('DD/MM/YYYY HH:mm:ss');
                      return (
                        <tr key={elem.id}>
                          
                          <td>
                            {elem.domain}
                          </td>
                          <td className="first-letter-uppercase">
                            {formattedDate}
                          </td>
                          <td>
                            <FilterButton
                              variant="danger"
                              size='sm'
                              onClick={() => handleDeleteClick(elem.id)}
                            >
                              <DeleteForeverIcon />
                            </FilterButton>
                            
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>

                  <Modal show={showModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Conferma eliminazione</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Sei sicuro di voler cancellare il dominio?</Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Annulla
                      </Button>
                      <Button variant="danger" onClick={handleConfirmDelete}>
                        Elimina
                      </Button>
                    </Modal.Footer>
                  </Modal> 
                  
                  
    </>
              )}
             
            </CardBody>
          </Card>
        </Col>
      </Row>   
    </Container>
  );
};

ApiTable.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ApiTable;

// region STYLES
const FilterButton = styled(Button)`
  margin-top: calc(1.5em);
`;

const TableSpinner = styled(Spinner)`
  color: ${colorAccent};
`;

const PaginationWrapper = styled.div`
  margin-top: 20px;
`;

const SearchWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 10px;
  
  input {
    margin-right: 10px;
  }
  
  button {
    margin: 0;
    height: 32px;
  }
`;

// endregion
