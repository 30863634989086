import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from '../../../B2bUsers/list';
import AddB2bUser from '../../../B2bUsers/addB2bUser';
import UpdateB2bUser from '../../../B2bUsers/updateB2bUser';

export default () => (
  <Switch>
    <Route path="/business-accounts/list" component={List} />
    <Route path="/business-accounts/add-b2b-user" component={AddB2bUser} />    
    <Route path="/business-accounts/update-b2b-user/:id" component={UpdateB2bUser} />
  </Switch>
);
