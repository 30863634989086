import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import showResults from '@/utils/showResults';
import HorizontalForm from './forms/detailUser/accesso';
import HorizontalFormTwo from './forms/detailUser/anagrafica';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { updateUser } from './actions/update'; // Assicurati che questa sia l'action corretta
import { useDispatch } from 'react-redux';

const FormValidation = () => {
    //const { t } = useTranslation('common');
    const dispatch = useDispatch();

    const handleSubmit = (formValues) => {
        //dispatch(updateUser(formValues)); // Invia i valori del form al tuo reducer
        console.log(formValues);
    };

    return (
        <Container>
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Scheda utente</h3>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/consumer_users/list" }}>
                            Torna all'elenco degli utenti
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <HorizontalForm onSubmit={showResults} />
                <HorizontalFormTwo onSubmit={handleSubmit} />
                {/* <HorizontalForm onSubmit={showResults} /> */}
            </Row>
        </Container>
    );
};

export default FormValidation;
