import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';
import endpoint from '../endpoints/plans';

export const postPlansData = createAsyncThunk(
    'plans/postPlansData',
    async (userData) => {
        const response = await api.post(endpoint.insert, userData);
        return response.data;
    }
);