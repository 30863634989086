import React, { useEffect, useState } from "react";
import { Form, Container, Row, Col, Card } from 'react-bootstrap';

const ApiCmsData = ({ elem, setElem }) => {
  const [item, setItem] = useState(elem);
  const eshopData = item?.eshopData;
  const structuredData = eshopData?.structured_data;

  const handleChange = (event) => {
    const { name, value } = event.target;
    const keys = name.split('[').map(key => key.replace(']', ''));
    setItem((prevState) => {
      let newState = { ...prevState };
      let currentPart = newState;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!currentPart[keys[i]]) {
          currentPart[keys[i]] = {};
        }
        currentPart = currentPart[keys[i]];
      }
      currentPart[keys[keys.length - 1]] = value;
      return newState;
    });
    setElem(item);
  };

  useEffect(() => {
    if (elem) {
      setItem(elem);
    }
  }, [elem]);

  return (
    <Container>
      <Row>
        <Col xs={12} lg={4}>
          <span>
            <b>Merchant code:</b>
            <span className="ms-2">
              {item.merchantCode}
            </span>
          </span>
        </Col>
        <Col xs={12} lg={4}>
          <span>
            <b>Api token:</b>
            <span className="ms-2">
              {item.apiToken}
            </span>
          </span>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="structuredData_pluginAlias">
            <Form.Label>Plugin alias</Form.Label>
            <Form.Control name="eshopData[structured_data][pluginAlias]" value={structuredData?.pluginAlias} type="text" onChange={handleChange} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6} className='my-3' bg={'secondary'}>
          <Card>
            <Card.Header>
              <h4>API</h4>
            </Card.Header>
            <Card.Body>
              {
                //TODO: Check api installed
                // text-success if installed and bold, text-secondary if not
              }
              <h4 className="text-secondary"> Link post-vendita </h4>
              <h4 className="text-secondary"> API Invitation Link </h4>
              <h4 className="text-secondary"> API Invitation </h4>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} lg={6} className='my-3' bg={'secondary'}>
          <Card>
            <Card.Header>
              <h4>CMS</h4>
            </Card.Header>
            <Card.Body>
              {
                //TODO: Check Cms installed, see esa_addon_install_info
                // text-success and bold if installed, text-secondary if not
              }
              <h4 className="text-secondary"> Woocommerce </h4>
              <h4 className="text-secondary"> Magento </h4>
              <h4 className="text-secondary"> Prestashop </h4>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ApiCmsData;