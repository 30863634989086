import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit,faAddressBook } from '@fortawesome/free-solid-svg-icons';

const sidebar_b2bUsers =
[
    {
        "path" : "/business-accounts/update-b2b-user/:id",
        "name" : "Dati generali",
        "namestate" : "general-data",
        "icon" : <FontAwesomeIcon icon={faEdit} />
    },
    {
        "name" : "Dati di fatturazione",
        "namestate" : "billing-data",
        "icon" : <FontAwesomeIcon icon={faAddressBook} />
    }
]

export default sidebar_b2bUsers;