import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Card, CardBody } from '@/shared/components/Card';
import ReviewsTable from '@/containers/Reviews/fetchReviews';
import ProductReviewsTable from "@/containers/Reviews/fetchProductReviews";

const EshopReviews = ({ esa_id, is_product }) => {

  return (
    <Card>
      <CardBody>
        {is_product
          ? <ProductReviewsTable esa_id={esa_id} />
          : <ReviewsTable esa_id={esa_id} />
        }
      </CardBody>
    </Card>
  )

}

export default EshopReviews
