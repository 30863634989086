import React from 'react';

const SubmenuButton = ({ iconClass, label, badgeCount, onClick, isActive }) => {
    const buttonStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '10px 20px',
        cursor: 'pointer',
        border: 'none',
        borderRadius: '4px',
        backgroundColor: isActive ? '#70bbfd' : 'white',
        color: '#000', 
        outline: 'none',
        transition: 'background-color 0.2s',
        width: '100%',
        marginBottom: '5px',
        textAlign: 'left' 
    };

    const buttonHoverStyle = {
        ...buttonStyle,
        backgroundColor: '#e0e0e0'  
    }

    const iconStyle = {
        fontSize: '1.2em',
        marginRight: '10px'
    };

    const textStyle = {
        flex: 1,
        textAlign: 'left',
        color: '#000' 
    };

    const badgeStyle = {
        backgroundColor: '#ff4500',
        color: 'white',
        padding: '5px 10px',
        borderRadius: '12px',
        fontSize: '0.9em',
        marginLeft: '10px'
    };

    const [hovered] = React.useState(false);

    return (
        <button
            type="button"
            style={hovered ? buttonHoverStyle : buttonStyle}
            onClick={onClick}
        >
            <span className={iconClass} style={iconStyle}></span>
            <p style={textStyle}>{label}</p>
            {badgeCount && <span style={badgeStyle}>{badgeCount}</span>}
        </button>
    );
}

export default SubmenuButton;
