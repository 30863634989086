import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { Card, CardBody, CardTitle, CardTitleWrap } from '@/shared/components/Card';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import { successNotify, warningNotify } from '../../App/Router';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { deleteProductPreview } from '../actions/deleteProductPreview';
import { imageNotFound } from '@/utils/utilities';

const ProductLogo = ({ item }) => {

  const [toRefresh, setToRefresh] = useState(false);
  const [elem, setElem] = useState(item);
  const logoUrl = elem.img ? process.env.REACT_APP_ESA_CDN_URL + 'img-products/' + elem.id + '/' + elem.img : false;
  const [imageSrc, setimageSrc] = useState(logoUrl);
  const dispatch = useDispatch();

  registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

  const handleDelete = () => {
    dispatch(deleteProductPreview({ id: elem.id })).then(() => {
      setToRefresh(true);
      successNotify();
    });
  }

  useEffect(() => {
    setToRefresh(false);
    setElem(item);
    setimageSrc(logoUrl + '?t=' + new Date().getTime());
  }, [item, toRefresh]);

  return (
    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle className="d-block">
            Anteprima prodotto
            <Button onClick={handleDelete} size="sm" variant={'danger'} className="float-end mx-2">
              <FontAwesomeIcon icon={faTrash} className="me-2" /> Elimina
            </Button>
          </CardTitle>
        </CardTitleWrap>
        <Form autoComplete="off">
          <Container>
            <Row>
              <Col xs={12} md={3} className='my-3'>
                <img src={imageSrc} className="img-fluid" alt="Logo" onError={(e) => { e.target.src = imageNotFound }} />
              </Col>
              <Col xs={12} md={9} className='my-3'>
                <FilePond
                  allowFileTypeValidation={true}
                  allowImagePreview={true}
                  imagePreviewMaxHeight={100}
                  acceptedFileTypes={[
                    "image/jpg",
                    "image/jpeg",
                    "image/png",
                    "image/webp",
                  ]}
                  allowFileSizeValidation={true}
                  maxFileSize={"10MB"}
                  maxTotalFileSize={"10MB"}
                  allowMultiple={false}
                  maxFiles={1}
                  credits={undefined}
                  allowPaste={false}
                  storeAsFile={false}
                  server={{
                    url: process.env.REACT_APP_API_URL + '/v1/esastore/upload-product-preview/' + item.id,
                  }}
                  labelIdle="Trascina il tuo file qui oppure <span class='filepond--label-action'> Seleziona dai files </span>"
                  onprocessfile={(error, file) => {
                    if (error) {
                      warningNotify();
                    } else {
                      setToRefresh(true);
                      successNotify();
                    }
                  }}
                />
              </Col>
            </Row>
          </Container>
        </Form>
      </CardBody>
    </Card>
  )
}

export default ProductLogo
