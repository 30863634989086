import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import renderDatePickerField from '@/shared/components/form/date-pickers/DatePicker';
import { FormContainer, FormGroup, FormGroupField, FormGroupLabel, } from '@/shared/components/form/FormElements';
import { Col, Row, } from 'react-bootstrap';
import { Button } from '@/shared/components/Button';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { Card, CardBody } from '@/shared/components/Card';
import renderSelectField from '@/shared/components/form/Select';
import styled from 'styled-components';
import '../../css/filterList.css';

const FilterProductsForm = ({ onSubmit }) => {

  let periodOptions = [
    { value: 'all', label: 'Tutti' },
    { value: '-1', label: '-' },
  ];

  for (let i = 1; i <= 12; i++) {
    periodOptions.push({ value: i, label: i.toString() });
  }

  return (
    <Card>
      <CardBody>
        <Form onSubmit={onSubmit}>
          {({ handleSubmit, form }) => (
            <FormContainer onSubmit={handleSubmit} className="filterForm">
              <Row>
                <Col>
                  <FormGroup>
                    <FormGroupLabel>Data creazione</FormGroupLabel>
                    <FormGroupField>
                      <div className='d-inline-flex align-items-center'>
                        <Field
                          name="creation_date_start"
                          component={renderDatePickerField}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Dal"
                        />
                        <span className='mx-2'> - </span>
                        <Field
                          name="creation_date_end"
                          component={renderDatePickerField}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Al"
                        />
                      </div>
                    </FormGroupField>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <FormGroupLabel>Nome prodotto</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="title"
                        component="input"
                        type="text"
                        placeholder="Nome prodotto"
                      />
                    </FormGroupField>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <FormGroupLabel>Cadenza</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="period"
                        component={renderSelectField}
                        type='select'
                        options={periodOptions}
                        placeholder="Tutti"
                      />
                    </FormGroupField>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup controlId="formButton">
                    <FilterButton
                      variant="outline-success"
                      size='sm'
                      onClick={handleSubmit}
                    >
                      <MagnifyIcon /><span>Filtra</span>
                    </FilterButton>
                  </FormGroup>
                </Col>
              </Row>
            </FormContainer>
          )}
        </Form>
      </CardBody>
    </Card>
  );
}

FilterProductsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default FilterProductsForm;

const FilterButton = styled(Button)`
    margin-top: calc(1.5em + .35rem);
`;