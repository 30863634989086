import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartArrowDown, faCartPlus, faCheck, faLink, faPen, faTags, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import { successNotify, warningNotify } from '../../App/Router';
import { updateSub } from '../actions/updateSub';
import { api } from '@/config/api';
import SubProductForm from '../forms/subProductForm';

const SubProductItemList = ({ item, showParent = false, refreshTable }) => {

  const dispatch = useDispatch();
  const [elem, setElem] = useState(item);
  const [toRefresh, setToRefresh] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleOpen = () => {
    setShowModal(true);
  }

  const handleClose = () => {
    setShowModal(false);
  }

  const handleToggleCatalogue = () => {
    const newValue = elem.catalogue ? '0' : '1';
    dispatch(updateSub({ id: elem.id, data: { catalogue: newValue } })).then(() => {
      successNotify();
      setToRefresh(true)
    });
  }

  const handleChangeStatus = (value) => {
    dispatch(updateSub({ id: elem.id, data: { status: value } })).then(() => {
      successNotify();
      if (value == '0') {
        refreshTable(true)
      }
    });
  }

  function formatDate(dateString) {
    return moment(dateString).format('DD/MM/YYYY')
  }

  const refreshData = async () => {
    const response = await api.get('/v1/esastore/subproducts-admin-list?id=' + elem.id);
    const refreshedData = response.data.items[0];
    setElem(refreshedData);
  }

  useEffect(() => {
    if (toRefresh) {
      setToRefresh(false);
      refreshData();
    }
    else {
      setElem(item);
    }
  }, [item, toRefresh]);

  return (
    <tr key={elem.id}>
      {showParent && (
        <td className="first-letter-uppercase">
          <Link to={'/esastore/update-product/' + elem.prod_id}>
            {elem.parent?.title ? elem.parent.title : '-'}
          </Link>
        </td>
      )}
      <td className="first-letter-uppercase">
        <Link to={'#'} onClick={() => handleOpen()} >
          {elem.title}
        </Link>
      </td>
      <td>
        {elem.price ? elem.price + ' €' : '-'}
        {elem.activation_price ? ' (+ ' + elem.activation_price + ' € Attivazione)' : ''}
      </td>
      <td>
        <Button variant={elem.catalogue ? 'success' : 'danger'} size='sm'>
          <FontAwesomeIcon icon={elem.catalogue ? faCheck : faTimes} />
        </Button>
      </td>
      <td>
        {elem.creation_date ? formatDate(elem.creation_date) : '-'}
      </td>
      <td className='text-start'>
        <Button className="me-2" onClick={() => handleToggleCatalogue()} variant={elem.catalogue ? 'secondary' : 'success'} size='sm' title={elem.catalogue ? 'Rimuovi' : 'Aggiungi' + ` al catalogo`}>
          <FontAwesomeIcon icon={elem.catalogue ? faCartArrowDown : faCartPlus} />
        </Button>
        <Button className="me-2" onClick={() => handleOpen()} variant='primary' size='sm' title="Modifica">
          <FontAwesomeIcon icon={faPen} />
        </Button>
        <Button
          className="me-2"
          onClick={() => {
            if (window.confirm('Conferma eliminazione')) {
              handleChangeStatus('0');
            }
          }}
          variant='danger'
          size='sm'
          title="Elimina"
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </td>
      <Modal show={showModal} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Modifica Sottoprodotto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SubProductForm elem={elem} prod_id={elem.prod_id} refreshTable={refreshTable} lockPrice={true} setShowModal={setShowModal} />
        </Modal.Body>
      </Modal>
    </tr>
  );
};

export default SubProductItemList;