import { React, useState} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { Row, Col, Stack } from 'react-bootstrap';

import renderSelectField from '@/shared/components/form/Select';
import PasswordField from '@/shared/components/form/Password';
import FormField from '@/shared/components/form/FormField';
import {
    Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import {
    FormButtonToolbar, FormContainer, FormGroupField, FormGroupLabel, FormGroup,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import validate from './validate';
import renderRadioButtonField from '@/shared/components/form/RadioButton';
import TextEditor from '@/shared/components/text-editor/TextEditor';
import SelectAJX from '../select2';
import endpoints from '../../endpoints/eshops';

const HorizontalForm = ({ onSubmit }) => {
    const {t} = useTranslation('eshops');

    const [description,setDescription] = useState('');
    
    const updateInputDescription = (value) => {
        setDescription(value);
    }
    const [b2bUsers,setB2bUsers] = useState();
    return (
        <Col md={12} lg={12} xl={12}>
            <Card>
                <CardBody>                

                    <Form onSubmit={onSubmit} validate={validate} autoComplete="off">
                        {({
                            handleSubmit,
                            form: { reset },
                        }) => (
                            <FormContainer onSubmit={handleSubmit} className="w-100">
                                
                                <Field 
                                    name="description"    
                                    component={FormField}
                                    type="hidden"
                                    id="showcase_data_description"
                                    initialValue={description}
                                />

                                <div className="w-100">
                                    <Row>
                                        <Col md={12} lg={12} xl={12}>   
                                            <Stack direction="horizontal">                                  
                                                <CardTitleWrap>
                                                    <CardTitle>{t('add_title')}</CardTitle>                                
                                                </CardTitleWrap>
                                                <FormButtonToolbar className="text-end ms-auto">
                                                    <Button variant="success" type="submit">Salva</Button>
                                                </FormButtonToolbar>
                                            </Stack>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} lg={6} xl={6}>
                                            <FormGroup>
                                                <FormGroupLabel>{t('b2b_user_label')}</FormGroupLabel>                                                
                                                <SelectAJX optionsUrl={endpoints.loadB2bUsersOptions} name="b2b_user"/>  
                                            </FormGroup> 
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} lg={6} xl={6}>
                                            <FormGroup>
                                                <FormGroupLabel>{t('domain_label')}</FormGroupLabel>
                                                <FormGroupField>
                                                    <Field
                                                        name="domain"
                                                        component={FormField}
                                                        type="text"
                                                        placeholder={t('domain_placeholder')}
                                                    />
                                                </FormGroupField>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} lg={6} xl={6}>
                                            <FormGroup>
                                                <FormGroupLabel>{t('url_label')}</FormGroupLabel>
                                                <FormGroupField>
                                                    <Field
                                                        name="url"
                                                        component={FormField}
                                                        type="text"
                                                        placeholder={t('url_placeholder')}
                                                    />
                                                </FormGroupField>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} lg={6} xl={6}>
                                            <FormGroup>
                                                <FormGroupLabel>{t('manager_name_label')}</FormGroupLabel>
                                                <FormGroupField>
                                                    <Field
                                                        name="structured_data[manager_name]"
                                                        component={FormField}
                                                        type="text"
                                                        placeholder={t('manager_name_placeholder')}
                                                    />
                                                </FormGroupField>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} lg={6} xl={6}>
                                            <FormGroup>
                                                <FormGroupLabel>{t('manager_surname_label')}</FormGroupLabel>
                                                <FormGroupField>
                                                    <Field
                                                        name="structured_data[manager_surname]"
                                                        component={FormField}
                                                        type="text"
                                                        placeholder={t('manager_surname_placeholder')}
                                                    />
                                                </FormGroupField>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} lg={6} xl={6}>
                                            <FormGroup>
                                                <FormGroupLabel>{t('email_label')}</FormGroupLabel>
                                                <FormGroupField>
                                                    <Field
                                                        name="email"
                                                        component={FormField}
                                                        type="text"
                                                        placeholder={t('email_placeholder')}
                                                    />
                                                </FormGroupField>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} lg={6} xl={6}>
                                            <FormGroup>
                                                <FormGroupLabel>{t('phone_label')}</FormGroupLabel>
                                                <FormGroupField>
                                                    <Field
                                                        name="showcase_data[phone]"
                                                        component={FormField}
                                                        type="text"
                                                        placeholder={t('phone_placeholder')}
                                                    />
                                                </FormGroupField>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} lg={6} xl={6}>
                                            <FormGroup>
                                                <FormGroupLabel>{t('aff_url_label')}</FormGroupLabel>
                                                <FormGroupField>
                                                    <Field
                                                        name="structured_data[aff_url]"
                                                        component={FormField}
                                                        type="text"
                                                        placeholder={t('aff_url_placeholder')}
                                                    />
                                                </FormGroupField>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} lg={6} xl={6}>
                                            <FormGroup>
                                                <FormGroupLabel>{t('sitemap_url_label')}</FormGroupLabel>
                                                <FormGroupField>
                                                    <Field
                                                        name="structured_data[sitemap_path]"
                                                        component={FormField}
                                                        type="text"
                                                        placeholder={t('sitemap_url_placeholder')}
                                                    />
                                                </FormGroupField>
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md={12} lg={12} xl={12}>
                                            <FormGroup>
                                                <FormGroupLabel>{t('description_label')}</FormGroupLabel>
                                                <TextEditor onChange={updateInputDescription} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                            </FormContainer>
                        )}
                    </Form>
                </CardBody>
            </Card>
        </Col>
    );
};

HorizontalForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default HorizontalForm;