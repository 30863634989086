import { createSlice } from '@reduxjs/toolkit';
import { fetchReportReviews } from '../actions/fetchReportReviews';

export const reportReviews = createSlice({
    name: 'reportReviews',
    initialState: {
        items: [],
        loading: false,
        error: null,
        pagination: {
            total_count: 0,
            page_count: 0,
            current_page: 0,
            per_page: 0,
            next_page_url: null,
            prev_page_url: null,
        }
    },
    reducers: {

    },
    extraReducers: {
        [fetchReportReviews.pending]: (state) => {
            state.loading = 'loading';
            state.error = null;
        },
        [fetchReportReviews.fulfilled]: (state, action) => {
            console.log("action", action);
            state.items = action.payload.items;
            state.pagination = action.payload.pagination;
            state.loading = 'idle';
            console.log("items", state.items);
            console.log("state", state);
        },
        [fetchReportReviews.rejected]: (state, action) => {
            state.error = action.payload;
            state.loading = 'idle';
        },
    },
});

export const { reviewApproved, reviewRejected } = reportReviews.actions;

export default reportReviews.reducer;
