// Import delle librerie e componenti necessari
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'; // Libreria per la manipolazione delle date
import PropTypes from 'prop-types'; // Utilizzato per definire i tipi delle prop

import { Button, ButtonToolbar } from '@/shared/components/Button';
import PlusIcon from 'mdi-react/PlusIcon';
import AddUserIcon from 'mdi-react/AccountPlusOutlineIcon';
import DeleteUserIcon from 'mdi-react/AccountCancelOutlineIcon';
import DatepickerField from '@/containers/UI/DatepickerField';
import DeleteForeverIcon from 'mdi-react/DeleteForeverIcon';

import { Modal } from 'react-bootstrap';
import { Form, Field } from 'react-final-form'; // Libreria per i form
//import FilterForm from './filter'; // Possibile componente per il form di filtro
//import ActionForm from './azioni'; // Possibile componente per azioni sulla tabella
import {
    FormButtonToolbar,
    FormContainer,
    FormGroup,
    FormGroupField,
    FormGroupIcon,
    FormGroupLabel
} from '@/shared/components/form/FormElements'; // Componenti per la costruzione di form

import { useState } from 'react';
import { Col, Row, Container, Spinner } from 'react-bootstrap';
import {
    Card,
    CardBody,
    CardTitleWrap,
    CardTitle,
    CardSubhead
} from '@/shared/components/Card'; // Componenti per le card
import { Table } from '@/shared/components/TableElements'; // Componente per la tabella
import styled from 'styled-components'; // Libreria per lo styling
import { colorAccent } from '@/utils/palette'; // Possibili colori
import { fetchEshopBlocklist } from './actions/fetch'; // Azione per il recupero degli eshops
import ReactTablePagination from '@/shared/components/table/components/ReactTablePagination';
import {
    TableCheckbox
} from '@/shared/components/MaterialTableElements'; // Componenti per la tabella con checkbox

import Badge from '@/shared/components/Badge';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import endpoint from './endpoints/eshopBlocklist';
import { manualPageSize } from '@/utils/utilities';

// Definizione del componente B2bUserTable

const EshopBlocklistTable = ({ onSubmit }) => {
    const dispatch = useDispatch(); // Hook per l'accesso al dispatch di Redux
    const eshopBlocklistState = useSelector(state => state.eshopBlocklist.fetch); // Stato degli eshops dallo store Redux
    const eshopBlocklist = eshopBlocklistState.entities; // Lista degli eshops
    const loading = eshopBlocklistState.loading; // Flag per indicare se i dati sono in caricamento
    const pagination = eshopBlocklistState.pagination; // *** Errore qui: dovrebbe essere eshopsState.pagination ***

    const [currentPage, setCurrentPage] = useState(1); // Stato locale per la pagina corrente
    const [rowsPerPage, setRowsPerPage] = useState(25); // Stato locale per le righe per pagina

    const [globalFilters, setGlobalFilters] = useState({}); // Stato locale per i filtri globali

    // Funzione per gestire l'invio del form di filtro
    const handleFilterSubmit = (values) => {
        let modifiedValues = { ...values }; // Copia dei valori del form

        // Manipolazione delle date di creazione se presenti nei filtri
        if (values.creation_date) {
            modifiedValues.creation_date = {
                start: values.creation_date.start ? values.creation_date.start.toISOString().split('T')[0] : null,
                end: values.creation_date.end ? values.creation_date.end.toISOString().split('T')[0] : null
            };
        }

        // Trasformazione dello stato e delle opzioni newsletter
        if (values.status) {
            modifiedValues.status = values.status.value;
        }
        if (values.newsletter) {
            modifiedValues.newsletter = values.newsletter.value;
        }

        // Aggiornamento dello stato dei filtri globali
        setGlobalFilters(modifiedValues);

        // Aggiunta delle informazioni di paginazione ai filtri
        modifiedValues.page = currentPage;
        modifiedValues['per-page'] = rowsPerPage;

        const jsonData = JSON.stringify(modifiedValues); // Conversione in JSON dei filtri

        console.log("JSON data: ", jsonData);

        const payload = {
            baseUrl: endpoint.adminList,
            params: modifiedValues
        };

        // Invio dell'azione Redux per il recupero degli eshops con i filtri
        dispatch(fetchEshopBlocklist(payload));
    }

    // Effetto per il recupero iniziale degli eshops all'avvio del componente
    useEffect(() => {
        const initialPayload = {
            baseUrl: endpoint.adminList,
            params: {
                'per-page': 25,
                page: 1
            }
        };

        // Invio dell'azione Redux per il recupero degli eshops iniziali
        dispatch(fetchEshopBlocklist(initialPayload));
    }, [dispatch]);

    // *** Mancano il ritorno e il rendering degli elementi UI ***


    // Funzione per gestire il cambio di pagina nella tabella
    const handlePageChange = (pageIndex) => {
        const page = pageIndex + 1;
        let filters = globalFilters;
        filters.page = page;
        filters['per-page'] = rowsPerPage;

        dispatch(fetchEshopBlocklist({
            baseUrl: endpoint.adminList,
            params: filters
        }));

    };

    // Funzione per passare alla pagina successiva nella tabella
    const nextPage = () => {
        console.log(pagination.next_page_url);
        if (pagination.next_page_url) {
            dispatch(fetchEshopBlocklist(pagination.next_page_url));
        }
    };

    // Funzione per passare alla pagina precedente nella tabella
    const previousPage = () => {
        if (pagination.prev_page_url) {
            dispatch(fetchEshopBlocklist(pagination.prev_page_url));
        }
    }

    const rowsToShow = eshopBlocklist;

    // Funzione per gestire il cambio del numero di righe per pagina nella tabella
    const handleRowsPerPageChange = (perPage) => {
        setRowsPerPage(parseInt(perPage, 10));
        setCurrentPage(1);

        let filters = globalFilters;
        filters.page = 1;
        filters['per-page'] = perPage;

        dispatch(fetchEshopBlocklist({
            baseUrl: endpoint.adminList,
            params: filters
        }));
    }

    const [selectAll, setSelectAll] = useState(false);
    const [selected, setSelected] = useState({});

    // Funzione per selezionare tutti gli eshops nella tabella
    const handleSelectAll = () => {

        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);

        const newSelected = {};
        eshopBlocklist.forEach(eshopBlocklist => newSelected[eshopBlocklist.id] = newSelectAll);
        setSelected(newSelected);
    }



    /**
     * Gestisce la selezione di un utente.
     * Aggiorna lo stato degli utenti selezionati e controlla se tutti gli utenti sono selezionati.
     * @param {number} id - L'ID dell'utente selezionato.
     */
    const handleSelectEshop = (id) => {
        // Crea una copia dello stato degli utenti selezionati e inverte lo stato dell'utente con l'ID specificato
        const newSelected = { ...selected, [id]: !selected[id] };
        // Inverte lo stato dell'utente con l'ID specificato
        setSelected(newSelected);
        // Controlla se tutti gli utenti sono selezionati
        const allSelected = Object.values(newSelected).every(value => value);
        // Aggiorna lo stato degli utenti selezionati e lo stato di selezione di tutti gli utenti
        setSelectAll(allSelected);
    }

    const [showModal, setShowModal] = useState(false);
    const [eshopIdToDelete, setEshopIdToDelete] = useState(null);

    const handleDeleteClick = (eshopId) => {
        setEshopIdToDelete(eshopId);
        setShowModal(true);
    }

    const handleClose = () => {
        setShowModal(false);
    }

    const handleConfirmDelete = () => {
        console.log(`Deleting user with ID:  ${eshopIdToDelete}`);
        handleClose();
    }

    return (
        <Container>
            <Row>
                <Col md={12} xs={12} lg={12}>
                    <h3 className="page-title">Eshop e marketplace bloccati</h3>
                </Col>
            </Row>
            <Row>
                <Col md={12} xs={12} lg={12}>
                    <Card>
                        <CardBody>
                            <CardTitleWrap>
                                <Row>
                                    <Col md={8}>
                                        <p className="page-subtitle">
                                            Trovati: {pagination.total_count}
                                        </p>
                                    </Col>
                                    <Col md={4} style={{ textAlign: 'right' }}>
                                        <Button as={Link} to="/blocked-eshops/add-rule" variant="outline-primary" size="sm">
                                            <AddUserIcon />
                                            <span>Aggiungi nuovo blocco</span>
                                        </Button>
                                    </Col>
                                </Row>
                            </CardTitleWrap>

                            {
                                loading === 'loading' && (
                                    <div className="text-center">
                                        <TableSpinner animation="border" />
                                    </div>
                                )
                            }
                            {
                                rowsToShow && loading === 'idle' && rowsToShow.length > 0 && (
                                    <>
                                        <Table responsive striped bordered>
                                            <thead>
                                                <tr>
                                                    <th><TableCheckbox checked={selectAll} onChange={handleSelectAll} /></th>
                                                    <th>Dominio</th>
                                                    <th>Data di inserimento</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    eshopBlocklist.map(item => {
                                                        const creationDate = moment(item.date).format('DD/MM/YYYY HH:mm');
                                                        return (
                                                            <tr key={item.id}>
                                                                <td>
                                                                    <TableCheckbox chacked={selected[item.id || false]} onChange={() => handleSelectEshop(item.id)} />
                                                                </td>
                                                                <td>
                                                                    {item.domain}
                                                                </td>
                                                                <td>
                                                                    {creationDate}
                                                                </td>
                                                                <td>
                                                                    <FilterButton
                                                                        variant="danger"
                                                                        size="sm"
                                                                        onClick={() => handleDeleteClick(item.id)}
                                                                    >
                                                                        <DeleteForeverIcon />
                                                                    </FilterButton>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </Table>

                                        <Modal show={showModal} onHide={handleClose}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Conferma eliminazione</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>Sei sicuro di voler cancellare l'utente business?</Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={handleClose}>
                                                    Annulla
                                                </Button>
                                                <Button variant="danger" onClick={handleConfirmDelete}>
                                                    Elimina
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                        <PaginationWrapper>
                                            <ReactTablePagination
                                                dataLength={pagination.total_count} // usa i dati di paginazione
                                                page={pagination.current_page}
                                                gotoPage={(pageIndex) => handlePageChange(pageIndex)} // semplicemente passa l'URL alla funzione
                                                canNextPage={pagination.next_page_url != null} // usa i dati di paginazione
                                                canPreviousPage={pagination.prev_page_url != null} // usa i dati di paginazione
                                                pageOptions={Array.from({ length: pagination.page_count }, (v, i) => i)} // usa i dati di paginazione
                                                pageSize={rowsPerPage} // usa i dati di paginazione
                                                pageIndex={pagination.current_page - 1}
                                                previousPage={previousPage} // chiama semplicemente la funzione
                                                nextPage={nextPage} // chiama semplicemente la funzione
                                                setPageSize={handleRowsPerPageChange}
                                                manualPageSize={manualPageSize}
                                            />
                                        </PaginationWrapper>

                                    </>
                                )
                            }

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );

};

EshopBlocklistTable.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default EshopBlocklistTable;

// region STYLES
const FilterButton = styled(Button)`
  margin-top: calc(1.5em);
`;

const TableSpinner = styled(Spinner)`
  color: ${colorAccent};
`;

const PaginationWrapper = styled.div`
  margin-top: 20px;
`;

const SearchWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 10px;
  
  input {
    margin-right: 10px;
  }
  
  button {
    margin: 0;
    height: 32px;
  }
`;

// endregion