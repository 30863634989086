import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from '../../../BlockedEmails/list';
// import Delete from '../../../BlockedEmails/delete';
import Add from '../../../BlockedEmails/add';

export default () => (
  <Switch>
    <Route path="/blocked-email/list" component={List} />
    <Route path="/blocked-email/add" component={Add} />
    {/* <Route path="/blocked-email/delete/:userId" component={Delete} />  */}
  </Switch>
);
