import React, { useEffect, useState } from 'react';
import { KJUR } from 'jsrsasign';
import { Container } from 'react-bootstrap';
import { get } from 'jquery';


const MetabaseQuestion = ({ question_id, bordered, titled, eshop = false, plan = false }) => {

    const [iframeUrl, setIframeUrl] = useState('');

    function getFilters() {
        let filters = '';
        const filterList = { 'plan_name': plan, 'eshop': eshop };
        Object.entries(filterList).forEach(([key, value]) => {
            if (value) {
                let separator = (filters == '') ? '?' : '&';
                filters += separator + key + '=' + value;
            }
        });
        return filters;
    }

    useEffect(() => {
        const METABASE_SITE_URL = "https://metabase.esascraper.net";
        const METABASE_SECRET_KEY = "e8d3ad3e3e6e27330fc249be3549f5dbbc704721145ce1f4e01ad990338a1156";
        const filterQuery = getFilters();

        const nowSeconds = Math.floor(Date.now() / 1000); // Current time in seconds
        const expTime = nowSeconds + (10 * 60); // Expiration time: 10 minutes from now

        console.log(question_id);
        const header = { alg: "HS256", typ: "JWT" };
        const payload = {
            resource: { question: question_id },
            params: {},
            // Adjusted to use the current time in seconds plus 10 minutes for expiration
            exp: expTime,
        };

        const token = KJUR.jws.JWS.sign(
            "HS256",
            JSON.stringify(header),
            JSON.stringify(payload),
            { utf8: METABASE_SECRET_KEY }
        );

        setIframeUrl(`${METABASE_SITE_URL}/embed/question/${token}${filterQuery}#theme=transparent&bordered=${bordered}&titled=${titled}`);
    }, [plan, eshop]);

    return (
        <Container>
            {iframeUrl && <iframe src={iframeUrl} frameBorder="0" width="100%" height="800" allowTransparency="true"></iframe>}
        </Container>
    );
};

export default MetabaseQuestion;