import { combineReducers } from 'redux';
import fetchReducer from './fetch';
import detailReducer from './detail';

const consumerUsersReducer = combineReducers({
    fetch: fetchReducer,
    detail: detailReducer,
});

export default consumerUsersReducer;
