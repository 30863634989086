// reducers.js

import { createSlice } from '@reduxjs/toolkit';
import { postEshopBlocklistData } from '../actions/addRule';

const insertEshopBlocklistSlice = createSlice({
    name: 'eshopBlocklist',
    initialState: { eshopBlocklist: {}, status: 'idle', error: null },
    reducers: {
        // Potrebbe avere eventuali altri reducer qui
    },
    extraReducers: (builder) => {
        builder
            .addCase(postEshopBlocklistData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(postEshopBlocklistData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // Aggiungi l'utente al stato
                state.eshopBlocklist = action.payload;
            })
            .addCase(postEshopBlocklistData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export const { /* other action creators, if any */ } = insertEshopBlocklistSlice.actions;

export default insertEshopBlocklistSlice.reducer;
