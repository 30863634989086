import React, { useState, useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { colorBackgroundBody } from '@/utils/palette';
import { paddingLeft } from '@/utils/directions';
import Layout from '../../../Layout/index';
import ConsumerUsers from './ConsumerUsers';
import Eshops from './Eshops';
import HibridEshops from './HibridEshops';
import Coupons from './Coupons';
import B2bUsers from './B2bUsers';
import Payments from './Payments';
import EshopBlocklist from './EshopBlocklist';
import Plans from './Plans';
import Invitations from './Invitations';
import Reviews from './Reviews';
import EsaStore from './EsaStore';
import Properties from './Properties';
import Admins from './Admins';
import BlockedEmails from './BlockedEmails';
import AnonymousReviews from './AnonymousReviews';
import HomeDashboard from '../../../Dashboard/homeDashboard';
import NewsLetter from '../../../NewsLetters/newsletter';
import { useAuth } from '../../AuthProvider';

const WrappedRoutes = () => {

  const history = useHistory();
  const { user, refreshToken } = useAuth();
  const [userLogged, setUserLogged] = useState(false);
  const token = useState(localStorage.getItem("site"));
  const userToken = token ? token[0] : false;

  const checkUser = async (user) => {
    if (!user && !userToken) {
      history.push("/login");
    }
    if (!user && userToken) {
      const refreshed = await refreshToken(userToken);
      return refreshed;
    }
    return user;
  }

  useEffect(() => {

    checkUser(user).then((result) => {
      setUserLogged(result);
    });

  }, [[user, userLogged]]);

  return (
    <div>
      <Layout />
      <ContainerWrap>
        <Route exact path="/" component={HomeDashboard} />
        <Route path="/reviews" component={Reviews} />
        <Route path="/consumer_users" component={ConsumerUsers} />
        <Route path="/registered-eshops" component={Eshops} />
        <Route path="/hibrid-eshops" component={HibridEshops} />
        <Route path="/coupons" component={Coupons} />
        <Route path="/business-accounts" component={B2bUsers} />
        <Route path="/payments" component={Payments} />
        <Route path="/blocked-eshops" component={EshopBlocklist} />
        <Route path="/plans" component={Plans} />
        <Route path="/invitations" component={Invitations} />
        <Route path="/esastore" component={EsaStore} />
        <Route path="/properties" component={Properties} />
        <Route path="/admins" component={Admins} />
        <Route path="/blocked-email" component={BlockedEmails} />
        <Route path="/anonymous-reviews" component={AnonymousReviews} />
        <Route path="/newsletters" component={NewsLetter} />
      </ContainerWrap>
    </div>
  );
}

export default WrappedRoutes;

// region STYLES

const ContainerWrap = styled.div`
  padding-top: 90px;
  min-height: 100vh;
  transition: padding-left 0.3s;

  ${paddingLeft}: 0;

  background: ${colorBackgroundBody};

  @media screen and (min-width: 576px) {
    ${paddingLeft}: 250px;
  }

  @media screen and (max-width: 576px) {
    padding-top: 150px;
  }
`;

// endregion
