import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import showResults from '@/utils/showResults';
//import HorizontalForm from './forms/addUser/accesso';
import HorizontalFormTwo from './forms/b2bUser/anagrafica';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { postB2bUserData } from './actions/insertB2bUser';
import { data } from 'jquery';
import { useHistory } from 'react-router-dom';

const FormB2bUserValidation = () => {
    //const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSubmit = (formData) => {
        const dataToSend = { ...formData }; // crea una copia
        delete dataToSend.confirm_password; // rimuovi confirm_password dalla copia
        delete dataToSend.gender; // rimuovi gender dalla copia
        dataToSend.active = 0;
        console.log(dataToSend); // mostra i dati del form senza confirm_password
        dispatch(postB2bUserData(dataToSend)) // invoca l'action
            .then(() => {
                history.push('/business-accounts/list'); // reindirizza alla lista utenti dopo l'invio dei dati
            });
    };

    return (
        <Container>
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Aggiungi utente</h3>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/business-accounts/list" }}>
                            Torna all'elenco degli utenti
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                
                <HorizontalFormTwo onSubmit={handleSubmit} />
               
            </Row>
        </Container>
    );
};

export default FormB2bUserValidation;