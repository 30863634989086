import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Col, Container, Row, Spinner, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faSave, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Card, CardBody, CardTitleWrap } from '@/shared/components/Card';
import { Table } from '@/shared/components/TableElements';
import styled from 'styled-components';
import { colorAccent } from '@/utils/palette';
import { fetchSettings } from './actions/settings';
import SettingsItem from './components/SettingsItem';
import PaginationTable from '@/containers/Components/PaginationTable';
import { Modal } from 'react-bootstrap';
import { customWarningNotify, successNotify, warningNotify,customSuccessNotify } from '../App/Router';
import { postSettings } from './actions/insertSettings';
import { updateSettings } from './actions/updateSettings';
import { api } from '@/config/api';

const SettingsList = ({ esa_id = false }) => {

  const dispatch = useDispatch();

  const settingsState = useSelector(state => state.properties.settings)  
  const [toRefreshTable, setToRefreshTable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [interactedItem, setInteractedItem] = useState({
    item: null,
    action: '',
  });    
  const [globalFilters, setGlobalFilters] = useState({ 'per-page': 100, page: 1 });
  const [formItem,setFormItem] = useState({
    category: null,
    key: null,
    value: null,
    description: null
  });

  const listSettings = settingsState.entities;
  const loading = settingsState.loading;
  const pagination = settingsState.pagination;
  const TableSpinner = styled(Spinner)`color: ${colorAccent};`;
  
  const tableLabels = [
    'Categoria',
    'Chiave',
    'Descrizione',
    'Valore'
  ];
  
  const handleOpen = () => {
    setFormItem({
      category: '',
      key: '',
      value: '',
      description: ''
    });
    setInteractedItem({
      item: null,
      action: 'create',
    });
    setShowModal(true);
  }

  const handleClose = () => {
    setShowModal(false);
  }

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    let val = type === 'checkbox' ? (checked ? 1 : 0) : value;
    const keys = name.split('[').map(key => key.replace(']', ''));
    setFormItem((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      let currentPart = newState;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!currentPart[keys[i]]) {
          currentPart[keys[i]] = {};
        }
        currentPart = currentPart[keys[i]];
      }
      currentPart[keys[keys.length - 1]] = val;
      return newState;
    });
  };

  const handleSubmit = async () => {
    const dataToSend = {...formItem};
    debugger;
    if(interactedItem.action == 'create' && dataToSend.category != '')
    {
      dispatch(postSettings(dataToSend)).then((response) => {
        if(response.error)
        {
          customWarningNotify(response.error)
        }
        else
        {
          customSuccessNotify('Impostazione create con successo');
          setToRefreshTable(true);
          setShowModal(false);
        }
      })
    }
    else
    {
      if(interactedItem.action === 'update' && dataToSend.category != '')
      {
        debugger;
        dispatch(updateSettings({id: interactedItem.item.id,data: dataToSend})).then((response) => {
          if(response.error)
          {
            customWarningNotify(response.error)
          }
          else
          {
            customSuccessNotify('Impostazione aggiornata con successo');
            setToRefreshTable(true);
            setShowModal(false);
          }
        })
      }
    }
  }

  useEffect(() => {
    const initialPayload = {
      params: globalFilters
    };

    dispatch(fetchSettings(initialPayload));
  }, [dispatch]);

  useEffect(() => {

    if(toRefreshTable)
    {
      const initialPayload = {
        params: globalFilters
      };
      dispatch(fetchSettings(initialPayload));
      setToRefreshTable(false);
    }

  },[toRefreshTable]);

  useEffect(() => {

    switch(interactedItem.action)
    {
      case 'update':
        {
          setFormItem(interactedItem.item);
          setShowModal(true);
        }
    }
  }, [interactedItem]);

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Impostazioni</h3>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <CardTitleWrap>
                  <Row className='align-items-center justify-content-between'>
                    <Col xs={12} lg={4}>
                      <p className="page-subtitle">Numero elementi: {pagination?.total_count}</p>
                    </Col>
                    <Col lg={4} style={{ textAlign: 'right' }}>
                      <Button onClick={() => handleOpen()} variant="outline-primary" size='sm'>
                        <FontAwesomeIcon icon={faPlus} className="me-2" />
                        <span>Aggiungi impostazione</span>
                      </Button>
                    </Col>
                  </Row>
                </CardTitleWrap>
                {loading === 'loading' ? (
                  <div className="text-center">
                    <TableSpinner animation="border" />
                  </div>
                ): (
                  <>
                    <Table responsive striped bordered>
                      <thead>
                        <tr>
                          {tableLabels.map((label) => {
                            return <th style={{minWidth: '200px'}}>{label}</th>
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {listSettings && loading === 'idle' && listSettings.length > 0 && (
                          listSettings.map((elem) => {
                            return (<SettingsItem item={elem} selectedItem={setInteractedItem} />)
                          })
                        ) || (
                            <tr>
                              <td colSpan={tableLabels.length} className='text-start'>Nessun elemento trovato</td>
                            </tr>
                          )}
                      </tbody>
                    </Table>
                    {listSettings && listSettings.length > 0 && (
                      <PaginationTable pagination={pagination} filters={globalFilters} action={fetchSettings} />
                    )}
                  </>                  
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal show={showModal} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>{interactedItem.actions == 'update' && formItem.key != '' ? 'Aggiornamento '+formItem.key : 'Inserimento setting'}</Modal.Title>
        </Modal.Header>

        <Modal.Body>          
          <Form autoComplete='off'>
            <Container>
              <Row>
                <Col xs={12} md={4}>
                  <Form.Group controlId={'category'}>
                    <Form.Label>Categoria</Form.Label>
                    <Form.Control
                      type='text'
                      name='category'
                      value={formItem.category}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                  <Form.Group controlId={'key'}>
                    <Form.Label>Chiave</Form.Label>
                    <Form.Control
                      type='text'
                      name='key'
                      value={formItem.key}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                  <Form.Group controlId={'value'}>
                    <Form.Label>Valore</Form.Label>
                    <Form.Control
                      type='text'
                      name='value'
                      value={formItem.value}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xs={12}>
                  <Form.Group controlId={'description'}>
                    <Form.Label>Descrizione</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows="5"
                      name='description'
                      value={formItem.description}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>              
              <Row>
                <Col xs={12} className='my-3 text-end'>
                  <Button onClick={() => handleSubmit()} size="sm" variant={'success'}>
                    <FontAwesomeIcon icon={faSave} className="me-2" /> Salva
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

SettingsList.propTypes = {
  onSubmit: PropTypes.func,
};

export default SettingsList;