import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Badge, Form, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faSave } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { couponStatusLabels, couponStatusColors, couponDiscountTypeMap } from '@/utils/utilities';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { successNotify, warningNotify } from '../../App/Router';
import { update } from '../actions/update';
import { it } from 'date-fns/locale';
import { api } from '@/config/api';

const CouponItemList = ({ item, showEshop = true }) => {

  const dispatch = useDispatch();
  const [elem, setElem] = useState(item);
  const [updatedElem, setUpdatedElem] = useState({ ...item });
  const [toRefresh, setToRefresh] = useState(false);
  const [status, setStatus] = useState(elem.status);
  const [showModal, setShowModal] = useState(false);
  const [visibleDropdownId, setVisibleDropdownId] = useState(false);

  const handleOpen = () => {
    setShowModal(true);
  }

  const handleClose = () => {
    setShowModal(false);
  }

  const toggleDropdown = (id) => {
    setVisibleDropdownId(visibleDropdownId === id ? null : id);
  };

  const handleUpdate = () => {
    const dataToSend = { ...updatedElem };
    dispatch(update({ id: item.id, data: dataToSend })).then((response) => {
      if (response.error) {
        warningNotify(response.error);
      }
      else {
        setToRefresh(true);
        handleClose()
        successNotify();
      }
    });
  }

  const handleChangeStatus = (id, value) => {
    dispatch(update({ id: id, data: { status: value, eshop_id: elem.eshop_id } })).then(() => {
      successNotify();
      setStatus(value)
      setToRefresh(true)
    });
  }

  function formatDate(dateString) {
    return moment(dateString).format('DD/MM/YYYY')
  }

  function formatDiscount() {
    return elem.discount_type == 1 ? '%' : '€';
  }

  const refreshData = async () => {
    const response = await api.get('/v1/coupons/admin-list?id=' + updatedElem.id);
    const refreshedData = response.data.items[0];
    setElem(refreshedData);
    setUpdatedElem(refreshedData);
  }

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    let val = type === 'checkbox' ? (checked ? 1 : 0) : value;
    const keys = name.split('[').map(key => key.replace(']', ''));
    setUpdatedElem((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      let currentPart = newState;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!currentPart[keys[i]]) {
          currentPart[keys[i]] = {};
        }
        currentPart = currentPart[keys[i]];
      }
      currentPart[keys[keys.length - 1]] = val;
      return newState;
    });
  };

  function handleDateChange(date) {
    const selectedDate = new Date(date);
    const formattedDate = selectedDate.toISOString().split('T')[0];
    let newItem = JSON.parse(JSON.stringify(updatedElem));
    newItem.expire_date = formattedDate;
    setUpdatedElem((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState = newItem;
      return newState;
    });
  }

  useEffect(() => {
    if (toRefresh) {
      setToRefresh(false);
      refreshData();
    }
    else {
      setElem(item);
    }
  }, [item, toRefresh]);

  return (
    <>
      <tr key={elem.id}>
        {showEshop && (
          <>
            <td className="first-letter-uppercase">
              <Link to={(elem.eshop.plan == "ibrido") ? '/hibrid-eshops/update/' : '/registered-eshops/update/' + elem.eshop_id}>
                {elem.eshop.domain}
              </Link>
            </td>
            <td className="first-letter-uppercase">
              <Badge bg={elem.eshop.plan == 'ibrido' ? 'warning' : 'success'}>{elem.eshop.plan}</Badge>
            </td>
          </>
        )}
        <td>
          {elem.discount + formatDiscount()}
        </td>
        <td>
          {elem.creation_date ? formatDate(elem.creation_date) : '-'}
        </td>
        <td>
          {elem.expire_date ? formatDate(elem.expire_date) : '-'}
        </td>
        <td>
          {elem.validation_date ? formatDate(elem.validation_date) : '-'}
        </td>
        <td>
          {elem.delete_date ? formatDate(elem.delete_date) : '-'}
        </td>
        <td>
          <DropdownButton
            id={`dropdown-button-drop-${elem.id}`}
            key={elem.id}
            size="sm"
            variant={couponStatusColors[status]}
            title={couponStatusLabels[status]}
            onClick={(e) => {
              e.preventDefault();
              toggleDropdown(elem.id);
            }}
          >
            <Dropdown.Item eventKey="1" onClick={() => handleChangeStatus(elem.id, 1)}>Valida</Dropdown.Item>
            <Dropdown.Item eventKey="2" onClick={() => handleChangeStatus(elem.id, 2)}>Rifiuta</Dropdown.Item>
          </DropdownButton>
        </td>
        <td>
          {
            <>
              {elem.private == 1 ? <Badge bg="secondary mx-2">Privato</Badge> : <Badge bg="success mx-2">Community</Badge>}
              {elem.gift == 1 ? <Badge bg="info mx-2">Omaggio</Badge> : ""}
            </>
          }
        </td>
        <td className='text-center'>
          <Button onClick={() => handleOpen(elem.id)} variant='info' size='sm'>
            <FontAwesomeIcon icon={faPen} />
          </Button>
        </td>
      </tr >
      <Modal show={showModal} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Modifica coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form autoComplete="off">
            <Container>
              <Row>
                <Col xs={12} lg={3} className='my-3'>
                  <Form.Group controlId={`cost`}>
                    <Form.Label>Codice</Form.Label>
                    <Form.Control
                      name={'code'}
                      value={updatedElem.code}
                      type="text"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} lg={3} className='my-3'>
                  <Form.Group controlId={`cost`}>
                    <Form.Label>Descrizione</Form.Label>
                    <Form.Control
                      name={'description'}
                      value={updatedElem.description}
                      type="text"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} lg={3} className='my-3'>
                  <Form.Group controlId={`cost`}>
                    <Form.Label>Sconto</Form.Label>
                    <Form.Control
                      name={'discount'}
                      value={updatedElem.discount}
                      type="text"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} lg={3} className='my-3'>
                  <Form.Group controlId="code">
                    <Form.Label>Tipo sconto</Form.Label>
                    <Form.Control
                      name={`discount_type`}
                      value={updatedElem.discount_type}
                      as="select"
                      onChange={handleChange}
                    >
                      {
                        Object.keys(couponDiscountTypeMap).map((key) => {
                          return <option key={key} value={key}>{couponDiscountTypeMap[key]}</option>
                        })
                      }
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={12} lg={4} className='my-3'>
                  <Form.Group controlId="expire_date">
                    <Form.Label className="d-block">Data scadenza</Form.Label>
                    <DatePicker
                      selected={updatedElem.expire_date ? new Date(updatedElem.expire_date) : null}
                      onChange={(date) => handleDateChange(date)}
                      locale={it}
                      className="form-control"
                      minDate={new Date()}
                      placeholderText="Seleziona data"
                      wrapperClassName="d-block"
                      dateFormat="dd/MM/yyyy"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={6} className="my-3">
                  <Form.Group controlId="code">
                    <Form.Label>Modalità utilizzo</Form.Label>
                    <Form.Control
                      name="usage_mod"
                      value={updatedElem.usage_mod}
                      as="textarea"
                      rows={5}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col xs={12} lg={6} className='my-3'>
                  <Form.Group controlId="code">
                    <Form.Label>Condizioni</Form.Label>
                    <Form.Control
                      name={`conditions`}
                      value={updatedElem.conditions}
                      as="textarea"
                      onChange={handleChange}
                      rows={5}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {
                /* TODO: Add coupon image
                <Row>
                  <Col xs={12} className='my-3'>
                  </Col>
                </Row> 
                */
              }
              <Row>
                <Col xs={12} className='my-3 text-end'>
                  <Button onClick={() => handleUpdate()} size="sm" variant={'success'}>
                    <FontAwesomeIcon icon={faSave} className="me-2" /> Salva
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body >
      </Modal >
    </>
  );
};

export default CouponItemList;