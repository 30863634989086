import React from 'react';
import { Col, Row, } from 'react-bootstrap';

const ReviewRatingDetails = ({ details_rating }) => {

  return (
    <>
      {Object.entries(details_rating).map(([key, rating], index) => {
        return (
          <Row className='my-1 my-md-3 border-bottom pb-2' key={index}>
            <Col xs={10} lg={6}><b>{key}</b></Col>
            <Col xs={2} lg={6}>{rating}</Col>
          </Row>
        );
      })}
    </>

  );
};

export default ReviewRatingDetails;