import { useEffect, useState } from "react";
import { useDispatch} from 'react-redux';
import { useParams } from "react-router-dom";
import { putBillingInfo } from "../../actions/updateBillingInfo";
import { Col,Row} from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { Card, CardBody, CardTitle, CardTitleWrap } from '@/shared/components/Card';
import {
    FormButtonToolbar, FormContainer, FormGroupField, FormGroupLabel, FormGroup,
} from '@/shared/components/form/FormElements';
import renderSelectField from '@/shared/components/form/Select';
import FormField from '@/shared/components/form/FormField';
import { Button } from '@/shared/components/Button';
import { api } from '@/config/api';
import endpoints from '../../endpoints/billingData';
import nations from '@/shared/helpers/nations';
import SelectAJX from '@/shared/components/form/select2';
import city_endpoint from '@/shared/endpoints/city';

const BillingData = () => {

    const {id} = useParams();
    const [billingData, setBillingData] = useState({});
    const dispatch = useDispatch();
    const [defaultNation, setDefaultNation] = useState('it');

    useEffect(() => {
        const fetchData = async () => {
            const response = await  api.get(endpoints.userBillingInfo.replace(':id', id));
            setBillingData(response.data[0]);
            setDefaultNation(response.data[0].nation_id);
        }
        fetchData();
    },[id]);

    const handleSubmit = (formData) => {
        const dataToSend = { ...formData };
        console.log(dataToSend);
        const billingInfoId = billingData.id;
        dispatch(putBillingInfo({dataToSend,billingInfoId}));
    }


    const loadOptions = (inputValue, callback) => {
        callback(loadCities(inputValue));
    }

    const loadCities = async (inputValue) => {
        const response = await api.get(city_endpoint.city.replace(':name', inputValue));
        debugger;
        let options = response.data.map((city) => {
            return { value: city.id, label: city.name }
        });

        return options;
    }

    return (
        <Col md={12} lg={9} xl={9}>
            <Card>
                <CardBody>
                    <CardTitleWrap>
                        <CardTitle>Dati di fatturazione</CardTitle>                        
                    </CardTitleWrap>
                    <Form onSubmit={handleSubmit} autoComplete="off">
                        {({
                            handleSubmit,
                            form: { reset },
                        }) => (
                            <FormContainer onSubmit={handleSubmit} className="w-100">
                                 <Col md={12} lg={12} xl={12}>
                                    <Row>
                                        <Col md={12} lg={12} xl={12}>
                                            <FormGroup>
                                                <FormGroupLabel>Nome</FormGroupLabel>
                                                <FormGroupField>
                                                    <Field
                                                        name="business_name"
                                                        component={FormField}
                                                        type="text"
                                                        placeholder="Ragione sociale"
                                                        defaultValue={billingData.business_name}
                                                    />                                                    
                                                </FormGroupField>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} lg={6} xl={6}>
                                            <FormGroup>
                                                <FormGroupLabel>Nome</FormGroupLabel>
                                                <FormGroupField>
                                                    <Field
                                                        name="name"
                                                        component={FormField}
                                                        type="text"
                                                        placeholder="Nome"
                                                        defaultValue={billingData.name}
                                                    />                                                    
                                                </FormGroupField>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} lg={6} xl={6}>
                                            <FormGroup>
                                                <FormGroupLabel>Nome</FormGroupLabel>
                                                <FormGroupField>
                                                    <Field
                                                        name="surname"
                                                        component={FormField}
                                                        type="text"
                                                        placeholder="Cognome"
                                                        defaultValue={billingData.surname}
                                                    />                                                    
                                                </FormGroupField>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} lg={12} xl={12}>
                                        <FormGroup>
                                                <FormGroupLabel>Nome</FormGroupLabel>
                                                <FormGroupField>
                                                    <Field
                                                        name="address"
                                                        component={FormField}
                                                        type="text"
                                                        placeholder="Indirizzo"
                                                        defaultValue={billingData.address}
                                                    />                                                    
                                                </FormGroupField>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} lg={6} xl={6}>
                                            <FormGroup>
                                                <FormGroupLabel>Nome</FormGroupLabel>
                                                <FormGroupField>
                                                    <Field
                                                        name="postal_code"
                                                        component={FormField}
                                                        type="text"
                                                        placeholder="CAP"
                                                        defaultValue={billingData.postal_code}
                                                    />                                                    
                                                </FormGroupField>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} lg={6} xl={6}>
                                            <FormGroup>
                                                <FormGroupLabel>Nome</FormGroupLabel>
                                                <FormGroupField>
                                                    <Field
                                                        name="phone"
                                                        component={FormField}
                                                        type="text"
                                                        placeholder="Telefono"
                                                        defaultValue={billingData.phone}
                                                    />                                                    
                                                </FormGroupField>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} lg={12} xl={12}>
                                            <FormGroup>
                                                <FormGroupLabel>Stato account</FormGroupLabel>
                                                    <FormGroupField>                                                
                                                        <Field
                                                            name="nation_id"
                                                            component={renderSelectField}
                                                            type='select'
                                                            options={nations} 
                                                            defaultValue={nations.filter(nation => nation.value === defaultNation)[0]}
                                                        />
                                                    </FormGroupField>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} lg={12} xl={12}>                             
                                            <SelectAJX optionsUrl={city_endpoint.city} name="b2b_user"/>  
                                        </Col>
                                    </Row>
                                </Col>
                            </FormContainer>
                        )}
                    </Form>
                </CardBody>
            </Card>
        </Col>
    )
}

export default BillingData