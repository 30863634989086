import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import FilterForm from './components/filters/filter';
import { useState } from 'react';
import {
    Col,
    Container,
    Row,
    Spinner,
} from 'react-bootstrap';
import {
    Card, CardBody, CardTitleWrap, CardTitle, CardSubhead
} from '@/shared/components/Card';
import { Table } from '@/shared/components/TableElements';
import styled from 'styled-components';
import { colorAccent } from '@/utils/palette';
import { fetchReviews } from './actions/fetchReviews';
import { fetchUsers } from './actions/fetch';
import ReactTablePagination from '@/shared/components/table/components/ReactTablePagination';
import {
    TableCheckbox
} from '@/shared/components/MaterialTableElements';
import Badge from '@/shared/components/Badge';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import { Button } from '@/shared/components/Button';
import DeleteForeverIcon from 'mdi-react/DeleteForeverIcon';

// import fetchButton from './ajax-button';
import { manualPageSize } from '@/utils/utilities';

const ReviewsTable = ({ onSubmit }) => {
    const dispatch = useDispatch();
    const reviewsState = useSelector(state => state.reviews.fetch || {});
    const reviews = reviewsState.entities;
    console.log("reviews ", reviews);
    const loading = reviewsState.loading;
    const pagination = reviewsState.pagination;


    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    // Funzione per gestire l'invio del modulo di filtro
    const [globalFilters, setGlobalFilters] = useState({});

    const handleFilterSubmit = (values) => {
        // Crea una copia dell'oggetto values
        let modifiedValues = { ...values };
        console.log(modifiedValues);
        // Modifica la data per rimuovere l'orario
        if (values.creation_date) {
            modifiedValues.creation_date = {
                start: values.creation_date.start ? values.creation_date.start.toISOString().split('T')[0] : null,
                end: values.creation_date.end ? values.creation_date.end.toISOString().split('T')[0] : null
            };
        }

        // Sostituisci "status" e "newsletter" con i loro valori
        if (values.status) {
            modifiedValues.status = values.status.value;
        }
        if (values.newsletter) {
            modifiedValues.newsletter = values.newsletter.value;
        }

        // Salva i filtri in una variabile globale
        setGlobalFilters(modifiedValues);

        // Aggiungi page e perPage ai valori modificati
        modifiedValues.page = currentPage;
        modifiedValues['per-page'] = rowsPerPage;

        // Converte l'oggetto modificato in una stringa JSON
        const jsonData = JSON.stringify(modifiedValues);


        console.log("JSON data:", jsonData);

        // Creo il payload per fetchUsers
        const payload = {
            baseUrl: '/v1/reviews',  // o la tua base URL
            params: modifiedValues  // passo i valori modificati come parametri di query
        };

        // Chiamata alla action fetchUsers con il payload
        dispatch(fetchUsers(payload));
    };

    useEffect(() => {
        const initialPayload = {
            baseUrl: '/v1/reviews',
            params: { 'per-page': 25, page: 1 }
        };

        dispatch(fetchReviews(initialPayload));
    }, [dispatch]);

    const rowsToShow = reviews;

    const handleRowsPerPageChange = (perPage) => {

        setRowsPerPage(parseInt(perPage, 10));
        setCurrentPage(1); // Reset to first page

        let filters = globalFilters;
        filters.page = 1;
        filters['per-page'] = perPage;
        dispatch(fetchUsers({
            baseUrl: '/v1/consumer-users',
            params: filters
        }));

    };

    // Pagination function
    const handlePageChange = (pageIndex) => {
        const page = pageIndex + 1; // Converti l'indice della pagina (basato su zero) in un numero di pagina (basato su 1)
        let filters = globalFilters;
        filters.page = page;
        filters['per-page'] = rowsPerPage;
        dispatch(fetchUsers({
            baseUrl: '/v1/consumer-users',
            params: filters
        }));
    };

    const nextPage = () => {
        console.log(pagination.next_page_url);
        if (pagination.next_page_url) {
            dispatch(fetchUsers(pagination.next_page_url));
        }
    };

    const previousPage = () => {
        if (pagination.prev_page_url) {
            dispatch(fetchUsers(pagination.prev_page_url));
        }
    };

    const [selectAll, setSelectAll] = useState(false);
    const [selected, setSelected] = useState({});
    const handleSelectAll = () => {
        // Inverti lo stato corrente di 'selectAll'
        const newSelectAll = !selectAll;

        // Imposta lo stato di 'selectAll'
        setSelectAll(newSelectAll);

        // Imposta lo stato di 'selected' per ogni user
        const newSelected = {};
        reviews.forEach(review => newSelected[review.id] = newSelectAll);
        setSelected(newSelected);
    }

    // Questa funzione viene chiamata quando un checkbox di un user viene cliccato
    const handleSelectUser = (id) => {
        // Inverti lo stato corrente di 'selected' per l'user specifico
        const newSelected = { ...selected, [id]: !selected[id] };

        // Imposta lo stato di 'selected'
        setSelected(newSelected);

        // Controlla se tutti gli users sono selezionati
        const allSelected = Object.values(newSelected).every(value => value);

        // Imposta lo stato di 'selectAll'
        setSelectAll(allSelected);
    }

    const [showModal, setShowModal] = useState(false);
    const [userIdToDelete, setUserIdToDelete] = useState(null);
    const handleDeleteClick = (userId) => {
        setUserIdToDelete(userId);
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    const handleConfirmDelete = () => {
        // qui si può eseguire la logica per cancellare l'utente, 
        // ad esempio chiamare un'API o dispatchare un'azione di Redux
        console.log(`Deleting user with id ${userIdToDelete}`);

        // dopo aver cancellato l'utente, chiudi il modal
        handleClose();
    };

    return (
        <Container>
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Elenco recensioni</h3>
                </Col>
            </Row>
            <FilterForm onSubmit={handleFilterSubmit} />
            <Row>
                <Col md={12} lg={12} xl={12}>
                    <Card>
                        <CardBody>
                            <CardTitleWrap>

                                <Row>
                                    <Col md={8}>
                                        <p className="page-subtitle">Numero recensioni: {pagination.total_count}</p>
                                    </Col>
                                </Row>
                            </CardTitleWrap>



                            {loading === 'loading' && (
                                <div className="text-center">
                                    <TableSpinner animation="border" />
                                </div>
                            )}
                            {rowsToShow && loading === 'idle' && rowsToShow.length > 0 && (
                                <>
                                    <Table responsive striped bordered>
                                        <thead>
                                            <tr>
                                                <th><TableCheckbox checked={selectAll} onChange={handleSelectAll} /></th>
                                                <th>Data e ora</th>
                                                <th>Eshop</th>
                                                <th>Piano</th>
                                                <th>Titolo</th>
                                                <th>Utente</th>
                                                <th>Voto</th>
                                                <th>Stato</th>
                                                <th>Media</th>
                                                <th>Ric.</th>
                                                <th>Cert.</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {reviews.map(elem => {
                                                const formattedDate = moment(elem.creation_date).format('DD/MM/YYYY HH:mm:ss');
                                                return (
                                                    <tr key={elem.id}>
                                                        <td>{elem.visibility === 0 && <TableCheckbox checked={selected[elem.id] || false} onChange={() => handleSelectUser(elem.id)} />}</td>
                                                        <td className="first-letter-uppercase">{formattedDate}</td>
                                                        <td className="first-letter-uppercase">
                                                            <Link to={`/consumer_users/detail/${elem.id}`}>{elem.name}</Link>
                                                        </td>
                                                        <td className="first-letter-uppercase">
                                                            <Link to={`/consumer_users/detail/${elem.id}`}>{elem.surname}</Link>
                                                        </td>
                                                        <td>{elem.title}</td>
                                                        <td className="first-letter-uppercase">{elem.visibility > 0 && <Badge bg="success">Abilitato</Badge>}
                                                            {elem.visibility === 0 && <Badge bg="warning">Disabilitato</Badge>}</td>
                                                        <td className="first-letter-uppercase"><Badge bg="success">Abilitato</Badge></td>
                                                        <td className="first-letter-uppercase">{elem.visibility === 0 && <EmailOutlineIcon />}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>
                                                            <FilterButton
                                                                variant="danger"
                                                                size='sm'
                                                                onClick={() => handleDeleteClick(elem.id)}
                                                            >
                                                                <DeleteForeverIcon />
                                                            </FilterButton>

                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>

                                    <Modal show={showModal} onHide={handleClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Conferma eliminazione</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>Sei sicuro di voler cancellare la recensione?</Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleClose}>
                                                Annulla
                                            </Button>
                                            <Button variant="danger" onClick={handleConfirmDelete}>
                                                Elimina
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                    <PaginationWrapper>
                                        <ReactTablePagination
                                            dataLength={pagination.total_count} // usa i dati di paginazione
                                            page={pagination.current_page}
                                            gotoPage={(pageIndex) => handlePageChange(pageIndex)} // semplicemente passa l'URL alla funzione
                                            canNextPage={pagination.next_page_url != null} // usa i dati di paginazione
                                            canPreviousPage={pagination.prev_page_url != null} // usa i dati di paginazione
                                            pageOptions={Array.from({ length: pagination.page_count }, (v, i) => i)} // usa i dati di paginazione
                                            pageSize={rowsPerPage} // usa i dati di paginazione
                                            pageIndex={pagination.current_page - 1}
                                            previousPage={previousPage} // chiama semplicemente la funzione
                                            nextPage={nextPage} // chiama semplicemente la funzione
                                            setPageSize={handleRowsPerPageChange}
                                            manualPageSize={manualPageSize}

                                        />
                                    </PaginationWrapper>
                                </>
                            )}

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

ReviewsTable.propTypes = {
    onSubmit: PropTypes.func,
};


export default ReviewsTable;

const TableSpinner = styled(Spinner)`
  color: ${colorAccent};
`;

const FilterButton = styled(Button)`
  margin-top: calc(1.5em);
`;

const PaginationWrapper = styled.div`
  margin-top: 20px;
`;
