import { createSlice } from '@reduxjs/toolkit';
import { fetchProducts } from '../actions/fetchProducts';

export const productsSlice = createSlice({
    name: 'fetchProducts',
    initialState: {
        entities: [],
        loading: 'idle',
        pagination: {
            total_count: 0,
            page_count: 0,
            current_page: 0,
            per_page: 0,
            next_page_url: null,
            prev_page_url: null,
        }
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProducts.pending, (state) => {
                state.loading = 'loading';
            })
            .addCase(fetchProducts.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.entities = action.payload.items;
                state.pagination = action.payload.pagination;
            })
            .addCase(fetchProducts.rejected, (state) => {
                state.loading = 'idle';
            });
    }

});

export default productsSlice.reducer;