// Import delle librerie e componenti necessari
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'; // Libreria per la manipolazione delle date
import PropTypes from 'prop-types'; // Utilizzato per definire i tipi delle prop

import { Button, ButtonToolbar } from '@/shared/components/Button';
import PlusIcon from 'mdi-react/PlusIcon';
import AddUserIcon from 'mdi-react/AccountPlusOutlineIcon';
import DeleteUserIcon from 'mdi-react/AccountCancelOutlineIcon';
import DatepickerField from '@/containers/UI/DatepickerField';
import DeleteForeverIcon from 'mdi-react/DeleteForeverIcon';

import { Modal } from 'react-bootstrap';
import { Form, Field } from 'react-final-form'; // Libreria per i form
import FilterForm from './filter'; // Possibile componente per il form di filtro
//import ActionForm from './azioni'; // Possibile componente per azioni sulla tabella
import {
    FormButtonToolbar,
    FormContainer,
    FormGroup,
    FormGroupField,
    FormGroupIcon,
    FormGroupLabel
} from '@/shared/components/form/FormElements'; // Componenti per la costruzione di form

import { useState } from 'react';
import { Col, Row, Container, Spinner } from 'react-bootstrap';
import {
    Card,
    CardBody,
    CardTitleWrap,
    CardTitle,
    CardSubhead
} from '@/shared/components/Card'; // Componenti per le card
import { Table } from '@/shared/components/TableElements'; // Componente per la tabella
import styled from 'styled-components'; // Libreria per lo styling
import { colorAccent } from '@/utils/palette'; // Possibili colori
import { fetchInvitations } from './actions/fetch'; // Azione per il recupero degli eshops
import ReactTablePagination from '@/shared/components/table/components/ReactTablePagination';
import {
    TableCheckbox
} from '@/shared/components/MaterialTableElements'; // Componenti per la tabella con checkbox

import Badge from '@/shared/components/Badge';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import endpoint from './endpoints/invitations';
import { useTranslation } from 'react-i18next';
import { manualPageSize } from '@/utils/utilities';


// Definizione del componente B2bUserTable

const InvitationsTable = ({ onSubmit }) => {

    const { t } = useTranslation('invitations');
    const dispatch = useDispatch(); // Hook per l'accesso al dispatch di Redux
    const invitationsState = useSelector(state => state.invitations.fetch); // Stato degli eshops dallo store Redux
    const invitations = invitationsState.entities; // Lista degli eshops
    const loading = invitationsState.loading; // Flag per indicare se i dati sono in caricamento
    const pagination = invitationsState.pagination; // *** Errore qui: dovrebbe essere eshopsState.pagination ***

    const [currentPage, setCurrentPage] = useState(1); // Stato locale per la pagina corrente
    const [rowsPerPage, setRowsPerPage] = useState(25); // Stato locale per le righe per pagina

    const [globalFilters, setGlobalFilters] = useState({}); // Stato locale per i filtri globali

    // Funzione per gestire l'invio del form di filtro
    const handleFilterSubmit = (values) => {
        let modifiedValues = { ...values }; // Copia dei valori del form

        // Manipolazione delle date di creazione se presenti nei filtri
        if (values.creation_date) {
            modifiedValues.creation_date = {
                start: values.creation_date.start ? values.creation_date.start.toISOString().split('T')[0] : null,
                end: values.creation_date.end ? values.creation_date.end.toISOString().split('T')[0] : null
            };
        }

        // Trasformazione dello stato e delle opzioni newsletter
        if (values.status) {
            modifiedValues.status = values.status.value;
        }
        if (values.newsletter) {
            modifiedValues.newsletter = values.newsletter.value;
        }

        // Aggiornamento dello stato dei filtri globali
        setGlobalFilters(modifiedValues);

        // Aggiunta delle informazioni di paginazione ai filtri
        modifiedValues.page = currentPage;
        modifiedValues['per-page'] = rowsPerPage;

        const jsonData = JSON.stringify(modifiedValues); // Conversione in JSON dei filtri

        console.log("JSON data: ", jsonData);

        const payload = {
            baseUrl: endpoint.adminList,
            params: modifiedValues
        };

        // Invio dell'azione Redux per il recupero degli eshops con i filtri
        dispatch(fetchInvitations(payload));
    }

    // Effetto per il recupero iniziale degli eshops all'avvio del componente
    useEffect(() => {
        const initialPayload = {
            baseUrl: endpoint.adminList,
            params: {
                'per-page': 25,
                page: 1
            }
        };

        // Invio dell'azione Redux per il recupero degli eshops iniziali
        dispatch(fetchInvitations(initialPayload));
    }, [dispatch]);

    // *** Mancano il ritorno e il rendering degli elementi UI ***


    // Funzione per gestire il cambio di pagina nella tabella
    const handlePageChange = (pageIndex) => {
        const page = pageIndex + 1;
        let filters = globalFilters;
        filters.page = page;
        filters['per-page'] = rowsPerPage;

        dispatch(fetchInvitations({
            baseUrl: endpoint.adminList,
            params: filters
        }));

    };

    // Funzione per passare alla pagina successiva nella tabella
    const nextPage = () => {
        console.log(pagination.next_page_url);
        if (pagination.next_page_url) {
            dispatch(fetchInvitations(pagination.next_page_url));
        }
    };

    // Funzione per passare alla pagina precedente nella tabella
    const previousPage = () => {
        if (pagination.prev_page_url) {
            dispatch(fetchInvitations(pagination.prev_page_url));
        }
    }

    const rowsToShow = invitations;

    // Funzione per gestire il cambio del numero di righe per pagina nella tabella
    const handleRowsPerPageChange = (perPage) => {
        setRowsPerPage(parseInt(perPage, 10));
        setCurrentPage(1);

        let filters = globalFilters;
        filters.page = 1;
        filters['per-page'] = perPage;

        dispatch(fetchInvitations({
            baseUrl: endpoint.adminList,
            params: filters
        }));
    }

    const [selectAll, setSelectAll] = useState(false);
    const [selected, setSelected] = useState({});

    // Funzione per selezionare tutti gli eshops nella tabella
    const handleSelectAll = () => {

        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);

        const newSelected = {};
        invitations.forEach(item => newSelected[item.id] = newSelectAll);
        setSelected(newSelected);
    }



    /**
     * Gestisce la selezione di un utente.
     * Aggiorna lo stato degli utenti selezionati e controlla se tutti gli utenti sono selezionati.
     * @param {number} id - L'ID dell'utente selezionato.
     */
    const handleSelectEshop = (id) => {
        // Crea una copia dello stato degli utenti selezionati e inverte lo stato dell'utente con l'ID specificato
        const newSelected = { ...selected, [id]: !selected[id] };
        // Inverte lo stato dell'utente con l'ID specificato
        setSelected(newSelected);
        // Controlla se tutti gli utenti sono selezionati
        const allSelected = Object.values(newSelected).every(value => value);
        // Aggiorna lo stato degli utenti selezionati e lo stato di selezione di tutti gli utenti
        setSelectAll(allSelected);
    }

    const [showModal, setShowModal] = useState(false);
    const [eshopIdToDelete, setEshopIdToDelete] = useState(null);

    const handleDeleteClick = (eshopId) => {
        setEshopIdToDelete(eshopId);
        setShowModal(true);
    }

    const handleClose = () => {
        setShowModal(false);
    }

    const handleConfirmDelete = () => {
        console.log(`Deleting user with ID:  ${eshopIdToDelete}`);
        handleClose();
    }

    return (
        <Container>
            <Row>
                <Col md={12} xs={12} lg={12}>
                    <h3 className="page-title">{t('section_title')}</h3>
                </Col>
            </Row>

            <FilterForm onSubmit={handleFilterSubmit} />
            <Row>
                <Col md={12} xs={12} lg={12}>
                    <Card>
                        <CardBody>
                            <CardTitleWrap>
                                <Row>
                                    <Col md={8}>
                                        <p className="page-subtitle">
                                            Trovati: {pagination.total_count}
                                        </p>
                                    </Col>
                                </Row>
                            </CardTitleWrap>

                            {
                                loading === 'loading' && (
                                    <div className="text-center">
                                        <TableSpinner animation="border" />
                                    </div>
                                )
                            }
                            {
                                rowsToShow && loading === 'idle' && rowsToShow.length > 0 && (
                                    <>
                                        <Table responsive striped bordered>
                                            <thead>
                                                <tr>
                                                    <th>Eshop</th>
                                                    <th>Riferimento ordine</th>
                                                    <th>Data ordine</th>
                                                    <th>Email</th>
                                                    <th>Nominativo</th>
                                                    <th>Telefono</th>
                                                    <th>Data generazione</th>
                                                    <th>Generato da</th>
                                                    <th>Invio</th>
                                                    <th>Reminder</th>
                                                    <th>Stato</th>
                                                    <th>Prodotti</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    invitations.map(item => {
                                                        const status_label = item.status_data?.status_email === 1 ? 'Email' : item.status_data?.status_sms === 1 ? 'Sms' : item.status_data?.status_whatsapp === 1 ? 'WhatsApp' : '';

                                                        const reminder_send_date = moment(item.reminder_date).format('DD/MM/YYYY');
                                                        return (
                                                            <tr key={item.id}>
                                                                <td>
                                                                    {item.domain}
                                                                </td>
                                                                <td>
                                                                    {item.order_reference}
                                                                </td>
                                                                <td>
                                                                    {moment(item.order_date).format('DD/MM/YYYY HH:mm')}
                                                                </td>
                                                                <td>
                                                                    {item.customer_email}
                                                                </td>
                                                                <td>
                                                                    {item.customer_firstname + ' ' + item.customer_lastname}
                                                                </td>
                                                                <td>
                                                                    {item.cell}
                                                                </td>
                                                                <td>
                                                                    {moment(item.creation_date).format('DD/MM/YYYY HH:mm')}
                                                                </td>
                                                                <td>
                                                                    {item.platform}
                                                                </td>
                                                                <td>
                                                                    {item.is_send === 1 ? (
                                                                        <Badge bg="success"> Inviato il {moment(item.send_date).format('DD/MM/YYYY')}</Badge>
                                                                    ) : (
                                                                        <Badge bg="warning">Da inviare il {moment(item.send_date).format('DD/MM/YYYY')}</Badge>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {item.reminder_send === 1 ? (
                                                                        <Badge bg="success"> Inviato il {reminder_send_date}</Badge>
                                                                    ) : (
                                                                        <Badge bg="warning">Da inviare il {reminder_send_date}</Badge>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.status === 1 ? (
                                                                            <Badge bg="success">Utilizzato il {moment(item.use_date).format('DD/MM/YYYY')}</Badge>
                                                                        ) : (
                                                                            <Badge bg="warning">Da utilizzare</Badge>
                                                                        )
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <Badge
                                                                        bg="primary"
                                                                        size="sm"
                                                                        onClick={() => handleDeleteClick(item.id)}
                                                                        style={{ cursor: 'pointer' }}
                                                                    >
                                                                        Vedi prodotti
                                                                    </Badge>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </Table>

                                        <Modal show={showModal} onHide={handleClose}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Conferma eliminazione</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>Sei sicuro di voler cancellare l'utente business?</Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={handleClose}>
                                                    Annulla
                                                </Button>
                                                <Button variant="danger" onClick={handleConfirmDelete}>
                                                    Elimina
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                        <PaginationWrapper>
                                            <ReactTablePagination
                                                dataLength={pagination.total_count} // usa i dati di paginazione
                                                page={pagination.current_page}
                                                gotoPage={(pageIndex) => handlePageChange(pageIndex)} // semplicemente passa l'URL alla funzione
                                                canNextPage={pagination.next_page_url != null} // usa i dati di paginazione
                                                canPreviousPage={pagination.prev_page_url != null} // usa i dati di paginazione
                                                pageOptions={Array.from({ length: pagination.page_count }, (v, i) => i)} // usa i dati di paginazione
                                                pageSize={rowsPerPage} // usa i dati di paginazione
                                                pageIndex={pagination.current_page - 1}
                                                previousPage={previousPage} // chiama semplicemente la funzione
                                                nextPage={nextPage} // chiama semplicemente la funzione
                                                setPageSize={handleRowsPerPageChange}
                                                manualPageSize={manualPageSize}
                                            />
                                        </PaginationWrapper>

                                    </>
                                )
                            }

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );

};

InvitationsTable.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default InvitationsTable;

// region STYLES
const FilterButton = styled(Button)`
  margin-top: calc(1.5em);
`;

const TableSpinner = styled(Spinner)`
  color: ${colorAccent};
`;

const PaginationWrapper = styled.div`
  margin-top: 20px;
`;

const SearchWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 10px;
  
  input {
    margin-right: 10px;
  }
  
  button {
    margin: 0;
    height: 32px;
  }
`;

// endregion