import React, { useState, useEffect } from 'react';
import {
    Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';

import { Field, Form } from 'react-final-form';
import { Col } from 'react-bootstrap';
import renderSelectField from '@/shared/components/form/Select';
import FormField from '@/shared/components/form/FormField';
import {
    FormButtonToolbar,
    FormContainer,
    FormGroupField,
    FormGroupLabel,
    FormGroup,
    FormGroupIcon,
    FormHalfContainer,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';

import TextEditor from '@/shared/components/text-editor/TextEditor';

import { BorderedTopTabs } from '@/shared/components/Tabs';
import { Nav, Tab } from 'react-bootstrap';
import { NavItem, NavLink, TabsWrap } from '@/shared/components/Tabs';

function formatInitialValues() {
    let periodValue = null;
    
    let categoryValue = null;
    

    return {
        period: periodValue,
        category: categoryValue
    };
}

function getLabelForCategory(categoryNumber) {
    switch (categoryNumber) {
        case 1:
            return 'Reputazione Offline';
        case 2:
            return 'Reputazione Online';
        case 3:
            return 'Visibilità';
        case 4:
            return 'Assistenza';
        default:
            return ''; // gestione di default, ma dovrebbe sempre corrispondere a una delle opzioni sopra
    }
}



function GeneralForm() {

    const formattedInitialValues = formatInitialValues();
    
    const onSubmit = (values) => {
        console.log('Dati inviati:', values);
        // Qui puoi gestire la logica di invio del tuo form, ad es. effettuare una chiamata API
    };

    return (
        <Form onSubmit={onSubmit}>
            {({ handleSubmit }) => (
                <FormContainer vertical onSubmit={handleSubmit}>
        <Col md={12}>
            
            <Card>
                <CardBody>
                    <CardTitleWrap>
                        <CardTitle>eShoppingAdvisor</CardTitle>
                                   
                    </CardTitleWrap>
                                
                    <BorderedTopTabs>
                        <Tab.Container defaultActiveKey="1">
                            <TabsWrap>
                                <Nav className="nav-tabs">
                                    <NavItem>
                                        <NavLink eventKey="1">
                                            Dati Generali
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink eventKey="2">
                                            Seo
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink eventKey="3">
                                            Dati Azienda
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink eventKey="4">
                                            Social
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                
                                    <Tab.Content>
                                        <Tab.Pane eventKey="1">
                                                    <FormGroup>
                                                        <FormGroupLabel>Titolo</FormGroupLabel>
                                                        <FormGroupField>
                                                            <Field
                                                                name="title"
                                                                component={FormField}
                                                                type="text"
                                                                placeholder=""
                                                            />
                                                        </FormGroupField>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <FormGroupLabel>Slogan</FormGroupLabel>
                                                        <FormGroupField>
                                                            <Field
                                                                name="slogan"
                                                                component={FormField}
                                                                type="text"
                                                                placeholder=""
                                                            />
                                                        </FormGroupField>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <FormGroupLabel>Testo</FormGroupLabel>
                                                        <TextEditor onChange={() => { }} />
                                                    </FormGroup>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="2">
                                                    <FormGroup>
                                                        <FormGroupLabel>Titolo</FormGroupLabel>
                                                        <FormGroupField>
                                                            <Field
                                                                name="text"
                                                                component={FormField}
                                                                type="text"
                                                                placeholder=""
                                                            />
                                                        </FormGroupField>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <FormGroupLabel>Meta-description</FormGroupLabel>
                                                        <FormGroupField>
                                                            <Field
                                                                name="description"
                                                                component={FormField}
                                                                type="text"
                                                                placeholder=""
                                                            />
                                                        </FormGroupField>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <FormGroupLabel>Meta-keywords</FormGroupLabel>
                                                        <FormGroupField>
                                                            <Field
                                                                name="keywords"
                                                                component={FormField}
                                                                type="text"
                                                                placeholder=""
                                                            />
                                                        </FormGroupField>
                                                    </FormGroup>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="3">
                                                    <FormGroup>
                                                        <FormGroupLabel>Azienda</FormGroupLabel>
                                                        <FormGroupField>
                                                            <Field
                                                                name="azienda"
                                                                component={FormField}
                                                                type="text"
                                                                placeholder=""
                                                            />
                                                        </FormGroupField>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <FormGroupLabel>P.IVA/Cod. Fiscale</FormGroupLabel>
                                                        <FormGroupField>
                                                            <Field
                                                                name="iva"
                                                                component={FormField}
                                                                type="text"
                                                                placeholder=""
                                                            />
                                                        </FormGroupField>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <FormGroupLabel>Indirizzo</FormGroupLabel>
                                                        <FormGroupField>
                                                            <Field
                                                                name="address"
                                                                component={FormField}
                                                                type="text"
                                                                placeholder=""
                                                            />
                                                        </FormGroupField>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <FormGroupLabel>CAP</FormGroupLabel>
                                                        <FormGroupField>
                                                            <Field
                                                                name="zip"
                                                                component={FormField}
                                                                type="text"
                                                                placeholder=""
                                                            />
                                                        </FormGroupField>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <FormGroupLabel>Città</FormGroupLabel>
                                                        <FormGroupField>
                                                            <Field
                                                                name="city"
                                                                component={FormField}
                                                                type="text"
                                                                placeholder=""
                                                            />
                                                        </FormGroupField>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <FormGroupLabel>Telefono</FormGroupLabel>
                                                        <FormGroupField>
                                                            <Field
                                                                name="phone"
                                                                component={FormField}
                                                                type="text"
                                                                placeholder=""
                                                            />
                                                        </FormGroupField>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <FormGroupLabel>Fax</FormGroupLabel>
                                                        <FormGroupField>
                                                            <Field
                                                                name="fax"
                                                                component={FormField}
                                                                type="text"
                                                                placeholder=""
                                                            />
                                                        </FormGroupField>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <FormGroupLabel>Email</FormGroupLabel>
                                                        <FormGroupField>
                                                            <Field
                                                                name="email"
                                                                component={FormField}
                                                                type="text"
                                                                placeholder=""
                                                            />
                                                        </FormGroupField>
                                                    </FormGroup>
                                                    
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="4"> 
                                                    
                                                    <FormGroup>
                                                        <FormGroupLabel>Facebook</FormGroupLabel>
                                                        <FormGroupField>
                                                            <Field
                                                                name="facebook"
                                                                component={FormField}
                                                                type="text"
                                                                placeholder=""
                                                            />
                                                        </FormGroupField>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <FormGroupLabel>Twitter - X</FormGroupLabel>
                                                        <FormGroupField>
                                                            <Field
                                                                name="twitter"
                                                                component={FormField}
                                                                type="text"
                                                                placeholder=""
                                                            />
                                                        </FormGroupField>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <FormGroupLabel>Linkedin</FormGroupLabel>
                                                        <FormGroupField>
                                                            <Field
                                                                name="linkedin"
                                                                component={FormField}
                                                                type="text"
                                                                placeholder=""
                                                            />
                                                        </FormGroupField>
                                                    </FormGroup>
                                                    
                                                    <FormGroup>
                                                        <FormGroupLabel>Instagram</FormGroupLabel>
                                                        <FormGroupField>
                                                            <Field
                                                                name="instagram"
                                                                component={FormField}
                                                                type="text"
                                                                placeholder=""
                                                            />
                                                        </FormGroupField>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <FormGroupLabel>Pinterest</FormGroupLabel>
                                                        <FormGroupField>
                                                            <Field
                                                                name="pinterest"
                                                                component={FormField}
                                                                type="text"
                                                                placeholder=""
                                                            />
                                                        </FormGroupField>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <FormGroupLabel>Youtube</FormGroupLabel>
                                                        <FormGroupField>
                                                            <Field
                                                                name="youtube"
                                                                component={FormField}
                                                                type="text"
                                                                placeholder=""
                                                            />
                                                        </FormGroupField>
                                                    </FormGroup>
                                                   
                                        </Tab.Pane>
                                    </Tab.Content>
                                        
                            </TabsWrap>
                        </Tab.Container>
                    </BorderedTopTabs>
                            
                               
                                <FormButtonToolbar>
                                    <Button variant="primary" type="submit">Salva</Button>
                                </FormButtonToolbar>
                </CardBody>

            </Card>
                        
        </Col>
                </FormContainer>
            )}
        </Form>
    );
}

export default GeneralForm;
