import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { Col } from 'react-bootstrap';

import renderSelectField from '@/shared/components/form/Select';
import PasswordField from '@/shared/components/form/Password';
import FormField from '@/shared/components/form/FormField';
import {
    Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import {
    FormButtonToolbar, FormContainer, FormGroupField, FormGroupLabel, FormGroup,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import validate from './validate';
import renderRadioButtonField from '@/shared/components/form/RadioButton';
const HorizontalForm = ({ onSubmit }) => {
    
    return (
        <Col md={12} lg={12} xl={12}>
            <Card>
                <CardBody>
                    <CardTitleWrap>
                        <CardTitle>anagrafica</CardTitle>
                        
                    </CardTitleWrap>
                    <Form onSubmit={onSubmit} validate={validate} autoComplete="off">
                        {({
                            handleSubmit,
                            form: { reset },
                        }) => (
                            <FormContainer horizontal onSubmit={handleSubmit}>
                                <FormGroup>
                                    <FormGroupLabel>Nome</FormGroupLabel>
                                    <FormGroupField>
                                        <Field
                                            name="name"
                                            component={FormField}
                                            type="text"
                                            placeholder="Nome"
                                        />
                                    </FormGroupField>
                                </FormGroup>
                                <FormGroup>
                                    <FormGroupLabel>Cognome</FormGroupLabel>
                                    <FormGroupField>
                                        <Field
                                            name="surname"
                                            component={FormField}
                                            type="text"
                                            placeholder="Cognome"
                                        />
                                    </FormGroupField>
                                </FormGroup>
                                <FormGroup>
                                    <FormGroupLabel>E-mail</FormGroupLabel>
                                    <FormGroupField>
                                        <Field
                                            name="email"
                                            component={FormField}
                                            type="email"
                                            placeholder="example@mail.com"
                                        />
                                    </FormGroupField>
                                </FormGroup>
                                <FormGroupField>
                                    <Field
                                        name="gender"
                                        component={renderRadioButtonField}
                                        label="Maschio"
                                        radioValue="male"
                                        
                                    />
                                </FormGroupField>
                                <FormGroupField>
                                    <Field
                                        name="gender"
                                        component={renderRadioButtonField}
                                        label="Femmina"
                                        radioValue="female"

                                    />
                                </FormGroupField>
                                <CardTitleWrap>
                                    <CardTitle>Dati di accesso</CardTitle>

                                </CardTitleWrap>
                                <FormGroup>
                                    <FormGroupLabel>Username</FormGroupLabel>
                                    <FormGroupField>
                                        <Field
                                            name="username"
                                            component={FormField}
                                            type="text"
                                            placeholder="Username"
                                        />
                                    </FormGroupField>
                                </FormGroup>
                                
                                <FormGroup>
                                    <FormGroupLabel>Password</FormGroupLabel>
                                    <FormGroupField>
                                        <Field
                                            name="password"
                                            component={PasswordField}
                                            placeholder="Password"
                                            autoComplete="off"
                                            keyIcon
                                        />
                                    </FormGroupField>
                                </FormGroup>
                                <FormGroup>
                                    <FormGroupLabel>Conferma password</FormGroupLabel>
                                    <FormGroupField>
                                        <Field
                                            name="confirm_password"
                                            component={PasswordField}
                                            placeholder="Ripeti Password"
                                            autoComplete="new-password"
                                            keyIcon
                                        />
                                    </FormGroupField>
                                </FormGroup>
                                <FormButtonToolbar>
                                    <Button variant="primary" type="submit">Aggiungi</Button>
                                    <Button variant="secondary" type="button" onClick={reset}>
                                        Reset
                                    </Button>
                                </FormButtonToolbar>
                            </FormContainer>
                        )}
                    </Form>
                </CardBody>
            </Card>
        </Col>
    );
};

HorizontalForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default HorizontalForm;
