import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { Form, Container, Row, Col, Button } from 'react-bootstrap';
import { nations } from '@/utils/nations';
import { eshopLangRefLabels } from '@/utils/utilities';
import ReactQuill from 'react-quill';
import { getCity } from '../../actions/getCity';
import { getCities } from '../../actions/getCities';
import 'react-quill/dist/quill.snow.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode } from "@fortawesome/free-solid-svg-icons";
import { debounce } from '@material-ui/core';

const ShowcaseData = ({ elem, setElem }) => {

  const dispatch = useDispatch();
  const [item, setItem] = useState(elem);
  const [showHtml, setShowHtml] = useState(false);
  const eshopData = item?.eshopData;
  const showcaseData = eshopData?.showcaseData;
  const structuredData = eshopData?.structured_data;
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const keys = name.split('[').map(key => key.replace(']', ''));
    setItem((prevState) => {
      let newState = { ...prevState };
      let currentPart = newState;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!currentPart[keys[i]]) {
          currentPart[keys[i]] = {};
        }
        currentPart = currentPart[keys[i]];
      }
      currentPart[keys[keys.length - 1]] = value;
      return newState;
    });
    setElem(item);
  };

  const handleChangeSelect = debounce((inputValue) => {
    setInputValue(inputValue);
  }, 500);

  const handleSelected = (option) => {
    setSelectedOption(option);
    showcaseData.municipality = option.value;
  };

  useEffect(() => {
    if (inputValue.length >= 2) {
      dispatch(getCities({ search: inputValue })).then((data) => {
        const items = data.payload.map((item) => {
          return { value: item.value, label: item.label };
        });
        setOptions(items);
      });
    }
  }, [inputValue]);

  useEffect(() => {
    if (elem) {
      setItem(elem);
      const cityId = elem.eshopData?.showcaseData?.municipality
      if (cityId) {
        dispatch(getCity({ id: cityId })).then((data) => {
          setSelectedOption({ value: data.payload.id, label: data.payload.fullName });
        });
      }
    }
  }, [elem]);

  return (
    <Container>
      <Row>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="showcaseData_email">
            <Form.Label>Indirizzo email contatto</Form.Label>
            <Form.Control name="eshopData[showcaseData][email]" value={showcaseData?.email} type="text" onChange={handleChange} />
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="showcaseData_phone">
            <Form.Label>Telefono contatto</Form.Label>
            <Form.Control name="eshopData[showcaseData][phone]" value={showcaseData?.phone} type="text" onChange={handleChange} />
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="showcaseData_nation_id">
            <Form.Label>Nazione di riferimento</Form.Label>
            <Form.Control name="eshopData[showcaseData][nation_id]" value={showcaseData?.nation_id} as="select" onChange={handleChange}>
              {
                Object.keys(nations).map((key) => {
                  return <option key={key} value={key}>{nations[key]}</option>
                })
              }
            </Form.Control>
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="showcaseData_lingua_riferimento">
            <Form.Label>Lingua riferimento</Form.Label>
            <Form.Control name="eshopData[structured_data][lingua_riferimento]" value={structuredData?.lingua_riferimento} as="select" onChange={handleChange}>
              {
                Object.keys(eshopLangRefLabels).map((key) => {
                  return <option key={key} value={key}>{eshopLangRefLabels[key]}</option>
                })
              }
            </Form.Control>
          </Form.Group>
        </Col>
        <Col xs={12} className='my-3'>
          <Form.Group controlId="eshopData_description">
            <Form.Label className="w-100">
              Descrizione
              <Button onClick={() => setShowHtml(!showHtml)} size="sm" variant={showHtml ? 'success' : 'info'} className="float-end">
                <FontAwesomeIcon icon={faCode} />
              </Button>
            </Form.Label>
            {showHtml ? (
              <Form.Control name="eshopData[description]" value={eshopData?.description} as="textarea" type="text" onChange={handleChange} rows={5} />
            ) : (
              <ReactQuill
                name="eshopData[description]"
                value={eshopData?.description}
                rows={5}
                onChange={value => handleChange({ target: { name: "eshopData[description]", value } })}
              />
            )}
          </Form.Group>
        </Col>
        <Col xs={12} className='my-3'>

        </Col>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="showcaseData_eshop_name">
            <Form.Label>Nome eshop</Form.Label>
            <Form.Control name="eshopData[showcaseData][eshop_name]" value={showcaseData?.eshop_name} type="text" onChange={handleChange} />
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="showcaseData_business_name">
            <Form.Label>Ragione sociale</Form.Label>
            <Form.Control name="eshopData[showcaseData][business_name]" value={showcaseData?.business_name} type="text" onChange={handleChange} />
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="structuredData_nazione">
            <Form.Label>Nazione</Form.Label>
            <Form.Control name="eshopData[structured_data][nazione]" value={structuredData?.nazione} as="select" onChange={handleChange}>
              {
                Object.keys(nations).map((key) => {
                  return <option key={key} value={key}>{nations[key]}</option>
                })
              }
            </Form.Control>
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="showcaseData_municipality">
            <Form.Label>Comune</Form.Label>
            <Select
              name="eshopData[showcaseData][municipality]"
              options={options}
              value={selectedOption}
              placeholder="Ricerca comune..."
              onInputChange={handleChangeSelect}
              onChange={(selectedOption) => handleSelected(selectedOption)}
            />
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="showcaseData_address">
            <Form.Label>Indirizzo</Form.Label>
            <Form.Control name="eshopData[showcaseData][address]" value={showcaseData?.address} type="text" onChange={handleChange} />
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="showcaseData_zip_code">
            <Form.Label>Cap</Form.Label>
            <Form.Control name="eshopData[showcaseData][zip_code]" value={showcaseData?.zip_code} type="text" onChange={handleChange} />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12} className='my-3'>
          <h4>Social</h4>
        </Col>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="showcaseData_facebook">
            <Form.Label>Facebook</Form.Label>
            <Form.Control name="eshopData[showcaseData][facebook]" value={showcaseData?.facebook} type="text" onChange={handleChange} />
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="showcaseData_instagram">
            <Form.Label>Instagram</Form.Label>
            <Form.Control name="eshopData[showcaseData][instagram]" value={showcaseData?.instagram} type="text" onChange={handleChange} />
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="showcaseData_linkedin">
            <Form.Label>Linkedin</Form.Label>
            <Form.Control name="eshopData[showcaseData][linkedin]" value={showcaseData?.linkedin} type="text" onChange={handleChange} />
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="showcaseData_pinterest">
            <Form.Label>Pinterest</Form.Label>
            <Form.Control name="eshopData[showcaseData][pinterest]" value={showcaseData?.pinterest} type="text" onChange={handleChange} />
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="showcaseData_twitter">
            <Form.Label>Twitter</Form.Label>
            <Form.Control name="eshopData[showcaseData][twitter]" value={showcaseData?.twitter} type="text" onChange={handleChange} />
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="showcaseData_youtube">
            <Form.Label>Youtube</Form.Label>
            <Form.Control name="eshopData[showcaseData][youtube]" value={showcaseData?.youtube} type="text" onChange={handleChange} />
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
};

export default ShowcaseData;