import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import renderDatePickerField from '@/shared/components/form/date-pickers/DatePicker';
import { FormContainer, FormGroup, FormGroupField, FormGroupLabel, } from '@/shared/components/form/FormElements';
import { Col, Row, } from 'react-bootstrap';
import { Button } from '@/shared/components/Button';
import { fetchProducts } from '../../actions/fetchProducts';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { Card, CardBody } from '@/shared/components/Card';
import renderSelectField from '@/shared/components/form/Select';
import styled from 'styled-components';
import '../../css/filterList.css';

const FilterSubProductsForm = ({ onSubmit, showPoductFilter = false }) => {

  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);

  let periodOptions = [
    { value: 'all', label: 'Tutti' },
    { value: '-1', label: '-' },
  ];

  for (let i = 1; i <= 12; i++) {
    periodOptions.push({ value: i, label: i.toString() });
  }

  const handleSelected = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    dispatch(fetchProducts({ title: inputValue })).then((data) => {
      const items = data.payload.items.map((item) => {
        return { value: item.id, label: item.title };
      });
      setOptions(items);
    });
  }, [inputValue]);

  return (
    <Card>
      <CardBody>
        <Form onSubmit={onSubmit}>
          {({ handleSubmit, form }) => (
            <FormContainer onSubmit={handleSubmit} className="filterForm">
              <Row>
                {
                  showPoductFilter && (
                    <Col xs={12}>
                      <FormGroup>
                        <FormGroupLabel>Riferimento prodotto</FormGroupLabel>
                        <FormGroupField>
                          <Select
                            name="prod_id"
                            options={options}
                            value={selectedOption}
                            placeholder="Ricerca prodotto..."
                            onInputChange={setInputValue}
                            onChange={(selectedOption) => handleSelected(selectedOption)}
                            className="w-100"
                          />
                        </FormGroupField>
                      </FormGroup>
                    </Col>
                  )
                }
                <Col>
                  <FormGroup>
                    <FormGroupLabel>Data creazione</FormGroupLabel>
                    <FormGroupField>
                      <div className='d-inline-flex align-items-center'>
                        <Field
                          name="creation_date_start"
                          component={renderDatePickerField}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Dal"
                        />
                        <span className='mx-2'> - </span>
                        <Field
                          name="creation_date_end"
                          component={renderDatePickerField}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Al"
                        />
                      </div>
                    </FormGroupField>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <FormGroupLabel>Nome sottoprodotto</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="title"
                        component="input"
                        type="text"
                        placeholder="Nome sottoprodotto"
                      />
                    </FormGroupField>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <FormGroupLabel>Cadenza</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="period"
                        component={renderSelectField}
                        type='select'
                        options={periodOptions}
                        placeholder="Tutti"
                      />
                    </FormGroupField>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup controlId="formButton">
                    <FilterButton
                      variant="outline-success"
                      size='sm'
                      onClick={handleSubmit}
                    >
                      <MagnifyIcon /><span>Filtra</span>
                    </FilterButton>
                  </FormGroup>
                </Col>
              </Row>
            </FormContainer>
          )}
        </Form>
      </CardBody>
    </Card>
  );
}

FilterSubProductsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default FilterSubProductsForm;

const FilterButton = styled(Button)`
    margin-top: calc(1.5em + .35rem);
`;