import React, { useEffect, useState } from 'react';
import { KJUR } from 'jsrsasign';
import MetabaseQuestion from '../UI/MetabaseQuestion';

const NewsLetterConsumer = () => {

    const NewsLetterConsumerQuestionId = process.env.REACT_APP_ENVIRONMENT =='production' ? 719 : 645;
    return (
        <MetabaseQuestion question_id={NewsLetterConsumerQuestionId} bordered={false} titled={true}/>
    )
};

export default NewsLetterConsumer;