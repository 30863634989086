import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import showResults from '@/utils/showResults';
import HorizontalFormTwo from './forms/detailUser/anagrafica';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { updateUser } from './actions/update'; // Assicurati che questa sia l'action corretta
import { useDispatch } from 'react-redux';
import { updateUserDetails } from './actions/update';
import { useHistory } from 'react-router-dom';
import { data } from 'jquery';
import { useParams } from 'react-router-dom';
const FormValidation = () => {
    //const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const history = useHistory();

    const { userId } = useParams(); // Ottiene lo userId dalla URL
    console.log("userId: " + userId);
    const handleSubmit = (formData) => {
        const dataToSend = { ...formData }; // crea una copia
        delete dataToSend.confirm_password; // rimuovi confirm_password dalla copia
        
        dataToSend.role = 2;
        dataToSend.active = 1;
        console.log("DATA TO SEND" + JSON.stringify(dataToSend)); // mostra i dati del form senza confirm_password
        dispatch(updateUserDetails({ userId: userId, userData: dataToSend }))
 // invoca l'action
            .then(() => {
                history.push('/admins/list'); // reindirizza alla lista utenti dopo l'invio dei dati
            });
        console.log("invio dati");
    };

    return (
        <Container>
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Modifica utente</h3>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admins/list" }}>
                            Torna all'elenco degli utenti
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>

                <HorizontalFormTwo onSubmit={handleSubmit} />

            </Row>
        </Container>
    );
};

export default FormValidation;
