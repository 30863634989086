import React from 'react';
import ReportItem from './ReportItem';
import { Table } from '@/shared/components/TableElements';

function ReportReviewItem({ item }) {

  const tableLabels = ['Autore', 'Creata', 'Esito', 'Motivazione', 'Azioni']

  return (
    <tr>
      <td className='align-top'> {item.review.title} </td>
      <td className='align-top'> {item.eshop.domain} </td>
      <td>
        <Table responsive bordered className="w-100">
          <thead>
            <tr>
              {tableLabels.map((label) => {
                return <th>{label}</th>
              })}
            </tr>
          </thead>
          <tbody>
            {
              item.reports.map(report => {
                return <ReportItem item={report} />
              })
            }
          </tbody>
        </Table>
      </td>
    </tr>
  )
}

export default ReportReviewItem;