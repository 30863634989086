import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Container, Row, Spinner, Button, Modal, Form, } from 'react-bootstrap';
import { Card, CardBody, CardTitleWrap } from '@/shared/components/Card';
import { Table } from '@/shared/components/TableElements';
import styled from 'styled-components';
import { colorAccent } from '@/utils/palette';
import { getPaymentMethods } from './actions/getPaymentMethods';
import { addPaymentMethod } from './actions/addPaymentMethod';
import PaymentMethodsItemList from './components/PaymentMethodsItemList'
import PaginationTable from '@/containers/Components/PaginationTable';
import { faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { successNotify, warningNotify } from '../App/Router';

const PaymentMethodsList = () => {
  const dispatch = useDispatch();
  const [toRefresh, setToRefresh] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newItem, setNewItem] = useState(false);
  const dataState = useSelector(state => state.eshops.paymentMethods)
  const dataItems = dataState.entities;
  const loading = dataState.loading;
  const pagination = dataState.pagination;
  const TableSpinner = styled(Spinner)`color: ${colorAccent};`;
  const tableLabels = ['Nome', 'Stato', ''];

  const handleOpen = () => {
    setShowModal(true);
  }

  const handleClose = () => {
    setShowModal(false);
  }

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    let val = type === 'checkbox' ? (checked ? 1 : 0) : value;
    const keys = name.split('[').map(key => key.replace(']', ''));
    setNewItem((prevState) => {
      let newState = { ...prevState };
      let currentPart = newState;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!currentPart[keys[i]]) {
          currentPart[keys[i]] = {};
        }
        currentPart = currentPart[keys[i]];
      }
      currentPart[keys[keys.length - 1]] = val;
      return newState;
    });
  };

  const handleSave = () => {
    const dataToSend = {};
    if (newItem.name) {
      dataToSend.name = newItem.name;
    }
    if (newItem.img) {
      dataToSend.img = newItem.img;
    }
    console.log(dataToSend);
    dispatch(addPaymentMethod({ data: dataToSend })).then((res) => {
      if (res.payload?.success) {
        successNotify();
        setToRefresh(true);
        setShowModal(false);
      }
      else {
        warningNotify();
      }
    });
  }

  useEffect(() => {
    if (toRefresh) {
      setToRefresh(false);
    }
    dispatch(getPaymentMethods());
  }, [dispatch, toRefresh]);

  return (
    <>
      <Card>
        <CardBody>
          <Container>
            <Row>
              <Col md={12}>
                <h3 className="page-title">Metodi di pagamento</h3>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <CardTitleWrap>
                      <Row className='align-items-center'>
                        <Col xs={12} lg={6}>
                          <p className="page-subtitle">Numero elementi: {pagination?.total_count}</p>
                        </Col>
                        <Col xs={12} lg={6} className="text-end">
                          <Button onClick={handleOpen} size="sm" variant={'success'} className="float-end">
                            <FontAwesomeIcon icon={faPlus} className="me-2" /> Aggiungi
                          </Button>
                        </Col>
                      </Row>
                    </CardTitleWrap>
                    {loading === 'loading' ? (
                      <div className="text-center">
                        <TableSpinner animation="border" />
                      </div>
                    ) : (
                      <>
                        <Table responsive striped bordered>
                          <thead>
                            <tr>
                              {tableLabels.map((label) => {
                                return <th>{label}</th>
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {dataItems && loading === 'idle' && dataItems.length > 0 && (
                              dataItems.map((elem) => {
                                return (<PaymentMethodsItemList item={elem} setToRefreshList={setToRefresh} />)
                              })
                            ) || (
                                <tr>
                                  <td colSpan={tableLabels.length} className='text-start'>Nessun elemento trovato</td>
                                </tr>
                              )}
                          </tbody>
                        </Table>
                        {dataItems && dataItems.length > 0 && (
                          <PaginationTable pagination={pagination} action={getPaymentMethods} />
                        )}
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Card >
      <Modal show={showModal} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Aggiungi Metodo Pagamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className='my-3'>
              <Form autoComplete="off">
                <Container>
                  <Row className='align-items-end'>
                    <Col xs={4} className='my-3'>
                      <Form.Group controlId="name">
                        <Form.Label>Nome</Form.Label>
                        <Form.Control name="name" value={newItem.name} type="text" onChange={handleChange} />
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
              </Form>
            </Row>
          </Container>
        </Modal.Body >
        <Modal.Footer >
          <Button onClick={handleSave} size="sm" variant={'success'} className="float-end">
            <FontAwesomeIcon icon={faSave} className="me-2" /> Salva
          </Button>
        </Modal.Footer >
      </Modal >
    </>
  );
};

PaymentMethodsList.propTypes = {
  onSubmit: PropTypes.func,
};

export default PaymentMethodsList;