import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { manualPageSize } from '@/utils/utilities';
import ReactTablePagination from '@/shared/components/table/components/ReactTablePagination';

function PaginationTable({ pagination, filters, action }) {

  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const PaginationWrapper = styled.div`margin-top: 20px;`;

  const handleRowsPerPageChange = (perPage) => {
    setRowsPerPage(parseInt(perPage, 10));
    filters.page = 1;
    filters['per-page'] = perPage;
    fetchAction(action, { params: filters })
  };

  const handlePageChange = (pageIndex) => {
    filters.page = pageIndex + 1;
    filters['per-page'] = rowsPerPage;
    fetchAction(action, { params: filters })
  };

  const nextPage = () => {
    filters.page = pagination.current_page + 1;
    filters['per-page'] = rowsPerPage;
    fetchAction(action, { params: filters })
  };

  const previousPage = () => {
    filters.page = pagination.current_page - 1;
    filters['per-page'] = rowsPerPage;
    fetchAction(action, { params: filters })
  };

  const fetchAction = (action, data) => {
    try {
      dispatch(action(data));
    }
    catch (err) {
      console.log('Error pagination: ', err);
    }
  };

  return (
    <PaginationWrapper>
      <ReactTablePagination
        dataLength={pagination.total_count}
        page={pagination.current_page}
        gotoPage={(pageIndex) => handlePageChange(pageIndex)}
        canNextPage={pagination.next_page_url != null}
        canPreviousPage={pagination.prev_page_url != null}
        pageOptions={Array.from({ length: pagination.page_count }, (v, i) => i)}
        pageSize={rowsPerPage}
        pageIndex={pagination.current_page - 1}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={handleRowsPerPageChange}
        manualPageSize={manualPageSize}
      />
    </PaginationWrapper>
  );
}

export default PaginationTable;