import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { Form, Button, Container, Row, Col, Modal } from 'react-bootstrap';
import { Card, CardBody, CardTitle, CardTitleWrap } from '@/shared/components/Card';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { successNotify, warningNotify } from '../../App/Router';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { deleteEshopGallery } from '../actions/deleteEshopGallery';
import { imageNotFound } from '@/utils/utilities';
import { api } from '@/config/api';
import endpoint_eshop from '../endpoints/eshops';

const EshopGallery = ({ item }) => {

  const [toRefresh, setToRefresh] = useState(false);
  const [elem, setElem] = useState(item);
  const [show, setShow] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const imageGallery = elem.eshopData?.structured_data?.image_gallery || [];
  const baseUrl = process.env.REACT_APP_ESA_CDN_URL + 'eshop-gallery/' + elem.id + '/';
  const dispatch = useDispatch();

  registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

  const handleClose = () => setShow(false);
  const handleShow = (image) => {
    setPreviewImage(image);
    setShow(true);
  };

  const handleDelete = (index) => {
    dispatch(deleteEshopGallery({ esa_id: elem.id, index: index })).then(() => {
      setToRefresh(true);
      successNotify();
    });
  }

  const refreshData = async () => {
    const response = await api.get(endpoint_eshop.getEshop.replace(':id', elem.id));
    setElem(response.data);
  }

  useEffect(() => {
    if (toRefresh) {
      setToRefresh(false);
      refreshData();
    }
    else {
      setElem(item);
    }
  }, [item, toRefresh]);

  return (
    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle className="d-block">
            Galleria immagini
          </CardTitle>
        </CardTitleWrap>
        <Form autoComplete="off">
          <Container>
            <Row>
              <Col xs={12} className='my-3'>
                <FilePond
                  allowFileTypeValidation={true}
                  allowImagePreview={true}
                  imagePreviewMaxHeight={100}
                  acceptedFileTypes={[
                    "image/jpg",
                    "image/jpeg",
                    "image/png",
                    "image/webp",
                  ]}
                  allowFileSizeValidation={true}
                  maxFileSize={"100MB"}
                  maxTotalFileSize={"100MB"}
                  allowMultiple={true}
                  maxFiles={4}
                  credits={undefined}
                  allowPaste={false}
                  storeAsFile={false}
                  server={{
                    url: process.env.REACT_APP_API_URL + '/v1/domains/upload-eshop-gallery/' + elem.id,
                  }}
                  labelIdle="Trascina il tuo file qui oppure <span class='filepond--label-action'> Seleziona dai files </span>"
                  onprocessfile={(error, file) => {
                    if (error) {
                      warningNotify();
                    } else {
                      setToRefresh(true);
                      successNotify();
                    }
                  }}
                />
              </Col>
              {imageGallery.map((image, index) => {
                return (
                  <Col xs={12} md={3} className='my-3 d-flex flex-column justify-content-end'>
                    <img
                      onClick={() => handleShow(baseUrl + image)}
                      onError={(e) => { e.target.src = imageNotFound }}
                      src={baseUrl + image}
                      className="img-fluid"
                      style={{ cursor: 'pointer' }}
                    />
                    <Button onClick={() => handleDelete(index)} size="sm" variant={'danger'} >
                      <FontAwesomeIcon icon={faTrash} className="me-2" /> Elimina
                    </Button>
                  </Col>
                );
              })}
            </Row>
            <Modal show={show} onHide={handleClose} size="xl">
              <Modal.Header closeButton>
                <Modal.Title>Anteprima immagine</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <img src={previewImage} className="img-fluid" alt="Preview" />
              </Modal.Body>
            </Modal>
          </Container>
        </Form>
      </CardBody>
    </Card>
  )
}

export default EshopGallery
