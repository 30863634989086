import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { JsonEditor } from 'json-edit-react'
import styled from 'styled-components';
import { Button } from '@/shared/components/Button';
import {
    FormButtonToolbar,
    FormContainer,
    FormGroup,
    FormGroupField,
    FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Container, Row, Col } from 'react-bootstrap';
import { fetchJson } from './actions/fetchJson';
import { updateJson } from './actions/updateJson';
import { successNotify, warningNotify } from '@/containers/App/Router/index';

const Homepage = () => {
    const dispatch = useDispatch();
    const jsonFromRedux = useSelector(state => state.properties.fetchJson.item || "");
    const [item, setItem] = useState(jsonFromRedux);

    useEffect(() => {
        dispatch(fetchJson(0));

    }, [dispatch]);

    useEffect(() => {
        setItem(jsonFromRedux);

    }, [jsonFromRedux]);

    const onSubmit = async (values) => {

        try {
            await dispatch(updateJson({ position: 0, text: values.jsonContent }));
            successNotify();
        } catch (error) {
            warningNotify();
            // console.log(error);
        }

    };

    const convertStringToJson = (str) => {
        try {
            return JSON.parse(str);
        } catch (e) {
            return {};
        }
    }

    return (

        <Container>
            <Row>
                <Col md={12}>
                    <h3 className="page-title">HP Box Consigliati</h3>

                </Col>
            </Row>

            <Form
                onSubmit={onSubmit}
                initialValues={{ jsonContent: convertStringToJson(item.content) }}
            >
                {({ handleSubmit, values }) => (
                    <FormContainer vertical onSubmit={handleSubmit}>
                        <FormGroup>
                            <FormGroupLabel>Gestione JSON Homepage Box</FormGroupLabel>
                            <FormGroupField>
                                <JsonEditor
                                    data={values.jsonContent || {}}
                                    onUpdate={({ newData }) => {
                                        values.jsonContent = newData;
                                        setItem({ ...item, content: JSON.stringify(values.jsonContent) });
                                    }}
                                />
                            </FormGroupField>
                        </FormGroup>
                        <FormButtonToolbar>
                            <Button variant="primary" type="submit">Salva</Button>
                        </FormButtonToolbar>
                    </FormContainer>
                )}
            </Form>
        </Container>
    );

}

export default Homepage;

const LargeTextarea = styled.textarea`
    height: calc(1.5em * 30);
    resize: vertical;
`;
