import { createSlice } from '@reduxjs/toolkit';
import { fetchReviews } from '../actions/fetchReviews';
import { updateReview } from '../actions/updateReview';
import { update } from 'immutable';
const reviewsSlice = createSlice({
    name: 'reviews',
    initialState: {
        updateCount: 0,
        entities: [],
        loading: 'idle',
        pagination: {
            total_count: 0,
            page_count: 0,
            current_page: 0,
            per_page: 0,
            next_page_url: null,
            prev_page_url: null,
        }
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchReviews.pending, (state) => {
                state.loading = 'loading';
            })
            .addCase(fetchReviews.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.entities = action.payload.items;
                state.pagination = action.payload.pagination;
                console.log("state", state);
            })
            .addCase(fetchReviews.rejected, (state) => {
                state.loading = 'idle';
            })
            .addCase(updateReview.fulfilled, (state, action) => {
                const index = state.entities.findIndex(review => review.id === action.payload.id);
                state.updateCount += 1;
                if (index !== -1) {
                    // Aggiorna l'entità della recensione con i dati appena ricevuti
                    state.entities[index] = action.payload;
                    console.log("state", action.payload);
                }
            });

    },
});

export default reviewsSlice.reducer;