import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../config/api';

export const updateReview = createAsyncThunk(
    'review/updateReview',
    async ({ id, data }) => {
        console.log("Invio dettagli: ", { id, data }); // Log dettagliato
        const response = await api.put(`/v1/reviews/${id}`, data);
        console.log("Risposta del server: ", response.data); // Log della risposta
        return response.data;
    }
);