import { createSlice } from '@reduxjs/toolkit';
import { updateJson } from '../actions/updateJson';

const updateJsonSlice = createSlice({
    name: 'updateJson',
    initialState: {
        item: '',
        loading: 'idle',
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateJson.pending, (state) => {
                state.loading = 'loading';
            })
            .addCase(updateJson.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.item = action.payload;

            })
            .addCase(updateJson.rejected, (state) => {
                state.loading = 'idle';
            });
    },
});

export default updateJsonSlice.reducer;