import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Badge, Form, Container, Row, Col, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClock, faCloudArrowDown, faCloudUpload, faCogs, faEye, faImage, faPen, faTrash, faTriangleExclamation, faUpload } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import { successNotify, warningNotify } from '../../App/Router';
import { updateEshopInvitation } from '../actions/updateEshopInvitation';
import { uploadEshopInvitation } from '../actions/uploadEshopInvitation';
import { eshopInvitationStatusLabels, eshopInvitationStatusColors } from '@/utils/utilities';

const EshopInvitationItemList = ({ item, setToRefreshList, showEshop = true }) => {

  const dispatch = useDispatch();
  const [elem, setElem] = useState(item);
  const [showModal, setShowModal] = useState(false);
  const logoUrl = process.env.REACT_APP_ESA_CDN_URL + 'logos/' + elem.eshop_id + '.webp';
  const bounceList = elem.bounce;

  const handleOpen = () => {
    setShowModal(true);
  }

  const handleClose = () => {
    setShowModal(false);
  }

  const handleChangeStatus = (value) => {
    // if (elem.status != 1) {
    dispatch(updateEshopInvitation({ id: elem.id, data: { status: value } })).then(() => {
      successNotify();
      setToRefreshList(true);
    });
    // }
  }

  const handleUploadList = () => {
    if (elem.status == 0) {
      dispatch(uploadEshopInvitation({ id: elem.id })).then((res) => {
        if (res.payload?.success) {
          successNotify();
          setToRefreshList(true);
        }
        else {
          warningNotify();
        }
      });
    }
  }

  function formatDate(dateString) {
    return moment(dateString).format('DD/MM/YYYY')
  }

  function percentage(num, total) {
    if (total > 0) {
      return Math.round((num / total) * 100) + '%';
    }
    else {
      return '-';
    }
  }

  useEffect(() => {
    setElem(item);
  }, [item]);

  return (
    <>
      <tr key={elem.id} /* className={`bg-${eshopInvitationStatusColors[status]}`} style={{ '--bs-bg-opacity': 0.2 }} */>
        {showEshop && (
          <>
            <td>
              <Link to={(elem.eshopInfo.domain == "ibrido") ? '/hibrid-eshops/update/' : '/registered-eshops/update/' + elem.eshop_id}>
                {elem.eshopInfo.domain}
              </Link>
            </td>
            <td className="first-letter-uppercase">
              <Badge bg={elem.eshopInfo.plan == 'ibrido' ? 'warning' : 'success'}>{elem.eshopInfo.plan}</Badge>
            </td>
          </>
        )}
        <td>
          {elem.date ? formatDate(elem.date) : '-'}
        </td>
        <td>
          {elem.invitations_uploaded ? elem.invitations_uploaded : '-'}
        </td>
        <td>
          {elem.ordersInfo.total ? elem.ordersInfo.total + '(' + percentage(elem.ordersInfo.total, elem.invitations_uploaded) + ')' : '-'}
        </td>
        <td>
          {elem.ordersInfo.used ? elem.ordersInfo.used + '(' + percentage(elem.ordersInfo.used, elem.invitations_uploaded) + ')' : '-'}
        </td>
        <td>
          {elem.ordersInfo.validEmails ? elem.ordersInfo.validEmails + '(' + percentage(elem.ordersInfo.validEmails, elem.invitations_uploaded) + ')' : '-'}
        </td>
        <td>
          {elem.ordersInfo.bounce ? elem.ordersInfo.bounce + '(' + percentage(elem.ordersInfo.bounce, elem.invitations_uploaded) + ')' : '-'}
        </td>
        <td>
          <Button
            variant={eshopInvitationStatusColors[elem.status]}
            size='sm'
            onClick={handleOpen}
          >
            {eshopInvitationStatusLabels[elem.status]}
          </Button>
        </td>
        <td>
          {elem.export_date ? formatDate(elem.export_date) : '-'}
        </td>
        <td className='text-center'>
          <Button size='sm' onClick={handleOpen} variant={'primary'} title='Opzioni' >
            <FontAwesomeIcon icon={faCogs} />
          </Button>
        </td>
      </tr >
      <Modal show={showModal} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Opzioni caricamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className='my-3 text-center border-bottom pb-3'>
              {elem.ordersInfo.waitingEmails > 0 ? (
                <Col xs={12} lg={3} className='my-3'>
                  {elem.status == 0 &&
                    <Button size='sm' variant={'warning'} className='me-2' size={'sm'}>
                      <FontAwesomeIcon icon={faClock} className='me-2' />
                      Email in attesa
                    </Button>
                  }
                </Col>
              ) : (
                <>
                  {elem.ordersInfo.total > 0 ? (
                    <>
                      {elem.status == 0 &&
                        <Col xs={12} lg={3} className='my-3'>
                          <Button size='sm' onClick={handleUploadList} variant={'warning'} className='me-2' size={'sm'}>
                            <FontAwesomeIcon icon={faCloudUpload} className='me-2' />
                            Carica su Brevo
                          </Button>
                        </Col>
                      }
                      {elem.status == 1 &&
                        <Col xs={12} lg={3} className='my-3'>
                          <Button size='sm' onClick={() => handleChangeStatus(2)} variant={'success'} className='me-2' size={'sm'}>
                            <FontAwesomeIcon icon={faCheck} className='me-2' />
                            Segna come spedito
                          </Button>
                        </Col>
                      }
                      <Col xs={12} lg={3} className='my-3'>
                        <Button size='sm' variant={elem.status == 1 ? 'primary' : 'info'} className='me-2' size={'sm'}>
                          <FontAwesomeIcon icon={faCloudArrowDown} className='me-2' />
                          Scarica {elem.status == 1 ? 'reminder' : 'lista email'}
                        </Button>
                      </Col>
                      {elem.ordersInfo.checkRegen == 'true' && (
                        <Col xs={12} lg={3} className='my-3'>
                          <Button size='sm' variant={'warning'} className='me-2' size={'sm'}>
                            <FontAwesomeIcon icon={faUpload} className='me-2' />
                            Rigenera inviti
                          </Button>
                        </Col>
                      )}
                    </>
                  ) : (
                    <Col xs={12} lg={3} className='my-3'>
                      <Button size='sm' variant={'danger'} className='me-2' size={'sm'}>
                        <FontAwesomeIcon icon={faTriangleExclamation} className='me-2' />
                        Nessun invito generato
                      </Button>
                    </Col>
                  )}
                </>
              )}
              <Col xs={12} lg={3} className='my-3'>
                <a href={logoUrl} target={'_blank'} download>
                  <Button size='sm' variant={'secondary'} className='me-2' size={'sm'}>
                    <FontAwesomeIcon icon={faImage} className='me-2' />
                    Scarica logo Eshop
                  </Button>
                </a>
              </Col>
              <Col xs={12} lg={3} className='my-3'>
                <Button onClick={() => handleChangeStatus(-1)} size='sm' variant={'danger'} className='me-2'>
                  <FontAwesomeIcon icon={faTrash} className='me-2' />
                  Elimina caricamento
                </Button>
              </Col>
            </Row>
            <Row className='my-3'>
              <Col xs={12}>
                <h3>Lista Bounce</h3>
              </Col>
              <Col xs={12}>
                {elem.ordersInfo.bounce ? (
                  <ul className="list-group">
                    {bounceList.map((bounce, index) => {
                      return (
                        <li key={index} className="list-group-item">Order: {bounce.ordine}, Email: {bounce.email}</li>
                      )
                    })}
                  </ul>
                ) : (
                  <>
                    <h4 className='text-success'>
                      <FontAwesomeIcon icon={faCheck} className='me-2' /> Nessun bounce
                    </h4>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </Modal.Body >
      </Modal >
    </>
  );
};

export default EshopInvitationItemList;