import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faImages, faFileImage } from "@fortawesome/free-solid-svg-icons";

const sidebar_product = [
  {
    "name": "Modifica",
    "namestate": "update-form",
    "icon": <FontAwesomeIcon icon={faEdit} />
  },
  {
    "name": "Anteprima",
    "namestate": "product-preview",
    "icon": <FontAwesomeIcon icon={faFileImage} />
  },
  {
    "name": "Galleria",
    "namestate": "product-gallery",
    "icon": <FontAwesomeIcon icon={faImages} />
  },
];


export default sidebar_product;