import React, { useEffect, useState } from 'react';
import { Form, Container, Row, Col } from 'react-bootstrap';
import { nations } from '@/utils/nations';
import { eshopMothlyOrdersLabels } from '@/utils/utilities';

const LoginData = ({ elem, setElem }) => {

  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [item, setItem] = useState(elem);
  const info = item?.info;

  const validatePasswords = () => {
    console.log(info.password + ' - ' + info.confirm_password);
    setPasswordsMatch(info.password === info.confirm_password);
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    const keys = name.split('[').map(key => key.replace(']', ''));

    setItem((prevState) => {
      let newState = { ...prevState };
      let currentPart = newState;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!currentPart[keys[i]]) {
          currentPart[keys[i]] = {};
        }
        currentPart = currentPart[keys[i]];
      }
      currentPart[keys[keys.length - 1]] = value;
      return newState;
    });
    setElem(item);
  };

  useEffect(() => {
    if (elem) {
      setItem(elem);
    }
  }, [elem]);

  return (
    <Container>
      <Row>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="info_username">
            <Form.Label>Username</Form.Label>
            <Form.Control name="info[username]" value={info?.username} type="text" onChange={handleChange} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="info_password">
            <Form.Label>Password</Form.Label>
            <Form.Control name="info[password]" type="password" onChange={handleChange} isInvalid={!passwordsMatch} />
            <Form.Control.Feedback type="invalid">Passwords do not match</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className='my-3'>
          <Form.Group controlId="info_confirm_password">
            <Form.Label>Conferma Password</Form.Label>
            <Form.Control name="info[confirm_password]" type="password" onChange={handleChange} isInvalid={!passwordsMatch} onBlur={validatePasswords} />
            <Form.Control.Feedback type="invalid">Passwords do not match</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginData;