import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';

export const getPaymentMethods = createAsyncThunk(
    'domains/getPaymentMethods',
    async () => {
        const actionUrl = '/v1/domains/get-payment-methods';
        const url = actionUrl;
        const response = await api.get(url);
        return response.data;

    }

);