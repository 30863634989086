import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';
import endpoints from '../endpoints/eshops';

export const updateEshopData = createAsyncThunk(
    'eshops/updateEshopData',
    async ({ id, data }) => {
        const response = await api.put('/v1/domains/update-eshop-data/' + id, data);
        return response.data;
    }
);