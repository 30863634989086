import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Badge, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Card, CardBody, CardTitle, CardTitleWrap } from '@/shared/components/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faSave, faPlus } from '@fortawesome/free-solid-svg-icons';
import { api } from '@/config/api';
import endpoints from './endpoints/payments';
import moment from 'moment';
import { nations } from '@/utils/nations';
import Select from 'react-select';
import { debounce } from '@material-ui/core';
import { fetchComuni } from '../Payments/actions/fetchComuni';
import { postInvoice } from './actions/createInvoice';
import { putInvoice } from './actions/updateInvoice';
import { customWarningNotify, successNotify, warningNotify, customSuccessNotify } from '../App/Router';

const EsaStoreInvoice = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const [paymentData, setPaymentData] = useState({});
  const [productData, setProductData] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [toRefreshPage, setToRefreshPage] = useState(false);

  const handleSelected = (option) => {
    setSelectedOption(option);

    setFormItem((prevState) => ({
      ...prevState,
      city_id: option.value,
    }))
  };

  const calcEndDate = (start_date) => {
    if (productData.period > 0) {
      return moment(start_date).add(productData.period, 'months').format('DD/MM/YYYY');
    }
    else {
      return moment(start_date).format('DD/MM/YYYY');
    }
  }

  const [formItem, setFormItem] = useState({
    total: '',
    app_vat: '',
    start_date: '',
    end_date: '',
    expire_date: '',
    name: '',
    surname: '',
    description: '',
    BillingInfo: {
      business_name: '',
      address: '',
      postal_code: '',
      nation_id: '',
      province_id: '',
      city_id: '',
      city_name: '',
      pec: '',
      sdi: '',
      p_iva: '',
      c_fiscale: '',
      phone: '',
      no_p_iva: '',
    }
  });

  const fetchProductData = async () => {
    const response = await api.get('v1/esastore/paid-product/' + paymentData.prod_id);
    setProductData(response.data);
  }

  const fetchData = async (payment_id) => {
    const response = await api.get(endpoints.paymentData + '/' + payment_id);
    setPaymentData(response.data);
  }

  const handleChangeSelect = debounce((inputValue) => {
    setInputValue(inputValue);
  }, 500);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    let val = type === 'checkbox' ? (checked ? 1 : 0) : value;
    const keys = name.split('[').map(key => key.replace(']', ''));
    setFormItem((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      let currentPart = newState;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!currentPart[keys[i]]) {
          currentPart[keys[i]] = {};
        }
        currentPart = currentPart[keys[i]];
      }
      currentPart[keys[keys.length - 1]] = val;
      return newState;
    });
  };

  const handleSubmit = async () => {
    const dataToSend = { ...formItem };
    if (paymentData.invoice?.id > 0) {
      dispatch(putInvoice({ id: paymentData.id, data: dataToSend })).then((response) => {
        if (response.error) {
          customWarningNotify(response.error)
        }
        else {
          customSuccessNotify('Fattura aggiornata con successo');
          setToRefreshPage(true);
        }
      })
    }
    else {
      dispatch(postInvoice({ id: paymentData.id, data: dataToSend })).then((response) => {
        if (response.error) {
          customWarningNotify(response.error)
        }
        else {
          customSuccessNotify('Fattura generata con success');
          setToRefreshPage(true);
        }
      })
    }

  }

  useEffect(() => {
    fetchData(id);
  }, [id]);

  useEffect(() => {
    if (toRefreshPage) {
      fetchData(id);
      setToRefreshPage(false);
    }
  }, [toRefreshPage]);

  useEffect(() => {
    if (paymentData.prod_id > 0) {
      fetchProductData(paymentData.prod_id);
    }
  }, [paymentData]);

  useEffect(() => {
    if (paymentData.prod_id > 0) {

      setFormItem({
        total: paymentData.amount,
        app_vat: 1,
        start_date: moment(paymentData.creation_date).format('DD/MM/YYYY'),
        end_date: calcEndDate(paymentData.creation_date),
        expire_date: moment(paymentData.creation_date).format('DD/MM/YYYY'),
        description: productData.productName,
        BillingInfo: {
          name: paymentData.billingInfo?.name,
          surname: paymentData.billingInfo?.surname,
          business_name: paymentData.billingInfo?.business_name,
          address: paymentData.billingInfo?.address,
          postal_code: paymentData.billingInfo?.postal_code,
          nation_id: paymentData.billingInfo?.nation_id,
          province_id: paymentData.billingInfo?.province_id,
          city_id: paymentData.billingInfo?.city_id,
          city_name: paymentData.billingInfo?.city_name,
          pec: paymentData.billingInfo?.pec,
          sdi: paymentData.billingInfo?.sdi,
          p_iva: paymentData.billingInfo?.p_iva,
          c_fiscale: paymentData.billingInfo?.c_fiscale,
          phone: paymentData.billingInfo?.phone,
          no_p_iva: paymentData.billingInfo?.no_p_iva,
        }
      });

      if (paymentData.billingInfo?.city_id > 0) {
        const currentCity = api.get("/v1/comune-item/" + paymentData.billingInfo?.city_id).then((response) => {
          setSelectedOption({ value: response.data.id, label: response.data.fullName });
        });

      }
    }
  }, [paymentData, productData]);


  useEffect(() => {
    if (inputValue.length >= 2) {
      dispatch(fetchComuni({ comune: inputValue })).then((data) => {
        const items = data.payload.map((item) => {
          return { value: item.value, label: item.label };
        });
        setOptions(items);
      });
    }
  }, [inputValue]);

  return (
    <>
      <Card>
        <CardBody>
          <Container>
            <Row>
              <Col xs={12}>
                <h3>Pagamento del {moment(paymentData.payment_date).format('DD/MM/YYYY')} di {paymentData.domain}</h3>
              </Col>
            </Row>
            <Row className="mt-4 justify-content-end">
              <Col xs={'auto'}>
                <a href={paymentData.xmlUrl} download={''} className="me-3">
                  <Button size="sm" variant={'primary'}>
                    <FontAwesomeIcon icon={faPlus} className="me-2" /> Scarica XML
                  </Button>
                </a>
                <a href={paymentData.pdfUrl} download={''} className="me-3">
                  <Button size="sm" variant={'primary'}>
                    <FontAwesomeIcon icon={faPlus} className="me-2" /> Scarica PDF
                  </Button>
                </a>
                <Button onClick={() => handleSubmit()} size="sm" variant={'success'}>
                  <FontAwesomeIcon icon={faSave} className="me-2" /> Salva
                </Button>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={12}>
                <Form autoComplete='off'>
                  <Row className='mt-4'>
                    <Col xs={12} md={4}>
                      <Form.Group>
                        <Form.Label>Importo</Form.Label>
                        <Form.Control
                          type="text"
                          name="total"
                          value={formItem.total}
                          onChange={handleChange}
                        />
                        <div className="mt-2">
                          <span style={{ fontWeight: 'bold' }}>
                            Importo
                          </span><br />
                          <span>Imponibile: {parseFloat(formItem.total / 1.22).toFixed(2)}</span><br />
                          <span>IVA: {parseFloat(formItem.total - (formItem.total / 1.22)).toFixed(2)}</span>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Group>
                        <Form.Check
                          name={'app_vat'}
                          type="checkbox"
                          label="Applicare iva"
                          checked={formItem.app_vat == 1}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col xs={12} md={4}>
                      <Form.Group>
                        <Form.Label>Data inizio servizio</Form.Label>
                        <Form.Control
                          type="text"
                          name={'start_date'}
                          value={formItem.start_date}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Group>
                        <Form.Label>Data fine servizio</Form.Label>
                        <Form.Control
                          type="text"
                          name={'end_date'}
                          value={formItem.end_date}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Group>
                        <Form.Label>Data scadenza</Form.Label>
                        <Form.Control
                          type="text"
                          name={'expire_date'}
                          value={formItem.expire_date}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <Form.Label>Descrizione</Form.Label>
                        <Form.Control
                          type="text"
                          name={'description'}
                          value={formItem.description}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <Form.Label>Ragione sociale</Form.Label>
                        <Form.Control
                          type="text"
                          name={'BillingInfo[business_name]'}
                          value={formItem.BillingInfo.business_name}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col xs={12} md={3}>
                      <Form.Group>
                        <Form.Label>Nome</Form.Label>
                        <Form.Control
                          type="text"
                          name={'BillingInfo[name]'}
                          value={formItem.BillingInfo.name}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={3}>
                      <Form.Group>
                        <Form.Label>Cognome</Form.Label>
                        <Form.Control
                          type="text"
                          name={'BillingInfo[surname]'}
                          value={formItem.BillingInfo.surname}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Group>
                        <Form.Label>Indirizzo</Form.Label>
                        <Form.Control
                          type="text"
                          name={'BillingInfo[address]'}
                          value={formItem.BillingInfo.address}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={2}>
                      <Form.Group>
                        <Form.Label>CAP</Form.Label>
                        <Form.Control
                          type="text"
                          name={'BillingInfo[postal_code]'}
                          value={formItem.BillingInfo.postal_code}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <Form.Label>Nazione</Form.Label>
                        <Form.Select
                          name={'BillingInfo[nation_id]'}
                          value={formItem.BillingInfo.nation_id}
                          onChange={handleChange}
                        >
                          {
                            Object.keys(nations).map((key) => {
                              return <option key={key} value={key}>{nations[key]}</option>
                            })
                          }
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <Form.Label>Città</Form.Label>
                        <Select
                          name="BillingInfo[city_id]"
                          options={options}
                          value={selectedOption}
                          placeholder="Cerca una città"
                          onInputChange={handleChangeSelect}
                          onChange={(selectedOption) => handleSelected(selectedOption)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col xs={12} md={3}>
                      <Form.Group>
                        <Form.Label>PEC</Form.Label>
                        <Form.Control
                          type="text"
                          name={'BillingInfo[pec]'}
                          value={formItem.BillingInfo.pec}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={3}>
                      <Form.Group>
                        <Form.Label>SDI</Form.Label>
                        <Form.Control
                          type="text"
                          name={'BillingInfo[sdi]'}
                          value={formItem.BillingInfo.sdi}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={3}>
                      <Form.Group>
                        <Form.Label>Partita IVA</Form.Label>
                        <Form.Control
                          type="text"
                          name={'BillingInfo[p_iva]'}
                          value={formItem.BillingInfo.p_iva}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={3}>
                      <Form.Group>
                        <Form.Label>Codice Fiscale</Form.Label>
                        <Form.Control
                          type="text"
                          name={'BillingInfo[c_fiscale]'}
                          value={formItem.BillingInfo.c_fiscale}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
            <Row className="mt-4 justify-content-end">
              <Col xs={'auto'}>
                <a href={paymentData.xmlUrl} download={''} className="me-3">
                  <Button size="sm" variant={'primary'} className="ms-2">
                    <FontAwesomeIcon icon={faPlus} className="me-2" /> Scarica XML
                  </Button>
                </a>
                <a href={paymentData.pdfUrl} download={''} className="me-3">
                  <Button size="sm" variant={'primary'} className="ms-2">
                    <FontAwesomeIcon icon={faPlus} className="me-2" /> Scarica PDF
                  </Button>
                </a>
                <Button onClick={() => handleSubmit()} size="sm" variant={'success'}>
                  <FontAwesomeIcon icon={faSave} className="me-2" /> Salva
                </Button>
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Card>
    </>
  );

}

export default EsaStoreInvoice;