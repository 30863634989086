export const nations = {
    "": "-",
    "it": "Italia",
    "af": "Afghanistan",
    "ax": "Isole \u00c5land",
    "al": "Albania",
    "dz": "Algeria",
    "as": "Samoa Americane",
    "ad": "Andorra",
    "ao": "Angola",
    "ai": "Anguilla",
    "aq": "Antartide",
    "ag": "Antigua e Barbuda",
    "ar": "Argentina",
    "am": "Armenia",
    "aw": "Aruba",
    "au": "Australia",
    "at": "Austria",
    "az": "Azerbaigian",
    "bs": "Bahamas",
    "bh": "Bahrein",
    "bd": "Bangladesh",
    "bb": "Barbados",
    "by": "Bielorussia",
    "be": "Belgio",
    "bz": "Belize",
    "bj": "Benin",
    "bm": "Bermuda",
    "bt": "Bhutan",
    "bo": "Bolivia",
    "ba": "Bosnia Erzegovina",
    "bw": "Botswana",
    "bv": "Isola Bouvet",
    "br": "Brasile",
    "io": "Territorio Britannico dellOceano Indiano (BIOT)",
    "vg": "Isole Vergini Britanniche",
    "bn": "Brunei",
    "bg": "Bulgaria",
    "bf": "Burkina Faso",
    "bi": "Burundi",
    "kh": "Cambogia",
    "cm": "Camerun",
    "ca": "Canada",
    "cv": "Capo Verde",
    "ky": "Isole Cayman",
    "cf": "Repubblica Centrafricana",
    "td": "Ciad",
    "cl": "Cile",
    "cn": "Cina",
    "cx": "Isola di Natale",
    "cc": "Isole Cocos (Keeling)",
    "co": "Colombia",
    "km": "Comore",
    "cg": "Congo",
    "ck": "Isole Cook",
    "cr": "Costa Rica",
    "hr": "Croazia",
    "cy": "Cipro",
    "cu": "Cuba",
    "cz": "Repubblica Ceca",
    "cd": "Repubblica Democratica del Congo",
    "dk": "Danimarca",
    "dj": "Gibuti",
    "dm": "Dominica",
    "do": "Repubblica Dominicana",
    "tl": "Timor Est",
    "ec": "Ecuador",
    "eg": "Egitto",
    "sv": "El Salvador",
    "gq": "Guinea Equatoriale",
    "er": "Eritrea",
    "ee": "Estonia",
    "et": "Etiopia",
    "fk": "Isole Falkland",
    "fo": "Isole Faroe",
    "fm": "Micronesia, Stati Federati della",
    "fj": "Isole Figi",
    "fi": "Finlandia",
    "fr": "Francia",
    "gf": "Guiana Francese",
    "pf": "Polinesia Francese",
    "tf": "Territori Australi Francesi",
    "ga": "Gabon",
    "gm": "Gambia",
    "ge": "Georgia",
    "de": "Germania",
    "gh": "Ghana",
    "gi": "Gibilterra",
    "gr": "Grecia",
    "gl": "Groenlandia",
    "gd": "Grenada",
    "gp": "Guadalupa",
    "gu": "Guam",
    "gt": "Guatemala",
    "gn": "Guinea",
    "gw": "Guinea-Bissau",
    "gy": "Guyana",
    "ht": "Haiti",
    "hm": "Isole Heard e McDonald",
    "hn": "Honduras",
    "hk": "Hong Kong",
    "hu": "Ungheria",
    "is": "Islanda",
    "in": "India",
    "id": "Indonesia",
    "iq": "Iraq",
    "ie": "Irlanda",
    "il": "Israele",
    "ci": "Costa dAvorio",
    "jm": "Giamaica",
    "jp": "Giappone",
    "jo": "Giordania",
    "kz": "Kazakistan",
    "ke": "Kenya",
    "ki": "Kiribati",
    "kw": "Kuwait",
    "kg": "Kirghizistan",
    "la": "Laos",
    "lv": "Lettonia",
    "lb": "Libano",
    "ls": "Lesotho",
    "lr": "Liberia",
    "ly": "Libia",
    "li": "Liechtenstein",
    "lt": "Lituania",
    "lu": "Lussemburgo",
    "mo": "Macau",
    "mk": "Macedonia",
    "mg": "Madagascar",
    "mw": "Malawi",
    "my": "Malesia",
    "mv": "Maldive",
    "ml": "Mali",
    "mt": "Malta",
    "mh": "Isole Marshall",
    "mq": "Martinica",
    "mr": "Mauritania",
    "mu": "Mauritius",
    "yt": "Mayotte",
    "mx": "Messico",
    "md": "Moldova",
    "mc": "Monaco",
    "mn": "Mongolia",
    "ms": "Montserrat",
    "ma": "Marocco",
    "mz": "Mozambico",
    "mm": "Myanmar (Birmania)",
    "na": "Namibia",
    "nr": "Nauru",
    "np": "Nepal",
    "nl": "Paesi Bassi",
    "an": "Antille Olandesi",
    "nc": "Nuova Caledonia",
    "nz": "Nuova Zelanda",
    "ni": "Nicaragua",
    "ne": "Niger",
    "ng": "Nigeria",
    "nu": "Niue",
    "nf": "Isola Norfolk",
    "kp": "Corea del Nord",
    "mp": "Isole Marianne Settentrionali",
    "no": "Norvegia",
    "om": "Oman",
    "pk": "Pakistan",
    "pw": "Palau",
    "ps": "Territori Occupati della Palestina",
    "pa": "Panama",
    "pg": "Papua Nuova Guinea",
    "py": "Paraguay",
    "pe": "Peru",
    "ph": "Filippine",
    "pn": "Isola Pitcairn",
    "pl": "Polonia",
    "pt": "Portogallo",
    "pr": "Puerto Rico",
    "qa": "Qatar",
    "re": "Reunion",
    "ro": "Romania",
    "ru": "Russia",
    "rw": "Ruanda",
    "sh": "SantElena",
    "kn": "St. Kitts e Nevis",
    "lc": "St. Lucia",
    "pm": "St. Pierre e Miquelon",
    "vc": "St. Vincent e Grenadine",
    "ws": "Samoa",
    "sm": "San Marino",
    "st": "Sao Tome e Principe",
    "sa": "Arabia Saudita",
    "sn": "Senegal",
    "cs": "Serbia e Montenegro",
    "sc": "Seychelles",
    "sl": "Sierra Leone",
    "sg": "Singapore",
    "sk": "Slovacchia",
    "si": "Slovenia",
    "sb": "Isole Solomon",
    "so": "Somalia",
    "za": "Sud Africa",
    "gs": "Isole Georgia del Sud e Sandwich meridionali",
    "kr": "Corea del Sud",
    "es": "Spagna",
    "pi": "Isole Spratly",
    "lk": "Sri Lanka",
    "sr": "Suriname",
    "sj": "Isole Svalbard e Jan Mayen",
    "sz": "Swaziland",
    "se": "Svezia",
    "ch": "Svizzera",
    "sy": "Siria",
    "tw": "Taiwan",
    "tj": "Tagikistan",
    "tz": "Tanzania",
    "th": "Thailandia",
    "tg": "Togo",
    "tk": "Isole Tokelau",
    "to": "Tonga",
    "tt": "Trinidad e Tobago",
    "tn": "Tunisia",
    "tr": "Turchia",
    "tm": "Turkmenistan",
    "tc": "Isole Turks e Caicos",
    "tv": "Tuvalu",
    "ug": "Uganda",
    "ua": "Ucraina",
    "ae": "Emirati Arabi Uniti",
    "uk": "Regno Unito",
    "us": "Stati Uniti",
    "um": "Isole minori degli Stati Uniti",
    "uy": "Uruguay",
    "vi": "Isole Vergini Statunitensi",
    "uz": "Uzbekistan",
    "vu": "Vanuatu",
    "va": "Citta&agrave; del Vaticano",
    "ve": "Venezuela",
    "vn": "Vietnam",
    "wf": "Isole Wallis e Futuna",
    "eh": "Sahara Occidentale",
    "ye": "Yemen",
    "zm": "Zambia",
    "zw": "Zimbabwe"
}