import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';

export const fetchAdmins = createAsyncThunk(
    'admins/fetchAdmins',
    async (payload = { baseUrl: '/v1/admins', params: { 'per-page': 25, page: 1 } }) => {
        let url;
        if (typeof payload === 'string') {
            // Se il payload è una stringa, assumi che sia una URL
            url = payload;
        } else {
            const { baseUrl, params } = payload;

            // Genera una stringa di query da 'params' e aggiungila alla 'baseUrl'
            const queryString = Object.entries(params)
                .map(([key, val]) => {
                    // Gestisci il caso in cui 'val' è un oggetto
                    if (typeof val === 'object' && val !== null) {
                        return `${encodeURIComponent(key)}_start=${encodeURIComponent(val.start)}&${encodeURIComponent(key)}_end=${encodeURIComponent(val.end)}`;
                    } else {
                        return `${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
                    }
                })
                .join('&');
            url = `${baseUrl}?${queryString}`;
        }

        console.log(url);
        const response = await api.get(url);
        console.log(response.data);
        return response.data;
    }
);


