import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from '../../../AnonymousReviews/list';
import ListCat from '../../../AnonymousReviews/listCat';
// import Delete from '../../../BlockedEmails/delete';
import Add from '../../../AnonymousReviews/add';

export default () => (
  <Switch>
    <Route path="/anonymous-reviews/list/eshop" component={List} />
    <Route path="/anonymous-reviews/list/categories" component={ListCat} />
    <Route path="/anonymous-reviews/add" component={Add} />
    {/* <Route path="/blocked-email/delete/:userId" component={Delete} />  */}
  </Switch>
);
