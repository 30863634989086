import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col, Container } from 'react-bootstrap';
import Badge from '@/shared/components/Badge';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReasons } from '../../actions/reasons';
import { statusLabels, statusColors, checkReviewMedia } from '@/utils/utilities';
import MediaModal from '@/containers/Reviews/mediaManagement';
import ReviewAlerts from '../ReviewAlerts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

function ReviewDetailModal({ show, handleClose, elem, handleSave, handleChangeStatus }) {

  const dispatch = useDispatch();
  const [editableElem, setEditableElem] = useState(elem);
  const [detailsRating, setDetailsRating] = useState([]);
  const [imageModalContent, setImageModalContent] = useState(null);
  const review_medias_obj = checkReviewMedia(editableElem.review);
  const reasons = useSelector(state => state.reviews.reasons);
  const optinWaiting = elem.user?.visibility != 1 && elem.review?.order_id == null && elem.review?.status == '0' ? true : false;

  useEffect(() => {
    if (elem) {
      setEditableElem(elem);
      let detailsRatingArray;
      try {
        const detailsRatingString = elem.review && typeof elem.review.details_rating === 'string'
          ? elem.review.details_rating
          : JSON.stringify(elem.review.details_rating || {});
        detailsRatingArray = JSON.parse(detailsRatingString);
        console.log("detailsRatingArray", detailsRatingArray);
      } catch (error) {
        console.error("Errore nella conversione di details_rating:", error);
        detailsRatingArray = [];
      }
      setDetailsRating(detailsRatingArray);
      dispatch(fetchReasons());
    }
  }, [elem]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditableElem(prevState => ({
      ...prevState,
      review: {
        ...prevState.review,
        [name]: value
      }
    }));
  };

  const handleReviewSave = () => {
    handleSave(editableElem);
  };

  const [showNonValidationReason, setShowNonValidationReason] = useState(false);
  const [nonValidationReason, setNonValidationReason] = useState('');

  const toggleNonValidationReason = () => {
    setShowNonValidationReason(!showNonValidationReason);
  };

  const handleChangeStatusConfirm = (status) => {

    handleChangeStatus(editableElem.review.id, status, nonValidationReason);
    setShowNonValidationReason(false);
  };

  const renderDetailsRating = (detailsRating) => {
    let details;
    try {
      details = JSON.parse(detailsRating);
    } catch (e) {
      console.error('Errore nel parsing della stringa JSON:', e);
      return <Col xs={12} md={6}>Dettagli non disponibili</Col>;
    }

    return (
      <>
        {Object.entries(details).map(([key, value]) => (
          <Col xs={12} md={4}><strong>{key}</strong>: {value}</Col>
        ))}
      </>
    );
  };

  let reviewReceipt = false;
  let countReceipt = 0
  if (editableElem.review.receipt !== null && editableElem.review.receipt !== "") {
    try {
      reviewReceipt = JSON.parse(editableElem.review.receipt);
      countReceipt = Array.isArray(reviewReceipt) ? reviewReceipt.length : 0
    } catch (error) {
      console.error('Error parsing receipt:', editableElem.review.receipt);
    }
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <Button variant="success" onClick={handleReviewSave}>Salva</Button>
            {reviewReceipt ? (
              <a
                href={process.env.REACT_APP_ESA_CDN_URL + `reviewsReceipt/${editableElem.review.id}/${reviewReceipt[countReceipt - 1]}`}
                target="_blank"
                rel="noreferrer"
                download
              >
                <Button variant="warning" className='mx-1'>P.Acquisto</Button>
              </a>
            ) : null}
            {(review_medias_obj !== undefined && review_medias_obj.has_data) && (
              <Button variant="info" onClick={() => setImageModalContent(review_medias_obj)} className='mx-1'>Media</Button>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {editableElem && (
              <>
                <div className="info-section">
                  <Row className='text-center'>
                    <Col xs={7} md={6}>
                      <span className="fs-6">
                        <strong>Eshop:</strong> {editableElem.eshop.domain}
                        <Badge bg="success">{editableElem.eshop.plan}</Badge>
                      </span>
                    </Col>
                    <Col xs={5} md={6}>
                      <span className="fs-6">
                        <strong className='me-3'>stato:</strong>
                        {
                          optinWaiting
                            ? <Badge bg={"secondary"}>{"Attesa optin"}</Badge>
                            : <Badge bg={statusColors[elem.review.status] || "secondary"}>{statusLabels[elem.review.status] || "Stato sconosciuto"}</Badge>
                        }
                      </span>
                    </Col>
                  </Row>
                  <Row className='text-center mb-2 pb-2 border-bottom'>
                    <Col xs={6} md={6}>
                      <span className="fs-6">
                        <strong>Utente:</strong>
                        {editableElem.user?.email
                          ? editableElem.user.email
                          : editableElem.review.user_name + ' ' + editableElem.review.user_surname
                        }
                        {
                          (editableElem.user?.visibility != 1 || !editableElem.user) && (
                            <Badge className="ms-2" bg={"warning"} title="Non registrato">
                              <FontAwesomeIcon icon={faExclamationTriangle} />
                            </Badge>
                          )
                        }
                      </span>
                    </Col>
                    <Col xs={6} md={6}>
                      <span className="fs-6">
                        <strong>Voto:</strong> {editableElem.review.overall_rating}
                      </span>
                    </Col>
                  </Row>
                  {editableElem.review.details_rating && (
                    <Row className='my-2 py-2 border-bottom'>
                      {renderDetailsRating(editableElem.review.details_rating)}
                    </Row>
                  )}
                  <Row className='my-2 py-2 border-bottom fs-5'>
                    <ReviewAlerts review={editableElem.review} />
                  </Row>
                </div>
                <Form.Group className="mb-3" controlId="reviewTitle">
                  <Form.Label>Titolo *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Inserisci titolo"
                    name="title"
                    value={editableElem.review.title}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="reviewDescription">
                  <Form.Label>Descrizione *</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    value={editableElem.review.description}
                    onChange={handleChange}
                  />
                </Form.Group>
              </>
            )}
            {showNonValidationReason && (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Motivazione Non Validazione</Form.Label>
                  <Form.Control as="select" value={nonValidationReason} onChange={(e) => setNonValidationReason(e.target.value)}>
                    <option value="">Seleziona una motivazione...</option>
                    {reasons.reasons.map((reason) => (
                      <option key={reason.id} value={reason.id}>{reason.title}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Button variant="danger" disabled={!nonValidationReason} onClick={() => handleChangeStatusConfirm("not_valid")}>Conferma Non Validazione</Button>
              </>
            )}
          </Form>


        </Modal.Body>
        <Modal.Footer>
          <Row className="w-100 justify-content-center">
            <Col xs={'auto'} className='my-2'>
              <Button variant="outline-dark" onClick={() => handleChangeStatusConfirm("suspend")}>Sospendi</Button>
            </Col>
            {
              !optinWaiting && (
                <>
                  <Col xs={'auto'} className='my-2'>
                    <Button variant="outline-info" onClick={() => handleChangeStatusConfirm("certificate")}>Verifica</Button>
                  </Col>
                  <Col xs={'auto'} className='my-2'>
                    <Button variant="outline-danger" onClick={toggleNonValidationReason}>Non Validare</Button>
                  </Col>
                  <Col xs={'auto'} className='my-2'>
                    <Button variant="outline-warning" onClick={() => handleChangeStatusConfirm("valid_certificate")}>Valida e Verifica</Button>
                  </Col>
                  <Col xs={'auto'} className='my-2'>
                    <Button variant="outline-success" onClick={() => handleChangeStatusConfirm("valid")}>Valida</Button>
                  </Col>
                </>
              )
            }
          </Row>
        </Modal.Footer>
      </Modal >
      <MediaModal show={imageModalContent !== null} handleClose={() => setImageModalContent(null)} title="Gestione Media" review_data={imageModalContent} />
    </>
  );
}

export default ReviewDetailModal;
