import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';
import endpoints from '../endpoints/plans';

export const updateCatalogo = createAsyncThunk(
    'plans/updateCatalogo',
    async ({ id,data }) => {
        const response = await api.put(endpoints.updateCatalogo+`/${id}`, data);
        return response.data;
    }
);