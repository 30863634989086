import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { Card, CardBody, CardTitle, CardTitleWrap } from '@/shared/components/Card';
import { api } from '@/config/api';
import SidebarMenu from '@/containers/Layout/custom_components/SidebarMenu'
import sidebar_links from './configs/sidebar_product';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faHome } from '@fortawesome/free-solid-svg-icons';
import Breadcrumbs from '@/containers/Components/Breadcrumbs';
import ProductForm from './forms/productForm';
import ProductPreview from './forms/productPreview';
import ProductGallery from './forms/productGallery';

const Update = () => {

  const { id } = useParams();
  const [currentSection, setCurrentSection] = useState('update-form');
  const [item, setItem] = useState({});
  const breadcrumbPaths = [
    { label: 'Home', url: '/', icon: faHome },
    { label: 'Prodotti', url: '/esastore/products', icon: false },
    { label: item.title, url: false, icon: false },
  ];

  const getSelection = () => {
    switch (currentSection) {
      case 'update-form':
        return <ProductForm elem={item} lockPrice={true} />;
      case 'product-preview':
        return <ProductPreview item={item} />;
      case 'product-gallery':
        return <ProductGallery item={item} refreshData={fetchData} />;
      default:
        debugger
        return null;
    }
  }

  const changePageClick = (page) => {
    setCurrentSection(page);
  }

  const fetchData = async () => {
    const response = await api.get('/v1/esastore/products-admin-list?id=' + id);
    const refreshedData = response.data.items[0];
    setItem(refreshedData);
  }

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <Container>
      <Row >
        <Col className='my-1'>
          <h2>
            <FontAwesomeIcon icon={faBoxOpen} className='me-3' />
            {item.title}
          </h2>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col>
          <Breadcrumbs paths={breadcrumbPaths} />
        </Col>
      </Row>
      <Row>
        <SidebarMenu routes={sidebar_links} changePageClick={changePageClick} currentPage={currentSection} />
        <Col xs={12} lg={8} xl={9}>
          <Card>
            <CardBody>
              {getSelection()}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default Update;