import React, { useState, useEffect, useContext } from 'react';
import {
  AccountCard,
  AccountContent,
  AccountHead,
  AccountLogo,
  AccountLogoAccent,
  AccountTitle,
  AccountWrap,
} from '@/shared/components/account/AccountElements';
import { Form, Row } from 'react-bootstrap';
import { useAuth } from '../App/AuthProvider';

const Login = () => {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { loginAction } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await loginAction({ username, password });
    } catch (error) {
      console.error('Login failed', error);
    }
  };

  const handleChanges = (e) => {
    const { name, value } = e.target;
    if (name === 'username') {
      setUsername(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  }

  return (
    <AccountWrap>
      <AccountContent>
        <AccountCard>
          <AccountHead>
            <AccountTitle>
              <AccountLogo>
                <img src={process.env.PUBLIC_URL + '/static/img/logo/logo_light.png'} alt="logo" className='img-fluid' />
                <AccountLogoAccent>ADMIN</AccountLogoAccent>
              </AccountLogo>
            </AccountTitle>
            {/* <h4 className="subhead">Start your business easily</h4> */}
          </AccountHead>
          <Form autoComplete='off'>
            <Row>
              <Form.Group className="my-3" controlId="formBasicEmail">
                <Form.Label>Username</Form.Label>
                <Form.Control name='username' type="text" placeholder="Enter username" onChange={handleChanges} />
              </Form.Group>
              <Form.Group className="my-3" controlId="formBasicEmail">
                <Form.Label>Password</Form.Label>
                <Form.Control name='password' type="password" placeholder="Enter password" onChange={handleChanges} />
              </Form.Group>
            </Row>
            <Row>
              <div className='text-center my-3'>
                <button className="btn btn-primary w-100" type="submit" onClick={handleSubmit}>
                  Accedi
                </button>
              </div>
            </Row>
          </Form>
        </AccountCard>
      </AccountContent>
    </AccountWrap>
  )
}

export default Login;