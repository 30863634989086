import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from '../../../Plans/list';
//import Add from '../../../Plans/add';
import Update from '../../../Plans/update';
import CatalogoPiani from '../../../Plans/catalogoPiani';
import ListPromo from '../../../Plans/listPromo';

export default () => (
  <Switch>
    <Route path="/plans/list" component={List} />  
    <Route path="/plans/update" component={Update} />
    <Route path="/plans/catalogo-piani" component={CatalogoPiani}/>
    <Route path="/plans/elenco-promo" component={ListPromo} />
  </Switch>
);
