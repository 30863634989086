import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App/App';
import * as serviceWorker from './serviceWorker';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Buffer } from 'buffer';
import 'dotenv/config';

window.Buffer = Buffer;
i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                eshops: require('./locales/en/eshops.json'),
                payments: require('./locales/en/payments.json'),
                b2bUsers: require('./locales/en/b2bUsers.json'),
                invitations: require('./locales/en/invitations.json'),
                eshopBlocklist: require('./locales/en/eshopBlocklist.json'),
                plans: require('./locales/en/plans.json'),
                esastore: require('./locales/en/esastore.json'),

            },
            it: {
                eshops: require('./locales/it/eshops.json'),
                payments: require('./locales/it/payments.json'),
                b2bUsers: require('./locales/it/b2bUsers.json'),
                invitations: require('./locales/it/invitations.json'),
                eshopBlocklist: require('./locales/it/eshopBlocklist.json'),
                plans: require('./locales/it/plans.json'),
                esastore: require('./locales/it/esastore.json'),
            },
        },
        lng: "it",
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    })

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
