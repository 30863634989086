import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from '../../../ConsumerUsers/list';
import AddUser from '../../../ConsumerUsers/addUser';
import DetailUser from '../../../ConsumerUsers/detailUser';

export default () => (
  <Switch>
    <Route path="/consumer_users/list" component={List} />
    <Route path="/consumer_users/add" component={AddUser} />
    <Route path="/consumer_users/detail/:userId" component={DetailUser} />
  </Switch>
);
