import React, { useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Breadcrumbs = ({ paths }) => {

  return (
    <Breadcrumb>
      {
        paths.map((path, i) => {
          return (
            <Breadcrumb.Item key={i} href={path.url ? path.url : '#'} active={!path.url}>
              {path.icon ? <FontAwesomeIcon icon={path.icon} className='me-2' /> : null}
              {path.label}
            </Breadcrumb.Item>
          )
        })
      }
    </Breadcrumb>
  );
};

export default Breadcrumbs;