import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';

export const addPaymentMethod = createAsyncThunk(
    'domains/addPaymentMethod',
    async ({ data }) => {
        const actionUrl = '/v1/domains/add-payment-method';
        const url = actionUrl;
        const response = await api.post(url, data);
        return response.data;
    }
);