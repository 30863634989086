import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../config/api';

export const toggleEshopVisioned = createAsyncThunk(
    'eshop/toggleEshopVisioned',
    async ({ id, value, isHibrid = false }) => {
        let url = `/v1/update-structured-data/pending_docs/${value}/${id}`
        url += isHibrid ? '/1' : '';
        const response = await api.get(url);
        return response.data;
    }
);