import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';
import endpoints from '../endpoints/eshops';

export const deleteEshopGallery = createAsyncThunk(
    'eshops/deleteEshopGallery',
    async ({ esa_id, index }) => {
        const response = await api.delete('/v1/domains/delete-eshop-gallery/' + esa_id + '/' + index);
        return response.data;
    }
);