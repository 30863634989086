import { createSlice } from '@reduxjs/toolkit';
import { fetchCatalogoPiani } from '../actions/catalogoPiani';

export const catalogoPianiSlice = createSlice({
    name: 'fetchCatalogoPiani',
    initialState: {
        entities: [],
        loading: 'idle',
        pagination: {
            total_count: 0,
            page_count: 0,
            current_page: 0,
            per_page: 0,
            next_page_url: null,
            prev_page_url: null,
        }
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCatalogoPiani.pending, (state) => {
                state.loading = 'loading';
            })
            .addCase(fetchCatalogoPiani.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.entities = action.payload.items;
                state.pagination = action.payload.pagination;
            })
            .addCase(fetchCatalogoPiani.rejected, (state) => {
                state.loading = 'idle';
            });
    }

});

export default catalogoPianiSlice.reducer;