import React from 'react';
import { Field, Form } from 'react-final-form';
import renderIntervalDatePickerField from '@/shared/components/form/date-pickers/IntervalDatePicker';

import {
    FormContainer,
    FormGroup,
    FormGroupField,
    FormGroupLabel,
    FormGroupIcon,
} from '@/shared/components/form/FormElements';

import { Button } from '@/shared/components/Button';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import {
    Col,
    Row,
} from 'react-bootstrap';

import {
    Card, CardBody
} from '@/shared/components/Card';

import renderSelectField from '@/shared/components/form/Select';
import styled from 'styled-components';

const FilterForm = ({ onSubmit }) => (

    <Card>
        <CardBody>
            <Form onSubmit={onSubmit} >
                {({ handleSubmit, form }) => (
                    <FormContainer onSubmit={handleSubmit} vertical>
                        <Row className='w-100'>
                            <Col>
                                <FormGroup>
                                    <FormGroupLabel>Prodotti</FormGroupLabel>
                                    <FormGroupField>
                                        <Field
                                            name="status"
                                            component={renderSelectField}
                                            type="select"
                                            options={[
                                                { value: 'all', label: 'Tutti i prodotti' },
                                                { value: '1', label: 'Prodotti Attivi' },
                                                { value: '2', label: 'Prodotti Scaduti' },
                                            ]}
                                        />
                                    </FormGroupField>
                                    <FilterButton
                                        variant="outline-success"
                                        size='sm'
                                        onClick={handleSubmit}
                                    >
                                        <MagnifyIcon /><span>Filtra</span>
                                    </FilterButton>
                                </FormGroup>
                            </Col>

                            <Col>
                                <FormGroup controlId="formButton">
                                    <FilterButton
                                        variant="outline-danger"
                                        size='sm'
                                        onClick={handleSubmit}
                                    >
                                        <span>Elimina Cache</span>
                                    </FilterButton>
                                </FormGroup>
                            </Col>
                        </Row>
                    </FormContainer>
                )}
            </Form >
        </CardBody>
    </Card>
);

export default FilterForm;

const FilterButton = styled(Button)`
  margin-top: calc(1.5em + .35rem);
`;

const ReceiptButton = styled(Button)`
  margin-top: calc(1.5em + .35rem);
`;