import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';

export const fetchCensoredWords = createAsyncThunk(
    'reviews/fetchCensoredWords',
    async () => {
        const response = await api.get(`/v1/reviews/fetch-censored-words`);
        
        return response.data;
    }
);
