import React from 'react';
import { Field, Form } from 'react-final-form';
import renderDatePickerField from '@/shared/components/form/date-pickers/DatePicker';
import renderCheckBoxField from '@/shared/components/form/CheckBox';

import { FormContainer, FormGroup, FormGroupField, FormGroupLabel } from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { Col, Row } from 'react-bootstrap';
import { Card, CardBody } from '@/shared/components/Card';
import renderSelectField from '@/shared/components/form/Select';
import styled from 'styled-components';

const FilterForm = ({ onSubmit }) => (
  <Card>
    <CardBody>
      < Form onSubmit={onSubmit} >
        {({ handleSubmit, form }) => (
          <FormContainer onSubmit={handleSubmit} vertical>
            <Row className='g-2 w-100'>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Eshop</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="domain"
                      component="input"
                      type="text"
                      placeholder="Eshop"
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Email utente</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="user_mail"
                      component="input"
                      type="email"
                      placeholder="example@mail.com"
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Titolo</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="title"
                      component="input"
                      type="text"
                      placeholder="Titolo"
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <FormGroupLabel>Data inserimento</FormGroupLabel>
                  <FormGroupField>
                    <div className='d-inline-flex align-items-center'>
                      <Field
                        name="update_date_start"
                        component={renderDatePickerField}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Dal"
                      />
                      <span className='mx-2'> - </span>
                      <Field
                        name="update_date_end"
                        component={renderDatePickerField}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Al"
                      />
                    </div>
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Stato</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="status"
                      component={renderSelectField}
                      type="select"
                      options={[
                        { value: 'all', label: 'Tutte' },
                        { value: '0', label: 'Attesa validazione' },
                        { value: '1', label: 'Approvate' },
                        { value: '2', label: 'Rifiutate' },
                      ]}
                      placeholder="Tutte"
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup controlId="formButton">
                  <FilterButton
                    variant="outline-success"
                    size='sm'
                    onClick={handleSubmit}
                  >
                    <MagnifyIcon /><span>Filtra</span>
                  </FilterButton>
                </FormGroup>
              </Col>
            </Row>
          </FormContainer>
        )}
      </Form >

    </CardBody>
  </Card>
);

export default FilterForm;

const FilterButton = styled(Button)`
  margin-top: calc(1.5em + .35rem);
`;