import React from 'react';
import {
    Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';

import { Field, Form } from 'react-final-form';
import { Col, Row } from 'react-bootstrap';
import renderSelectField from '@/shared/components/form/Select';
import FormField from '@/shared/components/form/FormField';
import {
    FormButtonToolbar,
    FormContainer,
    FormGroupField,
    FormGroupLabel,
    FormGroup,
    FormGroupIcon,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';

import renderDropZoneField from '@/shared/components/form/dropzones/DropZone';
import ProductItems from '@/shared/components/catalog/ProductItems';

function PreviewForm({ data }) {
    const onSubmit = (values) => {
        console.log('Dati inviati:', values);
        // Qui puoi gestire la logica di invio del tuo form, ad es. effettuare una chiamata API
    };

    return (
        <Col md={12}>
            <Card>
                <CardBody>
                    <CardTitleWrap>
                        <CardTitle>Anteprima prodotto</CardTitle>

                    </CardTitleWrap>
                    <Row>
                        <Col md={12} lg={6}>
                            <img src={process.env.PUBLIC_URL + '/img/for_store/catalog/' + data.img} alt="Descrizione dell'immagine" />
                        </Col>
                        <Col md={12} lg={6}>
                            <Form onSubmit={onSubmit}>
                                {({ handleSubmit }) => (

                                    <FormContainer horizontal onSubmit={handleSubmit}>

                                        <Field
                                            name="files"
                                            component={renderDropZoneField}
                                        />

                                        <FormButtonToolbar>
                                            <Button variant="primary" type="submit">Upload</Button>

                                        </FormButtonToolbar>
                                    </FormContainer>
                                )}
                            </Form>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>
    );
}

export default PreviewForm;
