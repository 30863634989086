import { createSlice } from '@reduxjs/toolkit';
import { putB2bUserData } from '../actions/updateB2bUser';

const updateB2bUserSlice = createSlice({
    name: 'b2bUserUpdate',
    initialState: { b2bUser: {}, status: 'idle', error: null },
    extraReducers: (builder) => {
        builder
            .addCase(putB2bUserData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(putB2bUserData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.b2bUser = action.payload;
            })
            .addCase(putB2bUserData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export const { /* other action creators, if any */ } = updateB2bUserSlice.actions;

export default updateB2bUserSlice.reducer;