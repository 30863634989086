import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';
import endpoint from '../endpoints/billingData';

export const putBillingInfo = createAsyncThunk(
    'b2bUsers/putBillingInfo',
    async ({userData,id}) => {
        debugger;
        const response = await api.put(endpoint.update.replace(':id',id), userData);
        debugger;
        return response.data;
    }
);