/* eslint-disable */
const validate = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = 'Il campo userbame non può essere vuoto';
  }
  if (!values.email) {
    errors.email = 'Email field shouldn’t be empty';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Indiritto email non valido';
  }
  if (!values.password) {
    errors.password = 'Il campo password non può essere vuoto';
  } 
  if (values.password != values.confirm_password) {
    errors.confirm_password = 'La password non corrisponde';
  } 
  // if (!values.gender) {
  //   errors.gender = 'Inserisci il genere';
  // }
  if (!values.name) {
    errors.name = 'Il campo nome non può essere vuoto';
  }
  if (!values.surname) {
    errors.surname = 'Il campo cognome non può essere vuoto';
  }

  return errors;
};

export default validate;
