import React from 'react';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';

const SidebarMenu = ({ routes, changePageClick, currentPage }) => {
  return (
    <Col xs={12} lg={4} xl={3}>
      <NavUl>
        {routes.map((route, i) => (
          <NavLi key={i} active={currentPage === route.namestate ? true : false} onClick={() => changePageClick(route.namestate)}>
            <IconSlot active={currentPage === route.namestate ? true : false}>{route.icon}</IconSlot>
            {route.name}
          </NavLi>
        ))}
      </NavUl>
    </Col>
  )
};

const NavUl = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const NavLi = styled.li`
    display: inline-block;
    width: 100%;
    background-color: ${(props) => props.active === true ? '#dcdcdc' : '#fff'};
    padding: 10px 5px 10px 50px;
    transition-duration: .35s;
    position: relative;
    ${(props) => props.active == false ?
    `&:hover {
      background-color: #dcdcdc;
    }` : null
  }
`;

const IconSlot = styled.span`
  width: 40px;
  position: absolute;
  height: 100%;
  background-color: ${(props) => props.active === true ? '#c6c6c6' : '#f0f0f0'};
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  transition-duration: .35s;
`;

export default SidebarMenu;
