import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Badge, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxes } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { orderSendLabels, orderStatusLabel, orderStatusColors, orderSendStatusColors, orderSendStatusLabel } from '@/utils/utilities';
import "react-datepicker/dist/react-datepicker.css";

const EshopOrderItemList = ({ item, showEshop = true }) => {

  const [elem, setElem] = useState(item);
  const [showModal, setShowModal] = useState(false);
  const sendInfo = formatSendInfo();
  const sendReminderInfo = formatSendInfo(true);
  const statusInfo = formatStatusInfo();

  const handleOpen = () => {
    setShowModal(true);
  }

  const handleClose = () => {
    setShowModal(false);
  }

  function formatSendInfo(isReminder = false) {
    let log;
    let result = "";
    try {
      log = typeof elem.send_data === 'string' ? JSON.parse(elem.send_data) : elem.send_data;
    } catch (error) {
      console.error("Error parsing status_data:", error);
      return "Error: Invalid status data";
    }
    const fields = isReminder
      ? ['email_reminder_send', 'sms_reminder_send', 'whatsapp_reminder_send']
      : ['email_send', 'sms_send', 'whatsapp_send'];

    if (log) {
      fields.forEach((field) => {
        const type = field.split('_')[0];
        //check if array key exists
        let check = log[field] === 0 ? '-' : 'OK';
        result += `${type}: ${check}\n`;
      });
    }

    return result;
  }

  function formatStatusInfo(isReminder = false) {
    let result = "";
    let log;
    try {
      log = typeof elem.status_data === 'string' ? JSON.parse(elem.status_data) : elem.status_data;
    } catch (error) {
      console.error("Error parsing status_data:", error);
      return "Error: Invalid status data";
    }
    const fields = isReminder
      ? ['email_reminder_status', 'sms_reminder_status', 'whatsapp_reminder_status']
      : ['email_status', 'sms_status', 'whatsapp_status'];

    if (log) {
      fields.forEach((field) => {
        const type = field.split('_')[0];
        const check = log[field] == "1" ? true : false;
        if (check) {
          result = `${type}`;
        }
      });
    }

    return result;
  }

  function formatDate(dateString) {
    return moment(dateString).format('DD/MM/YYYY')
  }

  useEffect(() => {
    setElem(item);
  }, [item]);

  return (
    <>
      <tr key={elem.id}>
        {showEshop && (
          <>
            <td className="first-letter-uppercase">
              <Link to={(elem.domain_info.plan == "ibrido") ? '/hibrid-eshops/update/' : '/registered-eshops/update/' + elem.eshop_id}>
                {elem.domain_info.domain}
              </Link>
            </td>
            <td className="first-letter-uppercase">
              <Badge bg={elem.domain_info.plan == 'ibrido' ? 'warning' : 'success'}>{elem.domain_info.plan}</Badge>
            </td>
          </>
        )}
        <td>
          {(elem.send_type != null) ? orderSendLabels[elem.send_type] : '-'}
        </td>
        <td>
          {elem.order_reference ? elem.order_reference : '-'}
        </td>
        <td>
          {elem.order_date ? formatDate(elem.order_date) : '-'}
        </td>
        <td>
          {elem.customer_email ? elem.customer_email : '-'}
        </td>
        <td>
          {elem.cell ? elem.cell : '-'}
        </td>
        <td>
          {(elem.customer_firstname) ? elem.customer_firstname : ''}
          {(elem.customer_lastname) ? elem.customer_lastname : '-'}
        </td>
        <td>
          {elem.creation_date ? formatDate(elem.creation_date) : '-'}
        </td>
        <td>
          {elem.platform ? elem.platform : '-'}
        </td>
        <td>
          <Button variant={orderSendStatusColors[elem.is_send]} size='sm' title={sendInfo}>
            {(elem.is_send != null) ? orderSendStatusLabel[elem.is_send] + ' Il ' + formatDate(elem.send_date) : '-'}
          </Button>
        </td>
        <td>
          <Button variant={orderSendStatusColors[elem.reminder_send]} size='sm' title={sendReminderInfo}>
            {(elem.reminder_send != null && elem.status == 0) ? orderSendStatusLabel[elem.reminder_send] + ' Il ' + formatDate(elem.reminder_date) : '-'}
          </Button>
        </td>
        <td>
          <Button variant={orderStatusColors[elem.status]} size='sm' title={statusInfo}>
            {/* {(elem.status == 1) ? statusInfo + ' ' : ''} */}
            {(elem.status != null) ? orderStatusLabel[elem.status] : '-'}
            {(elem.status == 1) ? ' Il: ' + elem.use_date : ''}
          </Button>
        </td>
        <td className='text-center'>
          {elem.products && Array.isArray(elem.products) ? (
            <Button onClick={() => handleOpen(elem.id)} variant='info' size='sm'>
              <FontAwesomeIcon icon={faBoxes} />
            </Button>
          ) : '-'}
        </td>
      </tr >
      <Modal show={showModal} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Elenco prodotti</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Immagine</th>
                <th>ID</th>
                <th>Nome</th>
              </tr>
            </thead>
            <tbody>
              {elem.products && Array.isArray(elem.products) && elem.products.map((product) => (
                <tr key={product.id}>
                  <td><img src={product.img_prod} alt={product.name} style={{ width: '50px', height: '50px' }} /></td>
                  <td>{product.id}</td>
                  <td>{product.name}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EshopOrderItemList;