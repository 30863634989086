import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSortAlphaAsc, faBook, faImage, faList, faEuro, faNewspaper, faTicket, faDownload, faChartBar, faMoneyBill, faClock, faImages, faFileImage, faArchive, faScroll } from "@fortawesome/free-solid-svg-icons";

const sidebar_eshops = [
  {
    "name": "Dati eshop",
    "namestate": "eshop-data",
    "icon": <FontAwesomeIcon icon={faEdit} />
  },
  {
    "name": "Categorie eshop",
    "namestate": "eshop-categories",
    "icon": <FontAwesomeIcon icon={faSortAlphaAsc} />
  },
  {
    "name": "Dati fatturazione",
    "namestate": "billing-data",
    "icon": <FontAwesomeIcon icon={faMoneyBill} />
  },
  {
    "name": "Storico contabilità",
    "namestate": "bookkeeping-story",
    "icon": <FontAwesomeIcon icon={faClock} />
  },
  {
    "name": "Note eshop",
    "namestate": "eshop-notes",
    "icon": <FontAwesomeIcon icon={faBook} />
  },
  {
    "name": "Immagine",
    "namestate": "eshop-image",
    "icon": <FontAwesomeIcon icon={faImage} />
  },
  {
    "name": "Thumb",
    "namestate": "eshop-thumb",
    "icon": <FontAwesomeIcon icon={faFileImage} />
  },
  {
    "name": "Galleria",
    "namestate": "eshop-galleries",
    "icon": <FontAwesomeIcon icon={faImages} />
  },
  {
    "name": "Piani",
    "namestate": "eshop-plans",
    "icon": <FontAwesomeIcon icon={faList} />
  },
  {
    "name": "Pagamenti",
    "namestate": "eshop-payments",
    "icon": <FontAwesomeIcon icon={faEuro} />
  },
  {
    "name": "Invii massivi",
    "namestate": "eshop-import-invitations",
    "icon": <FontAwesomeIcon icon={faList} />
  },
  {
    "name": "Recensioni eshop",
    "namestate": "eshop-reviews",
    "icon": <FontAwesomeIcon icon={faNewspaper} />
  },
  {
    "name": "Recensioni prodotto",
    "namestate": "product-reviews",
    "icon": <FontAwesomeIcon icon={faNewspaper} />
  },
  {
    "name": "Coupons",
    "namestate": "eshop-coupons",
    "icon": <FontAwesomeIcon icon={faTicket} />
  },
  {
    "name": "Recensioni importate (xls)",
    "namestate": "eshop-imported-reviews",
    "icon": <FontAwesomeIcon icon={faDownload} />
  },
  {
    "name": "Report inviti alla recensione",
    "namestate": "eshop-invitation-stats",
    "icon": <FontAwesomeIcon icon={faList} />
  },
  {
    "name": "Analisi eshop",
    "namestate": "eshop-analysis",
    "icon": <FontAwesomeIcon icon={faChartBar} />
  },
  {
    "name": "Funzionalità + Api",
    "namestate": "eshop-extra-reviews",
    "icon": <FontAwesomeIcon icon={faList} />
  },
  {
    "name": "Import prodotti",
    "namestate": "eshop-product-import",
    "icon": <FontAwesomeIcon icon={faList} />
  },
  {
    "name": "Log Accessi",
    "namestate": "eshop-access-logs",
    "icon": <FontAwesomeIcon icon={faScroll} />
  }
];


export default sidebar_eshops;