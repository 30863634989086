import { combineReducers } from 'redux';
import fetchReducer from './fetch';
import insertReducer from './insert';
import eshopImportsReducer from './eshopImports';
import eshopInvitationsReducer from './eshopInvitations';
import ordersReducer from './orders';
import paymentMethodsReducer from './paymentMethods';
//import detailReducer from './detail';

const eshopsReducer = combineReducers({
    fetch: fetchReducer,
    insert: insertReducer,
    eshopImports: eshopImportsReducer,
    eshopInvitations: eshopInvitationsReducer,
    paymentMethods: paymentMethodsReducer,
    orders: ordersReducer,
    //detail: detailReducer,
});

export default eshopsReducer;
