import React, { useState } from 'react';
import { Button } from '@/shared/components/Button';
import { statusColors, statusReportLabels } from '@/utils/utilities';
import customStyle from '@/shared/assets/css/custom.module.css'
import { updateReportReview } from '../actions/updateReportReview';
import { useDispatch } from 'react-redux';

function ReportItem({ item }) {
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [reportStatus, setReportStatus] = useState(statusReportLabels[item.status])
  const [reportColor, setReportColor] = useState(statusColors[item.status])

  const handleChangeReportStatus = (status) => {
    dispatch(updateReportReview({ id: item.id, status: status })).then(() => {
      setDropdownOpen(!dropdownOpen);
      setReportStatus(statusReportLabels[status])
      setReportColor(statusColors[status])
    });
  }

  const handleButtonClick = () => {
    if ([0, 3].includes(item.status)) {
      setDropdownOpen(!dropdownOpen);
    }
  }

  return (
    <tr>
      <td> {item.author} </td>
      <td> {item.insert_date} </td>
      <td> {item.result_date ? item.result_date : '-'} </td>
      <td> {item.reason} </td>
      <td>
        <div>
          <Button variant={reportColor} size={'sm'} className={'px-2 mx-0'} onClick={handleButtonClick}>
            {reportStatus}
          </Button>
          {dropdownOpen && (
            <div className={customStyle.dropdownMenu}>
              {
                Object.entries(statusReportLabels).map(([index, status]) => (
                  statusReportLabels[index] !== reportStatus && (
                    <button className={`text-${statusColors[index]}`} onClick={() => handleChangeReportStatus(index)}>{status}</button>
                  )
                ))
              }
            </div>
          )}
        </div>
      </td>
    </tr>
  )
}

export default ReportItem;