import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';
import endpoint from '../endpoints/plans';

export const postPromo = createAsyncThunk(
    'plans/postPromo',
    async (data) => {
        const response = await api.post(endpoint.createPromo, data);
        return response.data;
    }
);