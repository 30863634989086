import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Card, CardBody, CardTitle, CardTitleWrap } from '@/shared/components/Card';
import MetabaseQuestion from '../UI/MetabaseQuestion';

const PlanAnalysis = ({ plan }) => {

  const [planName, setPlanName] = useState(plan);
  const questionId = process.env.REACT_APP_ENVIRONMENT == 'production' ? 716 : 695;

  useEffect(() => {
    setPlanName(plan)
  }, [plan]);

  return (
    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle className="d-block">
            Analisi {planName}
          </CardTitle>
        </CardTitleWrap>
        <MetabaseQuestion question_id={questionId} plan={planName} bordered={false} titled={false} />
      </CardBody>
    </Card>
  )
}

export default PlanAnalysis
