import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { api } from '@/config/api';
import SidebarMenu from '@/containers/Layout/custom_components/SidebarMenu'
import sidebar_links from './configs/sidebar_hibrid';
import endpoint_eshop from './endpoints/eshops';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faStoreAlt } from '@fortawesome/free-solid-svg-icons';
import Breadcrumbs from '@/containers/Components/Breadcrumbs';
import EshopData from './forms/eshopData';
import EshopThumb from './forms/eshopThumb';

const HibridUpdate = () => {

  const { id } = useParams();
  const [currentSection, setCurrentSection] = useState('eshop-data');
  const [domainData, setDomainData] = useState({});
  const breadcrumbPaths = [
    { label: 'Home', url: '/', icon: faHome },
    { label: 'Ibridi', url: '/hibrid-eshops/list', icon: false },
    { label: domainData.domain, url: false, icon: false },
  ];

  const getSelection = () => {
    switch (currentSection) {
      case 'eshop-data':
        return <EshopData item={domainData} />;
      case 'eshop-thumb':
        return <EshopThumb item={domainData} />;
      default:
        debugger
        return null;
    }
  }

  const changePageClick = (page) => {
    setCurrentSection(page);
  }

  const fetchData = async () => {
    const response = await api.get(endpoint_eshop.getEshop.replace(':id', id));
    setDomainData(response.data);
  }

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <Container>
      <Row >
        <Col className='my-1'>
          <h2>
            <FontAwesomeIcon icon={faStoreAlt} className='me-3' />
            {domainData.domain}
          </h2>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col>
          <Breadcrumbs paths={breadcrumbPaths} />
        </Col>
      </Row>
      <Row>
        <SidebarMenu routes={sidebar_links} changePageClick={changePageClick} currentPage={currentSection} />
        <Col xs={12} lg={8} xl={9}>
          {getSelection()}
        </Col>
      </Row>
    </Container>
  )
}

export default HibridUpdate;