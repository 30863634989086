import React from 'react';
import {
    Card, CardBody,
} from '@/shared/components/Card';

import SubmenuButton from '@/shared/components/SubmenuButton';

function LeftPanel({ onMenuSelect, selectedMenuItem }) {

    const menuItemStyle = isActive => ({
        padding: '10px 20px',
        cursor: 'pointer',
        backgroundColor: isActive ? '#eee' : 'transparent',
        marginBottom: '5px',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        gap: '10px'
    });

    return (
        <Card>
            <CardBody>
                
                <SubmenuButton
                    iconClass="lnr lnr-list"
                    label="Modifica"
                    onClick={() => onMenuSelect('generalForm')}
                    isActive={selectedMenuItem === 'generalForm'}
                />
                <SubmenuButton
                    iconClass="lnr lnr-eye"
                    label="Immagine"
                    onClick={() => onMenuSelect('previewForm')}
                    isActive={selectedMenuItem === 'previewForm'}
                />
                

                </CardBody>
                </Card>


        
    );
}


export default LeftPanel;
