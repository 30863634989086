import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Card, CardBody, CardTitle, CardTitleWrap } from '@/shared/components/Card';
import MetabaseDashboard from '../../UI/MetabaseDashboard';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { addEshopPayment } from "../actions/addEshopPayment";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { successNotify, warningNotify } from '../../App/Router';
import { format } from 'date-fns';

const EshopPlans = ({ item }) => {

  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const dashboardId = process.env.REACT_APP_ENVIRONMENT == 'production' ? 173 : 171;

  const [dataToSend, setDataToSend] = useState({
    amount: '0',
    payment_date: new Date().toISOString().split('T')[0]
  });

  const handleDateChange = (date) => {
    const formattedDate = format(date, 'yyyy-MM-dd');
    setDataToSend({ ...dataToSend, payment_date: formattedDate });
  };

  const AddEshopPayment = () => {
    console.log('AddEshopPayment');
    dispatch(addEshopPayment({ eshop_id: item.id, data: dataToSend })).then((res) => {
      console.log('res', res);
      if (res.payload[0] == 'success') {
        successNotify();
        // refreshTable(true);
      }
      else {
        warningNotify();
      }
    });
  }

  return (
    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle className="d-block">
            Pagamenti
            <button className="btn btn-primary btn-sm float-end" onClick={() => setShowModal(true)}>
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Aggiungi Bonifico
            </button>
          </CardTitle>
        </CardTitleWrap>
        <MetabaseDashboard dashboard_id={dashboardId} eshop={item.domain} bordered={false} titled={false} />
      </CardBody>
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Aggiungi Bonifico</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Importo</Form.Label>
                  <Form.Control type="number" value={dataToSend.amount} onChange={(e) => setDataToSend({ ...dataToSend, amount: e.target.value })} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label className="d-block">Data pagamento</Form.Label>
                  <DatePicker
                    className="form-control d-block"
                    selected={new Date(dataToSend.payment_date)}
                    onChange={handleDateChange}
                    dateFormat="yyyy-MM-dd"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Annulla
          </Button>
          <Button variant="primary" onClick={() => AddEshopPayment()}>
            Salva
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  )
}

export default EshopPlans
