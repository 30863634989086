import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from '../../../Admins/list';
import AddUser from '../../../Admins/addUser';
import DetailUser from '../../../Admins/detailUser';

export default () => (
  <Switch>
    <Route path="/admins/list" component={List} />
    <Route path="/admins/add" component={AddUser} />
    <Route path="/admins/detail/:userId" component={DetailUser} />
  </Switch>
);
