import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import FilterForm from './components/filters/filterSegnalazioni';
import { Col, Container, Row, Spinner, } from 'react-bootstrap';
import { Card, CardBody, CardTitleWrap } from '@/shared/components/Card';
import { Table } from '@/shared/components/TableElements';
import styled from 'styled-components';
import { cleanDate } from '@/shared/helpers';
import { colorAccent } from '@/utils/palette';
import { fetchReportReviews } from './actions/fetchReportReviews';
import ReportReviewItem from './components/ReportReviewItem';
import PaginationTable from '@/containers/Components/PaginationTable';

const ReviewsTable = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const reportState = useSelector(state => state.reviews.reportReviews);
  const reports = reportState.items;
  const loading = reportState.loading;
  const pagination = reportState.pagination;
  const TableSpinner = styled(Spinner)`color: ${colorAccent};`;
  const [globalFilters, setGlobalFilters] = useState({ 'per-page': 25, page: 1 });
  const tableLabels = ['Titolo', 'Eshop', 'Segnalazioni']
  const actionBaseUrl = '/v1/fetch-review-reports'

  const handleFilterSubmit = (values) => {
    let modifiedValues = { ...values };

    delete modifiedValues.creation_date_start;
    delete modifiedValues.creation_date_end;
    modifiedValues.creation_date = {
      start: values.creation_date_start ? cleanDate(values.creation_date_start) : null,
      end: values.creation_date_end ? cleanDate(values.creation_date_end) : null,
    }

    if (values.status) {
      modifiedValues.status = values.status.value;
    }

    setGlobalFilters(modifiedValues);

    modifiedValues.page = pagination.current_page;
    const payload = {
      baseUrl: actionBaseUrl,
      params: modifiedValues
    };

    dispatch(fetchReportReviews(payload));
  };

  useEffect(() => {
    const initialPayload = {
      baseUrl: actionBaseUrl,
      params: globalFilters
    };
    dispatch(fetchReportReviews(initialPayload));
  }, [dispatch]);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Elenco segnalazioni</h3>
        </Col>
      </Row>
      <FilterForm onSubmit={handleFilterSubmit} />
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <CardTitleWrap>
                <Row>
                  <Col md={12}>
                    <p className="page-subtitle">Numero segnalazioni: {pagination.total_count}</p>
                  </Col>
                </Row>
              </CardTitleWrap>
              {loading === 'loading' && (
                <div className="text-center">
                  <TableSpinner animation="border" />
                </div>
              )}
              {reports && loading === 'idle' && reports.length > 0 && (
                <>
                  <Table responsive striped bordered>
                    <thead>
                      <tr>
                        {tableLabels.map((label) => {
                          return <th>{label}</th>
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {reports.map(elem => {
                        return (<ReportReviewItem item={elem} />);
                      })}
                    </tbody>
                  </Table>
                  <PaginationTable pagination={pagination} filters={globalFilters} action={fetchReportReviews} />
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

ReviewsTable.propTypes = {
  onSubmit: PropTypes.func,
};

export default ReviewsTable;