import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import renderDatePickerField from '@/shared/components/form/date-pickers/DatePicker';
import { FormContainer, FormGroup, FormGroupField, FormGroupLabel } from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { Col, Row } from 'react-bootstrap';
import { Card, CardBody } from '@/shared/components/Card';
import renderSelectField from '@/shared/components/form/Select';
import renderCheckBoxField from '@/shared/components/form/CheckBox';
import styled from 'styled-components';
import '../../css/filterEshopList.css';

const FilterForm = ({ onSubmit }) => (
  <Card>
    <CardBody>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, form }) => (
          <FormContainer onSubmit={handleSubmit} className="filterForm">
            <Row>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Dominio</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="domain"
                      component="input"
                      type="text"
                      placeholder="Dominio"
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Email</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="email"
                      component="input"
                      type="text"
                      placeholder="Email"
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Telefono</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="phone"
                      component="input"
                      type="text"
                      placeholder="Telefono"
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Codice promo</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="promo_code"
                      component="input"
                      type="text"
                      placeholder="Codice promo"
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Ragione sociale</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="business_name"
                      component="input"
                      type="text"
                      placeholder="Ragione sociale"
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Partita IVA</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="vat_number"
                      component="input"
                      type="text"
                      placeholder="Partita IVA"
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Modifica dati di fatturazione</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="billing_data"
                      component={renderSelectField}
                      type='select'
                      options={[
                        { value: 'all', label: 'Abilitata e disabilitata' },
                        { value: '1', label: 'Abilitata' },
                        { value: '0', label: 'Disabilitata' },
                      ]}
                      placeholder={'Abilitata e disabilitata'}

                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Stato eshop</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="status"
                      component={renderSelectField}
                      type='select'
                      options={[
                        { value: 'all', label: 'Tutti gli stati' },
                        { value: '0', label: 'Disabilitati' },
                        { value: '1', label: 'Attivi' },
                        // { value: '2', label: 'Ibridi' },
                        { value: '-1', label: 'In attesa di opt-in' },
                        { value: '4', label: 'In attesa di documentazione' }
                      ]}
                      placeholder="Tutti gli stati"
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Piano eshop</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="plan"
                      component={renderSelectField}
                      type='select'
                      options={[
                        { value: 'all', 'label': 'Tutti i piani' },
                        // { value: 'hibrid', label: 'Ibridi' },
                        { value: 'free', label: 'Free' },
                        { value: 'basic', label: 'Basic' },
                        { value: 'lite', label: 'Lite' },
                        { value: 'pro', label: 'Pro' },
                        { value: 'company', label: 'Company' }
                      ]}
                      placeholder={'Tutti i piani'}
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Visionati / Da visionare</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="pending_docs"
                      component={renderSelectField}
                      type='select'
                      options={[
                        { value: 'all', label: 'Tutti' },
                        { value: '0', label: 'Visionati' },
                        { value: '1', label: 'Da visionare' },
                      ]}
                      placeholder={'Tutti'}

                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Stato contabilità</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="bookkeeping"
                      component={renderSelectField}
                      type='select'
                      options={[
                        { value: 'all', label: 'Tutti' },
                        { value: '1', label: 'Regolari' },
                        { value: '0', label: 'Disdetti' },
                        { value: '-1', label: 'Morosi' },
                      ]}
                      placeholder={'Tutti'}

                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Data di registrazione</FormGroupLabel>
                  <FormGroupField>
                    <div className='d-inline-flex align-items-center'>
                      <Field
                        name="creation_date_start"
                        component={renderDatePickerField}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Dal"
                      />
                      <span className='mx-2'> - </span>
                      <Field
                        name="creation_date_end"
                        component={renderDatePickerField}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Al"
                      />
                    </div>
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Data di optin</FormGroupLabel>
                  <FormGroupField>
                    <div className='d-inline-flex align-items-center'>
                      <Field
                        name="optin_date_start"
                        component={renderDatePickerField}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Dal"
                      />
                      <span className='mx-2'> - </span>
                      <Field
                        name="optin_date_end"
                        component={renderDatePickerField}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Al"
                      />
                    </div>
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Data di upgrade</FormGroupLabel>
                  <FormGroupField>
                    <div className='d-inline-flex align-items-center'>
                      <Field
                        name="upgrade_date_start"
                        component={renderDatePickerField}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Dal"
                      />
                      <span className='mx-2'> - </span>
                      <Field
                        name="upgrade_date_end"
                        component={renderDatePickerField}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Al"
                      />
                    </div>
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel></FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="promo"
                      component={renderCheckBoxField}
                      type="checkbox"
                      label="Promo"
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel></FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="demo"
                      component={renderCheckBoxField}
                      type="checkbox"
                      label="Demo"
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup controlId="formButton">
                  <FilterButton
                    variant="outline-success"
                    size='sm'
                    onClick={handleSubmit}
                  >
                    <MagnifyIcon /><span>Filtra</span>
                  </FilterButton>
                </FormGroup>
              </Col>
            </Row>
          </FormContainer>
        )}
      </Form>
    </CardBody>
  </Card>
);

FilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default FilterForm;

const FilterButton = styled(Button)`
    margin-top: calc(1.5em + .35rem);
`;
