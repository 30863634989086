import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';

export const modApproved = createAsyncThunk(
    'reviews/modApproved',
    async (reviewId, { rejectWithValue }) => {
        try {
            const response = await api.put(`/v1/reviews/update-modified/${reviewId}`, {
                status: 1
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const modRejected = createAsyncThunk(
    'reviews/modRejected',
    async (reviewId, { rejectWithValue }) => {
        try {
            const response = await api.put(`/v1/reviews/update-modified/${reviewId}`, {
                status: 2
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
