import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../config/api';

export const updateUserDetails = createAsyncThunk(
    'admins/updateUserDetails',
    async ({ userId, userData }) => {
        console.log("PARTENZA" + JSON.stringify(userId));
        const response = await api.put(`/v1/admins/${userId}`, userData);
        console.log("RESPONSE" + JSON.stringify(response.data));
        return response.data;
    }
);