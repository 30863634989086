import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import LeftPanel from './forms/leftPanel';
import RightPanel from './forms/rightPanel';
// Modifica questa linea per importare direttamente dall'azione


function Homepage() {
    const [selectedContent, setSelectedContent] = useState('generalForm');

    const { id } = useParams();

    return (
        <Container>
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Homepage</h3>
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                    <LeftPanel onMenuSelect={setSelectedContent} selectedMenuItem={selectedContent} />
                </Col>
                <Col md={9}>
                    <RightPanel content={selectedContent} id={id} />
                </Col>
            </Row>
        </Container>
    );
}

export default Homepage;
