import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Badge, Form, Container, Row, Col, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClock, faCloudArrowDown, faCloudUpload, faCogs, faEye, faImage, faPen, faTrash, faTriangleExclamation, faUpload, faSave } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import { successNotify, warningNotify } from '../../App/Router';
import { updatePaymentMethod } from '../actions/updatePaymentMethod';
import { deletePaymentMethod } from '../actions/deletePaymentMethod';
import { defaultStatusColors, imageNotFound } from '@/utils/utilities';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

const PaymentMethodsItemList = ({ item, setToRefreshList }) => {

  const dispatch = useDispatch();
  const [elem, setElem] = useState(item);
  const [updatedElem, setUpdatedElem] = useState(item);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState(false);
  const logoUrl = process.env.REACT_APP_ESA_CDN_URL + 'assets/paymentsLogo/' + elem.img;
  const bounceList = elem.bounce;

  const handleOpen = () => {
    setShowModal(true);
  }

  const handleClose = () => {
    setShowModal(false);
  }

  const handleDeleteClose = () => {
    setShowDeleteModal(false);
  }

  const toggleStatus = () => {
    if (elem.status != 0) {
      const newStatus = (elem.status == 1) ? 2 : 1;
      dispatch(updatePaymentMethod({ id: elem.id, data: { status: newStatus } })).then((res) => {
        if (res.payload?.success) {
          successNotify();
          setToRefreshList(true);
        }
        else {
          warningNotify();
        }
      });
    }
  }

  const handleConfirmDelete = async () => {
    if (idToDelete) {
      await dispatch(deletePaymentMethod({ id: idToDelete })).then((res) => {
        setShowDeleteModal(false)
        if (res.payload?.success) {
          successNotify()
          setToRefreshList(true)
        }
        else {
          warningNotify();
        }
      });
    }
  };

  const handleDeleteClick = () => {
    setIdToDelete(elem.id);
    setShowDeleteModal(true);
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    let val = type === 'checkbox' ? (checked ? 1 : 0) : value;
    const keys = name.split('[').map(key => key.replace(']', ''));
    setUpdatedElem((prevState) => {
      let newState = { ...prevState };
      let currentPart = newState;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!currentPart[keys[i]]) {
          currentPart[keys[i]] = {};
        }
        currentPart = currentPart[keys[i]];
      }
      currentPart[keys[keys.length - 1]] = val;
      return newState;
    });
  };

  const handleSave = () => {
    const dataToSend = {};
    if (updatedElem.name) {
      dataToSend.name = updatedElem.name;
    }
    if (updatedElem.img) {
      dataToSend.img = updatedElem.img;
    }
    console.log(dataToSend);
    dispatch(updatePaymentMethod({ id: elem.id, data: dataToSend })).then((res) => {
      if (res.payload?.success) {
        successNotify();
        setToRefreshList(true);
        setShowModal(false);
      }
      else {
        warningNotify();
      }
    });
  }

  useEffect(() => {
    setElem(item);
  }, [item]);

  return (
    <>
      <tr key={elem.id}>
        <td>
          <Link to={'#'} onClick={handleOpen}>
            <img src={logoUrl} className='img-fluid' style={{ width: "35px" }} className="me-2" onError={(e) => { e.target.src = imageNotFound }} />
            {elem.name}
          </Link>
        </td>
        <td>
          <Button
            variant={defaultStatusColors[elem.status]}
            size='sm'
            onClick={toggleStatus}
          >
            {elem.status == 1 ? 'Abilitato' : 'Disabilitato'}
          </Button>
        </td>
        <td className='text-end'>
          <Button
            variant={'danger'}
            size='sm'
            onClick={(handleDeleteClick)}
          >
            <FontAwesomeIcon icon={faTrash} className='' />
          </Button>
        </td>
      </tr >
      <Modal show={showModal} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Modifica: {elem.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className='my-3'>
              <Form autoComplete="off">
                <Container>
                  <Row className='align-items-end'>
                    <Col xs={4} className='my-3'>
                      <Form.Group controlId="name">
                        <Form.Label>Nome</Form.Label>
                        <Form.Control name="name" value={updatedElem.name} type="text" onChange={handleChange} />
                      </Form.Group>
                    </Col>
                    <Col xs={4} className='my-3'>
                      <img src={logoUrl} className='img-fluid' style={{ width: "50px" }} className="me-2" onError={(e) => { e.target.src = imageNotFound }} />
                    </Col>
                    <Col xs={12} className='my-3'>
                      <Form.Label>Carica Immagine</Form.Label>
                      <FilePond
                        allowFileTypeValidation={true}
                        allowImagePreview={true}
                        imagePreviewMaxHeight={100}
                        acceptedFileTypes={[
                          "image/jpg",
                          "image/jpeg",
                          "image/png",
                          "image/webp",
                        ]}
                        allowFileSizeValidation={true}
                        maxFileSize={"10MB"}
                        maxTotalFileSize={"10MB"}
                        allowMultiple={false}
                        maxFiles={1}
                        credits={undefined}
                        allowPaste={false}
                        storeAsFile={false}
                        server={{
                          url: process.env.REACT_APP_API_URL + '/v1/domains/upload-payment-method-img/' + elem.id,
                        }}
                        labelIdle="Trascina il tuo file qui oppure <span class='filepond--label-action'> Seleziona dai files </span>"
                        onprocessfile={(error, file) => {
                          if (error) {
                            warningNotify();
                          } else {
                            setToRefreshList(true);
                            successNotify();
                          }
                        }}
                      />
                    </Col>
                  </Row>
                </Container>
              </Form>
            </Row>
          </Container>
        </Modal.Body >
        <Modal.Footer >
          <Button onClick={handleSave} size="sm" variant={'success'} className="float-end">
            <FontAwesomeIcon icon={faSave} className="me-2" /> Salva
          </Button>
        </Modal.Footer >
      </Modal >

      <Modal show={showDeleteModal} onHide={handleDeleteClose}>
        <Modal.Header closeButton>
          <Modal.Title>Conferma eliminazione</Modal.Title>
        </Modal.Header>
        <Modal.Body>Sei sicuro di voler eliminare?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteClose}>
            Annulla
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Elimina
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PaymentMethodsItemList;