import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Badge, Form, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { successNotify, warningNotify } from '../../App/Router';
import { updateEshopImport } from '../actions/updateEshopImport';
import { api } from '@/config/api';
import { eshopImportStatusLabels, eshopImportStatusColors } from '@/utils/utilities';

const EshopImportItemList = ({ item, domain, setToRefreshList }) => {

  const dispatch = useDispatch();
  const [elem, setElem] = useState(item);
  const [updatedElem, setUpdatedElem] = useState({ ...item });
  const [toRefresh, setToRefresh] = useState(false);
  const [status, setStatus] = useState(elem.import_status);
  const [visibleDropdownId, setVisibleDropdownId] = useState(false);
  const uploaded = parseInt(elem.uploaded) + parseInt(elem.prod_uploaded);
  const source = (elem.source) ? elem.source : ''
  const sourceProd = (elem.sourceProd) ? elem.sourceProd : ''
  const sources = source + source ? ', ' : '' + sourceProd
  const downloadLink = process.env.REACT_APP_ESA_CDN_URL + 'import/' + domain + '/' + item.file;

  const toggleDropdown = (id) => {
    setVisibleDropdownId(visibleDropdownId === id ? null : id);
  };

  const handleChangeStatus = (value) => {
    dispatch(updateEshopImport({ id: elem.id, data: { import_status: value } })).then(() => {
      successNotify();
      setStatus(value)
      // if (value === 3) {
      //   setToRefreshList(true);
      // }
      // else {
      //   setToRefresh(true)
      // }
      setToRefreshList(true)
    });
  }

  function formatDate(dateString) {
    return moment(dateString).format('DD/MM/YYYY')
  }

  const refreshData = async () => {
    const response = await api.get('/v1/domains/import-list/' + elem.eshop_id + '?id=' + elem.id);
    const refreshedData = response.data.items[0];
    setElem(refreshedData);
    setUpdatedElem(refreshedData);
  }

  useEffect(() => {
    if (toRefresh) {
      setToRefresh(false);
      refreshData();
    }
    else {
      setElem(item);
    }
  }, [item, toRefresh]);

  return (
    <>
      <tr key={elem.id}>
        <td>
          {elem.import_date ? formatDate(elem.import_date) : '-'}
        </td>
        <td>
          {elem.total ? elem.total : '-'}
        </td>
        <td>
          {uploaded ? uploaded : '-'}
        </td>
        <td>
          {uploaded ? elem.total - uploaded : '-'}
        </td>
        <td>
          {sources ? sources : '-'}
        </td>
        <td>
          <DropdownButton
            id={`dropdown-button-drop-${elem.id}`}
            key={elem.id}
            size="sm"
            variant={eshopImportStatusColors[status]}
            title={eshopImportStatusLabels[status]}
            onClick={(e) => {
              e.preventDefault();
              toggleDropdown(elem.id);
            }}
          >
            <Dropdown.Item eventKey="1" onClick={() => handleChangeStatus("1")}>Valida</Dropdown.Item>
            <Dropdown.Item eventKey="2" onClick={() => handleChangeStatus("-1")}>Elimina</Dropdown.Item>
          </DropdownButton>
        </td>
        <td className='text-center'>
          {elem.import_status != -1 && (
            <a href={downloadLink} download className="btn btn-primary btn-sm mx-2" target='_blank'>
              <FontAwesomeIcon icon={faDownload} />
            </a>
          )}
          {elem.import_status === -1 && (
            <Button onClick={() => handleChangeStatus("3")} variant='danger' size='sm' className='mx-2'>
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          )}
        </td>
      </tr >
    </>
  );
};

export default EshopImportItemList;