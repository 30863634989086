import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';

export const getEshopInvitations = createAsyncThunk(
    'domains/getEshopInvitations',
    async ({ baseUrl = false, params = { 'per-page': 25, page: 1 } }) => {

        const actionUrl = baseUrl ? baseUrl : '/v1/domains/invitation-list';
        const queryString = Object.entries(params).map(([key, val]) => {
            if (typeof val === 'object' && val !== null) {
                return `${encodeURIComponent(key)}_start=${encodeURIComponent(val.start)}&${encodeURIComponent(key)}_end=${encodeURIComponent(val.end)}`;
            }
            else {
                return `${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
            }
        }).join('&');

        const url = `${actionUrl}?${queryString}`;
        const response = await api.get(url);
        return response.data;

    }

);