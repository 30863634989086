import React, { useEffect , useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { api } from '@/config/api';
import endpoints from '../endpoints/plans';


const CatalogoItemList = ({ item, setUpdatedElem = false}) => {

  const [elem, setElem] = useState(item); 

  function formatDateFromTimestamp(dateString) {
    return moment.unix(dateString).format('DD/MM/YYYY')
  }
  
  useEffect(() => {
      setElem(item);
  }, [item]);

  return (
    <>
      <tr key={elem.id}>        
        <td className="first-letter-uppercase">
            <Link to="#" onClick={() => setUpdatedElem(elem)}>
              {elem.name}
            </Link>
        </td>
        <td>
          {elem.stripe_id}
        </td>
        <td>
          {elem.created ? formatDateFromTimestamp(elem.created) : '-'}
        </td>
        <td>
          {elem.updated ? formatDateFromTimestamp(elem.updated) : '-'}
        </td>
      </tr >      
    </>
  );
};

export default CatalogoItemList;