import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { Card, CardBody, CardTitleWrap } from '@/shared/components/Card';
import { Table } from '@/shared/components/TableElements';
import styled from 'styled-components';
import { colorAccent } from '@/utils/palette';
import { getEshopImports } from '../actions/getEshopImports';
import EshopImportItemList from '../components/EshopImportItemList';
import PaginationTable from '@/containers/Components/PaginationTable';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { successNotify, warningNotify } from '../../App/Router';

const EshopImportReviews = ({ esa_id = false, domain = '' }) => {
  const dispatch = useDispatch();
  const [toRefresh, setToRefresh] = useState(false);
  const importsState = useSelector(state => state.eshops.eshopImports)
  const eshopImports = importsState.entities;
  const loading = importsState.loading;
  const pagination = importsState.pagination;
  const TableSpinner = styled(Spinner)`color: ${colorAccent};`;
  const tableLabels = ['Data caricamento', 'Totale', 'Caricate', 'Non caricate', 'Fonti', 'Stato', '']

  registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

  useEffect(() => {
    if (toRefresh) {
      setToRefresh(false);
    }
    dispatch(getEshopImports({ id: esa_id }));
  }, [dispatch, toRefresh]);

  return (
    <Card>
      <CardBody>
        <Container>
          <Row>
            <Col md={12}>
              <h3 className="page-title">Elenco Import</h3>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} className='my-3'>
              <FilePond
                allowFileTypeValidation={true}
                allowImagePreview={true}
                imagePreviewMaxHeight={100}
                acceptedFileTypes={[
                  "file/xls",
                ]}
                allowFileSizeValidation={true}
                maxFileSize={"10MB"}
                maxTotalFileSize={"10MB"}
                allowMultiple={false}
                maxFiles={1}
                credits={undefined}
                allowPaste={false}
                storeAsFile={false}
                server={{
                  url: process.env.REACT_APP_API_URL + '/v1/domains/add-import-request/' + esa_id,
                }}
                labelIdle="Trascina il tuo file qui oppure <span class='filepond--label-action'> Seleziona dai files </span>"
                onprocessfile={(error, file) => {
                  if (error) {
                    warningNotify();
                  } else {
                    setToRefresh(true);
                    successNotify();
                  }
                }}
              />
            </Col>
            <Col md={12}>
              <Card>
                <CardBody>
                  <CardTitleWrap>
                    <Row className='align-items-center'>
                      <Col xs={12} lg={4}>
                        <p className="page-subtitle">Numero elementi: {pagination?.total_count}</p>
                      </Col>
                    </Row>
                  </CardTitleWrap>
                  {loading === 'loading' ? (
                    <div className="text-center">
                      <TableSpinner animation="border" />
                    </div>
                  ) : (
                    <>
                      <Table responsive striped bordered>
                        <thead>
                          <tr>
                            {tableLabels.map((label) => {
                              return <th>{label}</th>
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {eshopImports && loading === 'idle' && eshopImports.length > 0 && (
                            eshopImports.map((elem) => {
                              return (<EshopImportItemList item={elem} domain={domain} setToRefreshList={setToRefresh} />)
                            })
                          ) || (
                              <tr>
                                <td colSpan={tableLabels.length} className='text-start'>Nessun elemento trovato</td>
                              </tr>
                            )}
                        </tbody>
                      </Table>
                      {eshopImports && eshopImports.length > 0 && (
                        <PaginationTable pagination={pagination} action={getEshopImports} />
                      )}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </CardBody>
    </Card>
  );
};

EshopImportReviews.propTypes = {
  onSubmit: PropTypes.func,
};

export default EshopImportReviews;