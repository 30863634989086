import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { Form, Button, Container, Row, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faSave } from '@fortawesome/free-solid-svg-icons';
import { fetchProducts } from '../actions/fetchProducts';
import { insertLink } from '../actions/insertLink';
import { updateLink } from '../actions/updateLink';
import { successNotify, warningNotify } from '../../App/Router';

const LinkForm = ({ prod_id, elem = false, lockPrice = false, refreshTable, setShowModal = false }) => {

  const dispatch = useDispatch();
  const initialItem = elem ? elem : {};
  const action = elem ? updateLink : insertLink;
  const [updatedElem, setUpdatedElem] = useState(initialItem);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);

  const popover = (
    <Popover id="popover-basic">
      {/* <Popover.Header as="h3">Popover title</Popover.Header> */}
      <Popover.Body>
        Specificare eventuale taglio del prodotto separando il tag del prodotto di riferimento e il numero di crediti con un trattino basso (ES. 'prodotto_500'). Lasciare in bianco per utilizzare il tag predefinito del prodotto di riferimento.
      </Popover.Body>
    </Popover>
  );

  let renewOptions = [
    { value: 'all', label: 'Tutti' },
    { value: '-1', label: 'Nessun rinnovo' },
    { value: '0', label: 'Manuale' },
    { value: '1', label: 'Ricorsivo' },
  ];

  let periodOptions = [
    { value: null, label: 'Seleziona cadenza' },
    { value: '-1', label: '-' },
  ];

  for (let i = 1; i <= 12; i++) {
    periodOptions.push({ value: i, label: i.toString() });
  }

  const handleInsert = () => {
    const createdElem = { ...updatedElem };
    createdElem.prod_id = prod_id ? prod_id : selectedOption.value;
    const payload = elem ? { id: elem.id, data: createdElem } : { data: createdElem };
    dispatch(action(payload)).then((res) => {
      if (res.payload?.success) {
        successNotify();
        if (setShowModal) {
          setShowModal(false);
        }
        setUpdatedElem({});
        refreshTable(true);
      }
      else {
        warningNotify();
      }
    })
  }

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    let val = type === 'checkbox' ? (checked ? 1 : 0) : value;
    const keys = name.split('[').map(key => key.replace(']', ''));
    setUpdatedElem((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      let currentPart = newState;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!currentPart[keys[i]]) {
          currentPart[keys[i]] = {};
        }
        currentPart = currentPart[keys[i]];
      }
      currentPart[keys[keys.length - 1]] = val;
      return newState;
    });
  };

  const handleSelected = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    if (inputValue.length >= 2) {
      dispatch(fetchProducts({ title: inputValue })).then((data) => {
        const items = data.payload.items.map((item) => {
          return { value: item.id, label: item.title };
        });
        setOptions(items);
      });
    }
  }, [inputValue]);

  return (
    <Form autoComplete="off">
      <Container>
        <Row>
          <Col xs={12} className='my-3'>
            <Form.Group controlId={`prod_id`}>
              <Form.Label>Riferimento prodotto</Form.Label>
              {
                prod_id ? (
                  <Form.Control name="prod_id" type="hidden" value={prod_id} />
                ) : (
                  <Select
                    name="prod_id"
                    options={options}
                    value={selectedOption}
                    placeholder="Ricerca prodotto..."
                    onInputChange={setInputValue}
                    onChange={(selectedOption) => handleSelected(selectedOption)}
                  />
                )
              }
            </Form.Group>
          </Col>
          <Col xs={12} className='my-3'>
            <Form.Group controlId={`title`}>
              <Form.Label>Titolo</Form.Label>
              <Form.Control
                name={'title'}
                value={updatedElem.title}
                type="text"
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col xs={12} className='my-3'>
            <Form.Group controlId={`description`}>
              <Form.Label>Descrizione</Form.Label>
              <Form.Control
                name={'description'}
                value={updatedElem.description}
                type="text"
                as="textarea"
                rows={5}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg={3} className='my-3'>
            <Form.Group controlId={`price`}>
              <Form.Label>Prezzo</Form.Label>
              <Form.Control
                name={'price'}
                value={updatedElem.price}
                type="text"
                onChange={handleChange}
                disabled={lockPrice}
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg={3} className='my-3'>
            <Form.Group controlId={`period`}>
              <Form.Label>Cadenza</Form.Label>
              <Form.Select name={'period'} value={updatedElem.period} onChange={handleChange}>
                {periodOptions.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} lg={3} className='my-3'>
            <Form.Group controlId={`renew`}>
              <Form.Label>Rinnovo</Form.Label>
              <Form.Select name={'renew'} value={updatedElem.renew} onChange={handleChange}>
                {renewOptions.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} lg={3} className='my-3'>
            <Form.Group controlId={`tag`}>
              <Form.Label>
                Crediti
                <OverlayTrigger trigger="hover" placement="right" overlay={popover}>
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{ "fontSize": "18px", "cursor": "pointer" }}
                    className='text-primary ms-2'
                  />
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                name={'tag'}
                value={updatedElem.tag}
                type="text"
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          {!lockPrice && (
            <Col xs={12} lg={4} className='my-3'>
              <Form.Group controlId={`vat_free`}>
                <Form.Label>Esente iva</Form.Label>
                <Form.Check
                  name={'vat_free'}
                  value={updatedElem.vat_free}
                  type="checkbox"
                  checked={updatedElem.vat_free}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          )}
          <Col xs={12} lg={4} className='my-3'>
            <Form.Group controlId={`paypal_enabled`}>
              <Form.Label>Paypal abilitato</Form.Label>
              <Form.Check
                name={'paypal_enabled'}
                value={updatedElem.paypal_enabled}
                type="checkbox"
                checked={updatedElem.paypal_enabled}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className='my-3 text-end'>
            <Button onClick={() => handleInsert()} size="sm" variant={'success'}>
              <FontAwesomeIcon icon={faSave} className="me-2" /> Salva
            </Button>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default LinkForm;