import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, Row, Col, Form, Container } from 'react-bootstrap';
import { Button } from '@/shared/components/Button';
import { deleteReview } from '../actions/delete';
import { updateReview } from '../actions/updateReview';
import { deleteProductReview } from '../actions/deleteProductReview';
import { updateProductReview } from '../actions/updateProductReview';
import { sendHibridNotify } from '../actions/sendHibridNotify';
import Badge from '@/shared/components/Badge';
import ReviewDetailModal from './modals/ReviewDetailModal';
import DeleteForeverIcon from 'mdi-react/DeleteForeverIcon';
import moment from 'moment';
import { statusMapping, statusLabels, statusColors, checkReviewMedia } from '@/utils/utilities';
import ReviewAlerts from './ReviewAlerts';
import MediaModal from '@/containers/Reviews/mediaManagement';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileContract, faEnvelope, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import customStyle from '@/shared/assets/css/custom.module.css'
import { TableCheckbox } from '@/shared/components/MaterialTableElements';
import { warningNotify, customSuccessNotify, customWarningNotify } from '@/containers/App/Router';

function ReviewItemList({ item, isProduct = false, isSelected = false, handleSelected = false, selectedList = false, showEshop = true, setToRefresh }) {

  const dispatch = useDispatch();
  const formattedDate = moment(item.review.insert_date).format('DD/MM/YYYY HH:mm:ss');
  const [showModal, setShowModal] = useState(false);
  const [showModalHibrid, setShowModalHibrid] = useState(false);
  const [emailHibrid, setEmailHibrid] = useState(false);
  const [hibridReviewId, setHibridReviewId] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [selectedElem, setSelectedElem] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [imageModalContent, setImageModalContent] = useState(null);
  const review_medias_obj = checkReviewMedia(item.review);
  const [isItemSelected, setIsItemSelected] = useState(isSelected);

  const handleEditClick = (elem) => {
    setSelectedElem(elem);
    setModalShow(true);
  };

  const handleDeleteClick = (userId) => {
    setUserIdToDelete(userId);
    setShowModal(true);
  };

  const handleSendEmailHibrid = (eshop, reviewId) => {
    setEmailHibrid(eshop.email || '');
    setHibridReviewId(reviewId);
    setShowModalHibrid(true);
  };

  const handleConfirmDelete = () => {
    if (isProduct) {
      dispatch(deleteProductReview({ id: userIdToDelete })).then(() => {
        handleClose();
        setToRefresh(true);
      });
    }
    else {
      dispatch(deleteReview({ id: userIdToDelete })).then(() => {
        handleClose();
        setToRefresh(true);
      });
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleCloseHibrid = () => {
    setShowModalHibrid(false);
  };

  const handleSave = (updatedElem) => {

    const reviewData = {
      title: updatedElem.review.title,
      description: updatedElem.review.description
    }

    if (isProduct) {
      dispatch(updateProductReview({ id: updatedElem.review.id, data: reviewData })).then(() => {
        handleClose();
        setToRefresh(true);
      });
    }
    else {
      dispatch(updateReview({ id: updatedElem.review.id, data: reviewData })).then(() => {
        handleClose();
        setToRefresh(true);
      });
    }
  };

  const handleSelectItem = () => {
    if (handleSelected != false) {
      handleSelected(selectedList => ({
        ...selectedList,
        [item.review.id]: !isItemSelected
      }));
    }
    setIsItemSelected(!isItemSelected);
  }

  const handleChangeStatus = (id, status, reason = false) => {
    let reviewData = {};
    const statusCode = statusMapping[status] !== undefined ? statusMapping[status] : null;

    if (status === "valid_certificate") {
      reviewData = {
        status: 1,
        certified: 2,
      };
    } else if (status === "certificate") {
      reviewData = {
        certified: 2,
      };
    } else if (status === "not_valid") {
      reviewData = {
        status: 2,
      };
      if (reason) {
        reviewData['novalidation_reason'] = reason;
      }
    } else {
      reviewData = {
        status: statusCode,
      };
    }

    if (isProduct) {
      dispatch(updateProductReview({ id: id, data: reviewData })).then(() => {
        setModalShow(false);
        setToRefresh(true);
      });
    }
    else {
      dispatch(updateReview({ id: id, data: reviewData })).then(() => {
        setModalShow(false);
        setToRefresh(true);
      });
    }

  };

  const handleEmailHibridChange = (event) => {
    const { name, value, type, checked } = event.target;
    setEmailHibrid(value)
  };

  const handleHibridInvitationClick = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const testEmail = emailRegex.test(emailHibrid);
    if (testEmail && hibridReviewId) {
      dispatch(sendHibridNotify({ email: emailHibrid, reviewId: hibridReviewId })).then((response) => {
        if (response.error) {
          customWarningNotify('Errore durante l\'invio a: ' + emailHibrid)
        }
        else {
          customSuccessNotify('Richiesta inviata con successo');
        }
      })
    }
    else {
      warningNotify()
    }
  }

  useEffect(() => {
    if (handleSelected != false) {
      handleSelected({ [item.review.id]: isSelected });
    }
    setIsItemSelected(isSelected);
  }, [isSelected]);


  return (
    <>
      <tr key={item.id}>
        {!isProduct && (
          <td>
            <TableCheckbox checked={isItemSelected} onChange={() => handleSelectItem()} />
          </td>
        )}
        <td className="first-letter-uppercase">{formattedDate}</td>
        {showEshop && (
          <>
            <td>
              <Link to={`/registered-eshops/update/${item.eshop.id}`} target="_blank">{item.eshop.domain}</Link>
            </td>
            <td>
              <Badge bg={item.eshop.plan == 'ibrido' ? 'warning' : 'success'} >{item.eshop.plan}</Badge>
            </td>
          </>
        )}
        <td> <span className={customStyle.clickable} onClick={() => handleEditClick(item)}>{item.review.title}</span> </td>
        <td>
          {item.user?.email ? (
            <Link to={`/consumer_users/detail/${item.user.id}`} target="_blank">
              {item.user.email}
            </Link>
          ) : (
            item.review.user_name + ' ' + item.review.user_surname
          )}
          {
            (item.user?.visibility != 1 || !item.user) && (
              <Badge className="ms-2" bg={"warning"} title="Non registrato">
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </Badge>
            )
          }
        </td>
        <td className="first-letter-uppercase">{item.review.overall_rating}</td>

        <td className="first-letter-uppercase">
          {item.user?.visibility != 1 && item.review?.order_id == null && item.review?.status == '0' ?
            <Badge bg={"secondary"}>{"Attesa optin"}</Badge>
            :
            <Badge bg={statusColors[item.review.status] || "secondary"}>{statusLabels[item.review.status] || "Stato sconosciuto"}</Badge>
          }
        </td>
        <>
          <td>
            {(item.review.images || item.review.video_url) ? (
              <>
                <Button variant="primary" onClick={() => setImageModalContent(review_medias_obj)} className='p-2 m-0' size="sm">
                  <FontAwesomeIcon icon={faFileContract} />
                </Button>
                <MediaModal show={imageModalContent !== null} handleClose={() => setImageModalContent(null)} title="Gestione Media" review_data={imageModalContent} />
              </>
            ) : "-"}
          </td>
        </>
        <td>
          <ReviewAlerts review={item.review} />
        </td>
        <td>
          {item.eshop.plan === 'ibrido' && (
            <Button
              variant="secondary"
              size='sm'
              title="Invia email eshop"
              onClick={() => handleSendEmailHibrid(item.eshop, item.review.id)}
              className="m-0 mx-2"
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </Button>
          )}
          <Button
            variant="danger"
            size='sm'
            onClick={() => handleDeleteClick(item.review.id)}
            className="m-0"
          >
            <DeleteForeverIcon />
          </Button>
        </td>
      </tr>
      {
        modalShow && (
          <ReviewDetailModal
            show={modalShow}
            handleClose={handleModalClose}
            elem={selectedElem}
            handleSave={handleSave}
            handleChangeStatus={handleChangeStatus}
          />
        )
      }
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Conferma eliminazione</Modal.Title>
        </Modal.Header>
        <Modal.Body>Sei sicuro di voler cancellare la recensione?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Annulla
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Elimina
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModalHibrid} onHide={handleCloseHibrid}>
        <Modal.Header closeButton>
          <Modal.Title>Revisione email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form autoComplete="off">
            <Container>
              <Row>
                <Col xs={12} className='my-3'>
                  <Form.Group controlId="billingInfo_business_name">
                    <Form.Label>Invia a:</Form.Label>
                    <Form.Control name="emailHibrid" value={emailHibrid} type="text" onChange={handleEmailHibridChange} />
                  </Form.Group>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseHibrid}>
            Annulla
          </Button>
          <Button variant="primary" onClick={handleHibridInvitationClick}>
            Invia
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ReviewItemList;