import { combineReducers } from 'redux';
import productsSlice from './fetch';
import subProductsSlice from './fetchSub';
import linkSlice from './fetchLink';
import deadlines from './deadlines';

const esaStoreReducer = combineReducers({
    fetch: productsSlice,
    fetchSub: subProductsSlice,
    fetchLink: linkSlice,
    deadlines: deadlines,
});

export default esaStoreReducer;
