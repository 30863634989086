import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';
import endpoint from '../endpoints/payments';

export const putInvoice = createAsyncThunk(
    'esaStore/putInvoice',
    async ({id,data}) => {
        const response = await api.put(endpoint.handleInvoice+'/'+id, data);
        return response.data;
    }
);