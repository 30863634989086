import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Col, Container, Row, Spinner, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faSave, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Card, CardBody, CardTitleWrap } from '@/shared/components/Card';
import { Table } from '@/shared/components/TableElements';
import styled from 'styled-components';
import { cleanDate } from '@/shared/helpers';
import { colorAccent } from '@/utils/palette';
import { fetchCatalogoPiani } from './actions/catalogoPiani';
import CatalogoItemList from './components/CatalogoItemList';
import PaginationTable from '@/containers/Components/PaginationTable';
import { Modal } from 'react-bootstrap';
import { successNotify, warningNotify } from '../App/Router';
import { updateCatalogo } from './actions/updateCatalogo';
import { postCatalogoPianiData } from './actions/insertCatalogoItem';
import endpoints from './endpoints/plans';
import { api } from '@/config/api';

const CatalogoPianiList = ({ esa_id = false }) => {

  const dispatch = useDispatch();

  const catalogoPianiState = useSelector(state => state.plans.catalogoPiani)
  const [toRefreshTable, setToRefreshTable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [idToUpdate, setIdToUpdate] = useState(null);
  const [updatedElem, setUpdatedElem] = useState({
    id: 0,
    name: '',
    description: '',
    mensile: {
      cost: 0,
    },
    semestrale: {
      cost: 0,
    },
    annuale: {
      cost: 0,
    }
  });
  const [toRefresh, setToRefresh] = useState(false);

  const catalogoPiani = catalogoPianiState.entities;
  const loading = catalogoPianiState.loading;
  const pagination = catalogoPianiState.pagination;
  const TableSpinner = styled(Spinner)`color: ${colorAccent};`;
  const tableLabels = ['Piano', 'ID_Stripe', 'Data di creazione', 'Data di aggiornamento'];

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    let val = type === 'checkbox' ? (checked ? 1 : 0) : value;
    const keys = name.split('[').map(key => key.replace(']', ''));
    setUpdatedElem((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      let currentPart = newState;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!currentPart[keys[i]]) {
          currentPart[keys[i]] = {};
        }
        currentPart = currentPart[keys[i]];
      }
      currentPart[keys[keys.length - 1]] = val;
      return newState;
    });
  };

  const handleSubmit = () => {
    const dataToSend = { ...updatedElem };
    if (dataToSend.id > 0) {
      dispatch(updateCatalogo({ id: idToUpdate, data: dataToSend })).then((response) => {
        if (response.error) {
          warningNotify(response.error);
        }
        else {
          handleClose()
          setToRefreshTable(true);
          successNotify();
        }
      });
    }
    else {
      dispatch(postCatalogoPianiData(dataToSend)).then((response) => {
        if (response.error) {
          warningNotify(response.error);
        }
        else {
          setToRefreshTable(true);
          handleClose();
          successNotify();
        }
      });
    }

    setUpdatedElem({
      id: 0,
      name: '',
      description: '',
      mensile: {
        cost: 0,
      },
      semestrale: {
        cost: 0,
      },
      annuale: {
        cost: 0,
      }
    });
  }

  const handleOpen = () => {
    setUpdatedElem({
      id: 0,
      name: '',
      description: '',
      mensile: {
        cost: 0,
      },
      semestrale: {
        cost: 0,
      },
      annuale: {
        cost: 0,
      }
    });
    setShowModal(true);
  }

  const handleClose = () => {
    setIdToUpdate(false);
    setShowModal(false);
  }

  useEffect(() => {
    const initialPayload = [];
    dispatch(fetchCatalogoPiani(initialPayload));
  }, [dispatch]);

  useEffect(() => {

    if (toRefreshTable) {
      const initialPayload = [];
      dispatch(fetchCatalogoPiani(initialPayload));
      setToRefreshTable(false);
    }

  }, [toRefreshTable]);

  useEffect(() => {
    if (updatedElem && updatedElem.id > 0) {
      setIdToUpdate(updatedElem?.id);
      setShowModal(true);
    }
  }, [updatedElem]);

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Catalogo Piani</h3>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <CardTitleWrap>
                  <Row className='align-items-center justify-content-between'>
                    <Col xs={12} lg={4}>
                      <p className="page-subtitle">Numero elementi: {pagination?.total_count}</p>
                    </Col>
                    <Col lg={4} style={{ textAlign: 'right' }}>
                      <Button onClick={() => handleOpen()} variant="outline-primary" size='sm'>
                        <FontAwesomeIcon icon={faPlus} className="me-2" />
                        <span>Aggiungi piano</span>
                      </Button>
                    </Col>
                  </Row>
                </CardTitleWrap>
                {loading === 'loading' ? (
                  <div className="text-center">
                    <TableSpinner animation="border" />
                  </div>
                ) : (
                  <>
                    <Table responsive striped bordered>
                      <thead>
                        <tr>
                          {tableLabels.map((label) => {
                            return <th>{label}</th>
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {catalogoPiani && loading === 'idle' && catalogoPiani.length > 0 && (
                          catalogoPiani.map((elem) => {
                            return (<CatalogoItemList item={elem} setUpdatedElem={setUpdatedElem} />)
                          })
                        ) || (
                            <tr>
                              <td colSpan={tableLabels.length} className='text-start'>Nessun elemento trovato</td>
                            </tr>
                          )}
                      </tbody>
                    </Table>
                    {catalogoPiani && catalogoPiani.length > 0 && (
                      <PaginationTable pagination={pagination} action={fetchCatalogoPiani} />
                    )}
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal show={showModal} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Modifica coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form autoComplete="off">
            <Container>
              <Row>
                <Col xs={12} lg={6} className='my-3'>
                  <Row>
                    <Col xs={12} lg={12}>
                      <Form.Group controlId={`cost`}>
                        <Form.Label>Nome piano</Form.Label>
                        <Form.Control
                          name={'name'}
                          value={updatedElem?.name}
                          type="text"
                          onChange={handleChange}
                          disabled={idToUpdate ? true : false}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} lg={12} className='my-3'>
                      <Form.Group controlId={`cost`}>
                        <Form.Label>Descrizione</Form.Label>
                        <Form.Control
                          name={'description'}
                          value={updatedElem?.description}
                          type="text"
                          as="textarea"
                          rows="5"
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} lg={6} className='my-6'>
                  <Row>
                    <Col xs={12} lg={12} className='my-3'>
                      <Form.Group controlId={`cost`}>
                        <Form.Label>Costo mensile</Form.Label>
                        <Form.Control
                          name={'mensile[cost]'}
                          value={updatedElem?.mensile?.cost}
                          type="text"
                          onChange={handleChange}
                          disabled={idToUpdate ? true : false}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} lg={12} className='my-3'>
                      <Form.Group controlId={`cost`}>
                        <Form.Label>Costo semestrale</Form.Label>
                        <Form.Control
                          name={'semestrale[cost]'}
                          value={updatedElem?.semestrale?.cost}
                          type="text"
                          onChange={handleChange}
                          disabled={idToUpdate ? true : false}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} lg={12} className='my-3'>
                      <Form.Group controlId={`cost`}>
                        <Form.Label>Costo annuale</Form.Label>
                        <Form.Control
                          name={'annuale[cost]'}
                          value={updatedElem?.annuale?.cost}
                          type="text"
                          onChange={handleChange}
                          disabled={idToUpdate ? true : false}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className='my-3 text-end'>
                  <Button onClick={() => handleSubmit()} size="sm" variant={'success'}>
                    <FontAwesomeIcon icon={faSave} className="me-2" /> Salva
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

CatalogoPianiList.propTypes = {
  onSubmit: PropTypes.func,
};

export default CatalogoPianiList;