import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { Form, Button, Container, Row, Col, ListGroup, ProgressBar, Badge } from 'react-bootstrap';
import Select from 'react-select';
import { Card, CardBody, CardTitle, CardTitleWrap } from '@/shared/components/Card';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { successNotify, customWarningNotify } from '../../App/Router';
import { getEshopCategories } from '../actions/getEshopCategories';
import { categoriesSuggester } from '../actions/categoriesSuggester';
import { updateEshopCategories } from '../actions/updateEshopCategories';
import { getCategoriesCalculator } from "../actions/getCategoriesCalculator";

const EshopCategories = ({ esa_id }) => {

  const dispatch = useDispatch();
  const [toRefresh, setToRefresh] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoriesCalculator, setCategoriesCalculator] = useState([]);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState(null);

  const progressBarColor = (value) => {
    if (value < 30) {
      return 'danger';
    } else if (value < 70) {
      return 'warning';
    } else {
      return 'success';
    }
  }

  const resetOptions = () => {
    setInputValue('');
    setOptions([]);
  }

  const handleSelected = (e) => {
    const newCategories = [...categories];
    newCategories.push({ idxs: '[' + e.value.toString() + ']', value: e.label });
    setCategories(newCategories);
    resetOptions();
    setSelectedValue(null);
    setToRefresh(true);
  }

  const handleDelete = (index) => {
    let newCategories = [...categories];
    newCategories.splice(index, 1);
    setCategories(newCategories);
    setToRefresh(true);
  }

  const handleSave = () => {
    const dataToSend = { ...categories };
    dispatch(updateEshopCategories({ id: esa_id, data: dataToSend })).then(() => {
      successNotify();
    });
  }

  useEffect(() => {
    if (toRefresh) {
      setToRefresh(false);
      dispatch(getCategoriesCalculator({ data: categories })).then((data) => {
        setCategoriesCalculator(data.payload);
      });
    }
  }, [toRefresh]);

  useEffect(() => {
    dispatch(getEshopCategories({ id: esa_id })).then((data) => {
      setCategories(data.payload.categories);
      setCategoriesCalculator(data.payload.categoriesCalculator);
    });
  }, [esa_id]);

  useEffect(() => {
    if (inputValue.length >= 2) {
      dispatch(categoriesSuggester({ search: inputValue })).then((data) => {
        const items = data.payload.data.map((item) => {
          return { value: item.idxs, label: item.first + ' > ' + item.second };
        });
        setOptions(items);
      });
    }
  }, [inputValue]);

  return (
    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle className="d-block">
            Categorie
            <Button onClick={handleSave} size="sm" variant={'success'} className="float-end">
              <FontAwesomeIcon icon={faSave} className="me-2" /> Salva
            </Button>
          </CardTitle>
        </CardTitleWrap>
        <Form autoComplete="off">
          <Container>
            <Row>
              <Col xs={12} className='my-3'>
                <Form.Group controlId="product_keywords">
                  <Form.Label>Keyword prodotti</Form.Label>
                  <Select
                    name="product_kw"
                    options={options}
                    value={selectedValue}
                    placeholder="Inserisci nomi di prodotti o categorie e servizi"
                    onInputChange={setInputValue}
                    onBlur={() => resetOptions()}
                    onChange={(selectedOption) => handleSelected(selectedOption)}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} className='my-3'>
                {
                  categories.length > 0 && categories.map((category, index) => {
                    return (
                      <Badge variant="primary" className="me-2 py-2 px-2" style={{ fontSize: '12px' }}>
                        {category.value}
                        <span
                          onClick={() => handleDelete(index)}
                          className="ms-2 text-end"
                          style={{ cursor: 'pointer' }}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </span>
                      </Badge>
                    )
                  })
                }
              </Col>
            </Row>
            <Row>
              <Col xs={12} className='my-3'>
                <h4>Le Categorie dove apparirà il tuo eShop</h4>
                <p>In base ai prodotti o servizi inseriti, in tempo reale assoceremo in modo automatico le categorie più pertinenti. L'indicatore sotto al nome della categoria indica la rilevanza della categoria rispetto al tuo business: la rilevanza sarà determinante sia nella ricerca all'interno del portale eShoppingAdvisor sia nel posizionamento della tua vetrina eShop sui motori di ricerca. Per questo motivo ti consigliamo di scegliere categorie affini tra loro ed in numero limitato.</p>
              </Col>
              <Col xs={12} className='my-3'>
                <ListGroup>
                  {
                    categoriesCalculator.length > 0 && categoriesCalculator.map((category, index) => {
                      return (
                        <ListGroup.Item key={index}>
                          <Row>
                            <Col xs={12}>
                              {category.description}
                            </Col>
                            <Col xs={10}>
                              <ProgressBar now={category.weight} srOnly variant={progressBarColor(category.weight)} />
                            </Col>
                            <Col xs={2}>
                              <div className="text-center w-100">{category.weight}%</div>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      )
                    })
                  }
                </ListGroup>
              </Col>
            </Row>
          </Container>
        </Form>
      </CardBody>
    </Card>
  )
}

export default EshopCategories
