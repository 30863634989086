import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';
import endpoints from '../endpoints/eshops';

export const postEshopData = createAsyncThunk(
    'eshops/postEshopsData',
    async (userData) => {
        const response = await api.post(endpoints.insertNewEshop, userData);
        return response.data;
    }
);