import { useParams } from "react-router-dom";
import endpoints from "./endpoints/b2bUsers";
import { useDispatch } from 'react-redux';
import { api } from "@/config/api";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import HorizontalFormTwo from "./forms/b2bUser/anagrafica";
import Link from "react-router-dom/Link";
import { putB2bUserData } from "./actions/updateB2bUser";
import { useState, useEffect } from "react";
import SidebarMenu from "../Layout/custom_components/SidebarMenu";
import sidebar_b2bUsers from "./configs/sidebar_b2bUsers";
import BillingData from './forms/b2bUser/billing_data';

const UpdateB2bUser = () => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const [b2bUserData, setB2bUserData] = useState({});
    const [currentSection, setCurrentSection] = useState('general-data');

    useEffect(() => {
        const fetchData = async () => {
            const response = await api.get(endpoints.get.replace(':id', id))
            setB2bUserData(response.data);
        }

        fetchData();
    }, [id]);

    const handleSubmit = (formData) => {
        const dataToSend = { ...formData }; // crea una copia
        delete dataToSend.confirm_password; // rimuovi confirm_password dalla copia
        delete dataToSend.gender; // rimuovi gender dalla copia
        dataToSend.active = 0;
        console.log(dataToSend); // mostra i dati del form senza confirm_password
        dispatch(putB2bUserData({ dataToSend, id })); // invoca l'action
    };

    const changePageClick = (page) => {
        setCurrentSection(page);
    }

    return (
        <Container>
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Aggiungi utente</h3>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/business-accounts/list" }}>
                            Torna all'elenco degli utenti
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <SidebarMenu routes={sidebar_b2bUsers} changePageClick={changePageClick} currentPage={currentSection} />
                {
                    currentSection === 'general-data' && <HorizontalFormTwo onSubmit={handleSubmit} b2bUserData={b2bUserData} isUpdate={true} />
                }
                {
                    currentSection === 'billing-data' && <BillingData />
                }


            </Row>
        </Container>
    )
};

UpdateB2bUser.propTypes = {
    // prop: PropTypes.string
};

export default UpdateB2bUser;