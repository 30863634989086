import { createSlice } from '@reduxjs/toolkit';
import { fetchModifiedReviews } from '../actions/fetchModifiedReviews';

export const modifiedReviews = createSlice({
    name: 'modifiedReviews',
    initialState: {
        items: [],
        loading: false,
        error: null,
        pagination: {
            total_count: 0,
            page_count: 0,
            current_page: 0,
            per_page: 0,
            next_page_url: null,
            prev_page_url: null,
        }
    },
    reducers: {
        reviewApproved: (state, action) => {
            const index = state.items.findIndex(item => item.modified.id === action.payload);
            console.log("index", index);
            if (index !== -1) {
                // Aggiorna lo stato di `modified.status` a 1 per l'elemento trovato
                state.items[index].modified.status = 1; // Status per "approvato"
            }
        },
        reviewRejected: (state, action) => {
            const index = state.items.findIndex(item => item.modified.id === action.payload);
            if (index !== -1) {
                state.items[index].modified.status = 2; // Status per "rifiutato"
            }
        },
    },
    extraReducers: {
        [fetchModifiedReviews.pending]: (state) => {
            state.loading = 'loading';
            state.error = null;
        },
        [fetchModifiedReviews.fulfilled]: (state, action) => {
            console.log("action", action);
            state.items = action.payload.items;
            state.pagination = action.payload.pagination;
            state.loading = 'idle';
            console.log("state", state);
        },
        [fetchModifiedReviews.rejected]: (state, action) => {
            state.error = action.payload;
            state.loading = 'idle';
        },
    },
});

export const { reviewApproved, reviewRejected } = modifiedReviews.actions;

export default modifiedReviews.reducer;
