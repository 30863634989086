import { useContext, createContext, useState } from "react";
import { useHistory } from "react-router-dom";

const AuthContext = createContext();

const AuthProvider = ({ children } = {}) => {

  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("site") || "");
  const history = useHistory();

  const loginAction = async (data) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + "/v1/admins/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const res = await response.json();
      if (res.admin_id) {
        setUser(res);
        setToken(res.access_token);
        localStorage.setItem("site", res.access_token);
        // localStorage.setItem("user", { 'id': res.admin_id, 'name': res.name, 'role': res.role });
        history.push("/");
        return user;
      }
      throw new Error(res.message);
    } catch (err) {
      console.error(err);
    }
  };

  const logOut = () => {
    setUser(null);
    setToken("");
    localStorage.setItem("site", '');
    localStorage.removeItem("site");
    history.push("/login");
  };

  const refreshToken = async (token) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + "/v1/admins/check-token", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: token }),
      });
      const res = await response.json();
      if (res.admin_id) {
        setUser(res);
        setToken(res.access_token);
        localStorage.setItem("site", token);
        return res;
      }
      else {
        setToken("");
        localStorage.removeItem("site");
        history.push("/login");
        return false;
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <AuthContext.Provider value={{ token, user, loginAction, logOut, refreshToken, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext)
};