import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../config/api';

export const deleteEshopReply = createAsyncThunk(
    'review/deleteEshopReply',
    async ({ id, isProduct }) => {
        debugger
        const url = (isProduct == true) ? `/v1/reviews/delete-eshop-product-reply/${id}` : `/v1/reviews/delete-eshop-reply/${id}`;
        const response = await api.delete(url);
        return response.data;
    }
);