import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Homepage from '../../../Properties/homepage';
import Featured from '../../../Properties/featured';
import CarouselCat from '../../../Properties/carouselCat';
import SuggestedCat from '../../../Properties/suggestedCat';
import EcsSuggested from '../../../Properties/ecsSuggested';
import CarouselEshops from '../../../Properties/carouselEshops';
import CarouselCoupons from '../../../Properties/carouselCoupons';
import Banners from '../../../Properties/banners';
import Slide from '../../../Properties/slide';
import MenuTop from '../../../Properties/menutop';
import MenuFooter from '../../../Properties/menufooter';
import BannerDetails from '../../../Properties/bannerDetails';
import SettingsList from '../../../Properties/settings';


export default () => (
  <Switch>
    <Route path="/properties/homepage" component={Homepage} />
    <Route path="/properties/featured" component={Featured} />
    <Route path="/properties/carousel-cat" component={CarouselCat} />
    <Route path="/properties/suggested-cat" component={SuggestedCat} />
    <Route path="/properties/ecs-suggested" component={EcsSuggested} />
    <Route path="/properties/carousel-eshops" component={CarouselEshops} />
    <Route path="/properties/carousel-coupons" component={CarouselCoupons} />
    <Route path="/properties/banners" component={Banners} />
    <Route path="/properties/slide" component={Slide} />
    <Route path="/properties/menutop" component={MenuTop} />
    <Route path="/properties/menufooter" component={MenuFooter} />
    <Route path="/properties/banner-detail" component={BannerDetails} />    
    <Route path="/properties/settings" component={SettingsList} />
  </Switch>
);

