import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import {
    Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import { BorderedTopTabs } from '@/shared/components/Tabs';
import { Nav, Tab } from 'react-bootstrap';
import { NavItem, NavLink, TabsWrap } from '@/shared/components/Tabs';
import { Table } from '@/shared/components/TableElements';
import { Button, ButtonToolbar, ButtonGroup } from '@/shared/components/Button';
import Badge from '@/shared/components/Badge';
import FilterForm from './filters/filterCarouselEshops';

import FormField from '@/shared/components/form/FormField';

import {
    FormButtonToolbar,
    FormContainer,
    FormGroup,
    FormGroupField,
    FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Container, Row, Col } from 'react-bootstrap';
import { fetchJson } from './actions/fetchJson';
import { updateJson } from './actions/updateJson';
import { successNotify, warningNotify } from '@/containers/App/Router/index';

const Homepage = () => {
    const dispatch = useDispatch();
    const jsonFromRedux = useSelector(state => state.properties.fetchJson.item || "");
    const [item, setItem] = useState(jsonFromRedux);
    const entireState = useSelector(state => state);

    useEffect(() => {
        dispatch(fetchJson(5));

    }, [dispatch]);

    useEffect(() => {
        setItem(jsonFromRedux);

    }, [jsonFromRedux]);

    const onSubmit = async (values) => {
        try {
            await dispatch(updateJson({ position: 5, text: values.jsonContent }));
            successNotify();
        } catch (error) {
            warningNotify();
            // console.log(error);
        }
    };

    const handleFilterSubmit = (values) => {
    };

    const LargeTextarea = ({ input, meta, ...rest }) => (
        <textarea {...input} {...rest} style={{ height: 'calc(1.5em * 20)', resize: 'vertical' }} />
    );

    return (
        <Container>
            <Row>
                <Col md={12}>
                    <h3 className="page-title">HP Eshops Carousel</h3>
                </Col>
            </Row>
            <FilterForm onSubmit={handleFilterSubmit} />
            <Form onSubmit={onSubmit} initialValues={{ jsonContent: item.content }}>
                {({ handleSubmit }) => (
                    <FormContainer vertical onSubmit={handleSubmit}>
                        <Col md={12}>

                            <Card>
                                <CardBody>


                                    <BorderedTopTabs>
                                        <Tab.Container defaultActiveKey="1">
                                            <TabsWrap>
                                                <Nav className="nav-tabs">
                                                    <NavItem>
                                                        <NavLink eventKey="1">
                                                            Acquisti eshops
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink eventKey="2">
                                                            JSON
                                                        </NavLink>
                                                    </NavItem>

                                                </Nav>

                                                <Tab.Content>
                                                    <Tab.Pane eventKey="1">

                                                        <Table striped bordered>
                                                            <thead>
                                                                <tr>
                                                                    <th>Eshop</th>
                                                                    <th>Piano Eshop</th>
                                                                    <th>Stato</th>
                                                                    <th>Data Creazione</th>
                                                                    <th>Data Attivazione</th>
                                                                    <th>Data Disattivazione</th>
                                                                    <th>Data Pagamento</th>
                                                                    <th>Data Scadenza</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>

                                                                    <td className="first-letter-uppercase">Bottegasicana.com</td>
                                                                    <td className="first-letter-uppercase"><Badge bg="success">Company</Badge></td>
                                                                    <td className="first-letter-uppercase">Pagato</td>
                                                                    <td className="first-letter-uppercase">2023-04-17 19:00:53</td>
                                                                    <td className="first-letter-uppercase">-</td>
                                                                    <td className="first-letter-uppercase">-</td>
                                                                    <td className="first-letter-uppercase">-</td>
                                                                    <td className="first-letter-uppercase">-</td>

                                                                    <td>
                                                                        <ButtonToolbar>
                                                                            <ButtonGroup icons dir="ltr">
                                                                                <Button variant="success" title="Aggiungi al catalogo"><span className="lnr lnr-plus-circle" /></Button>
                                                                            </ButtonGroup>
                                                                        </ButtonToolbar>
                                                                    </td>
                                                                </tr>
                                                                <tr>

                                                                    <td className="first-letter-uppercase">Testedeare.com</td>
                                                                    <td className="first-letter-uppercase"><Badge bg="success">Company</Badge></td>
                                                                    <td className="first-letter-uppercase">Pagato</td>
                                                                    <td className="first-letter-uppercase">2023-04-17 19:00:53</td>
                                                                    <td className="first-letter-uppercase">-</td>
                                                                    <td className="first-letter-uppercase">-</td>
                                                                    <td className="first-letter-uppercase">-</td>
                                                                    <td className="first-letter-uppercase">-</td>

                                                                    <td>
                                                                        <ButtonToolbar>
                                                                            <ButtonGroup icons dir="ltr">

                                                                                <Button variant="danger" title="Elimina"><span className="lnr lnr-circle-minus" /></Button>
                                                                            </ButtonGroup>
                                                                        </ButtonToolbar>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="2">
                                                        <FormGroup>

                                                            <FormGroupField>
                                                                <Field
                                                                    component={LargeTextarea}
                                                                    name="jsonContent"
                                                                />
                                                            </FormGroupField>
                                                        </FormGroup>
                                                    </Tab.Pane>

                                                </Tab.Content>

                                            </TabsWrap>
                                        </Tab.Container>
                                    </BorderedTopTabs>


                                    <FormButtonToolbar>
                                        <Button variant="primary" type="submit">Salva</Button>
                                    </FormButtonToolbar>
                                </CardBody>

                            </Card>

                        </Col>
                    </FormContainer>
                )}
            </Form>
        </Container>
    );

}

export default Homepage;
