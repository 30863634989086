import React, { createContext, useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import MainWrapper from '../MainWrapper';
import WrappedRoutes from './WrappedRoutes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from '../../LogIn/login';
import AuthProvider from '../AuthProvider';

const successNotify = () => toast.success("Dati salvati correttamente.");
const warningNotify = () => toast.warning("Errore.");
const customSuccessNotify = (message) => toast.success(message);
const customWarningNotify = (message) => toast.warning(message);

const Router = () => (
  <AuthProvider>
    <MainWrapper>
      <ToastContainer />
      <main>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/" component={WrappedRoutes} />
        </Switch>
      </main>
    </MainWrapper>
  </AuthProvider>
);

export default Router;
export { successNotify, warningNotify, customSuccessNotify, customWarningNotify };
