import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faBan, faTrash, faEye, faLock, faLockOpen, faSignIn } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { TableCheckbox } from '@/shared/components/MaterialTableElements';
import { eshopStatusLabels, eshopStatusColors, eshopBillingStatusColors, eshopBillingStatusLabels } from '@/utils/utilities';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { successNotify, customSuccessNotify, customWarningNotify } from '../../App/Router';
import { sendOptinRequest } from '../actions/sendOptinRequest';
import { toggleHomeCarousel } from '../actions/toggleHomeCarousel';
import { toggleEshopVisioned } from '../actions/toggleEshopVisioned';
import { toggleBillingStatus } from '../actions/toggleBillingStatus';
import { updateStatus } from '../actions/updateStatus';
import { updateBookkeepingStatus } from '../actions/updateBookkeepingStatus';

const EshopItemList = ({ item, isSelected = false, handleSelected = false, selectedList = false, hibrid = false, refreshTable }) => {

  const dispatch = useDispatch();
  const [toRefresh, setToRefresh] = useState(false);
  const [isItemSelected, setIsItemSelected] = useState(isSelected);
  const [idToDelete, setIdToDelete] = useState(null);
  const [visibleDropdownId, setVisibleDropdownId] = useState(null);
  const [carouselBlocked, setCarouselBlocked] = useState((item.eshopData?.structured_data?.carousel_blocked) ? item.eshopData?.structured_data.carousel_blocked : 0);
  const [visioned, setVisioned] = useState((item.eshopData?.structured_data?.pending_docs != undefined) ? item.eshopData?.structured_data.pending_docs : 1)
  const [billingDisabled, setBillingDisabled] = useState((item.info?.disable_update_billing_info) ? item.info.disable_update_billing_info : 0)
  const [bookkeeping, setBookkeeping] = useState((item.info?.accounting_status) ? item.info.accounting_status : 'regolare')
  const [status, setStatus] = useState(item.status);
  const [showModal, setShowModal] = useState(false);
  const isHibrid = (item.plan == 'ibrido') ? true : false;
  const updateUrl = (isHibrid) ? '/hibrid-eshops/update/' : '/registered-eshops/update/';
  const showOptin = (status == -1 && !isHibrid) ? true : false;

  const handleDeleteClick = (id) => {
    setIdToDelete(id);
    setShowModal(true);
  }

  const handleClose = () => {
    setShowModal(false);
  }

  const handleSelectItem = () => {
    if (handleSelected != false) {
      handleSelected(selectedList => ({
        ...selectedList,
        [item.id]: !isItemSelected
      }));
    }
    setIsItemSelected(!isItemSelected);
  }

  const toggleDropdown = (id) => {
    setVisibleDropdownId(visibleDropdownId === id ? null : id);
  };

  const handleSendOptin = (id) => {
    const data = { ids: [id] };
    dispatch(sendOptinRequest({ data: data })).then(() => {
      customSuccessNotify('Richiesta inviata con successo');
    });
  }

  const handleToggleCarousel = (id) => {
    const value = (carouselBlocked == 1) ? 0 : 1;
    dispatch(toggleHomeCarousel({ id: id, value: value })).then(() => {
      customSuccessNotify('Carousel aggiornato con successo');
      setCarouselBlocked(value)
      setToRefresh(true)
    });
  }

  const handleToggleVisioned = (id) => {
    const value = (visioned == 1) ? 0 : 1;
    dispatch(toggleEshopVisioned({ id: id, value: value, isHibrid: hibrid })).then(() => {
      successNotify();
      setVisioned(value)
      setToRefresh(true)
    });
  }

  const handleToggleBilling = (id) => {
    const value = (billingDisabled == 1) ? 0 : 1;
    dispatch(toggleBillingStatus({ id: id })).then(() => {
      successNotify();
      setBillingDisabled(value)
      setToRefresh(true)
    });
  }

  const handleChangeStatus = (id, value) => {
    dispatch(updateStatus({ id: id, data: { status: value } })).then(() => {
      successNotify();
      setStatus(value)
      setToRefresh(true)
    });
  }

  const handleChangeBookkeepingStatus = (id, value) => {
    dispatch(updateBookkeepingStatus({ id: id, data: { status: value } })).then(() => {
      successNotify();
      setBookkeeping(value)
      setToRefresh(true)
    });
  }

  const handleConfirmDelete = () => {
    handleChangeStatus(idToDelete, -2);
    setShowModal(false);
    refreshTable(true);
  };

  const toLowerCase = (str) => {
    return str.toLowerCase();
  }

  useEffect(() => {
    if (showOptin) {
      if (handleSelected != false) {
        handleSelected(selectedList => ({
          ...selectedList,
          [item.id]: isSelected
        }));
      }
    }
    setIsItemSelected(isSelected);
  }, [isSelected, toRefresh]);

  return (
    <>
      <tr key={item.id}>
        {!hibrid && (
          <td>
            {showOptin && (
              <TableCheckbox checked={isItemSelected} onChange={() => handleSelectItem()} />
            )}
          </td>
        )}
        <td className="first-letter-uppercase">
          <Link to={updateUrl + item.id}>{item.domain}</Link>
        </td>
        <td className="first-letter-uppercase">
          <Badge bg={item.plan == 'ibrido' ? 'warning' : 'success'}>{item.plan}</Badge>
        </td>
        <td>
          {(item.eshopData?.email) ? item.eshopData?.email : '-'}
        </td>
        <td>
          {(item.eshopData?.registration_address?.phone) ? item.eshopData?.registration_address.phone : '-'}
        </td>
        <td>
          {moment(item.creation_date).format('DD/MM/YYYY HH:mm')}
        </td>
        {!hibrid && (
          <>
            <td>
              {(item.info?.optin_date) ? moment(item.info.optin_date).format('DD/MM/YYYY HH:mm') : '-'}
            </td>
            <td>
              {(item.esaPlan?.activation_date) ? moment(item.esaPlan.activation_date).format('DD/MM/YYYY HH:mm') : '-'}
            </td>
            <td>
              {(item.eshopData?.registration_address?.promo_code) ? item.eshopData?.registration_address.promo_code : '-'}
            </td>
            <td>
              {showOptin ? (
                <Button variant="warning" size="sm" onClick={() => handleSendOptin(item.id)}>
                  <FontAwesomeIcon icon={faEnvelope} />
                </Button>
              ) : '-'}
            </td>
            <td>
              <Button variant={(carouselBlocked == 1) ? 'danger' : 'success'} size="sm" onClick={() => handleToggleCarousel(item.eshop_data_id)}>
                <FontAwesomeIcon icon={faBan} />
              </Button>
            </td>
            <td>
              {status == 1 ? (
                <Button as='a' href={`${process.env.REACT_APP_BUSINESS_URL}/areariservata/logEshop?ck=${item.id}:${item.autologinCode}`} target='_blank' bg={"primary"} size="sm" >
                  <FontAwesomeIcon icon={faSignIn} />
                </Button>

              ) : "-"}
            </td>
            <td>
              {status != 2 && item.eshopData?.structured_data?.pending_docs != 1 ? (
                <DropdownButton
                  id={`dropdown-button-drop-${item.id}`}
                  key={item.id}
                  size="sm"
                  variant={eshopStatusColors[status]}
                  title={eshopStatusLabels[status]}
                  onClick={(e) => {
                    e.preventDefault();
                    toggleDropdown(item.id);
                  }}
                >
                  <Dropdown.Item eventKey="1" onClick={() => handleChangeStatus(item.id, 1)}>Abilita</Dropdown.Item>
                  <Dropdown.Item eventKey="0" onClick={() => handleChangeStatus(item.id, 0)}>Disabilita</Dropdown.Item>
                  <Dropdown.Item eventKey="-1" onClick={() => handleChangeStatus(item.id, -1)}>Sospendi</Dropdown.Item>
                </DropdownButton>
              ) : (
                item.eshopData?.structured_data?.pending_docs != 1 ? (
                  <Badge bg={eshopStatusColors[status]}>
                    {eshopStatusLabels[status]}
                  </Badge>
                ) : (
                  <Badge bg={'secondary'}>
                    In attesa documentazione
                  </Badge>
                )
              )}
            </td>
          </>
        )}
        <td>
          <Button variant={(visioned == 1) ? 'danger' : 'success'} size="sm" onClick={() => handleToggleVisioned(item.eshop_data_id)}>
            <FontAwesomeIcon icon={faEye} />
          </Button>
        </td>
        {!hibrid && (
          <>
            <td>
              <Button variant={(billingDisabled == 1) ? 'danger' : 'success'} size="sm" onClick={() => handleToggleBilling(item.id)}>
                <FontAwesomeIcon icon={(billingDisabled == 1) ? faLock : faLockOpen} />
              </Button>
            </td>
            <td>
              {status != 2 ? (
                <DropdownButton
                  id={`dropdown-button-drop-bookkeeping-${item.id}`}
                  key={item.id}
                  size="sm"
                  variant={eshopBillingStatusColors[bookkeeping]}
                  title={bookkeeping}
                  onClick={(e) => {
                    e.preventDefault();
                    toggleDropdown(item.id);
                  }}
                >
                  <Dropdown.Item eventKey="1" onClick={() => handleChangeBookkeepingStatus(item.id, toLowerCase(eshopBillingStatusLabels[1]))}>{eshopBillingStatusLabels[1]}</Dropdown.Item>
                  <Dropdown.Item eventKey="0" onClick={() => handleChangeBookkeepingStatus(item.id, toLowerCase(eshopBillingStatusLabels[0]))}>{eshopBillingStatusLabels[0]}</Dropdown.Item>
                  <Dropdown.Item eventKey="-1" onClick={() => handleChangeBookkeepingStatus(item.id, toLowerCase(eshopBillingStatusLabels[-1]))}>{eshopBillingStatusLabels[-1]}</Dropdown.Item>
                </DropdownButton>
              ) :
                "-"
              }
            </td>
          </>
        )}
        <td>
          <Button
            variant="danger"
            size="sm"
            onClick={() => handleDeleteClick(item.id)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </td>
      </tr >
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Conferma eliminazione</Modal.Title>
        </Modal.Header>
        <Modal.Body>Sei sicuro di voler cancellare l'eshop?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Annulla
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Elimina
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EshopItemList;