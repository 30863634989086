import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Card, CardBody, CardTitle, CardTitleWrap } from '@/shared/components/Card';
import MetabaseQuestion from '../../UI/MetabaseQuestion';

const EshopAnalysis = ({ item }) => {

  const questionId = process.env.REACT_APP_ENVIRONMENT == 'production' ? 716 : 695;;

  return (
    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle className="d-block">
            Analisi
          </CardTitle>
        </CardTitleWrap>
        <MetabaseQuestion question_id={questionId} eshop={item.domain} bordered={false} titled={false} />
      </CardBody>
    </Card>
  )
}

export default EshopAnalysis
