import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';
import endpoints from '../endpoints/eshops';

export const insertNote = createAsyncThunk(
    'eshops/insertNote',
    async ({ data }) => {
        const response = await api.post('/v1/domains/insert-eshop-note', data);
        return response.data;
    }
);