import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { Card, CardBody, CardTitle, CardTitleWrap } from '@/shared/components/Card';
import { Tab, Nav } from 'react-bootstrap';
import GeneralData from "./eshopDataTabs/generalData";
import ShowcaseData from "./eshopDataTabs/showcaseData";
import ApiCmsData from "./eshopDataTabs/apiCmsData";
import PaymentMethodsData from "./eshopDataTabs/paymentMethodsData";
import LoginData from "./eshopDataTabs/loginData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faSignIn } from "@fortawesome/free-solid-svg-icons";
import { updateEshopData } from '../actions/updateEshopData';
import { successNotify, customWarningNotify } from '../../App/Router';

const EshopData = ({ item }) => {

  const [elem, setElem] = useState(item);
  const dispatch = useDispatch();

  const handleSave = () => {
    const dataToSend = { ...item };
    console.log(dataToSend);
    dispatch(updateEshopData({ id: item.id, data: dataToSend })).then(() => {
      successNotify();
    });
  }

  useEffect(() => {
    setElem(item);
  }, [item]);

  // useEffect(() => {
  //   console.log("elem", elem);
  // }, [elem]);

  return (
    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle className="d-block">
            Dati eshop
            <Button onClick={handleSave} size="sm" variant={'success'} className="float-end mx-2">
              <FontAwesomeIcon icon={faSave} className="me-2" /> Salva
            </Button>
            {item.status == 1 && (
              <Button
                as='a'
                href={`${process.env.REACT_APP_BUSINESS_URL}/areariservata/logEshop?ck=${item.id}:${item.autologinCode}`}
                target='_blank'
                variant={"primary"}
                size="sm"
                className="float-end mx-2 text-capitalize"
              >
                <FontAwesomeIcon icon={faSignIn} className="me-2" /> Accedi
              </Button>

            )}
          </CardTitle>
        </CardTitleWrap>
        <Form autoComplete="off">
          <Tab.Container defaultActiveKey="general_data">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="general_data">Dati generali</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="showcase_data">Vetrina</Nav.Link>
              </Nav.Item>
              {
                item.status == 1 && (
                  <>
                    <Nav.Item>
                      <Nav.Link eventKey="payment_methods">Metodi di pagamento</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="api_cms">Api / Cms</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="login_data">Dati di accesso</Nav.Link>
                    </Nav.Item>
                  </>
                )
              }
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="general_data" className="mt-3">
                <GeneralData elem={elem} setElem={setElem} />
              </Tab.Pane>
              <Tab.Pane eventKey="showcase_data" className="mt-3">
                <ShowcaseData elem={elem} setElem={setElem} />
              </Tab.Pane>
              <Tab.Pane eventKey="payment_methods" className="mt-3">
                <PaymentMethodsData elem={elem} setElem={setElem} />
              </Tab.Pane>
              <Tab.Pane eventKey="api_cms" className="mt-3">
                <ApiCmsData elem={elem} setElem={setElem} />
              </Tab.Pane>
              <Tab.Pane eventKey="login_data" className="mt-3">
                <LoginData elem={elem} setElem={setElem} />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Form>
      </CardBody>
    </Card>
  )
}

export default EshopData
