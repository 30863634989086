import React from 'react';
import { Route, Switch } from 'react-router-dom';

import HibridTable from '../../../Eshops/hibridList';
import HibridUpdate from '../../../Eshops/hibridUpdate';

export default () => (
  <Switch>
    <Route path="/hibrid-eshops/list" component={HibridTable} />
    <Route path="/hibrid-eshops/update/:id" component={HibridUpdate} />
  </Switch>
);
