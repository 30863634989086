import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';
import endpoints from '../endpoints/eshops';

export const updateNote = createAsyncThunk(
    'eshops/updateNote',
    async ({ id, data }) => {
        const response = await api.put('/v1/domains/update-eshop-note/' + id, data);
        return response.data;
    }
);