import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from '../../../Payments/list';
import Invoice from '../../../Payments/invoice';

export default () => (
    <Switch>
        <Route path="/payments/list" component={List} />
        <Route path="/payments/invoice/:id" component={Invoice} />
    </Switch>
);