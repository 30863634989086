import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import { Button } from '@/shared/components/Button';
import { Container, Col, Row, Carousel, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updateImageReview } from './actions/updateImageReview';

function formatDate(dateString) {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };

  const date = new Date(dateString);
  return date.toLocaleDateString('it-IT', options);
}

const ManageReviewMedia = ({ initialVariant, review_id, media_idx, media_obj, type, isProduct, ...otherProps }) => {
  const dispatch = useDispatch();
  const [variant, setVariant] = useState(initialVariant);
  const [isLoading, setIsLoading] = useState(false);
  console.log(media_obj, media_obj.status);
  if (media_obj.status === undefined) {
    media_obj.status = media_obj.stato;
  }

  const handleClick = async () => {
    setIsLoading(true);
    const resultAction = await dispatch(updateImageReview(
      {
        id: review_id,
        data: {
          media_idx: media_idx,
          status: (media_obj.status == 0) ? 1 : 0,
          action: 'validate',
          type: type,
          is_product: isProduct
        }
      }
    ));
    if (resultAction.payload.status === 200) {
      media_obj.status = resultAction.payload.new_status
      media_obj.pub_date = (resultAction.payload.new_status === 1) ? new Date().toISOString() : null;
      setVariant((media_obj.status == 0) ? 'danger' : 'success'); // replace 'success' with the variant you want
      setIsLoading(false);
    } else {
      // Show an error message

      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="spinner-border text-primary" role="status">
          </div>
        </div>
      )}
      <Row className='pb-5 pt-3 border-top'>
        {(media_obj.upload_date !== undefined) && (
          <>
            <Col xs={12} md={'auto'}>
              <strong>Data caricamento:</strong>
              <span>
                {formatDate(media_obj.upload_date)}
              </span>
            </Col>
          </>
        )}
        {(media_obj.last_mod !== undefined) && (
          <>
            <Col xs={12} md={'auto'}>
              <strong>Ultima modifica:</strong>
              <span>
                {formatDate(media_obj.last_mod)}
              </span>
            </Col>
          </>
        )}
        {(media_obj.pub_date !== undefined) && (
          <>
            <Col xs={12} md={'auto'}>
              <strong>Pubblicata il:</strong>
              <span>
                {formatDate(media_obj.pub_date)}
              </span>
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Col md={12} className="d-flex justify-content-center mb-3">
          <Button variant={variant} onClick={handleClick} {...otherProps}>
            {(variant === 'danger') ? "Not Visible" : "Visible"}
          </Button>
        </Col>
      </Row>
    </>
  );
};

const MediaCarousel = ({ medias, review_id }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const total_count = (medias.images !== undefined ? medias.images.length : 0) + (medias.videos !== undefined ? medias.videos.length : 0);
  return (
    <>
      <Carousel activeIndex={index} onSelect={handleSelect} data-bs-theme="dark">
        {medias.images !== undefined && (
          medias.images.map((image, idx) => (
            <Carousel.Item key={idx}>
              <Card data-bs-theme="light">
                <Card.Img
                  variant="top"
                  src={process.env.REACT_APP_ESA_CDN_URL + `${(medias.isProduct ? 'productReviewsGallery' : 'reviewsGallery')}/${review_id}/${image.name}`}
                  alt={`Slide ${idx}`}
                  onError={(e) => {
                    e.target.src = 'https://placehold.co/600x400/EEE/31343C'; // Replace with your placeholder image URL
                  }}
                  className='img-fluid mx-auto'
                  style={{ maxHeight: '500px', maxWidth: 'max-content' }}
                />
                <Card.Body>
                  <ManageReviewMedia initialVariant={(image.status == 0) ? 'danger' : 'success'} review_id={review_id} media_idx={idx} media_obj={image} isProduct={medias.isProduct} type={"image"} />
                </Card.Body>
              </Card>
            </Carousel.Item>
          ))
        )}
        {medias.videos !== undefined && (
          medias.videos.map((video, idx) => (
            <Carousel.Item key={idx}>
              <Card data-bs-theme="light">
                <Card.Body>
                  <VideoReview video_obj={video} />
                  <ManageReviewMedia initialVariant={(video.stato == 0) ? 'danger' : 'success'} review_id={review_id} media_idx={idx} media_obj={video} type={"video"} />
                </Card.Body>
              </Card>
            </Carousel.Item>
          ))
        )}
      </Carousel>
      <p className='text-center'><strong>Media {index + 1}</strong> di {total_count}</p>
    </>
  );
};

const MediaModal = ({ show, handleClose, title, review_data }) => (
  (review_data !== null) && (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MediaCarousel medias={review_data} review_id={review_data.id} />
      </Modal.Body>
    </Modal>
  )
);


const VideoReview = ({ video_obj, }) => {
  return (
    <Container className="video-review">
      <Row>
        <Col>
          <div className="video-review-container">
            <div className="video-review-preview d-flex justify-content-center">
              {video_obj.fromUrl === 'yt' ? (
                <iframe
                  src={`https://www.youtube.com/embed/${video_obj.videoId}`}
                  frameBorder="0"
                  height="500"
                  width="auto"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              ) : (
                <iframe
                  src={`https://player.vimeo.com/video/${video_obj.videoId}`}
                  frameBorder="0"
                  height="500"
                  width="auto"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default MediaModal;
