import { createSlice } from '@reduxjs/toolkit';
import { fetchReasons, addReason, deleteReason } from '../actions/reasons';

const initialState = {
    reasons: [],
    loading: false,
    error: null,
};

const reasonsSlice = createSlice({
    name: 'reasons',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Fetch reasons
            .addCase(fetchReasons.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchReasons.fulfilled, (state, action) => {
                state.loading = false;
                state.reasons = action.payload;
            })
            .addCase(fetchReasons.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            // Add reason
            .addCase(addReason.pending, (state) => {
                state.loading = true;
            })
            .addCase(addReason.fulfilled, (state, action) => {
                state.loading = false;
                state.reasons.push(action.payload);
            })
            .addCase(addReason.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            // Delete reason
            .addCase(deleteReason.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteReason.fulfilled, (state, action) => {
                state.loading = false;
                state.reasons = state.reasons.filter(reason => reason.id !== action.payload);
            })
            .addCase(deleteReason.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default reasonsSlice.reducer;
