import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import DownloadIcon from 'mdi-react/CloudDownloadOutlineIcon';
import EmailIcon from 'mdi-react/EmailOutlineIcon';

import renderDatePickerField from '@/shared/components/form/date-pickers/DatePicker';
import renderIntervalDatePickerField from '@/shared/components/form/date-pickers/IntervalDatePicker';
import Collapse from '@/shared/components/Collapse';

import {
    FormButtonToolbar,
    FormContainer,
    FormGroup,
    FormGroupField,
    FormGroupLabel,
    FormGroupIcon,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import {
    Col,
    Container,
    Row,
    Spinner,
} from 'react-bootstrap';
import {
    Card, CardBody, CardTitleWrap, CardTitle, CardSubhead
} from '@/shared/components/Card';
import DatepickerField from '@/containers/UI/DatepickerField';
import renderSelectField from '@/shared/components/form/Select';
import styled from 'styled-components';

const FilterForm = ({ onSubmit }) => (
    
    <Card>
            <CardBody>
                    
    < Form onSubmit={onSubmit} >
        {({ handleSubmit, form }) => (
            <FormContainer onSubmit={handleSubmit} vertical>
                
                            <Row>
                                <Col>
                        <FormGroup>
                            <FormGroupLabel>Nominativo</FormGroupLabel>
                            <FormGroupField>
                                <Field
                                    name="name"
                                    component="input"
                                    type="text"
                                    placeholder="Nome Cognome"
                                />
                            </FormGroupField>
                        </FormGroup>
                        </Col>
                        <Col>
                    
                        <FormGroup>
                            <FormGroupLabel>Email</FormGroupLabel>
                            <FormGroupField>
                                <Field
                                    name="email"
                                    component="input"
                                    type="email"
                                    placeholder="example@mail.com"
                                />
                            </FormGroupField>
                        </FormGroup>
                                </Col>
                                <Col md={3}>
                    
                            <FormGroup>
                                <FormGroupLabel>Data registrazione</FormGroupLabel>
                                <FormGroupField>
                                    <Field
                                        name="creation_date"
                                        component={renderIntervalDatePickerField}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </FormGroupField>
                            </FormGroup>
                                </Col>
                                <Col>
                    
                        <FormGroup>
                            <FormGroupLabel>Stato</FormGroupLabel>
                            <FormGroupField>
                                <Field
                                    name="status"
                                    component={renderSelectField}
                                    type="select"
                                    options={[
                                        { value: 'all', label: 'Tutti gli stati' },
                                        { value: '1', label: 'Solo abilitati' },
                                        { value: '0', label: 'Solo disabilitati' },
                                    ]}
                                />
                            </FormGroupField>
                        </FormGroup>
                                </Col>
                                <Col>
                    
                        <FormGroup>
                            <FormGroupLabel>Newsletter</FormGroupLabel>
                            <FormGroupField>
                                <Field
                                    name="newsletter"
                                    component={renderSelectField}
                                    options={[
                                        { value: 'all', label: 'Tutti gli stati' },
                                        { value: '1', label: 'Si' },
                                        { value: '0', label: 'No' },
                                    ]}
                                />
                            </FormGroupField>
                        </FormGroup>
                                </Col>
                                <Col>
                
                    

                        <FormGroup controlId="formButton">
                                    <FilterButton
                                        variant="outline-success"
                                        size='sm'
                                        onClick={handleSubmit}
                                    >
                                        <MagnifyIcon /><span>Filtra</span>
                                    </FilterButton>
                </FormGroup>
                                </Col>
                                
                </Row>
                        <Row>
                            <Col>

                                {/* <Button variant="outline-primary" size='sm'><EmailIcon /><span>Richiesta Optin</span></Button> */}

                                {/* <Button variant="outline-success" size='sm'><DownloadIcon /><span>Esporta XLS</span></Button>

                                <Button variant="outline-warning" size='sm'><DownloadIcon /><span>Esporta CSV</span></Button> */}
                            </Col>
                        </Row>
                
            </FormContainer>
        )}
    </Form >
                
            </CardBody>
        </Card>
           
        
   

    
    
    

);


export default FilterForm;

const FilterButton = styled(Button)`
  margin-top: calc(1.5em + .35rem);
`;