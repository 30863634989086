// actions/deleteBlockedEmail.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';

export const deleteBlockedEmail = createAsyncThunk(
    'blockedEmails/delete',
    async (emailId) => {
        const response = await api.delete(`/v1/blocked-emails/${emailId}`);
        return emailId; // Restituisce l'ID dell'email eliminata
    }
);
