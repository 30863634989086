// reducers.js

import { createSlice } from '@reduxjs/toolkit';
import { postB2bUserData } from '../actions/insertB2bUser';

const insertB2bUserSlice = createSlice({
    name: 'b2bUsers',
    initialState: { b2bUser: {}, status: 'idle', error: null },
    reducers: {
        // Potrebbe avere eventuali altri reducer qui
    },
    extraReducers: (builder) => {
        builder
            .addCase(postB2bUserData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(postB2bUserData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // Aggiungi l'utente al stato
                state.b2bUser = action.payload;
            })
            .addCase(postB2bUserData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export const { /* other action creators, if any */ } = insertB2bUserSlice.actions;

export default insertB2bUserSlice.reducer;
