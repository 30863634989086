import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';
import endpoint from '../endpoints/b2bUsers';

export const postB2bUserData = createAsyncThunk(
    'b2bUsers/postB2bUserData',
    async (userData) => {
        const response = await api.post(endpoint.insert, userData);
        return response.data;
    }
);