import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import DownloadIcon from 'mdi-react/CloudDownloadOutlineIcon';
import EmailIcon from 'mdi-react/EmailOutlineIcon';

import renderDatePickerField from '@/shared/components/form/date-pickers/DatePicker';
import renderIntervalDatePickerField from '@/shared/components/form/date-pickers/IntervalDatePicker';
import Collapse from '@/shared/components/Collapse';
//import i18next from 'i18next';

import {
    FormButtonToolbar,
    FormContainer,
    FormGroup,
    FormGroupField,
    FormGroupLabel,
    FormGroupIcon,
} from '@/shared/components/form/FormElements';

import { Button } from '@/shared/components/Button';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import {
    Col,
    Container,
    Row,
    Spinner,
} from 'react-bootstrap';
import {
    Card, CardBody, CardTitleWrap, CardTitle, CardSubhead
} from '@/shared/components/Card';
import DatepickerField from '@/containers/UI/DatepickerField';
import renderSelectField from '@/shared/components/form/Select';
import renderCheckBoxField from '@/shared/components/form/CheckBox';
import styled from 'styled-components';
import i18next from 'i18next';


const FilterForm = ({ onSubmit }) => (

    <Card>
        <CardBody>
            <Form onSubmit={onSubmit}>
                {({ handleSubmit, form }) => (
                    <FormContainer onSubmit={handleSubmit} className="filterForm">
                        <Row>
                            <Col>
                                <FormGroup>
                                    <FormGroupLabel>Eshop</FormGroupLabel>
                                    <FormGroupField>
                                        <Field
                                            name="domain"
                                            component="input"
                                            type="text"
                                            placeholder="Eshop"
                                        />
                                    </FormGroupField>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <FormGroupLabel>Nome destinatario</FormGroupLabel>
                                    <FormGroupField>
                                        <Field
                                            name="name"
                                            component="input"
                                            type="text"
                                            placeholder="Nome utente"
                                        />
                                    </FormGroupField>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <FormGroupLabel>Email destinatario</FormGroupLabel>
                                    <FormGroupField>
                                        <Field
                                            name="email"
                                            component="input"
                                            type="text"
                                            placeholder="email"
                                        />
                                    </FormGroupField>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <FormGroupLabel>Piattaforma</FormGroupLabel>
                                    <FormGroupField>
                                        <Field
                                            name="platform"
                                            component="input"
                                            type="text"
                                            placeholder="Piattaforma"
                                        />
                                    </FormGroupField>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <FormGroupLabel>Stato</FormGroupLabel>
                                    <FormGroupField>
                                        <Field
                                            name="status"
                                            component="select"
                                            options={[
                                                { }
                                            ]}
                                            placeholder="Stato"
                                        />
                                    </FormGroupField>
                                </FormGroup>
                            </Col>
                        </Row>
                    </FormContainer>
                )}
            </Form>
        </CardBody>
    </Card>
);

FilterForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default FilterForm;

const FilterButton = styled(Button)`
    margin-top: calc(1.5em + .35rem);
`;
