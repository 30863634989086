import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { Col } from 'react-bootstrap';
import renderSelectField from '@/shared/components/form/Select';
import PasswordField from '@/shared/components/form/Password';
import FormField from '@/shared/components/form/FormField';
import {
    Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import {
    FormButtonToolbar,
    FormContainer,
    FormGroup,
    FormGroupField,
    FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import validate from './validate';

const HorizontalForm = ({ onSubmit }) => {
    
    return (
        <Col md={12} lg={12} xl={3}>
            <Card>
                <CardBody>
                    <CardTitleWrap>
                        <CardTitle>Dati utente</CardTitle>
                        
                    </CardTitleWrap>
                    
                </CardBody>
            </Card>
        </Col>
    );
};

HorizontalForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default HorizontalForm;
