import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Card, CardBody, CardTitle, CardTitleWrap } from '@/shared/components/Card';
import MetabaseDashboard from '../UI/MetabaseDashboard';

const PaymentsTable = () => {

  const dashboardId = process.env.REACT_APP_ENVIRONMENT == 'production' ? 173 : 171;

  return (
    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle className="d-block">
            Pagamenti Piani
          </CardTitle>
        </CardTitleWrap>
        <MetabaseDashboard dashboard_id={dashboardId} bordered={false} titled={false} />
      </CardBody>
    </Card>
  )
}

export default PaymentsTable
