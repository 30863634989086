import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Card, CardBody } from '@/shared/components/Card';
import CouponsTable from '@/containers/Coupons/list';

const EshopCoupons = ({ esa_id }) => {

  return (
    <Card>
      <CardBody>
        {<CouponsTable esa_id={esa_id} />}
      </CardBody>
    </Card>
  )

}

export default EshopCoupons
