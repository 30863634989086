import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Card, CardBody, CardTitle, CardTitleWrap } from '@/shared/components/Card';
import MetabaseQuestion from '../UI/MetabaseQuestion';

const EshopAccessLogs = ({ item = false }) => {

  const questionId = process.env.REACT_APP_ENVIRONMENT == 'production' ? 746 : 746;
  const eshopId = item ? item.domain : false

  return (
    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle className="d-block">
            Log Accessi
          </CardTitle>
        </CardTitleWrap>
        <MetabaseQuestion question_id={questionId} eshop={eshopId} bordered={false} titled={false} />
      </CardBody>
    </Card>
  )
}

export default EshopAccessLogs
