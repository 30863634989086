import { createSlice } from '@reduxjs/toolkit';
import { getPaymentMethods } from '../actions/getPaymentMethods';

export const paymentMethodsSlice = createSlice({
    name: 'fetchPaymentMethods',
    initialState: {
        entities: [],
        loading: 'idle',
        pagination: {
            total_count: 0,
            page_count: 0,
            current_page: 0,
            per_page: 0,
            next_page_url: null,
            prev_page_url: null,
        }
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPaymentMethods.pending, (state) => {
                state.loading = 'loading';
            })
            .addCase(getPaymentMethods.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.entities = action.payload.items;
                state.pagination = action.payload.pagination;
            })
            .addCase(getPaymentMethods.rejected, (state) => {
                state.loading = 'idle';
            });
    }

});

export default paymentMethodsSlice.reducer;