import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from '../../../EshopBlocklist/list';
import AddRule from '../../../EshopBlocklist/addRule';
import UpdateRule from '../../../EshopBlocklist/updateRule';

export default () => (
  <Switch>
    <Route path="/blocked-eshops/list" component={List} />
    <Route path="/blocked-eshops/add-rule" component={AddRule} />    
    <Route path="/blocked-eshops/update-rule" component={UpdateRule} />
  </Switch>
);
