import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';

export const postData = createAsyncThunk(
    'blocekdEmails/postData',
    async (userData) => {
        const response = await api.post(`/v1/blocked-emails`, userData);
        console.log(response.data);
        return response.data;
    }
);