import { combineReducers } from 'redux';
import fetchReducer from './fetch';
import insertReducer from './insert';
//import detailReducer from './detail';

const eshopBlocklistReducer = combineReducers({
    fetch: fetchReducer,
    insert: insertReducer
    //detail: detailReducer,
});

export default eshopBlocklistReducer;
