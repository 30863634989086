import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';

export const fetchJson = createAsyncThunk(
    'properties/fetchJson',
    async (position) => {  // <-- Prendi l'argomento qui
        const response = await api.get(`/v1/properties/json/${position}`);  // <-- Usalo qui

        return response.data;
    }
);
