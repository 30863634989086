import React, { useEffect, useState } from 'react';
import { KJUR } from 'jsrsasign';
import { Container } from 'react-bootstrap';


const MetabaseDashboard = ({dashboard_id, bordered, titled, eshop = false}) => {
    const [iframeUrl, setIframeUrl] = useState('');

    useEffect(() => {
        const METABASE_SITE_URL = "https://metabase.esascraper.net";
        const METABASE_SECRET_KEY = "e8d3ad3e3e6e27330fc249be3549f5dbbc704721145ce1f4e01ad990338a1156";
        const eshopFilter = eshop ? `?eshop=${eshop}` : '';


        const nowSeconds = Math.floor(Date.now() / 1000); // Current time in seconds
        const expTime = nowSeconds + (10 * 60); // Expiration time: 10 minutes from now

        console.log(dashboard_id);
        const header = { alg: "HS256", typ: "JWT" };
        const payload = {
            resource: { dashboard: dashboard_id },
            params: {},
            // Adjusted to use the current time in seconds plus 10 minutes for expiration
            exp: expTime,
        };

        const token = KJUR.jws.JWS.sign(
            "HS256",
            JSON.stringify(header),
            JSON.stringify(payload),
            { utf8: METABASE_SECRET_KEY }
        );

        setIframeUrl(`${METABASE_SITE_URL}/embed/dashboard/${token}${eshopFilter}#theme=transparent&bordered=${bordered}&titled=${titled}`);
    }, []);

    return (
        <Container>
            {iframeUrl && <iframe src={iframeUrl} frameBorder="0" width="100%" height="800" allowTransparency="true"></iframe>}
        </Container>
    );
};

export default MetabaseDashboard;