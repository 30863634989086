import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';

export const postUserData = createAsyncThunk(
    'admins/postUserData',
    async (userData) => {
        const response = await api.post(`/v1/admins`, userData);
        console.log(response.data);
        return response.data;
    }
);