import { createSlice } from '@reduxjs/toolkit';
import { fetchCensoredWords } from '../actions/censoredWords';

const censoredWordsSlice = createSlice({
    name: 'censoredWords',
    initialState: {
        words: '',
        loading: 'idle',
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCensoredWords.pending, (state) => {
                state.loading = 'loading';
            })
            .addCase(fetchCensoredWords.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.words = action.payload;
            })
            .addCase(fetchCensoredWords.rejected, (state) => {
                state.loading = 'idle';
            });
    },
});

export default censoredWordsSlice.reducer;