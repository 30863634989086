import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faFileImage } from "@fortawesome/free-solid-svg-icons";

const sidebar_eshops = [
  {
    "name": "Dati eshop",
    "namestate": "eshop-data",
    "icon": <FontAwesomeIcon icon={faEdit} />
  },
  {
    "name": "Thumb",
    "namestate": "eshop-thumb",
    "icon": <FontAwesomeIcon icon={faFileImage} />
  },
];


export default sidebar_eshops;