import React, { useEffect, useState, useContext } from "react";
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Table, Modal, Form, Container, Row, Col } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Card, CardBody, CardTitle, CardTitleWrap } from '@/shared/components/Card';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faPencil, faPlus, faSave } from "@fortawesome/free-solid-svg-icons";
import { api } from '@/config/api';
import endpoint_eshop from '../endpoints/eshops';
import { insertPlan } from '../../Plans/actions/insertPlan';
import { updatePlan } from '../../Plans/actions/updatePlan';
import { successNotify, warningNotify } from '../../App/Router';
import {
  plansStatusColors,
  plansStatusLabels,
  plansMapLabels,
  plansPaymentIntervalLabel,
  bonusInvitationThreshold
} from "@/utils/utilities";
import { it } from 'date-fns/locale';

const EshopPlans = ({ item, changePage }) => {

  const [elem, setElem] = useState(item);
  const [updatedElem, setUpdatedElem] = useState({ ...item });
  const [show, setShow] = useState(false);
  const [idToUpdate, setIdToUpdate] = useState(false);
  const [indexToUpdate, setIndexToUpdate] = useState(false);
  const [typeOption, setTypeOption] = useState(false);
  const [toRefresh, setToRefresh] = useState(false);
  const dispatch = useDispatch();

  const handleShowInsert = () => {
    setTypeOption('insert')
    setIdToUpdate(false);
    setIndexToUpdate(false);
    handleShow();
  }

  const handleShowUpdate = (id, index) => {
    setTypeOption('update')
    setIdToUpdate(id);
    setIndexToUpdate(index);
    handleShow();
  }

  const handleInsert = () => {
    let dataToSend = { ...updatedElem.newPlan };
    dataToSend.eshop_id = elem.id;
    dataToSend.plan = dataToSend.plan || 'lite';
    dataToSend.payment_interval = dataToSend.payment_interval || 'mensile';
    dataToSend.status = dataToSend.status || '0';
    dataToSend.cost = dataToSend.cost || '0.00';
    dataToSend.expire_date = dataToSend.expire_date || null;
    dataToSend.bonus_invitations = dataToSend.bonus_invitations || 0;
    dataToSend.wire_transfer_enabled = dataToSend.wire_transfer_enabled || 0;
    dataToSend.no_iva = dataToSend.no_iva || 0;
    dispatch(insertPlan({ data: dataToSend })).then((response) => {
      if (response.error) {
        warningNotify(response.error);
      }
      else {
        setToRefresh(true);
        handleClose()
        successNotify();
      }
    });
  }

  const handleUpdate = () => {
    const dataToSend = { ...updatedElem.esaPlans[indexToUpdate] };
    dispatch(updatePlan({ data: dataToSend })).then((response) => {
      if (response.error) {
        warningNotify(response.error);
      }
      else {
        setToRefresh(true);
        handleClose()
        successNotify();
      }
    });
  }

  const handleClose = () => setShow(false);

  const handleShow = () => {
    setShow(true);
  };

  const refreshData = async () => {
    const response = await api.get(endpoint_eshop.getEshop.replace(':id', elem.id));
    setElem(response.data);
    setUpdatedElem(response.data);
  }

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    let val = type === 'checkbox' ? (checked ? 1 : 0) : value;
    const keys = name.split('[').map(key => key.replace(']', ''));
    setUpdatedElem((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      let currentPart = newState;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!currentPart[keys[i]]) {
          currentPart[keys[i]] = {};
        }
        currentPart = currentPart[keys[i]];
      }
      currentPart[keys[keys.length - 1]] = val;
      return newState;
    });
  };

  function handleDateChange(index, date) {
    const selectedDate = new Date(date);
    const formattedDate = selectedDate.toISOString().split('T')[0];
    const newP = (indexToUpdate !== false) ? updatedElem.esaPlans : updatedElem.newPlan || {};
    let newEsaPlan = JSON.parse(JSON.stringify(newP));
    (indexToUpdate !== false) ? newEsaPlan[index].expire_date = formattedDate : newEsaPlan.expire_date = formattedDate;
    setUpdatedElem((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      (indexToUpdate !== false) ? newState.esaPlans = newEsaPlan : newState.newPlan = newEsaPlan;
      return newState;
    });
  }

  useEffect(() => {
    if (toRefresh) {
      setToRefresh(false);
      refreshData();
    }
    else {
      setElem(item);
    }
  }, [item, toRefresh]);

  return (
    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle className="d-block">
            Piani
            <Button onClick={handleShowInsert} size="sm" variant={'primary'} className="float-end ms-2">
              <FontAwesomeIcon icon={faPlus} className="me-2" /> Inserisci nuovo
            </Button>
            <Button onClick={() => { changePage('eshop-notes') }} size="sm" variant={'secondary'} className="float-end me-2">
              <FontAwesomeIcon icon={faBook} className="me-2" /> Vedi note
            </Button>
          </CardTitle>
        </CardTitleWrap>
        <Table striped bordered>
          <thead>
            <tr>
              <th>Data richiesta</th>
              <th>Piano</th>
              <th>Cadenza</th>
              <th>Costo</th>
              <th>Data attivazione</th>
              <th>Stato</th>
              <th>Data disattivazione</th>
              <th>Pagamento</th>
            </tr>
          </thead>
          <tbody>
            {elem.esaPlans?.length && elem.esaPlans.map((item, index) => (
              <tr key={index}>
                <td>{item.request_date}</td>
                <td>
                  <Link to="#" onClick={() => { handleShowUpdate(item.id, index) }}>
                    {item.plan}
                  </Link>
                </td>
                <td>{item.payment_interval}</td>
                <td>{item.cost}</td>
                <td>{item.activation_date || '-'}</td>
                <td>
                  <Button size="sm" variant={plansStatusColors[item.status]}>
                    {plansStatusLabels[item.status]}
                  </Button>
                </td>
                <td>{item.expire_date || '-'}</td>
                <td>{item.paymentMethod || '-'}</td>
              </tr>
            )) ||
              <tr>
                <td colSpan={8}>Nessun piano presente</td>
              </tr>
            }
          </tbody>
        </Table>
        <Modal show={show} onHide={handleClose} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>{typeOption == 'insert' ? 'Inserisci' : 'Modifica'} piano</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form autoComplete="off">
              <Container>
                <Row>
                  <Col xs={12} lg={4} className='my-3'>
                    <Form.Group controlId="plan">
                      <Form.Label>Piano</Form.Label>
                      <Form.Control
                        name={(indexToUpdate !== false) ? `esaPlans[${indexToUpdate}][plan]` : 'newPlan[plan]'}
                        value={(indexToUpdate !== false) ? updatedElem.esaPlans[indexToUpdate]?.plan : updatedElem.newPlan?.plan}
                        as="select"
                        onChange={handleChange}
                      >
                        {
                          Object.keys(plansMapLabels).map((key) => {
                            return <option key={key} value={key}>{plansMapLabels[key]}</option>
                          })
                        }
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={4} className='my-3'>
                    <Form.Group controlId="payment_interval">
                      <Form.Label>Cadenza</Form.Label>
                      <Form.Control
                        name={(indexToUpdate !== false) ? `esaPlans[${indexToUpdate}][payment_interval]` : 'newPlan[payment_interval]'}
                        value={(indexToUpdate !== false) ? updatedElem.esaPlans[indexToUpdate]?.payment_interval : updatedElem.newPlan?.payment_interval}
                        as="select"
                        onChange={handleChange}
                      >
                        {
                          Object.keys(plansPaymentIntervalLabel).map((key) => {
                            return <option key={key} value={key}>{plansPaymentIntervalLabel[key]}</option>
                          })
                        }
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={4} className='my-3'>
                    <Form.Group controlId="status">
                      <Form.Label>Stato</Form.Label>
                      <Form.Control
                        name={(indexToUpdate !== false) ? `esaPlans[${indexToUpdate}][status]` : 'newPlan[status]'}
                        value={(indexToUpdate !== false) ? updatedElem.esaPlans[indexToUpdate]?.status : updatedElem.newPlan?.status}
                        as="select"
                        onChange={handleChange}
                      >
                        {
                          Object.keys(plansStatusLabels).map((key) => {
                            return <option key={key} value={key}>{plansStatusLabels[key]}</option>
                          })
                        }
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={4} className='my-3'>
                    <Form.Group controlId={`cost`}>
                      <Form.Label>Costo</Form.Label>
                      <Form.Control
                        name={(indexToUpdate !== false) ? `esaPlans[${indexToUpdate}][cost]` : 'newPlan[cost]'}
                        value={(indexToUpdate !== false) ? updatedElem.esaPlans[indexToUpdate]?.cost : updatedElem.newPlan?.cost}
                        type="text"
                        onChange={handleChange}
                        placeholder="Inserisci costo (es: 0.00)"
                      />
                      <p className="text-danger"><b>Attenzione! Indicare il prezzo da pagare al checkout</b></p>
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={4} className='my-3'>
                    <Form.Group controlId="expire_date">
                      <Form.Label className="d-block">Data disattivazione</Form.Label>
                      <DatePicker
                        selected={
                          (indexToUpdate !== false)
                            ? updatedElem.esaPlans[indexToUpdate]?.expire_date ? new Date(updatedElem.esaPlans[indexToUpdate]?.expire_date) : null
                            : updatedElem.newPlan?.expire_date ? new Date(updatedElem.newPlan?.expire_date) : null
                        }
                        onChange={(date) => handleDateChange(indexToUpdate, date)}
                        locale={it}
                        className="form-control"
                        minDate={new Date()}
                        placeholderText="Seleziona data"
                        wrapperClassName="d-block"
                        dateFormat="dd/MM/yyyy"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} lg={4} className='my-3'>
                    <Form.Group controlId="bonus_invitations">
                      <Form.Label> Soglia inviti</Form.Label>
                      <Form.Control
                        name={(indexToUpdate !== false) ? `esaPlans[${indexToUpdate}][bonus_invitations]` : 'newPlan[bonus_invitations]'}
                        value={(indexToUpdate !== false) ? updatedElem.esaPlans[indexToUpdate]?.bonus_invitations : updatedElem.newPlan?.bonus_invitations}
                        as="select"
                        onChange={handleChange}
                      >
                        {
                          Object.keys(bonusInvitationThreshold).map((key) => {
                            return <option key={key} value={key}>{bonusInvitationThreshold[key]}</option>
                          })
                        }
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} lg={3} className='my-3'>
                    <Form.Group controlId="wire_transfer_enabled">
                      <Form.Check
                        name={(indexToUpdate !== false) ? `esaPlans[${indexToUpdate}][wire_transfer_enabled]` : 'newPlan[wire_transfer_enabled]'}
                        checked={(indexToUpdate !== false) ? updatedElem.esaPlans[indexToUpdate]?.wire_transfer_enabled == 1 : updatedElem.newPlan?.wire_transfer_enabled == 1}
                        type="checkbox"
                        label={'Bonifico abilitato'}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={3} className='my-3'>
                    <Form.Group controlId="no_iva">
                      <Form.Check
                        name={(indexToUpdate !== false) ? `esaPlans[${indexToUpdate}][no_iva]` : 'newPlan[no_iva]'}
                        checked={(indexToUpdate !== false) ? updatedElem.esaPlans[indexToUpdate]?.no_iva == 1 : updatedElem.newPlan?.no_iva == 1}
                        type="checkbox"
                        label={'Esente iva'}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className='my-3 text-end'>
                    <Button onClick={() => (indexToUpdate !== false) ? handleUpdate() : handleInsert()} size="sm" variant={'success'}>
                      <FontAwesomeIcon icon={faSave} className="me-2" /> Salva
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Form>
          </Modal.Body>
        </Modal>
      </CardBody>
    </Card>
  )
}

export default EshopPlans
