import React from "react";
import { Button, Table } from 'react-bootstrap';
import { Card, CardBody, CardTitle, CardTitleWrap } from '@/shared/components/Card';
import { eshopBillingStatusColors } from '@/utils/utilities';

const EshopBookkeepingHistory = ({ item }) => {

  const bookkeepingHistory = item.bookkeepingHistory || [];

  return (
    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle className="d-block">
            Storico cambio di stato contabilità
          </CardTitle>
        </CardTitleWrap>
        <Table striped bordered>
          <thead>
            <tr>
              <th>Data cambio stato contabilità</th>
              <th>Stato contabilità</th>
              <th>Piano</th>
            </tr>
          </thead>
          <tbody>
            {bookkeepingHistory.length && bookkeepingHistory.map((item, index) => (
              <tr key={index}>
                <td>{item.item.date}</td>
                <td>
                  <Button size="sm" variant={eshopBillingStatusColors[item.item.status]} className="me-2">
                    {item.item.status}
                  </Button>
                </td>
                <td>{item.plan_info?.plan + ' attivato il ' + item.plan_info?.activation_date}</td>
              </tr>
            )) ||
              <tr>
                <td colSpan={3}>Nessuna dato presente</td>
              </tr>
            }
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
}

export default EshopBookkeepingHistory
