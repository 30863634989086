import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import AddUserIcon from 'mdi-react/AccountPlusOutlineIcon';
import {
    Col,
    Container,
    Row,
    Spinner,
    
    Button
} from 'react-bootstrap';
import {
    Card, CardBody, CardTitleWrap, CardTitle, CardSubhead
} from '@/shared/components/Card';
import { Table } from '@/shared/components/TableElements';
import styled from 'styled-components';
import DeleteForeverIcon from 'mdi-react/DeleteForeverIcon';
import { Modal, Form } from 'react-bootstrap';
import { fetchReasons, deleteReason, addReason } from './actions/reasons';

const NovalidationReasons = ({ onSubmit }) => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [reasonIdToDelete, setReasonIdToDelete] = useState(null);

    const [reasonTitle, setReasonTitle] = useState('');
    const [reasonText, setReasonText] = useState('');

    const { reasons, loading } = useSelector((state) => state.reviews.reasons);

    useEffect(() => {
        dispatch(fetchReasons());
        console.log("reasons", reasons);
    }, [dispatch]);

    const handleDeleteClick = (id) => {
        setReasonIdToDelete(id);
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    const handleConfirmDelete = () => {
        dispatch(deleteReason(reasonIdToDelete));
        handleClose();
    };

    const handleAddMotivation = () => {
        
        const reasonData = { title: reasonTitle, reason: reasonText };
        console.log("Salvataggio della nuova motivazione...", reasonData);

        dispatch(addReason(reasonData));
        setReasonTitle('');
        setReasonText('');
        setShowAddModal(false);

    };

    return (
        <Container>
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Motivazioni non validazione</h3>
                </Col>
            </Row>

            <Row>
                <Col md={12} lg={12} xl={12}>
                    <Card>
                        <CardBody>
                            <CardTitleWrap>
                            <Row>
                                
                                <Col md={12} style={{ textAlign: 'right' }}>
                                        <Button variant="outline-primary" size='sm' onClick={() => setShowAddModal(true)}>
                                            <span>Aggiungi Motivazione</span>
                                        </Button>
                                </Col>
                            </Row>
                        </CardTitleWrap>
                            <Table responsive striped bordered>
                                <thead>
                                    <tr>
                                        
                                        <th>Motivazione</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reasons.map((reason) => (
                                        <tr key={reason.id}>

                                            <td className="first-letter-uppercase">{reason.title}</td>
                                            <td className="first-letter-uppercase">{reason.reason}</td>
                                        <td>
                                            <FilterButton
                                                variant="danger"
                                                size='sm'
                                                onClick={() => handleDeleteClick(reason.id)}
                                            >
                                                <DeleteForeverIcon />
                                            </FilterButton>

                                        </td>
                                    </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <Modal show={showModal} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Conferma eliminazione</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>Sei sicuro di voler cancellare la motivazione?</Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Annulla
                                    </Button>
                                    <Button variant="danger" onClick={handleConfirmDelete}>
                                        Elimina
                                    </Button>
                                </Modal.Footer>
                            </Modal> 

                            <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Aggiungi una Nuova Motivazione</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Titolo</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={reasonTitle}
                                                onChange={(e) => setReasonTitle(e.target.value)}
                                            />
                                            
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Motivazione</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={reasonText}
                                                onChange={(e) => setReasonText(e.target.value)}
                                            />

                                        </Form.Group>
                                        <Button variant="primary" onClick={handleAddMotivation}>
                                            Salva
                                        </Button>
                                    </Form>
                                </Modal.Body>
                            </Modal>

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default NovalidationReasons;

const FilterButton = styled(Button)`
  margin-top: calc(1.5em);
`;