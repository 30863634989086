import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';
import endpoint from '../endpoints/payments';

export const postInvoice = createAsyncThunk(
    'payments/postInvoice',
    async ({id,data}) => {
        const response = await api.post(endpoint.handleInvoice+'/'+id, data);
        return response.data;
    }
);