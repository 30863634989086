import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import showResults from '@/utils/showResults';

import HorizontalFormTwo from './forms/add/anagrafica';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { postData } from './actions/insert';
import { data } from 'jquery';
import { useHistory } from 'react-router-dom';

const FormValidation = () => {
    //const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSubmit = (formData) => {
        const dataToSend = { ...formData }; // crea una copia
        
        console.log("invio"); // mostra i dati del form senza confirm_password
        dispatch(postData(dataToSend)) // invoca l'action
            .then(() => {
                history.push('/blocked-email/list'); // reindirizza alla lista utenti dopo l'invio dei dati
            });
        console.log("invio dati");
    };

    return (
        <Container>
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Aggiungi dominio</h3>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/blocked-email/list" }}>
                            Torna all'elenco dei domini
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                
                <HorizontalFormTwo onSubmit={handleSubmit} />
               
            </Row>
        </Container>
    );
};

export default FormValidation;
