import { combineReducers } from 'redux';
import fetchReducer from './fetch';
import insertReducer from './insert';
import catalogoPianiReducer from './catalogoPiani';
import insertCatalogoItemReducer from './insertCatalogoItem';
import updateCatalogo from './updateCatalogo';
import listPromoReducer from './listPromo';
//import detailReducer from './detail';

const plansReducer = combineReducers({
    fetch: fetchReducer,
    insert: insertReducer,
    catalogoPiani: catalogoPianiReducer,
    insertCatalogoItem: insertCatalogoItemReducer,
    updateCatalogo: updateCatalogo,
    listPromo: listPromoReducer,
    //detail: detailReducer,
});

export default plansReducer;
