import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';

// Fetch reasons
export const fetchReasons = createAsyncThunk(
    'reviews/fetchReasons',
    async () => {
        const response = await api.get(`/v1/reviews/novalidations-reason`);
        return response.data;
    }
);

// Add a reason
export const addReason = createAsyncThunk(
    'reviews/addReason',
    async (reason) => {
        const response = await api.post(`/v1/reviews/novalidations-reason`, reason);
        return response.data;
    }
);

// Delete a reason
export const deleteReason = createAsyncThunk(
    'reviews/deleteReason',
    async (id) => {
        await api.delete(`/v1/reviews/novalidations-reason/${id}`);
        return id;
    }
);
