import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Container, Row, Col, Table } from 'react-bootstrap';
import { Card, CardBody, CardTitle, CardTitleWrap } from '@/shared/components/Card';
import { getEshopReviewsApi } from "../actions/getEshopReviewsApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment';
import { faLink } from "@fortawesome/free-solid-svg-icons";

const EshopReviewsApi = ({ item }) => {

  const dispatch = useDispatch();
  const [elem, setElem] = useState(item);
  const [info, setInfo] = useState([]);
  const structured_data = elem.eshopData?.structured_data;
  const autoReplies = structured_data?.auto_replies;

  function formatDate(dateString) {
    return moment(dateString).format('DD/MM/YYYY')
  }

  async function getInfo() {
    const infoData = await dispatch(getEshopReviewsApi({ esa_id: elem.id }));
    setInfo(infoData.payload);
  }

  useEffect(() => {
    setElem(item);
    getInfo();
  }, [item]);

  return (
    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle className="d-block">
            Funzionalità Eshop + Api
          </CardTitle>
        </CardTitleWrap>
        <Container>
          <Row>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Fonte</th>
                  <th>Stato</th>
                  <th>Data attivazione</th>
                  <th>Prima importazione</th>
                  <th>Totale</th>
                  <th>Pagina</th>
                  <th>Note</th>
                </tr>
              </thead>
              <tbody>
                {info && info.length > 0 ? info.map((item, index) => {
                  return (
                    <tr>
                      <td>{item.source}</td>
                      <td>
                        <Button variant={item.status == 1 ? 'success' : 'warning'} size="sm">
                          {item.status == 1 ? 'Attivo' : 'Disattivo'}
                        </Button>
                      </td>
                      <td> {item.activation_date ? formatDate(item.activation_date) : '-'} </td>
                      <td> {item.first_import ? formatDate(item.first_import) : '-'} </td>
                      <td> {item.total ? item.total : '-'} </td>
                      <td> {item.page ? <a href={item.page} target='_blank'> <Button variant="info" size="sm"> <FontAwesomeIcon icon={faLink} /> </Button> </a> : '-'} </td>
                      <td> {item.note ? item.note : '-'} </td>
                    </tr>
                  )
                }) : (
                  <tr>
                    <td colspan='7'> Nessun dato </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <span className="me-2">
                <b>Risposte automatiche:</b>
              </span>
              <Button variant={autoReplies == 1 ? 'success' : 'warning'} size="sm">
                {autoReplies == 1 ? 'Abilitate' : 'Disabilitate'}
              </Button>
            </Col>
            <Col xs={12} md={6}>
              <p>
                <span className="me-2">
                  <b>Metodo import:</b>
                </span>
                {
                  // TODO: Add method import
                }
                -
              </p>
              <p>
                <span className="me-2">
                  <b>Numero prodotti:</b>
                </span>
                {
                  // TODO: Add pruduct count
                }
                -
              </p>
            </Col>
          </Row>
        </Container>
      </CardBody>
    </Card >
  )
}

export default EshopReviewsApi
