import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import Badge from '@/shared/components/Badge';
import { statusLabels, statusColors } from '@/utils/utilities';

function ReviewReplyModal({ show, handleClose, elem, handleSave }) {

  const [item, setItem] = useState(elem);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setItem(prevState => ({
      ...prevState,
      reply: {
        ...prevState.reply,
        [name]: value
      }
    }));
  };

  const handleReviewSave = () => {
    handleSave(item);
  };

  useEffect(() => {
    if (elem) {
      setItem(elem);
    }
  }, [elem]);

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <Button variant="success" onClick={handleReviewSave}>Salva</Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {item && (
              <>
                <div className="info-section">
                  <Row className='text-center'>
                    <Col xs={7} md={6}>
                      <span className="fs-6">
                        <strong>Eshop:</strong> {item.eshop.domain}
                        <Badge bg="success">{item.eshop.plan}</Badge>
                      </span>
                    </Col>
                    <Col xs={5} md={6}>
                      <span className="fs-6">
                        <strong className='me-3'>stato:</strong>
                        <Badge bg={statusColors[elem.review.status] || "secondary"}>{statusLabels[elem.review.status] || "Stato sconosciuto"}</Badge>
                      </span>
                    </Col>
                  </Row>
                  <Row className='text-center mb-2 pb-2 border-bottom'>
                    <Col xs={6} md={6}>
                      <span className="fs-6">
                        <strong>Utente:</strong> {item.user.name}
                      </span>
                    </Col>
                    <Col xs={6} md={6}>
                      <span className="fs-6">
                        <strong>Voto:</strong> {item.review.overall_rating}
                      </span>
                    </Col>
                  </Row>
                </div>
                <Row className="mb-3">
                  <span className='d-block border-bottom pb-1'>
                    <strong>
                      Titolo
                    </strong>
                  </span>
                  <span className='d-block'>
                    {item.review.title}
                  </span>
                </Row>
                <Row className="mb-3">
                  <span className='d-block border-bottom pb-1'>
                    <strong>
                      Descrizione
                    </strong>
                  </span>
                  <span className='d-block'>
                    {item.review.description}
                  </span>
                </Row>
                <Form.Group className="mb-3" controlId="replyContent">
                  <Form.Label>Testo risposta *</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="content"
                    value={item.reply.content}
                    onChange={handleChange}
                  />
                </Form.Group>
              </>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReviewReplyModal;
