import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import FilterForm from './components/filters/filter';
import { useState } from 'react';
import { Col, Container, Row, Spinner, } from 'react-bootstrap';
import { Card, CardBody, CardTitleWrap } from '@/shared/components/Card';
import { Table } from '@/shared/components/TableElements';
import styled from 'styled-components';
import { cleanDate } from '@/shared/helpers';
import { colorAccent } from '@/utils/palette';
import { fetchValidateReviews } from './actions/fetchValidateReviews';
import ReviewValidationItem from './components/ReviewValidationItem';
import PaginationTable from '@/containers/Components/PaginationTable';
import { fetchReasons } from './actions/reasons';

const ReviewsTable = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const reviewsState = useSelector(state => state.reviews.fetch || {});
  const reviews = reviewsState.entities;
  const TableSpinner = styled(Spinner)`color: ${colorAccent};`;
  const loading = reviewsState.loading;
  const pagination = reviewsState.pagination;
  const [globalFilters, setGlobalFilters] = useState({ 'per-page': 25, 'page': 1, 'status': 0 });
  const actionBaseUrl = '/v1/reviews';
  const reasons = useSelector(state => state.reviews.reasons);
  const [toRefresh, setToRefresh] = useState(false);

  const handleFilterSubmit = (values) => {
    let modifiedValues = { ...values };

    delete modifiedValues.creation_date_start;
    delete modifiedValues.creation_date_end;
    modifiedValues.creation_date = {
      start: values.creation_date_start ? cleanDate(values.creation_date_start) : null,
      end: values.creation_date_end ? cleanDate(values.creation_date_end) : null,
    }
    if (values.status) {
      modifiedValues.status = values.status.value;
    }
    if (values.newsletter) {
      modifiedValues.newsletter = values.newsletter.value;
    }
    modifiedValues['per-page'] = 25;
    modifiedValues.status = 0;
    setGlobalFilters(modifiedValues);
    const payload = {
      baseUrl: actionBaseUrl,
      params: modifiedValues
    };
    dispatch(fetchValidateReviews(payload));
  };

  useEffect(() => {
    if (toRefresh) {
      setToRefresh(false);
    }
    const initialPayload = {
      baseUrl: actionBaseUrl,
      params: globalFilters
    };

    dispatch(fetchValidateReviews(initialPayload));
  }, [dispatch, toRefresh]);

  useEffect(() => {
    dispatch(fetchReasons());
  }, []);

  const rowsToShow = reviews;

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Elenco recensioni da validare</h3>
        </Col>
      </Row>
      <FilterForm onSubmit={handleFilterSubmit} onlyValidation={true} />
      <Row>
        <Col xs={12} className='p-0 p-lg-3'>
          <Card>
            <CardBody>
              <CardTitleWrap>
                <Row>
                  <Col xs={12}>
                    <p className="page-subtitle">Numero recensioni: {pagination.total_count}</p>
                  </Col>
                </Row>
              </CardTitleWrap>
              {loading === 'loading' && (
                <div className="text-center">
                  <TableSpinner animation="border" />
                </div>
              )}
              {rowsToShow && loading === 'idle' && rowsToShow.length > 0 && (
                <>
                  <Table striped bordered>
                    <tbody>
                      {reviews.map((elem, index) => {
                        return (
                          elem.eshop
                            ? <ReviewValidationItem item={elem} reasons={reasons} toRefresh={setToRefresh} />
                            : (console.log('NO ESHOP: ' + JSON.stringify(elem)))
                        );
                      })}
                    </tbody>
                  </Table>
                  <PaginationTable pagination={pagination} filters={globalFilters} action={fetchValidateReviews} />
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

ReviewsTable.propTypes = {
  onSubmit: PropTypes.func,
};

export default ReviewsTable;