import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';

export const deletePaymentMethod = createAsyncThunk(
    'domains/deletePaymentMethod',
    async ({ id }) => {
        const actionUrl = '/v1/domains/delete-payment-method/' + id;
        const url = actionUrl;
        const response = await api.delete(url);
        return response.data;
    }
);