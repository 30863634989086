import { createSlice } from '@reduxjs/toolkit';
import { fetchPayments } from '../actions/fetch';

export const paymentsSlice = createSlice({
    name : 'fetchPayments',
    initialState : {
        entities : [],
        loading : 'idle',
        pagination : {
            total_count : 0,
            page_count : 0,
            current_page : 0,
            per_page : 0,
            next_page_url : null,
            prev_page_url : null,
        }
    },
    reducers : {},
    extraReducers : (builder) => {
        builder
            .addCase(fetchPayments.pending, (state) => {
                state.loading = 'loading';
            })
            .addCase(fetchPayments.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.entities = action.payload.items;
                state.pagination = action.payload.pagination;
            })
            .addCase(fetchPayments.rejected, (state) => {
                state.loading = 'idle';
            });
    }
});

export default paymentsSlice.reducer;