import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';

export const fetchEshops = createAsyncThunk(
    'eshops/fetchEshops',
    async (payload = { baseUrl: false, params: { 'per-page': 25, page: 1 } }) => {
        let url;
        if (typeof payload === 'string') {
            url = payload;
        }
        else {
            const { baseUrl, params } = payload;
            const actionUrl = baseUrl ? baseUrl : '/v1/registered-eshops/admin-list';

            const queryString = Object.entries(params).map(([key, val]) => {
                if (typeof val === 'object' && val !== null) {
                    return `${encodeURIComponent(key)}_start=${encodeURIComponent(val.start)}&${encodeURIComponent(key)}_end=${encodeURIComponent(val.end)}`;
                }
                else {
                    return `${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
                }
            }).join('&');
            url = `${actionUrl}?${queryString}`;
        }

        console.log(url);
        const response = await api.get(url);
        return response.data;
    }

);