import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Col, Row } from 'react-bootstrap';
import { Card, CardBody } from '@/shared/components/Card';
import { Button } from '@/shared/components/Button';
import {
    FormButtonToolbar,
    FormContainer,
    FormGroup,
    FormGroupField,
    FormGroupLabel,
    FormGroupIcon,
} from '@/shared/components/form/FormElements';
import renderSelectField from '@/shared/components/form/Select';
import renderCheckBoxField from '@/shared/components/form/CheckBox';
import styled from 'styled-components';

import '../../../Eshops/css/filterEshopList.css';

const Filter = ({onSubmit}) => {


    return (
        <Card>
            <CardBody>
                <Form onSubmit={onSubmit}>
                    {({ handleSubmit, form }) => (
                        <FormContainer onSubmit={handleSubmit} className="filterForm">
                            <Row className='align-items-center'>
                                <Col>
                                    <FormGroup>
                                        <FormGroupLabel>Nome</FormGroupLabel>
                                        <FormGroupField>
                                            <Field
                                                name="name"
                                                component="input"
                                                type="text"
                                                placeholder="Nome"
                                            />
                                        </FormGroupField>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <FormGroupLabel>Email</FormGroupLabel>
                                        <FormGroupField>
                                            <Field
                                                name="email"
                                                component="input"
                                                type="text"
                                                placeholder="Email"
                                            />
                                        </FormGroupField>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <FormGroupLabel>Stato account</FormGroupLabel>
                                        <FormGroupField>                                                
                                            <Field
                                                name="active"
                                                component={renderSelectField}
                                                type='select'
                                                options={[
                                                    {value : 'all', label : 'Tutti gli stati'},
                                                    {value : -1, label : 'In attesa di optin'},
                                                    {value : 1, label : 'Attivi'},
                                                    {value : 2, label : 'Disattivati'},
                                                ]} 
                                                value={{value : 'all', label : 'Tutti gli stati'}}
                                            />
                                        </FormGroupField>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FilterButton type="submit" variant="primary">
                                        Filtra
                                    </FilterButton>
                                </Col>
                            </Row>
                        </FormContainer>
                    )}
                </Form>
            </CardBody>
        </Card>

            
    );
}

Filter.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

const FilterButton = styled(Button)`
    margin-top: calc(1.5em + .35rem);
`;

export default Filter;