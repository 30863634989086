import React, { useState, useEffect } from 'react';
import GeneralForm from './GeneralForm';
import PreviewForm from './PreviewForm';

import { useDispatch, useSelector } from 'react-redux';

function RightPanel({ content, id }) {

    const dispatch = useDispatch();

    const onSubmit = (values) => {
        console.log('Dati inviati:', values);
        // Qui puoi gestire la logica di invio del tuo form, ad es. effettuare una chiamata API
    };

    const productDetails = useSelector(state => state.esastore.productDetails.details);
    return (
        
        <>
            {content === 'generalForm' && <GeneralForm />}
            {content === 'previewForm' && <PreviewForm data={productDetails} />}
        </>
        
    );
}


export default RightPanel;
