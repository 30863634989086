import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';

export const fetchBlochedEmails = createAsyncThunk(
    'blockedEmails/fetch',
    async (payload) => {
        const baseUrl = typeof payload === 'string' ? payload : payload.baseUrl;
        console.log("Fetching from URL:", baseUrl);
        try {
            const response = await api.get(baseUrl);
            console.log("Data received:", response.data);
            return response.data;
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
);



