import { combineReducers } from 'redux';
import fetchJson from './fetchJson';
import updateJson from './updateJson';
import censoredWords from './censoredWords';
import settings from './settings';

const propertiesReducer = combineReducers({
    fetchJson: fetchJson,
    updateJson: updateJson,
    censoredWords: censoredWords,
    settings: settings
    
});

export default propertiesReducer;