import { combineReducers } from 'redux';
import fetchReducer from './fetch';
// import deleteReducer from './delete';
// import addReducer from './add';

const blockedEmailsReducer = combineReducers({
    fetch: fetchReducer,
    // delete: deleteReducer,
    // addReducer: addReducer,
});

export default blockedEmailsReducer;
