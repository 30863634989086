import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';
import endpoints from '../endpoints/eshops';

export const updateEshopBillingInfo = createAsyncThunk(
    'eshops/updateEshopBillingInfo',
    async ({ id, data }) => {
        const response = await api.put('/v1/domains/update-eshop-billing-info/' + id, data);
        return response.data;
    }
);