import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';
import endpoints from '../endpoints/eshops';

export const deleteEshopLogo = createAsyncThunk(
    'eshops/deleteEshopLogo',
    async ({ esa_id }) => {
        const response = await api.delete('/v1/domains/delete-eshop-logo/' + esa_id);
        return response.data;
    }
);